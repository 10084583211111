import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { DevTool } from "@hookform/devtools";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import type { LinkToSync } from "../features/add-members/addMembersTypes";
import {
  ADFieldLabels,
  ButtonPropsToSync,
  defaultSyncADConfigs,
  MemberButtonADFieldLabelsMap,
  MemberFieldLabelsMap,
  MemberTextADFieldLabelsMap,
  SyncADConfigsFormData,
} from "../features/integrations";
import { LinkTypeCode } from "../features/links/linksEnums";
import theme, { FormControlSyncFields } from "../styles/mui-styled";
import DialogX from "./DialogX";
import SelectLinkDialog from "./SelectLinkDialog";

type SyncADConfigsDialogProps = DialogProps & {
  links?: LinkToSync[];
  data?: SyncADConfigsFormData;
  onSave?: (data: SyncADConfigsFormData) => void;
  onClose?: () => void;
  loading?: boolean;
};

function SyncADConfigsDialog({
  links,
  data = defaultSyncADConfigs,
  onSave,
  loading = false,
  className = "",
  ...props
}: SyncADConfigsDialogProps) {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [selectLinkDialogOpen, setSelectLinkDialogOpen] =
    useState<boolean>(false);
  const [memberPropToSync, setMemberPropToSync] = useState<ButtonPropsToSync>();

  const {
    control,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: {
      // eslint-disable-next-line
      isDirty,
    },
  } = useForm<SyncADConfigsFormData>({
    defaultValues: data,
  });

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  function getADFieldLabelSyncedWith(
    linkId: LinkToSync["id"]
  ): ADFieldLabels | undefined {
    return Array.from(MemberButtonADFieldLabelsMap.entries()).find(
      ([aMemberProp]) =>
        getValues(`propsToSync.${aMemberProp}.templateButtonId`) === linkId
    )?.[1];
  }

  function getCompatibleLinks(aLink: LinkToSync): aLink is LinkToSync {
    return memberPropToSync
      ? (memberPropToSync === "companyAddressButton" &&
          aLink.linkTypeCode === LinkTypeCode.ADDRESS) ||
          (memberPropToSync === "phoneBusinessButton" &&
            aLink.linkTypeCode === LinkTypeCode.BUSINESS_CALL) ||
          (memberPropToSync === "phoneMobileButton" &&
            [
              LinkTypeCode.CALL,
              LinkTypeCode.TEXT,
              LinkTypeCode.WHATSAPP,
            ].includes(aLink.linkTypeCode))
      : false;
  }

  return (
    <DialogX
      className={`flex !max-w-3xl flex-col gap-4 ${className}`}
      fullScreen
      {...props}
    >
      <DialogTitle className="!p-0 !font-sans !font-semibold text-black">
        Configurations to Sync with Microsoft Active Directory
      </DialogTitle>
      <DialogContent className="!p-0">
        <FormControlSyncFields className="!block">
          <FormLabel id="sync-fields-label">
            Choose what fields you want to Sync and/or Overwrite:
            <Tooltip
              title="When 'Overwrite' is checked, if the integration finds the same member already exists on AddMee, it will overwrite new data from AD on future syncs."
              placement="top"
              open={tooltipOpen}
              onClose={() => setTooltipOpen(false)}
              disableHoverListener
              classes={{ tooltip: "!mb-0" }}
            >
              <IconButton onClick={() => setTooltipOpen(!tooltipOpen)}>
                <HelpOutlineIcon className="!text-base !text-gray-500" />
              </IconButton>
            </Tooltip>
          </FormLabel>
          <TableContainer aria-labelledby="sync-fields-label">
            <Table size="small" className="!w-full border border-gray-300">
              <TableHead className="bg-slate-100">
                <TableRow>
                  <TableCell className="text-nowrap">
                    Active Directory
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell className="text-nowrap">AddMee</TableCell>
                  <TableCell colSpan={2} className="text-nowrap">
                    Sync/Overwrite
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(MemberTextADFieldLabelsMap.entries()).map(
                  ([aMemberProp, anADFieldLabel], index) => (
                    <TableRow
                      key={aMemberProp}
                      className={`${index % 2 === 1 && "bg-slate-50"}`}
                    >
                      <TableCell className="text-nowrap">
                        {anADFieldLabel}
                      </TableCell>
                      <TableCell>
                        <FontAwesomeIcon
                          icon={faArrowRightLong}
                          className="text-primary"
                        />
                      </TableCell>
                      <TableCell className="text-nowrap">
                        {MemberFieldLabelsMap.get(aMemberProp)}
                      </TableCell>
                      <TableCell>
                        <Controller
                          control={control}
                          name={`propsToSync.${aMemberProp}.sync`}
                          render={({ field: { value, onChange } }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  checked={value}
                                  onChange={(_, checked) => {
                                    onChange(_, checked);
                                    setValue(
                                      `propsToSync.${aMemberProp}.overwrite`,
                                      checked,
                                      { shouldDirty: true }
                                    );
                                  }}
                                  classes={{ checked: "!text-black" }}
                                />
                              }
                              label="Sync"
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          control={control}
                          name={`propsToSync.${aMemberProp}.overwrite`}
                          render={({ field: { value, onChange } }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  checked={value}
                                  onChange={onChange}
                                  disabled={
                                    !getValues(
                                      `propsToSync.${aMemberProp}.sync`
                                    )
                                  }
                                  classes={{
                                    checked: "!text-black",
                                    disabled: "!text-gray-400",
                                  }}
                                />
                              }
                              label="Overwrite"
                              classes={{
                                disabled: "!text-gray-400",
                              }}
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  )
                )}
                {links && (
                  <>
                    {Array.from(MemberButtonADFieldLabelsMap.entries()).map(
                      ([aMemberProp, anADFieldLabel], index) => {
                        const syncedButtonId = getValues(
                          `propsToSync.${aMemberProp}.templateButtonId`
                        );

                        return (
                          <TableRow
                            key={aMemberProp}
                            className={`${index % 2 === 0 && "bg-slate-50"}`}
                          >
                            <TableCell className="text-nowrap">
                              {anADFieldLabel}
                            </TableCell>
                            <TableCell>
                              <FontAwesomeIcon
                                icon={faArrowRightLong}
                                className="text-primary"
                              />
                            </TableCell>
                            <TableCell className="text-nowrap">
                              {syncedButtonId ? (
                                <span className="flex items-center gap-1">
                                  {
                                    links?.find((l) => l.id === syncedButtonId)
                                      ?.title
                                  }
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      setValue(
                                        `propsToSync.${aMemberProp}.templateButtonId`,
                                        undefined,
                                        {
                                          shouldDirty: true,
                                        }
                                      );
                                      setValue(
                                        `propsToSync.${aMemberProp}.sync`,
                                        false,
                                        {
                                          shouldDirty: true,
                                        }
                                      );
                                      setValue(
                                        `propsToSync.${aMemberProp}.overwrite`,
                                        false,
                                        {
                                          shouldDirty: true,
                                        }
                                      );
                                    }}
                                    className="!p-0 !text-primary"
                                  >
                                    <CancelRoundedIcon fontSize="small" />
                                  </IconButton>
                                </span>
                              ) : (
                                <Button
                                  onClick={() => {
                                    setMemberPropToSync(aMemberProp);
                                    setSelectLinkDialogOpen(true);
                                  }}
                                  className="!-mx-2 !-mb-[0.3125rem] !-mt-1.5 text-nowrap !font-sans !text-sm !font-normal !normal-case !text-primary"
                                >
                                  Select Button to Sync
                                </Button>
                              )}
                            </TableCell>
                            <TableCell>
                              <Controller
                                control={control}
                                name={`propsToSync.${aMemberProp}.sync`}
                                render={({ field: { value, onChange } }) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        size="small"
                                        checked={value}
                                        onChange={(_, checked) => {
                                          onChange(_, checked);
                                          setValue(
                                            `propsToSync.${aMemberProp}.overwrite`,
                                            checked,
                                            { shouldDirty: true }
                                          );
                                        }}
                                        disabled={!syncedButtonId}
                                        classes={{
                                          checked: "!text-black",
                                          disabled: "!text-gray-400",
                                        }}
                                      />
                                    }
                                    label="Sync"
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              <Controller
                                control={control}
                                name={`propsToSync.${aMemberProp}.overwrite`}
                                render={({ field: { value, onChange } }) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        size="small"
                                        checked={value}
                                        onChange={onChange}
                                        disabled={
                                          !getValues(
                                            `propsToSync.${aMemberProp}.sync`
                                          )
                                        }
                                        classes={{
                                          checked: "!text-black",
                                          disabled: "!text-gray-400",
                                        }}
                                      />
                                    }
                                    label="Overwrite"
                                    classes={{
                                      disabled: "!text-gray-400",
                                    }}
                                  />
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                    {memberPropToSync && (
                      <SelectLinkDialog
                        links={links?.filter(getCompatibleLinks).map((l) => ({
                          ...l,
                          syncedWith: getADFieldLabelSyncedWith(l.id),
                        }))}
                        value={getValues(
                          `propsToSync.${memberPropToSync}.templateButtonId`
                        )}
                        onChange={(linkId) => {
                          setValue(
                            `propsToSync.${memberPropToSync}.templateButtonId`,
                            linkId,
                            { shouldDirty: true }
                          );
                          setMemberPropToSync(undefined);
                          setSelectLinkDialogOpen(false);
                        }}
                        open={selectLinkDialogOpen}
                        onCancel={() => {
                          setMemberPropToSync(undefined);
                          setSelectLinkDialogOpen(false);
                        }}
                        className="!max-w-2xl"
                      />
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </FormControlSyncFields>
      </DialogContent>
      <DialogActions className="gap-4 !border-t border-gray-200 !p-0 !pt-4">
        <Button
          onClick={() => props.onClose?.()}
          variant="outlined"
          className="!m-0 !rounded-full !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-black"
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          startIcon={<FontAwesomeIcon icon={faSave} className="!text-sm" />}
          loadingPosition="start"
          loading={loading}
          onClick={onSave && handleSubmit(onSave)}
          classes={theme.LoadingButtonX}
        >
          Save Configurations
        </LoadingButton>
      </DialogActions>
      <DevTool control={control} placement="top-left" />
    </DialogX>
  );
}

export default SyncADConfigsDialog;
