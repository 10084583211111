export enum LinkCategoryType {
  URL = "url",
  USERNAME = "username",
  NUMBER = "number",
  OTHER = "other",
}

export enum LinkTypeCode {
  ADDRESS = "address",
  BUSINESS_CALL = "business_call",
  CALL = "call",
  CONTACT_CARD = "contact-card",
  SOS = "Notfallnummer",
  TEXT = "text",
  VIOKID = "viokid",
  WHATSAPP = "whatsapp",
  WIFI = "wifi",
}

export enum AddedToContactCardBy {
  TEMPLATE = "template",
  MEMBER = "member",
  NONE = "none",
}
