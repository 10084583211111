import type { MemberCustomColors } from "../members/membersTypes";

export enum SettingsColorsDefaults {
  SECTION_COLOR = "rgba(255, 255, 255, 1)",
  PROFILE_COLOR = "rgba(255, 255, 255, 1)",
  BORDER_COLOR = "rgba(255, 255, 255, 1)",
  TEXT_COLOR = "rgba(17, 24, 3, 1)",
  CONTROL_BUTTON_TEXT_COLOR = "rgba(255, 255, 255, 1)",
  CONTROL_BUTTON_BG_COLOR = "rgba(0, 0, 0, 1)",
  PROFILE_BUTTON_ICON_COLOR = "rgba(255, 255, 255, 1)",
  PROFILE_BUTTON_BG_COLOR = "rgba(0, 0, 0, 1)",
  COLOR_LINK_ICONS = 0,
}

export const defaultColorsSettings: MemberCustomColors = {
  sectionColor: SettingsColorsDefaults.SECTION_COLOR.toString(),
  profileColor: SettingsColorsDefaults.PROFILE_COLOR.toString(),
  borderColor: SettingsColorsDefaults.BORDER_COLOR.toString(),
  textColor: SettingsColorsDefaults.TEXT_COLOR.toString(),
  controlButtonTextColor:
    SettingsColorsDefaults.CONTROL_BUTTON_TEXT_COLOR.toString(),
  controlButtonBgColor:
    SettingsColorsDefaults.CONTROL_BUTTON_BG_COLOR.toString(),
  profileButtonIconColor:
    SettingsColorsDefaults.PROFILE_BUTTON_ICON_COLOR.toString(),
  profileButtonBgColor:
    SettingsColorsDefaults.PROFILE_BUTTON_BG_COLOR.toString(),
  colorLinkIcons: Boolean(SettingsColorsDefaults.COLOR_LINK_ICONS),
};

export enum SettingsSections {
  MANAGE_ROLES = 0,
}

export function getSettingsSectionLabel(value: SettingsSections): string {
  return value === SettingsSections.MANAGE_ROLES
    ? "ManageRoles"
    : "ManageRoles";
}

export function getSettingsHashTag(value: SettingsSections): string {
  return value === SettingsSections.MANAGE_ROLES
    ? "#ManageRoles"
    : "#ManageRoles";
}

export function getSettingsSection(value: string): SettingsSections {
  return value === "#ManageRoles"
    ? SettingsSections.MANAGE_ROLES
    : SettingsSections.MANAGE_ROLES;
}
