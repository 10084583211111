import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faArrowsRotate, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { IconButton, Tooltip } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  ConfirmationDialog,
  PelletWithColorPicker,
  SwitchX,
  TabX,
  TabsX,
  tabPanelA11yProps,
} from "../../components";
import { useAppDispatch } from "../../store";
import { FetchError, handleFetchError } from "../../utils";
import { defaultColorsSettings } from "../settings/settingsEnums";
import {
  Template,
  TemplateCustomColorsSections,
  TemplateSettings,
  TemplateUpdatedColors,
  UpdateTemplateDataRequestArgs,
  editTemplateData,
  editTemplateSettings,
  getUpdatedTemplateColors,
  useSwitchTemplateLocksMutation,
  useUpdateTemplateDataMutation,
} from "../templates";

type EditTemplateColorsProps = {
  template: Template;
  colors: TemplateSettings["colors"];
  onChange?: (
    updatedIsColorsCustomLocked: Template["isColorsCustomLocked"],
    updatedTemplateColors: TemplateUpdatedColors
  ) => void;
  changed?: boolean;
  onChangesSaved?: () => void;
  onChangesDiscarded?: () => void;
  className?: string;
};

function EditTemplateColors({
  template,
  colors,
  onChange,
  changed = false,
  onChangesSaved,
  onChangesDiscarded,
  className = "",
  ...props
}: EditTemplateColorsProps) {
  const initUpdatedTemplateColors: TemplateUpdatedColors = {
    sectionColor: colors.sectionColor,
    profileColor: colors.profileColor,
    borderColor: colors.borderColor,
    textColor: colors.textColor,
    controlButtonTextColor: colors.controlButtonTextColor,
    controlButtonBgColor: colors.controlButtonBgColor,
    profileButtonIconColor: colors.profileButtonIconColor,
    profileButtonBgColor: colors.profileButtonBgColor,
    colorLinkIcons: colors.colorLinkIcons,
  };

  const [updatedIsColorsCustomLocked, setUpdatedIsColorsCustomLocked] =
    useState<Template["isColorsCustomLocked"]>(template.isColorsCustomLocked);
  const [updatedTemplateColors, setUpdatedTemplateColors] =
    useState<TemplateUpdatedColors>(initUpdatedTemplateColors);
  const [tabIndex, setTabIndex] = useState<TemplateCustomColorsSections>(
    TemplateCustomColorsSections.SECTION
  );
  const [buttonsTabIndex, setButtonsTabIndex] =
    useState<TemplateCustomColorsSections>(
      TemplateCustomColorsSections.CONTROL_BUTTONS
    );
  const [showPendingChagesDialog, setShowPendingChagesDialog] =
    useState<boolean>(false);
  const [templateDataToUpdate, setTemplateDataToUpdate] =
    useState<UpdateTemplateDataRequestArgs>({ id: template.id });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (template) {
      setUpdatedIsColorsCustomLocked(template.isColorsCustomLocked);
    }
  }, [template]);

  useEffect(() => {
    if (colors) {
      setUpdatedTemplateColors({
        sectionColor: colors.sectionColor,
        profileColor: colors.profileColor,
        borderColor: colors.borderColor,
        textColor: colors.textColor,
        controlButtonTextColor: colors.controlButtonTextColor,
        controlButtonBgColor: colors.controlButtonBgColor,
        profileButtonIconColor: colors.profileButtonIconColor,
        profileButtonBgColor: colors.profileButtonBgColor,
        colorLinkIcons: colors.colorLinkIcons,
      });
    }
  }, [colors]);

  useEffect(() => {
    onChange?.(updatedIsColorsCustomLocked, updatedTemplateColors);
    setTemplateDataToUpdate(
      getUpdatedTemplateColors(template.id, updatedTemplateColors, colors)
    );
  }, [
    updatedIsColorsCustomLocked,
    updatedTemplateColors,
    template,
    colors,
    onChange,
  ]);

  const [updateTemplateData, { isLoading: isUpdateTemplateDataPending }] =
    useUpdateTemplateDataMutation();
  const [switchTemplateLocks, { isLoading: isSwitchTemplateLocksPending }] =
    useSwitchTemplateLocksMutation();

  async function handleUpdateTemplateDataClick() {
    if (Object.keys(templateDataToUpdate).length > 1) {
      try {
        const {
          success,
          message,
          data: {
            template: updatedTemplateData,
            templateSettings: updatedTemplateSettings,
          },
        } = await updateTemplateData(templateDataToUpdate).unwrap();
        if (success) {
          dispatch(editTemplateData(updatedTemplateData));
          dispatch(editTemplateSettings(updatedTemplateSettings));
          toast.success(message);
          setTemplateDataToUpdate({ id: template.id });
        } else {
          toast.error(message);
        }
      } catch (error) {
        handleFetchError(error as FetchError);
      }
    }

    if (updatedIsColorsCustomLocked !== template.isColorsCustomLocked) {
      try {
        const {
          success,
          message,
          data: { updatedTemplate },
        } = await switchTemplateLocks({
          id: template.id,
          isColorsCustomLocked: updatedIsColorsCustomLocked,
        }).unwrap();

        if (success) {
          dispatch(editTemplateData(updatedTemplate));
          toast.success(message);
        } else {
          setUpdatedIsColorsCustomLocked(!updatedIsColorsCustomLocked);
          toast.error(message);
        }
      } catch (error) {
        setUpdatedIsColorsCustomLocked(!updatedIsColorsCustomLocked);
        handleFetchError(error as FetchError);
      }
    }
  }

  useEffect(() => {
    setShowPendingChagesDialog(changed);
  }, [changed]);

  function handlePendingChagesConfirm(): void {
    handleUpdateTemplateDataClick().then(() => {
      setShowPendingChagesDialog(false);
      onChangesSaved?.();
    });
  }

  function handlePendingChagesCancel(): void {
    setUpdatedIsColorsCustomLocked(template.isColorsCustomLocked);
    setUpdatedTemplateColors(initUpdatedTemplateColors);
    setShowPendingChagesDialog(false);
    onChangesDiscarded?.();
  }

  return (
    <div className={`flex flex-col gap-8 ${className}`} {...props}>
      <div className="flex items-center justify-between">
        <h4 className="text-xl font-semibold text-black">
          Color Customization
        </h4>
        <Tooltip
          title={
            updatedIsColorsCustomLocked
              ? "The Assignees will not be able to customize the Colors."
              : "The Assignees can customize the Colors."
          }
          placement="top"
          classes={{ popper: "!mb-0" }}
        >
          <IconButton
            onClick={() =>
              setUpdatedIsColorsCustomLocked(!updatedIsColorsCustomLocked)
            }
            className="!p-2.5"
          >
            {updatedIsColorsCustomLocked ? (
              <LockIcon fontSize="small" />
            ) : (
              <LockOpenIcon fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
        <button
          onClick={() => setUpdatedTemplateColors(defaultColorsSettings)}
          className="flex gap-2 text-xs text-black"
        >
          <FontAwesomeIcon icon={faArrowsRotate} />
          Reset colors to default
        </button>
      </div>
      <div className="flex flex-col gap-4 rounded-2xl bg-gray-100 p-4">
        <TabsX
          name={`Profile Colors Customization for ${template.name}`}
          value={tabIndex}
          onChange={(_e, v: number) => setTabIndex(v)}
        >
          <TabX label="Section" index={TemplateCustomColorsSections.SECTION} />
          <TabX label="Profile" index={TemplateCustomColorsSections.PROFILE} />
          <TabX
            label="Profile Border"
            index={TemplateCustomColorsSections.PROFILE_BORDER}
          />
          <TabX label="Text" index={TemplateCustomColorsSections.TEXT} />
        </TabsX>
        {tabIndex === TemplateCustomColorsSections.SECTION && (
          <div {...tabPanelA11yProps(TemplateCustomColorsSections.SECTION)}>
            <PelletWithColorPicker
              color={updatedTemplateColors.sectionColor}
              onColorChange={(newColor) =>
                setUpdatedTemplateColors({
                  ...updatedTemplateColors,
                  sectionColor: newColor,
                })
              }
            />
          </div>
        )}
        {tabIndex === TemplateCustomColorsSections.PROFILE && (
          <div {...tabPanelA11yProps(TemplateCustomColorsSections.PROFILE)}>
            <PelletWithColorPicker
              color={updatedTemplateColors.profileColor}
              onColorChange={(newColor) =>
                setUpdatedTemplateColors({
                  ...updatedTemplateColors,
                  profileColor: newColor,
                })
              }
            />
          </div>
        )}
        {tabIndex === TemplateCustomColorsSections.PROFILE_BORDER && (
          <div
            {...tabPanelA11yProps(TemplateCustomColorsSections.PROFILE_BORDER)}
          >
            <PelletWithColorPicker
              color={updatedTemplateColors.borderColor}
              onColorChange={(newColor) =>
                setUpdatedTemplateColors({
                  ...updatedTemplateColors,
                  borderColor: newColor,
                })
              }
            />
          </div>
        )}
        {tabIndex === TemplateCustomColorsSections.TEXT && (
          <div {...tabPanelA11yProps(TemplateCustomColorsSections.TEXT)}>
            <PelletWithColorPicker
              color={updatedTemplateColors.textColor}
              onColorChange={(newColor) =>
                setUpdatedTemplateColors({
                  ...updatedTemplateColors,
                  textColor: newColor,
                })
              }
            />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <h5 className="pl-4 text-xl font-semibold text-black">Buttons</h5>
        <div className="flex flex-col gap-4 rounded-2xl bg-gray-100 p-4">
          <TabsX
            name={`Buttons Colors Customization for ${template.name}`}
            value={buttonsTabIndex - 4}
            onChange={(_e, v: number) => setButtonsTabIndex(v + 4)}
          >
            <TabX
              label="Control Buttons"
              index={TemplateCustomColorsSections.CONTROL_BUTTONS}
            />
            <TabX
              label="Profile Buttons"
              index={TemplateCustomColorsSections.PROFILE_BUTTONS}
            />
          </TabsX>
          {buttonsTabIndex === TemplateCustomColorsSections.CONTROL_BUTTONS && (
            <div
              className="flex flex-col gap-4"
              {...tabPanelA11yProps(
                TemplateCustomColorsSections.CONTROL_BUTTONS
              )}
            >
              <div className="flex flex-col gap-2">
                <h6 className="text-xs font-normal text-gray-600">
                  Text Color
                </h6>
                <PelletWithColorPicker
                  color={updatedTemplateColors.controlButtonTextColor}
                  onColorChange={(newColor) =>
                    setUpdatedTemplateColors({
                      ...updatedTemplateColors,
                      controlButtonTextColor: newColor,
                    })
                  }
                />
              </div>
              <div className="flex flex-col gap-2">
                <h6 className="text-xs font-normal text-gray-600">
                  Background Color
                </h6>
                <PelletWithColorPicker
                  color={updatedTemplateColors.controlButtonBgColor}
                  onColorChange={(newColor) =>
                    setUpdatedTemplateColors({
                      ...updatedTemplateColors,
                      controlButtonBgColor: newColor,
                    })
                  }
                />
              </div>
            </div>
          )}
          {buttonsTabIndex === TemplateCustomColorsSections.PROFILE_BUTTONS && (
            <div
              className="flex flex-col gap-4"
              {...tabPanelA11yProps(
                TemplateCustomColorsSections.PROFILE_BUTTONS
              )}
            >
              <div className="flex flex-col gap-2">
                <h6 className="text-xs font-normal text-gray-600">
                  Icon Color
                </h6>
                <PelletWithColorPicker
                  color={updatedTemplateColors.profileButtonIconColor}
                  onColorChange={(newColor) =>
                    setUpdatedTemplateColors({
                      ...updatedTemplateColors,
                      profileButtonIconColor: newColor,
                    })
                  }
                />
              </div>
              <div className="flex flex-col gap-2">
                <h6 className="text-xs font-normal text-gray-600">
                  Background Color
                </h6>
                <PelletWithColorPicker
                  color={updatedTemplateColors.profileButtonBgColor}
                  onColorChange={(newColor) =>
                    setUpdatedTemplateColors({
                      ...updatedTemplateColors,
                      profileButtonBgColor: newColor,
                    })
                  }
                />
              </div>
              <div className="flex items-center justify-between gap-4 rounded-lg bg-white p-2">
                <label className="text-sm font-medium text-gray-700">
                  Color the button icons
                </label>
                <SwitchX
                  checked={updatedTemplateColors.colorLinkIcons}
                  onChange={(_e, v) =>
                    setUpdatedTemplateColors({
                      ...updatedTemplateColors,
                      colorLinkIcons: v,
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between gap-8 border-t border-gray-200 pt-8">
        <p className="text-sm text-gray-500">
          Any changes made will update all cards using this template.
        </p>
        <div className="flex items-center gap-8">
          <LoadingButton
            type="submit"
            startIcon={<FontAwesomeIcon icon={faSave} className="!text-sm" />}
            loading={isUpdateTemplateDataPending}
            loadingPosition="start"
            classes={{
              root: `!rounded-full ${
                (Object.keys(templateDataToUpdate).length > 1 ||
                  updatedIsColorsCustomLocked !==
                    template.isColorsCustomLocked) &&
                "!bg-primary"
              } !px-6 !py-3 !font-sans !text-sm !normal-case !text-white`,
              disabled: "!bg-gray-300",
              loadingIndicator: "!left-5",
            }}
            disabled={
              Object.keys(templateDataToUpdate).length <= 1 &&
              updatedIsColorsCustomLocked === template.isColorsCustomLocked
            }
            onClick={handleUpdateTemplateDataClick}
          >
            Update
          </LoadingButton>
        </div>
      </div>
      <ConfirmationDialog
        title="Save Pending Changes?"
        content="There are pending changes. Do you want to update them?"
        confirmButtonIcon={
          <FontAwesomeIcon
            icon={
              isUpdateTemplateDataPending || isSwitchTemplateLocksPending
                ? faSpinner
                : faSave
            }
            className="!text-sm"
          />
        }
        confirmButtonText="Update"
        onConfirm={handlePendingChagesConfirm}
        cancelButtonText="Discard"
        onCancel={handlePendingChagesCancel}
        open={showPendingChagesDialog}
      />
    </div>
  );
}

export default EditTemplateColors;
