import { useState } from "react";
import { toast } from "react-toastify";
import AuthConfigs from "../configs/authConfigs";
import {
  defaultSyncADConfigsCommon,
  Platforms,
} from "../features/integrations/integrationsEnums";
import { useCreateADIntegrationMutation } from "../features/integrations/integrationsService";
import { addIntegration } from "../features/integrations/integrationsSlice";
import { useAppDispatch } from "../store";
import { FetchError, handleFetchError } from "../utils";

interface IntegrationHookProps {
  platform: Platforms;
  callback?: Function;
}

interface IntegrationHook {
  openOAuthPopup: () => void;
  isLoading: boolean;
}

function useIntegration({
  platform,
  callback,
}: IntegrationHookProps): IntegrationHook {
  const [popup, setPopup] = useState<Window | null>(null);

  const dispatch = useAppDispatch();

  const [createADIntegration, { isLoading: isCreateADIntegrationPending }] =
    useCreateADIntegrationMutation();

  async function handleStorageChange(this: Window, ev: StorageEvent) {
    if (ev.key === "oAuthTenant") {
      const tenantId = ev.newValue;
      if (tenantId) {
        try {
          const { success, message, data } = await createADIntegration({
            tenantId,
            settings: defaultSyncADConfigsCommon,
          }).unwrap();
          if (success) {
            dispatch(addIntegration(data));
            callback?.();
            toast.success(message);
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }

        localStorage.removeItem("oAuthTenant");
        window.removeEventListener("storage", handleStorageChange);
      }
    } else if (ev.key === "oAuthError") {
      const error = ev.newValue;
      if (error) {
        toast.error(error);
      }

      localStorage.removeItem("oAuthError");
      window.removeEventListener("storage", handleStorageChange);
    }
  }

  function openOAuthPopup() {
    popup?.close();

    const oAuthPopup = window.open(
      AuthConfigs.ActiveDirectory.OAUTH_URL,
      "ActiveDirectoryOAuthWindow",
      "popup"
    );

    window.addEventListener("storage", handleStorageChange);

    setPopup(oAuthPopup);
  }

  return { openOAuthPopup, isLoading: isCreateADIntegrationPending };
}

export default useIntegration;
