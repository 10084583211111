import {
  faArrowLeft,
  faArrowsRotate,
  faEye,
  faLock,
  faShieldHalved,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToggleButton } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MemberEditMenu, MembersDropdownList } from "../../components";
import { useAppDispatch } from "../../store";
import { FetchError, handleFetchError } from "../../utils";
import {
  Member,
  MemberEditMenuInfo,
  editMember,
  editMembers,
  useLockMembersMutation,
  useSendResetPasswordInviteMutation,
  useUnlockMembersMutation,
  useUpdateMemberMutation,
} from "../members";

type CardCustomizationHeaderProps = {
  member: Member;
  className?: string;
};

function CardCustomizationHeader({
  member,
  className,
  ...props
}: CardCustomizationHeaderProps) {
  const [isMemberLocked, setIsMemberLocked] = useState<Member["locked"]>();

  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();

  useEffect(() => {
    setIsMemberLocked(member.locked);
  }, [member]);

  const [lockMembers] = useLockMembersMutation();
  const [unlockMembers] = useUnlockMembersMutation();

  async function toggleLock(id: number, isLocked: boolean = false) {
    setIsMemberLocked(!isLocked);
    try {
      const response = isLocked
        ? await unlockMembers([id]).unwrap()
        : await lockMembers([id]).unwrap();
      if (response.success) {
        dispatch(editMembers(response.data.members));
        toast.success(response.message);
      } else {
        setIsMemberLocked(isLocked);
        toast.error(response.message);
      }
    } catch (error) {
      setIsMemberLocked(isLocked);
      handleFetchError(error as FetchError);
    }
  }

  const [updateMember] = useUpdateMemberMutation();

  async function togglePublic(id: number, isPublic: boolean = true) {
    try {
      const {
        success,
        message,
        data: updatedMember,
      } = await updateMember({
        id,
        isPublic,
      }).unwrap();
      if (success) {
        dispatch(editMember(updatedMember));
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  const [sendResetPasswordInvite] = useSendResetPasswordInviteMutation();

  async function resetPasswordSendMail(ids: number[]) {
    try {
      const {
        success,
        message,
        // data: { members },
      } = await sendResetPasswordInvite(ids).unwrap();
      if (success) {
        // dispatch(editMembers(members));
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  return (
    <div className={`flex h-[7.25rem] gap-6 p-8 ${className ?? ""}`} {...props}>
      <Link
        to="/members"
        aria-label="Go back to Members list page"
        className="flex w-[7.5rem] items-center justify-center gap-2 rounded-full border border-gray-200 bg-white text-sm text-black"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        <span className="font-semibold">Back</span>
      </Link>
      <div className="flex grow border-l border-gray-200">
        <MembersDropdownList
          selected={member}
          onSelect={(id: number) => navigateTo(`/members/${id}/edit`)}
          className="w-[14.5rem]"
        />
      </div>
      <div className="flex gap-4">
        <ToggleButton
          value="locked"
          selected={isMemberLocked}
          onChange={() => toggleLock(member.id, isMemberLocked)}
          title={`Lock this member to disable any editing \nfrom the AddMee mobile app.`}
          classes={{
            root: "aspect-square !rounded-full !border-gray-200 !bg-white",
          }}
        >
          <FontAwesomeIcon
            icon={isMemberLocked ? faLock : faUnlock}
            className="text-sm"
          />
        </ToggleButton>
        <MemberEditMenu
          member={member as MemberEditMenuInfo}
          items={[
            member.isPublic
              ? {
                  name: "Set as Private Profile",
                  icon: <FontAwesomeIcon icon={faShieldHalved} />,
                  onClick: (ids) => togglePublic(ids[0], false),
                }
              : {
                  name: "Set as Public Profile",
                  icon: <FontAwesomeIcon icon={faEye} />,
                  onClick: (ids) => togglePublic(ids[0], true),
                },
            {
              name: "Reset Password & Send Mail",
              icon: <FontAwesomeIcon icon={faArrowsRotate} />,
              onClick: resetPasswordSendMail,
            },
          ]}
          aria-label="More options"
          className="aspect-square !min-w-0 !rounded-full !border-gray-200 !bg-white !text-black"
        />
      </div>
    </div>
  );
}

export default CardCustomizationHeader;
