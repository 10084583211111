import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DevTool } from "@hookform/devtools";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, IconButton } from "@mui/material";
import { useEffect, useRef } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { Spinner, SwitchX } from "../../components";
import { useAppDispatch, useAppSelector } from "../../store";
import { TextFieldX } from "../../styles/mui-styled";
import { FetchError, handleFetchError } from "../../utils";
import {
  getMembers,
  setMemberSettings,
  setMembers,
  useGetAllMembersMutation,
} from "../members";
import type { AddMembersByEmailsFormData } from "./addMembersTypes";

type AddByEmailProps = {
  onSubmit?: ({
    newMembers,
    doSendInvites,
  }: AddMembersByEmailsFormData) => void;
  inProgress?: boolean;
  isSuccess?: boolean;
  className?: string;
};

function AddByEmail({
  onSubmit,
  inProgress = false,
  isSuccess = false,
  className = "",
  ...props
}: AddByEmailProps) {
  const dispatch = useAppDispatch();

  const members = useAppSelector(getMembers);

  const [getAllMembers, { isLoading: isGetAllMembersPending }] =
    useGetAllMembersMutation();

  useEffect(() => {
    if (!members) {
      (async () => {
        try {
          const {
            success,
            message,
            data: { members: allMembers, settings },
          } = await getAllMembers().unwrap();
          if (success) {
            dispatch(setMembers(allMembers));
            dispatch(setMemberSettings(settings));
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    }
  }, [members, dispatch, getAllMembers]);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm<AddMembersByEmailsFormData>({
    defaultValues: {
      newMembers: [
        {
          fullName: "",
          email: "",
        },
      ],
      doSendInvites: true,
    },
  });

  function addMembers({
    newMembers,
    doSendInvites,
  }: AddMembersByEmailsFormData): void {
    !!onSubmit && onSubmit({ newMembers, doSendInvites });
  }

  const { fields, append, remove } = useFieldArray({
    control,
    name: "newMembers",
  });

  const emailRefs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    emailRefs.current = emailRefs.current.slice(0, fields.length);
  }, [fields]);

  function addAnother() {
    if (isValid) {
      append({
        fullName: "",
        email: "",
      });
    }
  }

  function removeThis(index: number): void {
    remove(index);

    // Focus on the next field, or the first field if the last one was removed
    const nextIndex = index < fields.length - 1 ? index : 0;
    emailRefs.current[nextIndex].focus();
  }

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess, reset]);

  return (
    <div className={`flex flex-col gap-8 ${className}`} {...props}>
      <div>
        <h4 className="text-xl font-semibold text-black">Invite Member(s)</h4>
        <p className="text-sm text-gray-500">
          Add members to your team via email. If an AddMee user with that email
          already exists, the member will be pending until the user accepts the
          email invite.
        </p>
      </div>
      <div className="relative flex flex-col gap-4">
        {isGetAllMembersPending && <Spinner className="rounded-3xl" />}
        {!!fields.length && (
          <table>
            <thead hidden>
              <tr>
                <th>Full Name</th>
                <th>Email Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="flex flex-col gap-4 sm:gap-2 md:gap-4 xl:gap-2">
              {fields.map((field, index) => (
                <tr
                  key={field.id}
                  className="flex flex-col gap-1 sm:flex-row sm:gap-2 md:flex-col md:gap-1 xl:flex-row xl:gap-2"
                >
                  <td className="sm:w-44 md:w-full xl:w-64 2xl:w-80">
                    <TextFieldX
                      {...register(
                        `newMembers.${index}.fullName`
                        // , {
                        //   pattern: /^[a-zA-ZäöüÄÖÜß\s]+$/,
                        // }
                      )}
                      label="Full Name"
                      helperText={
                        index === fields.length - 1 &&
                        "Please enter your first and last name. Avoid using titles or prefixes."
                      }
                      // onChange={(e) => {
                      //   // Remove non-alphabets and German characters
                      //   e.target.value = e.target.value.replace(
                      //     /[^a-zA-ZäöüÄÖÜß\s]/g,
                      //     ""
                      //   );
                      // }}
                      error={!!errors.newMembers?.[index]?.fullName}
                      size="small"
                      fullWidth
                    />
                  </td>
                  <td className="sm:w-44 md:w-full xl:w-64 2xl:w-80">
                    <TextFieldX
                      {...register(`newMembers.${index}.email`, {
                        required: true,
                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      })}
                      inputRef={(ref) => (emailRefs.current[index] = ref)}
                      type="email"
                      label="Email Address"
                      helperText={
                        index === fields.length - 1 &&
                        "Please enter your valid email address. We'll send an invite with temporary password."
                      }
                      error={!!errors.newMembers?.[index]?.email}
                      size="small"
                      fullWidth
                    />
                  </td>
                  <td className="grow">
                    {fields.length > 1 && (
                      <IconButton
                        color="error"
                        onClick={() => removeThis(index)}
                        aria-label="Remove this member"
                        className="h-[2.75rem] w-full !rounded-full !bg-error-600 !text-white sm:w-[2.75rem] sm:!bg-transparent sm:!text-error-600 md:w-full md:!bg-error-600 md:!text-white xl:w-[2.75rem] xl:!bg-transparent xl:!text-error-600"
                      >
                        <PersonOffIcon />
                      </IconButton>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div>
          <Button
            variant="outlined"
            startIcon={<PersonAddAlt1Icon />}
            onClick={addAnother}
            aria-label="Add another member"
            className={`h-[2.75rem] !rounded-full !border-2 ${
              isValid
                ? "!border-black !text-black"
                : "border-gray-200 !text-gray-400"
            } !px-6 !font-sans !text-sm !normal-case`}
            disabled={!isValid}
          >
            Add More
          </Button>
        </div>
      </div>
      <div className="flex flex-col items-center justify-between gap-4 border-t border-gray-200 pt-8 sm:flex-row sm:gap-8 sm:pt-4 md:flex-col md:gap-4 md:pt-8 xl:flex-row xl:gap-8 xl:pt-4">
        <Controller
          name="doSendInvites"
          control={control}
          render={({ field }) => (
            <SwitchX
              checked={field.value}
              onChange={(_e, v) => field.onChange(v)}
              label="Send email invites"
            />
          )}
        />
        <LoadingButton
          type="submit"
          startIcon={<FontAwesomeIcon icon={faUserPlus} className="!text-sm" />}
          loading={inProgress}
          loadingPosition="start"
          onClick={handleSubmit(addMembers)}
          disabled={!isValid}
          className={`w-full !rounded-full ${
            isValid && "!bg-primary"
          } !px-6 !py-3 !font-sans !text-sm !normal-case !text-white sm:w-auto md:w-full xl:w-auto`}
          classes={{
            disabled: "!bg-gray-300",
            loadingIndicator: "!left-5",
          }}
        >
          Add Member(s)
        </LoadingButton>
      </div>
      <DevTool control={control} />
    </div>
  );
}

export default AddByEmail;
