import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import { useState } from "react";
import { toast } from "react-toastify";
import SyncADConfigsCommonDialog from "../../components/SyncADConfigsCommonDialog";
import useIntegration from "../../hooks/useIntegration";
import { useAppDispatch } from "../../store";
import theme from "../../styles/mui-styled";
import { FetchError, handleFetchError } from "../../utils";
import IntegrationCard from "./IntegrationCard";
import { Platforms } from "./integrationsEnums";
import { useUpdateIntegrationMutation } from "./integrationsService";
import { editIntegration } from "./integrationsSlice";
import type {
  IPlatform,
  Integration,
  SyncADConfigsCommon,
} from "./integrationsTypes";

type ActiveDirectoryIntegrationProps = {
  platform: IPlatform;
  authInfo?: Pick<Integration, "id" | "accessToken" | "expiresOn" | "settings">;
  onDisconnect?: (id: Integration["id"]) => void;
  className?: string;
};

function ActiveDirectoryIntegration({
  platform,
  authInfo,
  onDisconnect,
  className = "",
  ...props
}: ActiveDirectoryIntegrationProps) {
  const [configsDialogOpen, setConfigsDialogOpen] = useState<boolean>(false);
  const [isUpdatingSettings, setIsUpdatingSettings] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { openOAuthPopup, isLoading: isCreateADIntegrationPending } =
    useIntegration({ platform: Platforms.ACTIVE_DIRECTORY });

  const [updateIntegration] = useUpdateIntegrationMutation();

  async function saveConfigurations(
    settings: SyncADConfigsCommon
  ): Promise<void> {
    try {
      if (authInfo) {
        setIsUpdatingSettings(true);
        const { success, message, data } = await updateIntegration({
          id: authInfo.id,
          settings,
        }).unwrap();
        setIsUpdatingSettings(false);
        if (success) {
          dispatch(editIntegration(data));
          setConfigsDialogOpen(false);
        } else {
          toast.error(message);
        }
      }
    } catch (error) {
      setIsUpdatingSettings(false);
      handleFetchError(error as FetchError);
    }
  }

  return (
    <IntegrationCard platform={platform} className={className} {...props}>
      {!authInfo?.accessToken ? (
        <LoadingButton
          loading={isCreateADIntegrationPending}
          fullWidth
          onClick={openOAuthPopup}
          classes={theme.LoadingButtonXNoStartIcon}
        >
          Connect
        </LoadingButton>
      ) : (
        <div className="grid grid-cols-2 gap-2">
          <Button
            variant="outlined"
            fullWidth
            onClick={() => onDisconnect?.(authInfo.id)}
            classes={{
              ...theme.OutlinedButtonX,
              root: theme.OutlinedButtonX.root
                .replace("!border-gray-200", "!border-error-200")
                .replace(
                  "!text-black",
                  "!text-error-400  hover:!text-error-500"
                ),
            }}
          >
            Disconnect
          </Button>
          <Button
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faGear} className="!text-sm" />}
            onClick={() => setConfigsDialogOpen(true)}
            classes={theme.OutlinedButtonX}
          >
            Configure
          </Button>
          <SyncADConfigsCommonDialog
            data={authInfo.settings}
            onSave={saveConfigurations}
            onCancel={() => setConfigsDialogOpen(false)}
            loading={isUpdatingSettings}
            open={configsDialogOpen}
            onClose={() => setConfigsDialogOpen(false)}
            fullScreen
            className="!max-w-2xl"
          />
        </div>
      )}
    </IntegrationCard>
  );
}

export default ActiveDirectoryIntegration;
