import Styled from "@emotion/styled";
import {
  faArrowLeft,
  faLayerGroup,
  faLinkSlash,
  faPlus,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SvgIcon,
  Tooltip,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Upload, { RcFile } from "antd/es/upload";
import parse from "html-react-parser";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  ConfirmationDialog,
  FloatingLabel,
  Spinner,
  SwitchX,
  TestLinkButton,
  WifiLinkValue,
} from "../../components";
import AppConfigs from "../../configs/appConfigs";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import {
  FetchError,
  getSVGString,
  handleFetchError,
  isDeepEqual,
} from "../../utils";
import {
  LinkToPreview,
  LinkTypeCode,
  getLinkTypeByCode,
  getLinkValuePlaceholder,
  getMemberLinkIcon,
  removeLinks,
  setLinkCategories,
  setLinkTypes,
  updateLinks,
  useReadLinkTypesMutation,
} from "../links";
import { MemberDataLabels } from "../members/membersEnums";
import {
  EditableTemplateLinkProps,
  TemplateLink,
  TemplateLinkToEdit,
  editTemplateLink,
  getUpdatedTemplateLinkProps,
  removeTemplateLink,
  useDeleteTemplateLinkMutation,
  useUpdateTemplateLinkMutation,
} from "../templates";

const UploadX = Styled(Upload)`
  width: auto !important;

  .ant-upload.ant-upload-select {
    background: none !important;
    border: none !important;
    border-radius: 0.5rem !important;
    height: 3.75rem !important;
    margin: 0 !important;
    overflow: hidden;
    width: 3.75rem !important;
  }
`;

type EditTemplateLinkProps = {
  link: TemplateLink;
  onBack?: () => void;
  onChange?: (linkToPreview: LinkToPreview) => void;
  onUpdate?: (link: TemplateLink) => void;
  onRemove?: (linkId: TemplateLink["id"]) => void;
  onCancel?: () => void;
  className?: string;
};

function EditTemplateLink({
  link,
  onBack,
  onChange,
  onUpdate,
  onRemove,
  onCancel,
  className = "",
  ...props
}: EditTemplateLinkProps) {
  const [removeTemplateLinkDialogShow, setRemoveTemplateLinkDialogShow] =
    useState<boolean>(false);
  const initLinkToEdit = useMemo(
    () => ({
      id: link.id,
      linkTypeCode: link.linkTypeCode,
      iconSVG: link.iconSVG,
      title: link.title,
      value: link.value,
      isHighlighted: link.isHighlighted,
      templateId: link.templateId,
      iconFile: null,
    }),
    [link]
  );
  const [linkToEdit, setLinkToEdit] =
    useState<TemplateLinkToEdit>(initLinkToEdit);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const inputLinkValueRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  const thisLinkType = useAppSelector((state: RootState) =>
    getLinkTypeByCode(state, link.linkTypeCode)
  );

  const [readLinkTypes, { isLoading: isReadLinkTypesPending }] =
    useReadLinkTypesMutation();

  useEffect(() => {
    if (thisLinkType === undefined) {
      (async () => {
        try {
          const {
            success,
            message,
            data: { linkCategories, linkTypes },
          } = await readLinkTypes().unwrap();
          if (success) {
            dispatch(setLinkCategories(linkCategories));
            dispatch(setLinkTypes(linkTypes));
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    }
  }, [thisLinkType, readLinkTypes, dispatch]);

  useEffect(() => {
    if (thisLinkType) {
      setIsUpdated(
        (!thisLinkType?.isIconEditable || !!linkToEdit.iconSVG) &&
          (!thisLinkType?.isTitleEditable || !!linkToEdit.title) &&
          (!thisLinkType?.isValueEditable ||
            !!linkToEdit.value ||
            link.isUnique) &&
          !isDeepEqual(initLinkToEdit, linkToEdit)
      );
    }
  }, [thisLinkType, link.isUnique, initLinkToEdit, linkToEdit]);

  useEffect(() => {
    if (linkToEdit) {
      onChange?.(linkToEdit);
    }
  }, [linkToEdit, onChange]);

  async function handleBeforeUpload(image: RcFile) {
    const {
      success,
      message,
      data: { iconUrl, iconObj },
    } = await getMemberLinkIcon(image);
    if (success) {
      setLinkToEdit({
        ...linkToEdit,
        iconSVG: getSVGString(iconUrl),
        iconFile: iconObj || null,
      });
    } else {
      toast.error(message);
    }

    return false;
  }

  function handleHighlightChange(
    _e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void {
    setLinkToEdit({ ...linkToEdit, isHighlighted: checked });
  }

  const [updateTemplateLink, { isLoading: isUpdateTemplateLinkPending }] =
    useUpdateTemplateLinkMutation();

  async function handleSubmitClick() {
    if (thisLinkType?.isTitleEditable && !linkToEdit.title) {
      toast.error("Title is required!");
      return;
    }

    const updatedTemplateLinkProps: EditableTemplateLinkProps =
      getUpdatedTemplateLinkProps(link, linkToEdit);

    if (Object.keys(updatedTemplateLinkProps).length === 0) {
      onCancel?.();
      return;
    }

    try {
      const {
        success,
        message,
        data: { updatedTemplateLink, updatedMemberLinks },
      } = await updateTemplateLink({
        id: linkToEdit.id,
        templateId: linkToEdit.templateId,
        ...updatedTemplateLinkProps,
      }).unwrap();
      if (success) {
        dispatch(editTemplateLink(updatedTemplateLink));
        dispatch(updateLinks(updatedMemberLinks));
        onUpdate?.({
          ...link,
          ...updatedTemplateLink,
        });
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  const [deleteTemplateLink, { isLoading: isDeleteTemplateLinkPending }] =
    useDeleteTemplateLinkMutation();

  async function handleRemoveTemplateLinkConfirm() {
    try {
      const {
        success,
        message,
        data: { deletedTemplateLinkId, deletedMemberLinkIds },
      } = await deleteTemplateLink({
        id: linkToEdit.id,
        templateId: linkToEdit.templateId,
      }).unwrap();
      if (success) {
        dispatch(removeTemplateLink(deletedTemplateLinkId));
        dispatch(removeLinks(deletedMemberLinkIds));
        setRemoveTemplateLinkDialogShow(false);
        onRemove?.(deletedTemplateLinkId);
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  function resetToDefaultIcon() {
    setLinkToEdit({
      ...linkToEdit,
      iconFile: null,
      iconSVG:
        thisLinkType?.iconSVGDefault ||
        thisLinkType?.iconSVGColorized ||
        linkToEdit.iconSVG,
    });
  }

  return (
    <div className={`relative flex flex-col gap-8 ${className}`} {...props}>
      <div className="flex items-center gap-4">
        <Button
          variant="outlined"
          onClick={() => {
            onChange?.(link);
            onBack?.();
          }}
          className="inline-flex cursor-pointer items-center gap-2 !rounded-full !border !border-gray-200 !bg-white !px-4 !py-2 !font-sans !text-xs !normal-case !text-black !no-underline"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <span className="font-semibold">Back</span>
        </Button>
        <DialogTitle className="!p-0 !font-sans !font-semibold text-black">
          {linkToEdit?.title}
        </DialogTitle>
      </div>
      <DialogContent className="!p-0">
        <div className="flex flex-col gap-8">
          <div className="flex items-center justify-between gap-8 rounded-2xl bg-gray-100 px-4 py-3">
            {!thisLinkType?.isIconEditable ? (
              <Avatar
                variant="rounded"
                className="!h-[3.75rem] !w-[3.75rem] !rounded-[0.658rem] !bg-transparent"
              >
                {linkToEdit.iconSVG && (
                  <SvgIcon className="!h-full !w-full">
                    {parse(linkToEdit.iconSVG)}
                  </SvgIcon>
                )}
              </Avatar>
            ) : (
              <div className="relative flex items-center gap-4">
                <UploadX
                  accept={AppConfigs.getAcceptedFileTypes(
                    MemberDataLabels.PROFILE_LINK
                  )}
                  name={linkToEdit.title}
                  listType="picture-circle"
                  showUploadList={false}
                  beforeUpload={handleBeforeUpload}
                  className="overflow-hidden rounded-[0.658rem]"
                >
                  {linkToEdit.iconSVG && (
                    <SvgIcon className="!h-full !w-full">
                      {parse(linkToEdit.iconSVG)}
                    </SvgIcon>
                  )}
                </UploadX>
                {![
                  thisLinkType.iconSVGDefault,
                  thisLinkType.iconSVGColorized,
                ].includes(linkToEdit.iconSVG) && (
                  <Tooltip
                    title="Click here to restore the original default button icon."
                    placement="top"
                    disableFocusListener
                    disableTouchListener
                    className="!absolute -top-4 left-10"
                    classes={{ tooltip: "!mb-0" }}
                  >
                    <IconButton onClick={resetToDefaultIcon}>
                      <ClearIcon
                        fontSize="small"
                        className="!h-5 !w-5 cursor-pointer rounded-full bg-white !p-0.5 text-black"
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <span className="w-60 text-sm font-medium text-gray-500">
                  Select photo here or drag and drop one in place of current
                </span>
              </div>
            )}
            <div className="flex items-center gap-8">
              <SwitchX
                checked={linkToEdit.isHighlighted}
                label="Highlight"
                labelPlacement="end"
                onChange={handleHighlightChange}
              />
              <Button
                classes={{
                  root: "!flex !items-center !justify-center !gap-2 !rounded-full !border !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !text-black !normal-case",
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
                Add to other templates
              </Button>
            </div>
          </div>
          {thisLinkType?.isValueEditable && (
            <div>
              {thisLinkType?.code === LinkTypeCode.WIFI ? (
                link.isUnique ? (
                  <WifiLinkValue
                    name="link-value"
                    placeholder={getLinkValuePlaceholder(thisLinkType)}
                    value={link.value}
                    icon={faLayerGroup}
                    iconTitle={`This is a Unique Link. The value \nis distinct for every member.`}
                    disabled
                  />
                ) : (
                  <WifiLinkValue
                    name="link-value"
                    placeholder={`${getLinkValuePlaceholder(thisLinkType)}`}
                    value={linkToEdit.value}
                    onChange={(value: string) =>
                      setLinkToEdit({ ...linkToEdit, value })
                    }
                  />
                )
              ) : link.isUnique ? (
                <FloatingLabel
                  icon={faLayerGroup}
                  iconTitle={`This is a Unique Link. The value \nis distinct for every member.`}
                >
                  <input
                    type="text"
                    name="link-value"
                    placeholder={`${getLinkValuePlaceholder(thisLinkType)}*`}
                    value={link.value}
                    disabled
                  />
                </FloatingLabel>
              ) : (
                <FloatingLabel
                  onInputValueChange={(value: string) =>
                    setLinkToEdit({ ...linkToEdit, value })
                  }
                >
                  <input
                    type="text"
                    ref={inputLinkValueRef}
                    name="link-value"
                    placeholder={`${getLinkValuePlaceholder(thisLinkType)}*`}
                    value={linkToEdit.value}
                  />
                </FloatingLabel>
              )}
            </div>
          )}
          {thisLinkType?.isTitleEditable && (
            <div>
              <FloatingLabel
                onInputValueChange={(value: string) =>
                  setLinkToEdit({ ...linkToEdit, title: value })
                }
              >
                <input
                  type="text"
                  name="link-title"
                  placeholder="Button title"
                  value={linkToEdit.title}
                />
              </FloatingLabel>
            </div>
          )}
          {thisLinkType?.description && (
            <p className="whitespace-pre-line text-sm font-medium text-gray-500">
              {thisLinkType.description}
            </p>
          )}
          {thisLinkType && (
            <div>
              <TestLinkButton
                linkType={thisLinkType}
                linkValue={thisLinkType.isValueEditable ? linkToEdit.value : ""}
              />
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions
        classes={{
          root: "gap-4 !border-t border-gray-200 !p-0 !pt-8 !justify-end",
        }}
      >
        <span className="grow">
          <Button
            variant="outlined"
            onClick={() => setRemoveTemplateLinkDialogShow(true)}
            startIcon={<DeleteOutlinedIcon />}
            className="!m-0 !rounded-full !border-error-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-error-500"
          >
            Remove
          </Button>
          <ConfirmationDialog
            title="Confirm Removal"
            content="Are you sure you want to remove this button?"
            confirmButtonIcon={
              <FontAwesomeIcon
                icon={isDeleteTemplateLinkPending ? faSpinner : faLinkSlash}
                className="!text-sm"
              />
            }
            confirmButtonText="Remove"
            onConfirm={handleRemoveTemplateLinkConfirm}
            onCancel={() => setRemoveTemplateLinkDialogShow(false)}
            open={removeTemplateLinkDialogShow}
            danger
          />
          {isDeleteTemplateLinkPending && <Spinner className="bg-white/50" />}
        </span>
        <Button
          variant="outlined"
          onClick={() => {
            onChange?.(link);
            onCancel?.();
          }}
          className="!m-0 !rounded-full !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-black"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={handleSubmitClick}
          disabled={!isUpdated || isUpdateTemplateLinkPending}
          classes={{
            root: `!rounded-full !px-6 !py-3 !font-semibold !text-white !normal-case !font-sans !text-sm !m-0 ${
              !isUpdated || isUpdateTemplateLinkPending
                ? "!bg-gray-300"
                : "!bg-primary"
            }`,
          }}
        >
          Update
        </Button>
        {isUpdateTemplateLinkPending && <Spinner className="bg-white/50" />}
      </DialogActions>
      {isReadLinkTypesPending && <Spinner className="bg-white/50" />}
    </div>
  );
}

export default EditTemplateLink;
