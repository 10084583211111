import { Button as MuiButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, Dropdown } from "antd";
import { ReactElement, cloneElement } from "react";
import type {
  DropdownMenuItem,
  MemberEditMenuInfo,
} from "../features/members/membersTypes";
import type { Template } from "../features/templates/templatesTypes";

type MemberEditMenuProps = {
  member: MemberEditMenuInfo;
  template?: Template;
  items: DropdownMenuItem[];
  className?: string;
};

function MemberEditMenu({
  member,
  template,
  items,
  className = "",
  ...props
}: MemberEditMenuProps) {
  return (
    <Dropdown
      menu={{
        items: items
          // Skip if any of the following condition is true
          ?.filter(
            (anItem) =>
              !(
                (anItem.key === "ActivateOneShare" &&
                  (member.openDirect || template?.isProfileOpensLocked)) ||
                (anItem.key === "DeactivateOneShare" &&
                  (!member.openDirect || template?.isProfileOpensLocked)) ||
                (anItem.key === "Lock" && member.locked) ||
                (anItem.key === "Unlock" && !member.locked) ||
                (anItem.key === "Public" && member.isPublic) ||
                (anItem.key === "Private" && !member.isPublic) ||
                (anItem.key === "ADSyncSettings" && !member.isSyncedWithAD) ||
                (anItem.key === "ShowConnectButton" &&
                  (member.connectButton || template?.isControlButtonsLocked)) ||
                (anItem.key === "HideConnectButton" &&
                  (!member.connectButton ||
                    template?.isControlButtonsLocked)) ||
                (anItem.key === "ShowSaveContactButton" &&
                  (member.saveContactButton ||
                    template?.isControlButtonsLocked)) ||
                (anItem.key === "HideSaveContactButton" &&
                  (!member.saveContactButton ||
                    template?.isControlButtonsLocked)) ||
                (anItem.key === "SetProfilePicture" &&
                  !!template?.profileImage) ||
                (anItem.key === "SetCoverPhoto" && !!template?.profileBanner) ||
                (anItem.key === "SetCompanyLogo" && !!template?.companyLogo)
              )
          )
          .map((anItem, index) => ({
            key: index,
            label:
              anItem.type === "divider" ? (
                <hr className="mx-1" />
              ) : (
                <span
                  className={`group relative block hover:bg-slate-50 ${
                    anItem.className || ""
                  }`}
                >
                  <Button
                    onClick={() =>
                      anItem.type !== "uploader" &&
                      anItem.onClick?.([member.id])
                    }
                    className="flex h-auto w-full cursor-pointer items-center justify-start gap-3 border-none px-6 py-3.5 font-sans text-sm text-gray-500 shadow-none group-hover:!text-primary"
                  >
                    {anItem.icon && anItem.classes?.icon
                      ? cloneElement(anItem.icon, {
                          className: [
                            anItem.icon?.props.className,
                            anItem.classes?.icon,
                          ].join(" "),
                        })
                      : anItem.icon}
                    <span
                      className={`text-xs font-semibold group-hover:text-inherit ${
                        anItem.classes?.name || ""
                      }`}
                    >
                      {anItem.name}
                    </span>
                  </Button>
                  {anItem.type === "uploader" && (
                    <input
                      type="file"
                      accept={anItem.acceptedFileTypes}
                      onClick={(e) => {
                        (e.target as HTMLInputElement).value = "";
                      }}
                      onInput={(e) =>
                        anItem.onClick?.(
                          [member.id],
                          (e.target as HTMLInputElement).files
                        )
                      }
                      className="absolute inset-0 cursor-pointer opacity-0"
                    />
                  )}
                </span>
              ),
          })),
      }}
      placement="bottomRight"
      trigger={["click"]}
      dropdownRender={(menu) =>
        cloneElement(menu as ReactElement, {
          className:
            "addmee-menu-edit !rounded-xl bg-white !px-0 !py-2 shadow-xl",
        })
      }
    >
      <MuiButton
        variant="outlined"
        aria-label="settings"
        classes={{ root: className }}
        {...props}
      >
        <MoreVertIcon fontSize="small" />
      </MuiButton>
    </Dropdown>
  );
}

export default MemberEditMenu;
