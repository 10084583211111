import { faContactBook } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Avatar, SvgIcon, Tooltip } from "@mui/material";
import parse from "html-react-parser";
import React from "react";
import { ReactComponent as ContactCardIcon } from "../assets/images/buttons/contact_card_icon.svg";
import type { MemberLink } from "../features/links/linksTypes";
import type { TemplateLink } from "../features/templates/templatesTypes";

type ContactCardButtonProps = {
  links: Array<
    | Pick<MemberLink, "iconSVG" | "title">
    | Pick<TemplateLink, "iconSVG" | "title">
  >;
  onClick?: () => void;
  className?: string;
};

function ContactCardButton({
  links,
  onClick,
  className = "",
  ...props
}: ContactCardButtonProps) {
  return (
    <div
      role="button"
      onClick={onClick}
      className={`relative flex items-center gap-5 overflow-hidden rounded-2xl bg-gradient-to-t from-primary-50 to-gray-50 p-3 ${className}`}
      {...props}
    >
      <FontAwesomeIcon
        icon={faContactBook}
        className="absolute right-4 top-1/2 -translate-y-1/2 -rotate-12 text-8xl text-primary opacity-10"
      />
      <span className="flex grow items-center gap-4">
        <Avatar
          alt="Contact Card"
          variant="rounded"
          className="!rounded-[0.439rem] !bg-transparent shadow-md"
        >
          <SvgIcon className="!h-full !w-full">
            <ContactCardIcon />
          </SvgIcon>
        </Avatar>
        <strong className="font-semibold">Contact Card</strong>
      </span>
      {links.length > 0 && (
        <div className="flex gap-4 pr-36">
          {links
            .filter((_, i) => i < 8)
            .map((aLink, index) => (
              <Tooltip
                key={"contact-card-added-" + index}
                title={index < 7 ? aLink.title : "Others"}
                placement="top-start"
                enterDelay={1000}
                enterNextDelay={2000}
                classes={{ tooltip: "!mb-1" }}
              >
                <Avatar
                  alt={aLink.title}
                  variant="rounded"
                  className="!rounded-[0.439rem] !bg-transparent shadow-md"
                >
                  {index < 7 ? (
                    aLink.iconSVG && (
                      <SvgIcon className="!h-full !w-full">
                        {parse(aLink.iconSVG)}
                      </SvgIcon>
                    )
                  ) : (
                    <MoreHorizIcon className="text-black" />
                  )}
                </Avatar>
              </Tooltip>
            ))}
        </div>
      )}
    </div>
  );
}

export default ContactCardButton;
