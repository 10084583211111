import { createApi } from "@reduxjs/toolkit/query/react";
import APIConfigs from "../../configs/apiConfigs";
import { baseQueryWithReauth } from "../rtk-query/baseQuery";
import {
  getAddMemberLinksToContactCardRequestBody,
  getCreateBulkLinkRequestBody,
  getCreateNewLinkRequestBody,
  getUpdateMemberLinkRequestBody,
  mapAddMemberLinksToContactCardResponse,
  mapCreateBulkLinkResponse,
  mapCreateNewLinkResponse,
  mapReadLinkTypesResponse,
  mapReadMemberLinksResponse,
  mapReorderMemberLinksResponse,
  mapUpdateMemberLinkResponse,
} from "./linksMapper";
import type {
  AddMemberLinksToContactCardRequestArgs,
  AddMemberLinksToContactCardResponse,
  CreateBulkLinkRequestArgs,
  CreateBulkLinkResponse,
  CreateNewLinkRequestArgs,
  CreateNewLinkResponse,
  DeleteMemberLinksRequestArgs,
  DeleteMemberLinksResponse,
  ReadLinkTypesResponse,
  ReadMemberLinksResponse,
  ReorderMemberLinksRequestArgs,
  ReorderMemberLinksResponse,
  UpdateMemberLinkRequestArgs,
  UpdateMemberLinkResponse,
} from "./linksTypes";

const linksService = createApi({
  reducerPath: "linksService",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    readLinkTypes: builder.mutation<ReadLinkTypesResponse, void>({
      query: () => APIConfigs.Links.READ_LINK_TYPES,
      transformResponse: mapReadLinkTypesResponse,
    }),
    readMemberLinks: builder.mutation<ReadMemberLinksResponse, void>({
      query: () => APIConfigs.Links.READ_MEMBER_LINKS,
      transformResponse: mapReadMemberLinksResponse,
    }),
    createBulkLink: builder.mutation<
      CreateBulkLinkResponse,
      CreateBulkLinkRequestArgs
    >({
      query: (args) => ({
        method: "POST",
        url: APIConfigs.Links.CREATE_MEMBERS_LINK,
        body: getCreateBulkLinkRequestBody(args),
      }),
      transformResponse: mapCreateBulkLinkResponse,
    }),
    createNewLink: builder.mutation<
      CreateNewLinkResponse,
      CreateNewLinkRequestArgs
    >({
      query: (link) => ({
        method: "POST",
        url: APIConfigs.Links.CREATE_MEMBER_LINK.replace(
          ":memberId",
          link.userId.toString()
        ),
        body: getCreateNewLinkRequestBody(link),
      }),
      transformResponse: mapCreateNewLinkResponse,
    }),
    reorderMemberLinks: builder.mutation<
      ReorderMemberLinksResponse,
      ReorderMemberLinksRequestArgs
    >({
      query: ({ memberId, links }) => ({
        method: "PUT",
        url: APIConfigs.Links.UPDATE_MEMBER_LINKS.replace(
          ":memberId",
          memberId.toString()
        ),
        body: {
          sequence: JSON.stringify(
            links.reduce(
              (sequence: { [key: number]: number }, aLink, index) => {
                sequence[aLink.id] = index;
                return sequence;
              },
              {}
            )
          ),
        },
      }),
      transformResponse: mapReorderMemberLinksResponse,
    }),
    updateMemberLink: builder.mutation<
      UpdateMemberLinkResponse,
      UpdateMemberLinkRequestArgs
    >({
      query: ({ id: linkId, userId, ...propsToUpdate }) => ({
        method: "POST",
        url: APIConfigs.Links.UPDATE_MEMBER_LINK.replace(
          ":memberId",
          userId.toString()
        ).replace(":linkId", linkId.toString()),
        body: getUpdateMemberLinkRequestBody(propsToUpdate),
      }),
      transformResponse: mapUpdateMemberLinkResponse,
    }),
    deleteMemberLinks: builder.mutation<
      DeleteMemberLinksResponse,
      DeleteMemberLinksRequestArgs
    >({
      query: ({ id, userId, globalId }) => ({
        method: "DELETE",
        url: APIConfigs.Links.DELETE_MEMBER_LINK.replace(
          ":memberId",
          userId.toString()
        )
          .replace(":linkId", id.toString())
          .replace("/:globalId", globalId ? "/" + globalId.toString() : ""),
      }),
    }),
    addMemberLinksToContactCard: builder.mutation<
      AddMemberLinksToContactCardResponse,
      AddMemberLinksToContactCardRequestArgs
    >({
      query: ({ userId, memberLinkIds, isAddedToContactCardBy }) => ({
        method: "POST",
        url: APIConfigs.ContactCard.ADD_MEMBER_LINKS.replace(
          ":memberId",
          userId.toString()
        ),
        body: getAddMemberLinksToContactCardRequestBody(
          memberLinkIds,
          isAddedToContactCardBy
        ),
      }),
      transformResponse: mapAddMemberLinksToContactCardResponse,
    }),
  }),
});

export default linksService;
export const {
  useReadLinkTypesMutation,
  useReadMemberLinksMutation,
  useCreateBulkLinkMutation,
  useCreateNewLinkMutation,
  useReorderMemberLinksMutation,
  useUpdateMemberLinkMutation,
  useDeleteMemberLinksMutation,
  useAddMemberLinksToContactCardMutation,
} = linksService;
