import { faAddressCard, faSave } from "@fortawesome/free-regular-svg-icons";
import { faArrowsRotate, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { LoadingButton } from "@mui/lab";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  ConfirmationDialog,
  PelletWithColorPicker,
  SwitchX,
  TabX,
  TabsX,
  tabPanelA11yProps,
} from "../../components";
import { useAppDispatch } from "../../store";
import { FetchError, handleFetchError } from "../../utils";
import {
  editMemberColorsSettings,
  getUpdatedColorsSettings,
  Member,
  MemberCustomColors,
  MemberCustomColorsSections,
  MemberSettings,
  useUpdateMemberColorsSettingsMutation,
} from "../members";
import { defaultColorsSettings } from "../settings/settingsEnums";
import type { Template, TemplateSettings } from "../templates/templatesTypes";

type EditMemberColorsProps = {
  member: Member;
  memberColors: MemberSettings["colors"];
  template?: Pick<Template, "id" | "isColorsCustomLocked">;
  templateColors?: TemplateSettings["colors"];
  onChange?: (updatedMemberColors: MemberCustomColors) => void;
  changed?: boolean;
  onChangesSaved?: () => void;
  onChangesDiscarded?: () => void;
  className?: string;
};

function EditMemberColors({
  member,
  memberColors,
  template,
  templateColors,
  onChange,
  changed = false,
  onChangesSaved,
  onChangesDiscarded,
  className = "",
  ...props
}: EditMemberColorsProps) {
  const initUpdatedMemberColors = useMemo(() => {
    return template?.isColorsCustomLocked
      ? { ...memberColors, ...templateColors }
      : memberColors;
  }, [template, memberColors, templateColors]);

  const [updatedMemberColors, setUpdatedMemberColors] =
    useState<MemberCustomColors>(initUpdatedMemberColors);
  const [tabIndex, setTabIndex] = useState<MemberCustomColorsSections>(
    MemberCustomColorsSections.SECTION
  );
  const [buttonsTabIndex, setButtonsTabIndex] =
    useState<MemberCustomColorsSections>(
      MemberCustomColorsSections.CONTROL_BUTTONS
    );
  const [showPendingChagesDialog, setShowPendingChagesDialog] =
    useState<boolean>(false);
  const [thisTemplate, setThisTemplate] =
    useState<EditMemberColorsProps["template"]>();
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (template?.isColorsCustomLocked && templateColors) {
      setUpdatedMemberColors(templateColors);
    } else if (memberColors) {
      setUpdatedMemberColors(memberColors);
    }
  }, [memberColors, template, templateColors]);

  useEffect(() => {
    setThisTemplate(template);
  }, [template]);

  useEffect(() => {
    onChange?.(updatedMemberColors);
  }, [thisTemplate, updatedMemberColors, onChange]);

  useEffect(() => {
    const updatedColorsSettings = getUpdatedColorsSettings(
      updatedMemberColors,
      initUpdatedMemberColors
    );
    setUnsavedChanges(Object.keys(updatedColorsSettings).length > 0);
  }, [member, updatedMemberColors, initUpdatedMemberColors]);

  const [
    updateMemberColorsSettings,
    { isLoading: isUpdateMemberColorsSettingsPending },
  ] = useUpdateMemberColorsSettingsMutation();

  async function handleColorsSubmit() {
    const updatedColorsSettings = getUpdatedColorsSettings(
      updatedMemberColors,
      memberColors
    );
    if (Object.keys(updatedColorsSettings).length > 0) {
      try {
        const {
          success,
          message,
          data: { colors: updatedColors },
        } = await updateMemberColorsSettings({
          userId: member.id,
          ...updatedColorsSettings,
        }).unwrap();
        if (success) {
          dispatch(editMemberColorsSettings(updatedColors));
          toast.success(message);
        } else {
          toast.error(message);
        }
      } catch (error) {
        handleFetchError(error as FetchError);
      }
    }
  }

  useEffect(() => {
    setShowPendingChagesDialog(changed);
  }, [changed]);

  function handlePendingChagesConfirm(): void {
    handleColorsSubmit().then(() => {
      setShowPendingChagesDialog(false);
      onChangesSaved?.();
    });
  }

  function handlePendingChagesCancel(): void {
    setUpdatedMemberColors(initUpdatedMemberColors);
    setShowPendingChagesDialog(false);
    onChangesDiscarded?.();
  }

  return (
    <div className={`relative flex flex-col gap-8 ${className}`} {...props}>
      <div className="flex items-center justify-between">
        <h4 className="text-xl font-semibold text-black">
          Color Customization
        </h4>
        {thisTemplate?.isColorsCustomLocked ? (
          <>
            <FontAwesomeIcon
              icon={faAddressCard}
              title={`This section comes from a template, \nand is unable to be edited.`}
            />
            <div className="absolute -inset-4 z-20 flex items-center justify-center rounded-2xl backdrop-blur-sm">
              <p className="flex w-2/3 flex-col items-center gap-2 rounded-2xl border border-warning-200 bg-white p-6 pt-3 text-center shadow-lg">
                <WarningRoundedIcon
                  fontSize="large"
                  className="!h-7 !w-7 text-warning-400"
                />
                <strong className="text-sm text-gray-700">
                  The color cannot be change because it's defined by the
                  Template.
                </strong>
                <small className="text-xs text-gray-600">
                  Either edit the color within the Template or open the lock
                  symbol within the Template to allow all assigned members to
                  customize the colors.
                </small>
              </p>
            </div>
          </>
        ) : (
          <button
            onClick={() => setUpdatedMemberColors(defaultColorsSettings)}
            className="flex gap-2 text-xs text-black"
          >
            <FontAwesomeIcon icon={faArrowsRotate} />
            Reset colors to default
          </button>
        )}
      </div>
      <div className="flex flex-col gap-4 rounded-2xl bg-gray-100 p-4">
        <div className="flex flex-col gap-4">
          <TabsX
            name={`Profile Colors Customization for ${member.firstName}`}
            value={tabIndex}
            onChange={(_e, v: number) => setTabIndex(v)}
          >
            <TabX label="Section" index={MemberCustomColorsSections.SECTION} />
            <TabX label="Profile" index={MemberCustomColorsSections.PROFILE} />
            <TabX
              label="Profile Border"
              index={MemberCustomColorsSections.PROFILE_BORDER}
            />
            <TabX label="Text" index={MemberCustomColorsSections.TEXT} />
          </TabsX>
          {tabIndex === MemberCustomColorsSections.SECTION && (
            <div {...tabPanelA11yProps(MemberCustomColorsSections.SECTION)}>
              <PelletWithColorPicker
                color={updatedMemberColors.sectionColor}
                onColorChange={(newColor) =>
                  setUpdatedMemberColors({
                    ...updatedMemberColors,
                    sectionColor: newColor,
                  })
                }
                disabled={thisTemplate?.isColorsCustomLocked}
              />
            </div>
          )}
          {tabIndex === MemberCustomColorsSections.PROFILE && (
            <div {...tabPanelA11yProps(MemberCustomColorsSections.PROFILE)}>
              <PelletWithColorPicker
                color={updatedMemberColors.profileColor}
                onColorChange={(newColor) =>
                  setUpdatedMemberColors({
                    ...updatedMemberColors,
                    profileColor: newColor,
                  })
                }
                disabled={thisTemplate?.isColorsCustomLocked}
              />
            </div>
          )}
          {tabIndex === MemberCustomColorsSections.PROFILE_BORDER && (
            <div
              {...tabPanelA11yProps(MemberCustomColorsSections.PROFILE_BORDER)}
            >
              <PelletWithColorPicker
                color={updatedMemberColors.borderColor}
                onColorChange={(newColor) =>
                  setUpdatedMemberColors({
                    ...updatedMemberColors,
                    borderColor: newColor,
                  })
                }
                disabled={thisTemplate?.isColorsCustomLocked}
              />
            </div>
          )}
          {tabIndex === MemberCustomColorsSections.TEXT && (
            <div {...tabPanelA11yProps(MemberCustomColorsSections.TEXT)}>
              <PelletWithColorPicker
                color={updatedMemberColors.textColor}
                onColorChange={(newColor) =>
                  setUpdatedMemberColors({
                    ...updatedMemberColors,
                    textColor: newColor,
                  })
                }
                disabled={thisTemplate?.isColorsCustomLocked}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <h5 className="pl-4 text-xl font-semibold text-black">Buttons</h5>
        <div className="flex flex-col gap-4 rounded-2xl bg-gray-100 p-4">
          <TabsX
            name={`Buttons Colors Customization for ${member.firstName}`}
            value={buttonsTabIndex - 4}
            onChange={(_e, v: number) => setButtonsTabIndex(v + 4)}
          >
            <TabX
              label="Control Buttons"
              index={MemberCustomColorsSections.CONTROL_BUTTONS}
            />
            <TabX
              label="Profile Buttons"
              index={MemberCustomColorsSections.PROFILE_BUTTONS}
            />
          </TabsX>
          {buttonsTabIndex === MemberCustomColorsSections.CONTROL_BUTTONS && (
            <div
              className="flex flex-col gap-4"
              {...tabPanelA11yProps(MemberCustomColorsSections.CONTROL_BUTTONS)}
            >
              <div className="flex flex-col gap-2">
                <h6 className="text-xs font-normal text-gray-600">
                  Text Color
                </h6>
                <PelletWithColorPicker
                  color={updatedMemberColors.controlButtonTextColor}
                  onColorChange={(newColor) =>
                    setUpdatedMemberColors({
                      ...updatedMemberColors,
                      controlButtonTextColor: newColor,
                    })
                  }
                  disabled={thisTemplate?.isColorsCustomLocked}
                />
              </div>
              <div className="flex flex-col gap-2">
                <h6 className="text-xs font-normal text-gray-600">
                  Background Color
                </h6>
                <PelletWithColorPicker
                  color={updatedMemberColors.controlButtonBgColor}
                  onColorChange={(newColor) =>
                    setUpdatedMemberColors({
                      ...updatedMemberColors,
                      controlButtonBgColor: newColor,
                    })
                  }
                  disabled={thisTemplate?.isColorsCustomLocked}
                />
              </div>
            </div>
          )}
          {buttonsTabIndex === MemberCustomColorsSections.PROFILE_BUTTONS && (
            <div
              className="flex flex-col gap-4"
              {...tabPanelA11yProps(MemberCustomColorsSections.PROFILE_BUTTONS)}
            >
              <div className="flex flex-col gap-2">
                <h6 className="text-xs font-normal text-gray-600">
                  Icon Color
                </h6>
                <PelletWithColorPicker
                  color={updatedMemberColors.profileButtonIconColor}
                  onColorChange={(newColor) =>
                    setUpdatedMemberColors({
                      ...updatedMemberColors,
                      profileButtonIconColor: newColor,
                    })
                  }
                  disabled={thisTemplate?.isColorsCustomLocked}
                />
              </div>
              <div className="flex flex-col gap-2">
                <h6 className="text-xs font-normal text-gray-600">
                  Background Color
                </h6>
                <PelletWithColorPicker
                  color={updatedMemberColors.profileButtonBgColor}
                  onColorChange={(newColor) =>
                    setUpdatedMemberColors({
                      ...updatedMemberColors,
                      profileButtonBgColor: newColor,
                    })
                  }
                  disabled={thisTemplate?.isColorsCustomLocked}
                />
              </div>
              <div className="flex items-center justify-between gap-4 rounded-lg bg-white p-2">
                <label className="text-sm font-medium text-gray-700">
                  Color the button icons
                </label>
                <SwitchX
                  checked={updatedMemberColors.colorLinkIcons}
                  onChange={(_e, v) =>
                    setUpdatedMemberColors({
                      ...updatedMemberColors,
                      colorLinkIcons: v,
                    })
                  }
                  disabled={thisTemplate?.isColorsCustomLocked}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center justify-end gap-8 border-t border-gray-200 pt-8">
        <LoadingButton
          type="submit"
          startIcon={<FontAwesomeIcon icon={faSave} className="!text-sm" />}
          loading={isUpdateMemberColorsSettingsPending}
          loadingPosition="start"
          disabled={!unsavedChanges}
          classes={{
            root: `!rounded-full ${
              unsavedChanges ? "!bg-primary" : ""
            } !px-6 !py-3 !font-sans !text-sm !normal-case !text-white`,
            disabled: "!bg-gray-300",
            loadingIndicator: "!left-5",
          }}
          onClick={handleColorsSubmit}
        >
          Update
        </LoadingButton>
      </div>
      <ConfirmationDialog
        title="Save Pending Changes?"
        content="There are pending changes. Do you want to update them?"
        confirmButtonIcon={
          <FontAwesomeIcon
            icon={isUpdateMemberColorsSettingsPending ? faSpinner : faSave}
            className="!text-sm"
          />
        }
        confirmButtonText="Update"
        onConfirm={handlePendingChagesConfirm}
        cancelButtonText="Discard"
        onCancel={handlePendingChagesCancel}
        open={showPendingChagesDialog}
      />
    </div>
  );
}

export default EditMemberColors;
