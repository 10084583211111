import { ColorResult, RGBColor } from "react-color";
import type { RGBAString } from "./utilsTypes";

/** Method to convert a HEX value as string to RGBAString */
function hexToRGBAString(hexString: string): RGBAString | null {
  const hex = hexString.replace("#", "");
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])([a-f\d])?$/i;
  const longhandRegex =
    /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i;

  let result = longhandRegex.exec(hex) || shorthandRegex.exec(hex);

  if (result) {
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    let a = 1.0;

    if (result[4]) {
      // If alpha channel is present in the Hex string
      a = parseInt(result[4], 16) / 255.0;
    }

    return getRGBAString({ r, g, b, a });
  } else {
    return null;
  }
}

/** Method to convert a RGBA/RGB value as string to RGBAString */
function rgbToRGBAString(rgbString: string): RGBAString | null {
  const rgbaRegex = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)/;
  const match = rgbaRegex.exec(rgbString);

  if (match) {
    const r = parseInt(match[1], 10);
    const g = parseInt(match[2], 10);
    const b = parseInt(match[3], 10);
    const a = match[4] ? parseFloat(match[4]) : 1.0;

    return getRGBAString({ r, g, b, a });
  } else {
    return null;
  }
}

/** Method to convert a HSLA/HSL value as string to RGBAString */
function hslToRGBAString(hslString: string): RGBAString | null {
  const hslaRegex = /hsla?\((\d+),\s*(\d+)%,\s*(\d+)%(?:,\s*([\d.]+))?\)/;
  const hslaMatch = hslaRegex.exec(hslString);

  if (hslaMatch) {
    const h = parseInt(hslaMatch[1], 10);
    const s = parseInt(hslaMatch[2], 10);
    const l = parseInt(hslaMatch[3], 10);
    const a = hslaMatch[4] ? parseFloat(hslaMatch[4]) : 1.0;

    const c = (1 - Math.abs(2 * l - 1)) * (s / 100);
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l - c / 2;

    let r = 0;
    let g = 0;
    let b = 0;

    if (0 <= h && h < 60) {
      r = c;
      g = x;
    } else if (60 <= h && h < 120) {
      r = x;
      g = c;
    } else if (120 <= h && h < 180) {
      g = c;
      b = x;
    } else if (180 <= h && h < 240) {
      g = x;
      b = c;
    } else if (240 <= h && h < 300) {
      r = x;
      b = c;
    } else if (300 <= h && h < 360) {
      r = c;
      b = x;
    }

    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return getRGBAString({ r, g, b, a });
  } else {
    return null;
  }
}

/** Method to convert a HEX, RGBA/RGB, or HSLA/HSL value as string to RGBAString */
export function getRGBAString(color: string | RGBColor): RGBAString | null {
  if (typeof color === "string") {
    // Check if the string is in Hex format (e.g., #702C8955 or #702C89)
    if (color.startsWith("#")) {
      return hexToRGBAString(color);
    }

    // Check if the string is in RGBA format (e.g., rgba(112, 44, 137, 0.33) or rgb(112, 44, 137))
    else if (color.startsWith("rgba(") || color.startsWith("rgb(")) {
      return rgbToRGBAString(color);
    }

    // Check if the string is in HSLA format (e.g., hsla(284, 51%, 35%, 0.33) or hsl(284, 51%, 35%))
    else if (color.startsWith("hsl(") || color.startsWith("hsla(")) {
      return hslToRGBAString(color);
    }

    // Invalid Hex Color String
    else {
      return null;
    }
  }

  // Convert an RGBColor to a RGBA String
  else if (
    typeof color === "object" &&
    color !== null &&
    "r" in color &&
    "g" in color &&
    "b" in color
  ) {
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a ?? 1})`;
  }

  // Invalid Color value
  else {
    return null;
  }
}

/** Method to convert a HEX, RGBA/RGB, or HSLA/HSL value as string to RGBColor */
export function getRGBColor(colorString: string): RGBColor | null {
  const rgbaString = getRGBAString(colorString);

  // Chech for invalid color format
  const validColor = rgbaString
    ?.replace(/\s/g, "")
    .match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)/);
  if (!validColor) {
    return null;
  }

  // Extract RGBA values from the string
  const [r, g, b, a] = validColor.map(parseFloat).slice(1);

  return { r, g, b, a: a ?? 1 };
}

/** Method to convert a HEX, RGBA/RGB, or HSLA/HSL value as string to ColorResult */
export function getColorResult(colorString: string): ColorResult | null {
  const rgbaString = getRGBAString(colorString);

  // Chech for invalid color format
  const validColor = rgbaString?.match(
    /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)/
  );
  if (!validColor) {
    return null;
  }

  // Extract RGBA values from the string
  const [r, g, b, a] = validColor.map(parseFloat).slice(1);

  // Convert RGBA to Hex
  const hex =
    "#" +
    Math.floor(r).toString(16).padStart(2, "0") +
    Math.floor(g).toString(16).padStart(2, "0") +
    Math.floor(b).toString(16).padStart(2, "0");

  // Convert RGBA to HSL
  const rNormalized = r / 255;
  const gNormalized = g / 255;
  const bNormalized = b / 255;
  const max = Math.max(rNormalized, gNormalized, bNormalized);
  const min = Math.min(rNormalized, gNormalized, bNormalized);
  const delta = max - min;

  let h, s, l;

  // Calculate Hue (h)
  if (delta === 0) {
    h = 0; // grayscale
  } else if (max === rNormalized) {
    h = ((gNormalized - bNormalized) / delta) % 6;
  } else if (max === gNormalized) {
    h = (bNormalized - rNormalized) / delta + 2;
  } else {
    h = (rNormalized - gNormalized) / delta + 4;
  }

  h = Math.round(h * 60);
  if (h < 0) {
    h += 360;
  }

  // Calculate Lightness (l)
  l = (max + min) / 2;

  // Calculate Saturation (s)
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  return {
    hex,
    hsl: { h, s, l, a },
    rgb: { r, g, b, a },
  };
}

export function isColorDark(color: string): boolean {
  // Remove any alpha value
  const rgb = color.replace(/^rgba?[(|)]+/i, "");

  // Extract red, green, and blue values
  const [r, g, b] = rgb.split(",").map(Number);

  // Calculate darkness
  const darkness = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Define a threshold (adjust as needed)
  const threshold = 0.5;

  // Check if darkness is above the threshold (darker)
  return darkness >= threshold;
}

// Adjusted function to generate monochromatic colors based on the input color
function getMonoChromaticColors(rgbaColor: RGBColor) {
  // Slightly darken the color
  const darken = (color: number) => Math.max(0, color - 30);
  const lighten = (color: number) => Math.min(255, color + 30);

  // Dark monochrome color (slightly darker than the original)
  const darkColor = `rgba(${darken(rgbaColor.r)}, ${darken(
    rgbaColor.g
  )}, ${darken(rgbaColor.b)}, ${rgbaColor.a ?? 1})`;

  // Light monochrome color (slightly lighter than the original)
  const lightColor = `rgba(${lighten(rgbaColor.r)}, ${lighten(
    rgbaColor.g
  )}, ${lighten(rgbaColor.b)}, ${rgbaColor.a ?? 1})`;

  return { darkColor, lightColor };
}

export function getCoolHueGradient(color: string): string | undefined {
  // Get the RGBA value of the color
  const rgbaColor = getRGBColor(color);
  if (!rgbaColor) return;

  // Generate monochromatic colors
  const { darkColor, lightColor } = getMonoChromaticColors(rgbaColor);

  return `linear-gradient(135deg, ${lightColor} 10%, ${darkColor} 100%)`;
}
