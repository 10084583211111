import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { Platforms } from "./integrationsEnums";
import type {
  Integration,
  IntegrationsState,
  IPlatform,
  SyncedMember,
} from "./integrationsTypes";

const initialState: IntegrationsState = {
  platforms: undefined,
  integrations: undefined,
  syncedMembers: undefined,
};

const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    setIPlatforms: (
      state: IntegrationsState,
      action: PayloadAction<IPlatform[]>
    ) => {
      state.platforms = action.payload;
    },
    setIntegrations: (
      state: IntegrationsState,
      action: PayloadAction<Integration[]>
    ) => {
      state.integrations = action.payload;
    },
    setSyncedMembers: (
      state: IntegrationsState,
      action: PayloadAction<SyncedMember[]>
    ) => {
      state.syncedMembers = action.payload;
    },
    resetIntegrations: (state: IntegrationsState) => {
      state.platforms = undefined;
      state.integrations = undefined;
      state.syncedMembers = undefined;
    },
    addIntegration: (
      state: IntegrationsState,
      action: PayloadAction<Integration>
    ) => {
      state.integrations = [...(state.integrations ?? []), action.payload];
    },
    removeIntegration: (
      state: IntegrationsState,
      action: PayloadAction<Integration["id"]>
    ) => {
      state.integrations = state.integrations?.filter(
        (i) => i.id !== action.payload
      );
    },
    editIntegration: (
      state: IntegrationsState,
      action: PayloadAction<Partial<Integration> & Pick<Integration, "id">>
    ) => {
      if (state.integrations) {
        const index = state.integrations.findIndex(
          (i) => i.id === action.payload.id
        );
        if (index !== -1) {
          state.integrations[index] = {
            ...state.integrations[index],
            ...action.payload,
          };
        }
      }
    },
    addSyncedMembers: (
      state: IntegrationsState,
      action: PayloadAction<SyncedMember[]>
    ) => {
      state.syncedMembers = [...(state.syncedMembers ?? []), ...action.payload];
    },
    editSyncedMembers: (
      state: IntegrationsState,
      action: PayloadAction<
        Array<Partial<SyncedMember> & Pick<SyncedMember, "memberId">>
      >
    ) => {
      if (state.syncedMembers) {
        for (const aSyncedMember of action.payload) {
          const index = state.syncedMembers.findIndex(
            (s) => s.memberId === aSyncedMember.memberId
          );
          if (index !== -1) {
            state.syncedMembers[index] = {
              ...state.syncedMembers[index],
              ...aSyncedMember,
            };
          }
        }
      }
    },
  },
});

export const {
  setIPlatforms,
  setIntegrations,
  setSyncedMembers,
  resetIntegrations,
  addIntegration,
  removeIntegration,
  editIntegration,
  addSyncedMembers,
  editSyncedMembers,
} = integrationsSlice.actions;

export default integrationsSlice.reducer;

export const getIPlatforms = (state: RootState) => state.integrations.platforms;

export const getIntegrations = (state: RootState) =>
  state.integrations.integrations;

export const getSyncedMembers = (state: RootState) =>
  state.integrations.syncedMembers;

export const getPlatformByName = createSelector(
  [getIPlatforms, (_, name: string) => name],
  (platforms, name) => platforms?.find((p) => p.name === name)
);

export const getIntegrationsByPlatformName = createSelector(
  [
    getIPlatforms,
    getIntegrations,
    (_, platformName: Platforms) => platformName,
  ],
  (platforms, integrations, platformName) =>
    integrations?.filter(
      (i) =>
        i.platformId === platforms?.find((p) => p.name === platformName)?.id
    )
);
