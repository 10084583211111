import { ReactNode } from "react";

export function tabPanelA11yProps(index: number) {
  return {
    id: `add-members-tabpanel-${index}`,
    "aria-labelledby": `add-members-tab-${index}`,
  };
}

type TabPanelXProps = {
  children?: ReactNode;
  index: number;
  value: number;
};

function TabPanelX({ children, value, index, ...props }: TabPanelXProps) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

export default TabPanelX;
