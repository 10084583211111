import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import type { SettingsState } from "./settingsTypes";

const initialState: SettingsState = {
  isBrandingActivated: undefined,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings: (
      state: SettingsState,
      action: PayloadAction<Omit<SettingsState, "status" | "error">>
    ) => {
      state.isBrandingActivated = action.payload.isBrandingActivated;
    },
    resetSettings: (state: SettingsState) => {
      state.isBrandingActivated = undefined;
    },
  },
});

export const { setSettings, resetSettings } = settingsSlice.actions;

export default settingsSlice.reducer;

export const getIsBrandingActivated = (state: RootState) =>
  state.settings.isBrandingActivated;
