import { faEyeDropper } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { ColorResult, SketchPicker } from "react-color";
import { getRGBAString, getRGBColor } from "../utils/colors";

const pelletColors = [
  "rgba(255, 255, 255, 1)",
  "rgba(152, 162, 179, 1)",
  "rgba(0, 0, 0, 1)",
  "rgba(235, 87, 86, 1)",
  "rgba(241, 153, 73, 1)",
  "rgba(241, 201, 75, 1)",
  "rgba(32, 150, 83, 1)",
  "rgba(46, 128, 236, 1)",
  "rgba(154, 81, 223, 1)",
];

type PelletWithColorPickerProps = {
  color?: string;
  onColorChange?: (color: string) => void;
  disabled?: boolean;
  className?: string;
};

function PelletWithColorPicker({
  color,
  onColorChange,
  disabled = false,
  className = "",
  ...props
}: PelletWithColorPickerProps) {
  const [customColor, setCustomColor] = useState<string>("");
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

  useEffect(() => {
    if (color && !pelletColors.includes(color)) {
      setCustomColor(color);
    }
  }, [color]);

  function handleColorChange(
    _event: ReactMouseEvent<HTMLElement>,
    newColorValue: string
  ): void {
    if (disabled) return;

    if (!!newColorValue) {
      setCustomColor(newColorValue);
      onColorChange?.(newColorValue);
    }
  }

  function handleCutomColorClick(): void {
    if (disabled) return;

    !showColorPicker && setShowColorPicker(true);
  }

  function handleSketchPickerChange(newColor: ColorResult): void {
    if (disabled) return;

    let rgbaString = getRGBAString(newColor.rgb);

    // If the new color is transparent, then we need to set the alpha to 1
    if (newColor.rgb.a === 0 && color) {
      const prevColorRGB = getRGBColor(color);
      if (prevColorRGB) {
        delete prevColorRGB.a;
        const ifOnlyColorChanged = Object.keys(prevColorRGB).some(
          (aColorKey) =>
            (prevColorRGB as any)[aColorKey] !==
            (newColor.rgb as any)[aColorKey]
        );
        if (ifOnlyColorChanged) {
          newColor.rgb.a = 1;
          rgbaString = getRGBAString(newColor.rgb);
        }
      }
    }

    if (rgbaString) {
      onColorChange?.(rgbaString);
    }
  }

  const sketchPickerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      sketchPickerRef.current &&
      !sketchPickerRef.current.contains(event.target as Node)
    ) {
      setShowColorPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <ToggleButtonGroup
      value={color}
      exclusive
      onChange={handleColorChange}
      aria-label="text alignment"
      classes={{
        root: `!flex items-center justify-between gap-4 ${className}`,
      }}
      {...props}
    >
      <ToggleButton
        value={customColor}
        onClick={handleCutomColorClick}
        classes={{
          root: `relative !rounded-full !p-0 w-10 h-10 ${
            color !== customColor || pelletColors.includes(customColor)
              ? "!border-transparent"
              : ""
          }`,
          selected: "!border-gray-400 !bg-transparent",
        }}
      >
        <FontAwesomeIcon
          icon={faEyeDropper}
          className="flex h-3 w-3 rounded-full border border-gray-200 p-2"
          style={{ backgroundColor: customColor }}
        />
        {showColorPicker && (
          <div
            ref={sketchPickerRef}
            className="absolute -left-4 top-12 z-30 cursor-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <SketchPicker
              color={color}
              onChangeComplete={handleSketchPickerChange}
            />
          </div>
        )}
      </ToggleButton>
      {pelletColors.map((aPelletColor, index) => (
        <ToggleButton
          key={`color-${index}`}
          value={aPelletColor}
          classes={{
            root: `!rounded-full !p-0 w-10 h-10 ${
              color !== aPelletColor ? "!border-transparent" : ""
            }`,
            selected: "!border-gray-400 !bg-transparent",
          }}
        >
          <span
            className="flex h-7 w-7 rounded-full border border-gray-200"
            style={{ backgroundColor: aPelletColor }}
          ></span>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

export default PelletWithColorPicker;
