// import type { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {
  BaseQueryApi,
  FetchArgs,
  fetchBaseQuery,
  // FetchBaseQueryError,
  // FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query/react";
import APIConfigs from "../../configs/apiConfigs";
import { RootState } from "../../store";
import {
  // setAuthentication,
  removeAuthentication,
} from "../authentication/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: APIConfigs.API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    headers.set("Accept", "application/json");
    const token = (getState() as RootState).auth.accessToken;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export async function baseQueryWithReauth(
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) {
  let result = await baseQuery(args, api, extraOptions);

  // 401 Unauthorized - Invalid Access Token
  if (result?.error?.status === 401) {
    console.error(
      "Your company licenses are Inactive. Please get in contact with the AddMee Customer Care (customercare@addmee.de)."
    );
    api.dispatch(removeAuthentication());
  }

  // // 403 Forbidden - Expired Access Token
  // else if (result?.error?.status === 403) {
  //   console.log("Sending refresh token to get new access token...");
  //   const refreshTokenResponse = (await baseQuery(
  //     APIConfigs.Authentication.REFRESH_TOKEN,
  //     api,
  //     extraOptions
  //   )) as QueryReturnValue<
  //     { access_token: string },
  //     FetchBaseQueryError,
  //     FetchBaseQueryMeta
  //   >;
  //   console.log("refreshTokenResponse", refreshTokenResponse);

  //   const currentUser = (api.getState() as RootState).auth.currentUser;

  //   if (currentUser && refreshTokenResponse.data?.access_token) {
  //     console.log("Storing the new accessToken...");
  //     api.dispatch(
  //       setAuthentication({
  //         accessToken: refreshTokenResponse?.data?.access_token,
  //         currentUser,
  //       })
  //     );

  //     console.log("Retrying the original query with new access token...");
  //     result = await baseQuery(args, api, extraOptions);
  //   } else {
  //     api.dispatch(removeAuthentication());
  //   }
  // }

  return result;
}

export default baseQueryWithReauth;
