import type {
  GetSettingsAPIResponse,
  GetSettingsResponse,
} from "./settingsTypes";

export function mapGetSettingsResponse(
  response: GetSettingsAPIResponse
): GetSettingsResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      isBrandingActivated: Boolean(response.data.branding_activated),
    },
  };
}
