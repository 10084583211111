import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { faPanorama } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Badge,
  Button,
  Checkbox,
  FormControlLabel,
  SvgIcon,
} from "@mui/material";
import parse from "html-react-parser";
import { ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Logo } from "../assets/images/addmee_logo.svg";
import { ReactComponent as LogoWhite } from "../assets/images/addmee_logo_white.svg";
import { DialogX, FloatingLabel, Spinner } from "../components";
import AppConfigs from "../configs/appConfigs";
import {
  MemberLink,
  getLinkTypes,
  setLinkCategories,
  setLinkTypes,
  useReadLinkTypesMutation,
} from "../features/links";
import type {
  AppMember,
  MemberColorsSettings,
  MemberSettings,
} from "../features/members/membersTypes";
import { SettingsColorsDefaults } from "../features/settings/settingsEnums";
import { useGetSettingsQuery } from "../features/settings/settingsServices";
import {
  getIsBrandingActivated,
  setSettings,
} from "../features/settings/settingsSlice";
import type { AppTemplate } from "../features/templates/templatesTypes";
import { useAppDispatch, useAppSelector } from "../store";
import {
  FetchError,
  getCoolHueGradient,
  handleFetchError,
  isColorDark,
} from "../utils";

type AddMeeAppProps = {
  member: AppMember | AppTemplate;
  links: MemberLink[];
  settings: MemberSettings;
  className?: string;
};

type LeadForm = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  note: string;
};

function AddMeeApp({
  member,
  links,
  settings,
  className = "",
  ...props
}: AddMeeAppProps) {
  const [thisLinks, setThisLinks] = useState<MemberLink[]>([]);
  const [leadForm, setLeadForm] = useState<LeadForm>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    note: "",
  });
  const [directLink, setDirectLink] = useState<MemberLink>();

  const dispatch = useAppDispatch();

  const linkTypes = useAppSelector(getLinkTypes);
  const isBrandingActivated = useAppSelector(getIsBrandingActivated);

  const [readLinkTypes, { isLoading: isReadLinkTypesPending }] =
    useReadLinkTypesMutation();

  useEffect(() => {
    if (linkTypes.length === 0) {
      (async () => {
        try {
          const {
            success,
            message,
            data: { linkCategories, linkTypes: allLinkTypes },
          } = await readLinkTypes().unwrap();
          if (success) {
            dispatch(setLinkCategories(linkCategories));
            dispatch(setLinkTypes(allLinkTypes));
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    }
  }, [linkTypes, readLinkTypes, dispatch]);

  const { data: getSettingsResponse, isLoading: isGetSettingsLoading } =
    useGetSettingsQuery();

  useEffect(() => {
    if (isBrandingActivated === undefined) {
      if (getSettingsResponse) {
        const { success, message, data } = getSettingsResponse;
        if (success) {
          dispatch(setSettings(data));
        } else {
          toast.error(message);
        }
      }
    }
  }, [isBrandingActivated, dispatch, getSettingsResponse]);

  useEffect(() => {
    setThisLinks(
      links
        .slice()
        .filter((aLink) => aLink.visible)
        .sort((a, b) => a.sequence - b.sequence)
    );
    setDirectLink(links.find((l) => l.sequence === 0));
  }, [links]);

  function getLinkIcon(
    iconSVG: MemberLink["iconSVG"],
    linkTypeCode: MemberLink["linkTypeCode"],
    colorLinkIcons: MemberColorsSettings["colorLinkIcons"]
  ): ReactNode {
    if (!colorLinkIcons) {
      return parse(iconSVG);
    }

    const thisLinkTypes = linkTypes.find((lt) => lt.code === linkTypeCode);
    return parse(
      iconSVG === thisLinkTypes?.iconSVGDefault
        ? thisLinkTypes?.iconSVGColorized
            .replaceAll(
              'fill="black"',
              'fill="' + settings.colors.profileButtonBgColor + '"'
            )
            .replaceAll(
              'stroke="black"',
              'stroke="' + settings.colors.profileButtonBgColor + '"'
            )
            .replaceAll(
              'fill="white"',
              'fill="' + settings.colors.profileButtonIconColor + '"'
            )
            .replaceAll(
              'stroke="white"',
              'stroke="' + settings.colors.profileButtonIconColor + '"'
            ) || iconSVG
        : iconSVG
    );
  }

  if (isReadLinkTypesPending || isGetSettingsLoading || !member)
    return <Spinner />;

  return (
    <div
      className={`flex min-h-full flex-col gap-3 bg-white p-3 pt-12 ${className}`}
      style={{
        ...(settings.colors.profileColor !==
          SettingsColorsDefaults.PROFILE_COLOR && {
          backgroundImage: getCoolHueGradient(settings.colors.profileColor),
        }),
      }}
      {...props}
    >
      {(!member.openDirect || !directLink) && (
        <>
          <div className="flex flex-col gap-11">
            <div
              className="relative flex aspect-[30/11] items-end rounded-2xl bg-gray-200 bg-cover bg-center"
              style={{
                ...(member.profileBanner && {
                  backgroundImage: `url(${member.profileBanner})`,
                  backgroundColor: "transparent",
                }),
              }}
            >
              {!member.profileBanner && (
                <FontAwesomeIcon
                  icon={faPanorama}
                  className="absolute bottom-3 right-3 text-white"
                />
              )}
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <Avatar
                    src={member.companyLogo}
                    imgProps={{ alt: member.companyName }}
                    className={`!h-8 !w-8 -translate-x-1 -translate-y-1 border border-white ${
                      member.companyLogo ? "!bg-tranparent" : "!bg-gray-200"
                    } !text-sm`}
                    sx={{
                      ...(settings.colors.borderColor !==
                        SettingsColorsDefaults.BORDER_COLOR && {
                        borderColor: settings.colors.borderColor,
                      }),
                    }}
                  >
                    <FontAwesomeIcon icon={faBuilding} />
                  </Avatar>
                }
                className="ml-3 translate-y-1/2"
              >
                <Avatar
                  src={member.profileImage}
                  imgProps={{ alt: member.fullName }}
                  className={`!h-20 !w-20 border-2 border-white ${
                    member.profileImage ? "!bg-transparent" : "!bg-gray-200"
                  }`}
                  sx={{
                    ...(settings.colors.borderColor !==
                      SettingsColorsDefaults.BORDER_COLOR && {
                      borderColor: settings.colors.borderColor,
                    }),
                  }}
                />
              </Badge>
            </div>
            <div className="flex flex-col px-3">
              <h1
                className="text-base font-semibold text-gray-900"
                style={{
                  color:
                    settings.colors.textColor !==
                    SettingsColorsDefaults.TEXT_COLOR
                      ? settings.colors.textColor
                      : "",
                }}
              >
                {member.fullName || member.username}
              </h1>
              <h2
                className="text-[0.625rem] font-normal text-gray-700"
                style={{
                  color:
                    settings.colors.textColor !==
                    SettingsColorsDefaults.TEXT_COLOR
                      ? settings.colors.textColor
                      : "",
                }}
              >
                <strong>{member.designation}</strong>
                {!!member.designation && !!member.companyName && " at "}
                {member.companyName}
              </h2>
              <p
                className="text-[0.625rem] font-normal text-gray-700"
                style={{
                  color:
                    settings.colors.textColor !==
                    SettingsColorsDefaults.TEXT_COLOR
                      ? settings.colors.textColor
                      : "",
                }}
              >
                {member.companyAddress}
              </p>
            </div>
          </div>
          {!!member.subtitle && (
            <div
              className="relative flex flex-col items-center rounded-lg border border-gray-200 bg-white p-3"
              style={{
                backgroundColor:
                  settings.colors.sectionColor !==
                  SettingsColorsDefaults.SECTION_COLOR
                    ? settings.colors.sectionColor
                    : "",
                borderColor:
                  settings.colors.sectionColor !==
                  SettingsColorsDefaults.SECTION_COLOR
                    ? settings.colors.sectionColor
                    : "",
              }}
            >
              {/* <h3
                className='absolute -top-[0.5625rem] inline-flex rounded-full border border-gray-200 bg-white px-2 text-[0.625rem] font-medium text-gray-500'
                style={{
                  backgroundColor:
                    settings.colors.sectionColor !==
                    SettingsColorsDefault.SECTION_COLOR
                      ? settings.colors.sectionColor
                      : "",
                  borderColor:
                    settings.colors.sectionColor !==
                    SettingsColorsDefault.SECTION_COLOR
                      ? SettingsColorsDefault.SECTION_COLOR
                      : "",
                  color:
                    settings.colors.textColor !==
                    SettingsColorsDefault.TEXT_COLOR
                      ? settings.colors.textColor
                      : "",
                }}
              >
                Subtitle
              </h3> */}
              <p
                className="text-center text-[0.625rem] text-gray-600"
                style={{
                  color:
                    settings.colors.textColor !==
                    SettingsColorsDefaults.TEXT_COLOR
                      ? settings.colors.textColor
                      : "",
                }}
              >
                {member.subtitle}
              </p>
            </div>
          )}
          <div
            className="flex flex-col gap-3 rounded-lg bg-white p-3"
            style={{
              backgroundColor:
                settings.colors.sectionColor !==
                SettingsColorsDefaults.SECTION_COLOR
                  ? settings.colors.sectionColor
                  : "",
            }}
          >
            <div
              className={`flex ${
                member.connectButton && member.saveContactButton
                  ? "justify-between gap-1"
                  : "flex-col"
              }`}
            >
              {member.connectButton && (
                <Button
                  classes={{
                    root: "min-h-[2.125rem] !rounded-full !bg-black !px-5 !py-2 !text-xs !font-semibold !normal-case !text-white",
                  }}
                  sx={{
                    color:
                      settings.colors.controlButtonTextColor !==
                      SettingsColorsDefaults.CONTROL_BUTTON_TEXT_COLOR
                        ? settings.colors.controlButtonTextColor + " !important"
                        : "",
                    backgroundColor:
                      settings.colors.controlButtonBgColor !==
                      SettingsColorsDefaults.CONTROL_BUTTON_BG_COLOR
                        ? settings.colors.controlButtonBgColor + " !important"
                        : "",
                  }}
                >
                  Connect
                </Button>
              )}
              {member.saveContactButton && (
                <Button
                  classes={{
                    root: "min-h-[2.125rem] !rounded-full !bg-black !px-4 !py-2 !text-xs !font-semibold !normal-case !text-white",
                  }}
                  sx={{
                    color:
                      settings.colors.controlButtonTextColor !==
                      SettingsColorsDefaults.CONTROL_BUTTON_TEXT_COLOR
                        ? settings.colors.controlButtonTextColor + " !important"
                        : "",
                    backgroundColor:
                      settings.colors.controlButtonBgColor !==
                      SettingsColorsDefaults.CONTROL_BUTTON_BG_COLOR
                        ? settings.colors.controlButtonBgColor + " !important"
                        : "",
                  }}
                >
                  Save Contact
                </Button>
              )}
            </div>
            {thisLinks && thisLinks.length > 0 && (
              <ul className="flex flex-wrap items-center justify-center gap-x-5 gap-y-3">
                {thisLinks.map((aLink, index) => (
                  <li
                    key={index}
                    className={`${
                      aLink.isHighlighted
                        ? "w-full rounded-lg p-3 shadow-sm backdrop-blur-sm " +
                          (settings.colors.sectionColor !==
                          SettingsColorsDefaults.SECTION_COLOR
                            ? "bg-white/30"
                            : "bg-slate-50")
                        : ""
                    }`}
                  >
                    <a
                      href={aLink.href}
                      target="_blank"
                      rel="noreferrer"
                      className={`flex w-12 items-center gap-2 ${
                        aLink.isHighlighted
                          ? "w-full"
                          : "flex-col justify-center"
                      }`}
                    >
                      <Avatar
                        alt={aLink.title}
                        variant="rounded"
                        className="!h-12 !w-12 !rounded-[0.526rem] !bg-transparent shadow-md"
                      >
                        <SvgIcon className="!h-full !w-full">
                          {getLinkIcon(
                            aLink.iconSVG,
                            aLink.linkTypeCode,
                            settings.colors.colorLinkIcons
                          )}
                        </SvgIcon>
                      </Avatar>
                      <span
                        className={`w-full truncate font-semibold text-black ${
                          aLink.isHighlighted
                            ? "text-xs"
                            : "text-center text-[0.5625rem]"
                        }`}
                        style={{
                          color:
                            settings.colors.textColor !==
                            SettingsColorsDefaults.TEXT_COLOR
                              ? settings.colors.textColor
                              : "",
                        }}
                      >
                        {aLink.title}
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
          {isBrandingActivated && (
            <div className="flex justify-center">
              <Link
                to={AppConfigs.BUSINESS_URL || "/"}
                target="_blank"
                className="flex aspect-[4/1] w-20 items-center overflow-hidden"
              >
                {isColorDark(settings.colors.profileColor) ? (
                  <LogoWhite />
                ) : (
                  <Logo />
                )}
              </Link>
            </div>
          )}
        </>
      )}
      <DialogX open={member.captureLead} className="ignore-scroll">
        <h4 className="text-center text-sm font-semibold text-black">
          {`Connect with ${member.fullName || member.username}`}
        </h4>
        <div className="flex flex-col gap-2">
          <FloatingLabel
            size="small"
            onInputValueChange={(v) =>
              setLeadForm({ ...leadForm, firstName: v })
            }
          >
            <input
              name="lead-first-name"
              placeholder="First Name"
              value={leadForm.firstName}
            />
          </FloatingLabel>
          <FloatingLabel
            size="small"
            onInputValueChange={(v) =>
              setLeadForm({ ...leadForm, lastName: v })
            }
          >
            <input
              name="lead-last-name"
              placeholder="Last Name"
              value={leadForm.lastName}
            />
          </FloatingLabel>
          <FloatingLabel
            size="small"
            onInputValueChange={(v) => setLeadForm({ ...leadForm, email: v })}
          >
            <input
              name="lead-email"
              placeholder="Email*"
              value={leadForm.email}
            />
          </FloatingLabel>
          <FloatingLabel
            size="small"
            onInputValueChange={(v) => setLeadForm({ ...leadForm, phone: v })}
          >
            <input
              name="lead-phone"
              placeholder="Phone Number"
              value={leadForm.phone}
            />
          </FloatingLabel>
          <FloatingLabel
            size="small"
            onInputValueChange={(v) =>
              setLeadForm({ ...leadForm, companyName: v })
            }
          >
            <input
              name="lead-company-name"
              placeholder="Company"
              value={leadForm.companyName}
            />
          </FloatingLabel>
          <FloatingLabel
            size="small"
            onInputValueChange={(v) => setLeadForm({ ...leadForm, note: v })}
          >
            <input
              name="lead-note"
              placeholder="Leave a Note"
              value={leadForm.note}
            />
          </FloatingLabel>
          <FormControlLabel
            control={
              <Checkbox size="small" classes={{ checked: "!text-gray-900" }} />
            }
            label="I have read and agree with the privacy policy."
            classes={{
              root: "px-4 text-gray-500",
              label: "!text-xs",
            }}
          />
        </div>
        <div>
          <Button
            type="submit"
            classes={{
              root: "min-h-[2.125rem] w-full !rounded-full !bg-primary !px-4 !py-2 !text-xs !font-semibold !normal-case !text-white",
            }}
          >
            Connect
          </Button>
        </div>
        <div>
          <small className="text-[0.625rem] font-normal text-gray-600">
            AddMee does not sell or share your data
          </small>
        </div>
      </DialogX>
      {member.openDirect && directLink && (
        <div className="ignore-scroll bg-image-direct absolute inset-3 top-12 flex items-center justify-center rounded-3xl bg-gray-100 bg-cover bg-center">
          <div>
            <a
              href={directLink.href}
              target="_blank"
              rel="noreferrer"
              className="flex w-12 flex-col items-center justify-center gap-2"
            >
              <Avatar
                alt={directLink.title}
                variant="rounded"
                className="!h-12 !w-12 !rounded-[0.526rem] !bg-transparent shadow-md"
              >
                <SvgIcon className="!h-full !w-full">
                  {getLinkIcon(
                    directLink.iconSVG,
                    directLink.linkTypeCode,
                    settings.colors.colorLinkIcons
                  )}
                </SvgIcon>
              </Avatar>
              <span className="w-full truncate text-[0.5625rem] font-semibold text-black">
                {directLink.title}
              </span>
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddMeeApp;
