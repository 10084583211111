import { faSave } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DevTool } from "@hookform/devtools";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  AutoSyncFrequencies,
  SyncADConfigsCommon,
  defaultSyncADConfigsCommon,
} from "../features/integrations";
import theme, { FormControlAutoSync } from "../styles/mui-styled";
import DialogX from "./DialogX";

type SyncADConfigsCommonDialogProps = DialogProps & {
  data?: SyncADConfigsCommon;
  onSave?: (data: SyncADConfigsCommon) => void;
  onCancel?: () => void;
  onClose?: () => void;
  loading?: boolean;
};

function SyncADConfigsCommonDialog({
  data = defaultSyncADConfigsCommon,
  onSave,
  onCancel,
  loading = false,
  className = "",
  ...props
}: SyncADConfigsCommonDialogProps) {
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<SyncADConfigsCommon>({
    defaultValues: data,
  });

  return (
    <DialogX className={`flex flex-col gap-8 ${className}`} {...props}>
      <DialogTitle className="!p-0 !font-sans !font-semibold text-black">
        Configurations to Sync with Microsoft Active Directory
      </DialogTitle>
      <DialogContent className="!p-0">
        <div className="flex flex-col gap-4">
          <FormControlAutoSync>
            <FormLabel id="sync-frequency-label">
              Choose how often you want to Auto-Sync:
            </FormLabel>
            <Controller
              control={control}
              name="autoSyncFrequency"
              render={({ field: { name, value, onChange } }) => (
                <RadioGroup
                  row
                  name={name}
                  defaultValue={AutoSyncFrequencies.NONE}
                  value={value}
                  onChange={(event) =>
                    onChange(event.target.value as AutoSyncFrequencies)
                  }
                  aria-labelledby="sync-frequency-label"
                >
                  {Object.values(AutoSyncFrequencies).map((aFrequency) => (
                    <FormControlLabel
                      key={"sync-frequency-" + aFrequency}
                      value={aFrequency}
                      control={
                        <Radio
                          size="small"
                          classes={{ checked: "!text-primary" }}
                        />
                      }
                      label={
                        aFrequency.charAt(0).toUpperCase() + aFrequency.slice(1)
                      }
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </FormControlAutoSync>
        </div>
      </DialogContent>
      <DialogActions className="gap-4 !border-t border-gray-200 !p-0 !pt-4">
        <Button
          onClick={() => onCancel?.()}
          variant="outlined"
          className="!m-0 !rounded-full !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-black"
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          onClick={onSave && handleSubmit(onSave)}
          startIcon={<FontAwesomeIcon icon={faSave} className="!text-sm" />}
          loadingPosition="start"
          loading={loading}
          disabled={!isDirty}
          classes={theme.LoadingButtonX}
        >
          Save Configurations
        </LoadingButton>
      </DialogActions>
      <DevTool control={control} placement="top-left" />
    </DialogX>
  );
}

export default SyncADConfigsCommonDialog;
