import { createApi } from "@reduxjs/toolkit/query/react";
import APIConfigs from "../../configs/apiConfigs";
import { baseQueryWithReauth } from "../rtk-query/baseQuery";
import {
  getCreateMembersByCSVRequestBody,
  getCreateMembersByEmailRequestBody,
  getSyncMembersWithADRequestBody,
  mapCreateMembersByCSVResponse,
  mapCreateMembersByEmailResponse,
  transformSyncMembersWithADResponse,
} from "./addMembersMapper";
import type {
  CreateMembersByCSVRequestArgs,
  CreateMembersByEmailRequestArgs,
  CreateMembersResponse,
  KeepMissingUsersRequestArgs,
  KeepMissingUsersResponse,
  SyncMembersWithADRequestArgs,
  SyncMembersWithADResponse,
} from "./addMembersTypes";

const addMembersService = createApi({
  reducerPath: "addMembersService",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    createMembersByEmail: builder.mutation<
      CreateMembersResponse,
      CreateMembersByEmailRequestArgs
    >({
      query: (args) => ({
        method: "POST",
        url: APIConfigs.Members.CREATE_MEMBERS_BY_EMAILS,
        body: getCreateMembersByEmailRequestBody(args),
      }),
      transformResponse: mapCreateMembersByEmailResponse,
    }),
    createMembersByCSV: builder.mutation<
      CreateMembersResponse,
      CreateMembersByCSVRequestArgs
    >({
      query: (args) => ({
        method: "POST",
        url: APIConfigs.Members.CREATE_MEMBERS_BY_CSV,
        body: getCreateMembersByCSVRequestBody(args),
      }),
      transformResponse: mapCreateMembersByCSVResponse,
    }),
    syncMembersWithAD: builder.mutation<
      SyncMembersWithADResponse,
      SyncMembersWithADRequestArgs
    >({
      query: ({ integrationId, ...args }) => ({
        method: "POST",
        url: APIConfigs.ActiveDirectory.SYNC_AD_USERS.replace(
          ":id",
          integrationId.toString()
        ),
        body: getSyncMembersWithADRequestBody(args),
      }),
      transformResponse: transformSyncMembersWithADResponse,
    }),
    keepMissingUsers: builder.mutation<
      KeepMissingUsersResponse,
      KeepMissingUsersRequestArgs
    >({
      query: ({ integrationId, memberIdsToKeep }) => ({
        method: "PUT",
        url: APIConfigs.ActiveDirectory.KEEP_MISSING_USERS.replace(
          ":id",
          integrationId.toString()
        ),
        body: { kept_member_ids: memberIdsToKeep },
      }),
    }),
  }),
});

export default addMembersService;
export const {
  useCreateMembersByEmailMutation,
  useCreateMembersByCSVMutation,
  useSyncMembersWithADMutation,
  useKeepMissingUsersMutation,
} = addMembersService;
