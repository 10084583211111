import AuthConfigs from "../../configs/authConfigs";
import { Platforms } from "./integrationsEnums";
import type {
  APIIntegration,
  CreateADIntegrationRequestArgs,
  CreateADIntegrationRequestBody,
  CreateDynamicsIntegrationRequestArgs,
  CreateDynamicsIntegrationRequestBody,
  CreateHubSpotIntegrationRequestBody,
  CreateIntegrationAPIResponse,
  CreateIntegrationResponse,
  CreateSalesforceIntegrationRequestArgs,
  CreateSalesforceIntegrationRequestBody,
  Integration,
  ReadADGroupsUsersAPIResponse,
  ReadADGroupsUsersResponse,
  ReadIPlatformsAPIResponse,
  ReadIPlatformsResponse,
  ReadIntegrationsAPIResponse,
  ReadIntegrationsResponse,
  ReadSyncedMembersAPIResponse,
  ReadSyncedMembersResponse,
  UpdateIntegrationAPIResponse,
  UpdateIntegrationResponse,
  UpdateSyncedMembersAPIResponse,
  UpdateSyncedMembersRequestArgs,
  UpdateSyncedMembersRequestBody,
  UpdateSyncedMembersResponse,
} from "./integrationsTypes";

export function mapReadIPlatformsResponse(
  response: ReadIPlatformsAPIResponse
): ReadIPlatformsResponse {
  return {
    success: response.success,
    message: response.message,
    data: response.data.map((anIPlatform) => ({
      id: anIPlatform.id,
      name: anIPlatform.name as Platforms,
      iconSvg: anIPlatform.icon_svg,
      title: anIPlatform.title,
      description: anIPlatform.description,
    })),
  };
}

export function mapReadIntegrationsResponse(
  response: ReadIntegrationsAPIResponse
): ReadIntegrationsResponse {
  return {
    success: response.success,
    message: response.message,
    data: response.data.map((anIPlatform) => ({
      id: anIPlatform.id,
      platformId: anIPlatform.platform_id,
      accessToken: anIPlatform.access_token,
      expiresOn: anIPlatform.expires_on,
      settings: anIPlatform.settings
        ? JSON.parse(anIPlatform.settings)
        : undefined,
    })),
  };
}

export function getCreateHubSpotIntegrationRequestBody(
  code: string
): CreateHubSpotIntegrationRequestBody {
  return {
    client_id: AuthConfigs.HubSpot.CLIENT_ID,
    client_secret: AuthConfigs.HubSpot.CLIENT_SECRET,
    redirect_url: AuthConfigs.HubSpot.REDIRECT_URL,
    scope: AuthConfigs.HubSpot.SCOPE,
    code,
  };
}

export function getCreateADIntegrationRequestBody({
  tenantId,
  settings,
}: CreateADIntegrationRequestArgs): CreateADIntegrationRequestBody {
  return {
    client_id: AuthConfigs.ActiveDirectory.CLIENT_ID,
    client_secret: AuthConfigs.ActiveDirectory.CLIENT_SECRET,
    redirect_url: AuthConfigs.ActiveDirectory.REDIRECT_URL,
    scope: AuthConfigs.ActiveDirectory.SCOPE,
    tenant_id: tenantId,
    settings: JSON.stringify(settings),
  };
}

export function getCreateSalesforceIntegrationRequestBody({
  codeVerifier,
  codeChallenge,
  code,
}: CreateSalesforceIntegrationRequestArgs): CreateSalesforceIntegrationRequestBody {
  return {
    client_id: AuthConfigs.Salesforce.CLIENT_ID,
    client_secret: AuthConfigs.Salesforce.CLIENT_SECRET,
    redirect_url: AuthConfigs.Salesforce.REDIRECT_URL,
    scope: AuthConfigs.Salesforce.SCOPE,
    code_verifier: codeVerifier,
    code_challenge: codeChallenge,
    code,
  };
}

export function mapCreateIntegrationResponse(
  response: CreateIntegrationAPIResponse
): CreateIntegrationResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      id: response.data.id,
      platformId: response.data.platform_id,
      accessToken: response.data.access_token,
      expiresOn: response.data.expires_on,
      settings: response.data.settings
        ? JSON.parse(response.data.settings)
        : undefined,
    },
  };
}

export function getUpdateIntegrationRequestBody(
  args: Partial<Omit<Integration, "id">>
): Partial<Omit<APIIntegration, "id">> {
  return {
    ...(args.accessToken && { access_token: args.accessToken }),
    ...(args.expiresOn && { expires_on: args.expiresOn }),
    ...(args.settings && { settings: JSON.stringify(args.settings) }),
  };
}

export function mapUpdateIntegrationResponse(
  response: UpdateIntegrationAPIResponse
): UpdateIntegrationResponse {
  const data: Partial<Integration> & Pick<Integration, "id"> = {
    id: response.data.id,
  };

  if (response.data.access_token) {
    data.accessToken = response.data.access_token;
  }
  if (response.data.expires_on) {
    data.expiresOn = response.data.expires_on;
  }
  if (response.data.settings) {
    data.settings = JSON.parse(response.data.settings);
  }

  return {
    success: response.success,
    message: response.message,
    data,
  };
}

export function mapReadADGroupsUsersResponse(
  response: ReadADGroupsUsersAPIResponse
): ReadADGroupsUsersResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      adGroups: response.data.groups,
      adUsers: response.data.users,
      missingMemberIds: response.data.missing_member_ids,
    },
  };
}

export function getCreateDynamicsIntegrationRequestBody({
  instanceUrl,
  tenantId,
}: CreateDynamicsIntegrationRequestArgs): CreateDynamicsIntegrationRequestBody {
  return {
    client_id: AuthConfigs.Dynamics.CLIENT_ID,
    client_secret: AuthConfigs.Dynamics.CLIENT_SECRET,
    redirect_url: AuthConfigs.Dynamics.REDIRECT_URL,
    scope: AuthConfigs.Dynamics.SCOPE + ` ${instanceUrl}/user_impersonation`,
    instance_url: instanceUrl,
    tenant_id: tenantId,
  };
}

export function mapReadSyncedMembersResponse(
  response: ReadSyncedMembersAPIResponse
): ReadSyncedMembersResponse {
  return {
    success: response.success,
    message: response.message,
    data: response.data.map((aSyncedMember) => ({
      memberId: aSyncedMember.member_id,
      integrationId: aSyncedMember.integration_id,
      settings: JSON.parse(aSyncedMember.settings),
    })),
  };
}

export function getUpdateSyncedMembersRequestBody(
  args: Omit<UpdateSyncedMembersRequestArgs, "integrationId">
): UpdateSyncedMembersRequestBody {
  return {
    member_ids: args.memberIds,
    settings: JSON.stringify(args.settings),
  };
}

export function mapUpdateSyncedMembersResponse(
  response: UpdateSyncedMembersAPIResponse
): UpdateSyncedMembersResponse {
  return {
    success: response.success,
    message: response.message,
    data: response.data.map((aSyncedMember) => ({
      memberId: aSyncedMember.member_id,
      settings: JSON.parse(aSyncedMember.settings),
    })),
  };
}
