import { toast } from "react-toastify";
import type { FetchError } from "./utilsTypes";

export function handleFetchError({
  status,
  error,
  originalStatus,
  data,
  ...props
}: FetchError) {
  if (data && data.message) {
    toast.error(data.message);
  } else if (originalStatus === 401) {
    toast.error("You are not authorized to perform this action!");
  } else if (originalStatus === 403) {
    toast.error("You are not allowed to perform this action!");
  } else if (originalStatus === 404) {
    toast.error("Resource not found!");
  } else if (originalStatus === 405) {
    toast.error("Method not allowed!");
  } else if (originalStatus === 422) {
    toast.error("Invalid data!");
  } else if (originalStatus === 429) {
    toast.error("Too many requests!");
  } else if (originalStatus === 500 || ["FETCH_ERROR"].includes(status)) {
    toast.error("Something went wrong!");
  } else {
    toast.error(error);
  }
}
