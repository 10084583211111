import { Tab } from "@mui/material";
import { JSXElementConstructor, ReactElement, ReactNode } from "react";

function tabA11yProps(index: number) {
  return {
    id: `add-members-tab-${index}`,
    "aria-controls": `add-members-tabpanel-${index}`,
  };
}

type TabXProps = {
  icon?:
    | string
    | ReactElement<any, string | JSXElementConstructor<any>>
    | undefined;
  label?: ReactNode;
  index: number;
  className?: string;
};

function TabX({ icon, label, index, className = "", ...props }: TabXProps) {
  return (
    <Tab
      icon={icon}
      label={label}
      iconPosition="start"
      classes={{
        root: `z-10 !min-h-0 !max-w-none fill-black !px-6 !font-sans !font-semibold !normal-case !text-black ${className}`,
        iconWrapper: "!font-normal",
        selected: "fill-white !text-white",
      }}
      {...tabA11yProps(index)}
      {...props}
    />
  );
}

export default TabX;
