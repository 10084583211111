import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "@mui/material/Link";
import {
  LinkCategoryType,
  LinkType,
  LinkTypeCode,
  MemberLink,
} from "../features/links";

type TestLinkButtonProps = {
  linkType: Pick<
    LinkType,
    "code" | "valueType" | "baseUrl" | "isValueEditable"
  >;
  linkValue: MemberLink["value"];
};

function TestLinkButton({ linkType, linkValue }: TestLinkButtonProps) {
  function getTestLink(): string {
    if (linkType.valueType !== LinkCategoryType.URL) {
      return linkType.baseUrl + linkValue;
    }

    return linkValue.startsWith("http") ? linkValue : `https://${linkValue}`;
  }

  return (
    <Link
      href={linkType.code === LinkTypeCode.WIFI ? undefined : getTestLink()}
      target="_blank"
      classes={{
        root: `inline-flex items-center gap-2 !rounded-full !border !border-gray-200 !bg-white !text-xs !font-sans !normal-case !px-4 !py-2 !no-underline cursor-pointer ${
          (!linkType.isValueEditable || linkValue) &&
          linkType.code !== LinkTypeCode.WIFI
            ? "!text-blue-500"
            : "!text-gray-300 pointer-events-none"
        }`,
      }}
    >
      <span className="font-semibold">Test your button</span>
      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
    </Link>
  );
}

export default TestLinkButton;
