import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import { DevTool } from "@hookform/devtools";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import FloatingSelect from "./FloatingSelect";

type MemberCSVMappingProps = {
  propLabels: Map<string, string>;
  csvHeaders: string[];
  mappedFields?: Map<string, string>;
  onSave?: (changedMappedFields: Map<string, string>) => void;
  onCancel?: () => void;
  className?: string;
};

type MappedFields = {
  [key: string]: string;
};

function MemberCSVMapping({
  propLabels,
  csvHeaders,
  mappedFields = new Map(),
  onSave,
  onCancel,
  className = "",
  ...props
}: MemberCSVMappingProps) {
  const [isFullNameExpanded, setIsFullNameExpanded] = useState<boolean>(false);
  const [isCompanyAddressExpanded, setIsCompanyAddressExpanded] =
    useState<boolean>(false);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm<MappedFields>({
    defaultValues: Object.fromEntries(
      Array.from(propLabels.keys()).map((prop) => [
        prop,
        mappedFields.get(prop) || "",
      ])
    ),
  });

  function saveMappings(thisMappedFields: MappedFields): void {
    onSave?.(
      new Map(
        Object.entries(thisMappedFields).filter(
          ([_prop, header]) => header !== ""
        )
      )
    );
  }

  function handleExpandClick(prop: string) {
    switch (prop) {
      case "full_name":
        setIsFullNameExpanded(true);
        break;
      case "company_address":
        setIsCompanyAddressExpanded(true);
        break;
      default:
        break;
    }
  }

  function handleCollapseClick(prop: string) {
    switch (prop) {
      case "full_name":
        setIsFullNameExpanded(false);
        break;
      case "company_address":
        setIsCompanyAddressExpanded(false);
        break;
      default:
        break;
    }
  }

  function getTBodyClassName(): string {
    return isFullNameExpanded && isCompanyAddressExpanded
      ? "md:grid-rows-7"
      : isFullNameExpanded
      ? "md:grid-rows-5"
      : isCompanyAddressExpanded
      ? "md:!grid-rows-6"
      : "";
  }

  function getTRClassName(prop: string): string {
    switch (prop) {
      case "first_name":
      case "last_name":
        return `${!isFullNameExpanded && "hidden"}`;
      case "company_address":
        return `${isCompanyAddressExpanded && "row-start-1"}`;
      case "street_address":
      case "zip_post_code":
      case "city":
      case "state_province":
      case "country":
        return `${!isCompanyAddressExpanded && "hidden"}`;
      default:
        return "";
    }
  }

  function getTHClassName(prop: string): string {
    switch (prop) {
      case "full_name":
        return `${isFullNameExpanded && "!font-bold"}`;
      case "company_address":
        return `${isCompanyAddressExpanded && "!font-bold"}`;
      default:
        return "";
    }
  }

  function isExpandableField(prop: string): boolean {
    return (
      (prop === "full_name" && !isFullNameExpanded) ||
      (prop === "company_address" && !isCompanyAddressExpanded)
    );
  }

  function getFieldDisabledValue(prop: string): boolean {
    return (
      (prop === "full_name" && isFullNameExpanded) ||
      (prop === "company_address" && isCompanyAddressExpanded)
    );
  }

  function handleFieldChange(
    field: ControllerRenderProps<MappedFields, string>,
    v: unknown
  ): void {
    field.onChange(v);

    switch (field.name) {
      case "full_name":
        setValue("first_name", "");
        setValue("last_name", "");
        break;
      case "first_name":
      case "last_name":
        setValue("full_name", "");
        break;
      case "company_address":
        setValue("street_address", "");
        setValue("zip_post_code", "");
        setValue("city", "");
        setValue("state_province", "");
        setValue("country", "");
        break;
      case "street_address":
      case "zip_post_code":
      case "city":
      case "state_province":
      case "country":
        setValue("company_address", "");
        break;
      default:
        break;
    }
  }

  return (
    <div className={`flex flex-col ${className}`} {...props}>
      <div className="flex flex-col gap-0.5">
        <DialogTitle className="!p-0 !font-sans !font-semibold text-black">
          Choose columns to be imported
        </DialogTitle>
        <p className="text-sm text-gray-500">
          Select the Columns in your CSV to map with the respective field in
          Members data.
        </p>
      </div>
      <DialogContent className="relative !grow !px-0 !py-4  md:!pl-4">
        <div
          className="absolute left-2 top-[4.0625rem] z-0 h-[10.25rem] w-[18.5rem] rounded-3xl border border-gray-200"
          hidden={!isFullNameExpanded}
        ></div>
        <div
          className="absolute right-0 top-[0.6875rem] z-0 h-[20.375rem] w-[18.5rem] rounded-3xl border border-gray-200"
          hidden={!isCompanyAddressExpanded}
        ></div>
        <table className="relative z-10 w-full">
          <tbody
            className={`grid gap-x-4 gap-y-2 md:grid-flow-col md:grid-rows-3 ${getTBodyClassName()}`}
          >
            {Array.from(propLabels).map(([prop, label], index) => (
              <tr
                key={index}
                className={`flex flex-col sm:flex-row sm:items-center ${getTRClassName(
                  prop
                )}`}
              >
                <th
                  className={`text-sm font-normal sm:w-28 sm:text-left ${getTHClassName(
                    prop
                  )}`}
                >
                  {label}:
                </th>
                <td className="sm:w-[8.75rem]">
                  <Controller
                    name={prop}
                    control={control}
                    rules={{
                      required: prop === "email" && {
                        value: true,
                        message: "This email field is not mapped.",
                      },
                    }}
                    render={({ field }) => (
                      <FloatingSelect
                        value={field.value}
                        onChange={(v) => handleFieldChange(field, v)}
                        displayEmpty={true}
                        size="small"
                        className="w-full"
                        disabled={getFieldDisabledValue(prop)}
                        error={errors[prop]?.message}
                      >
                        {[
                          <MenuItem key={-1} value="">
                            <span className="!text-gray-400">Unmapped</span>
                          </MenuItem>,
                          ...(csvHeaders.map((aCSVHeader, i) => (
                            <MenuItem key={i} value={aCSVHeader}>
                              {aCSVHeader}
                            </MenuItem>
                          )) || []),
                        ]}
                      </FloatingSelect>
                    )}
                  />
                </td>
                <td className="h-9 w-9">
                  {isExpandableField(prop) && (
                    <IconButton onClick={() => handleExpandClick(prop)}>
                      <UnfoldMoreOutlinedIcon className="!text-lg !text-gray-500" />
                    </IconButton>
                  )}
                  {getFieldDisabledValue(prop) && (
                    <IconButton onClick={() => handleCollapseClick(prop)}>
                      <UnfoldLessIcon className="!text-lg !text-gray-500" />
                    </IconButton>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </DialogContent>
      <DialogActions className="gap-4 !border-t border-gray-200 !p-0 !pt-4">
        <Button
          variant="outlined"
          onClick={() => onCancel?.()}
          className="!m-0 !hidden !rounded-full !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-black sm:!block"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(saveMappings)}
          disabled={!isDirty}
          className={`!m-0 w-full !rounded-full !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-white sm:w-auto ${
            isDirty ? "!bg-primary" : "!bg-gray-300"
          }`}
        >
          Save Mapping
        </Button>
      </DialogActions>
      <DevTool control={control} />
    </div>
  );
}

export default MemberCSVMapping;
