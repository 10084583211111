import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import type { Member } from "../features/members/membersTypes";
import { UserRole } from "../features/users/usersEnums";
import useMembers from "../hooks/useMembers";
import useTemplates from "../hooks/useTemplates";
import theme from "../styles/mui-styled";
import DialogX, { DialogXProps } from "./DialogX";
import FloatingLabel from "./FloatingLabel";
import MembersSelectList from "./MembersSelectList";

type AddAdminDialogProps = DialogXProps & {
  onAdd?: (ids: Array<Member["id"]>) => void;
  onCancel?: () => void;
  className?: string;
};

function AddAdminDialog({
  onAdd,
  onCancel,
  className = "",
  ...props
}: AddAdminDialogProps) {
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [selectedMemberIds, setSelectedMemberIds] = useState<
    Array<Member["id"]>
  >([]);

  const {
    members,
    addMembersAsAdmin,
    isLoading: isMembersLoading,
  } = useMembers({
    filters: { role: [UserRole.BUSINESS_USER] },
    keyword: searchKeyword,
  });
  const { templates, isLoading: isTemplatesLoading } = useTemplates();

  async function addSelectedMembersAsAdmin() {
    const { success, message } = await addMembersAsAdmin(selectedMemberIds);
    if (success) {
      toast.success(message);
      onAdd?.(selectedMemberIds);
    } else {
      toast.error(message);
    }
  }

  return (
    <DialogX
      className={`relative flex flex-col overflow-hidden ${className}`}
      fullScreen
      {...props}
    >
      <div className="flex items-center justify-between gap-8">
        <div className="flex flex-col gap-0.5">
          <DialogTitle className="!p-0 !font-sans !font-semibold text-black">
            Upgrade Members to Admin
          </DialogTitle>
          <p className="text-sm text-gray-500">
            Please select the members you want to make Admins.
          </p>
        </div>
        <FloatingLabel
          size="small"
          onInputValueChange={setSearchKeyword}
          onSearchClick={setSearchKeyword}
        >
          <input
            type="search"
            name="search-buttons"
            placeholder="Search"
            value={searchKeyword}
            onKeyUp={() => setSearchKeyword(searchKeyword)}
          />
        </FloatingLabel>
      </div>
      <DialogContent className="flex flex-col !p-0">
        <MembersSelectList
          members={
            members?.map((m) => ({
              ...m,
              templateName: templates?.find((t) => t.assigneeIds.includes(m.id))
                ?.name,
            })) || []
          }
          title="Select All"
          emptyListMessage="No member found to be upgraded."
          onSelect={setSelectedMemberIds}
          loading={(!members && isMembersLoading) || isTemplatesLoading}
          className="flex flex-col"
        />
      </DialogContent>
      <DialogActions className="gap-4 !border-t border-gray-200 !p-0 !pt-4">
        <Button
          variant="outlined"
          onClick={() => onCancel?.()}
          className="!m-0 !rounded-full !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-black"
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          startIcon={<AdminPanelSettingsIcon />}
          loadingPosition="start"
          loading={members && isMembersLoading}
          onClick={addSelectedMembersAsAdmin}
          disabled={!selectedMemberIds.length}
          classes={theme.LoadingButtonX}
        >
          Make Admin
        </LoadingButton>
      </DialogActions>
    </DialogX>
  );
}

export default AddAdminDialog;
