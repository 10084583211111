import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  getStoredItem,
  removeStoredItem,
  setStoredItem,
} from "../../utils/storage";
import type { AuthState } from "./authTypes";

const accessTokenStored = getStoredItem("accessToken");

const initialState: AuthState = {
  isAuthenticated: accessTokenStored !== null,
  accessToken: accessTokenStored || null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthentication: (state: AuthState, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
      setStoredItem("accessToken", action.payload);
      state.isAuthenticated = true;
    },
    removeAuthentication: (state: AuthState) => {
      state.accessToken = null;
      removeStoredItem("accessToken");
      state.isAuthenticated = false;
    },
  },
});

export const { setAuthentication, removeAuthentication } = authSlice.actions;

export default authSlice.reducer;

export const getIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated;
