import { createApi } from "@reduxjs/toolkit/query/react";
import APIConfigs from "../../configs/apiConfigs";
import { baseQueryWithReauth } from "../rtk-query/baseQuery";
import {
  getAddTemplateLinksToContactCardRequestBody,
  getCreateNewTemplateRequestBody,
  getCreateTemplateLinkRequestBody,
  getSwitchTemplateButtonsRequestBody,
  getSwitchTemplateLocksRequestBody,
  getUpdateTemplateDataRequestBody,
  getUpdateTemplateLinkRequestBody,
  mapAddTemplateLinksToContactCardResponse,
  mapAssignTemplateResponse,
  mapCreateNewTemplateResponse,
  mapCreateTemplateLinkResponse,
  mapDeleteTemplateLinkResponse,
  mapDeleteTemplateResponse,
  mapReadTemplatesResponse,
  mapReorderTemplateLinksResponse,
  mapSetAsDefaultTemplateDataResponse,
  mapSetTemplateCaptureLeadResponse,
  mapSetTemplateOpenDirectResponse,
  mapSwitchTemplateButtonsResponse,
  mapSwitchTemplateLocksResponse,
  mapUpdateTemplateDataResponse,
  mapUpdateTemplateLinkResponse,
} from "./templatesMapper";
import type {
  AddTemplateLinksToContactCardRequestArgs,
  AddTemplateLinksToContactCardResponse,
  AssignTemplateRequestArgs,
  AssignTemplateResponse,
  CreateNewTemplateResponse,
  CreateTemplateLinkRequestArgs,
  CreateTemplateLinkResponse,
  DeleteTemplateLinkRequestArgs,
  DeleteTemplateLinkResponse,
  DeleteTemplateResponse,
  ReadTemplatesResponse,
  ReorderTemplateLinksRequestArgs,
  ReorderTemplateLinksResponse,
  SetAsDefaultTemplateDataResponse,
  SetTemplateCaptureLeadRequestArgs,
  SetTemplateOpenDirectRequestArgs,
  SetTemplateProfileOpensResponse,
  SwitchTemplateButtonsRequestArgs,
  SwitchTemplateButtonsResponse,
  SwitchTemplateLocksRequestArgs,
  SwitchTemplateLocksResponse,
  Template,
  UpdateTemplateDataRequestArgs,
  UpdateTemplateDataResponse,
  UpdateTemplateLinkRequestArgs,
  UpdateTemplateLinkResponse,
} from "./templatesTypes";

const templatesService = createApi({
  reducerPath: "templatesService",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    readTemplates: builder.mutation<ReadTemplatesResponse, void>({
      query: () => APIConfigs.Templates.CREATE_READ_TEMPLATES,
      transformResponse: mapReadTemplatesResponse,
    }),
    createTemplate: builder.mutation<
      CreateNewTemplateResponse,
      Template["name"]
    >({
      query: (name) => ({
        method: "POST",
        url: APIConfigs.Templates.CREATE_READ_TEMPLATES,
        body: getCreateNewTemplateRequestBody(name),
      }),
      transformResponse: mapCreateNewTemplateResponse,
    }),
    deleteTemplate: builder.mutation<DeleteTemplateResponse, Template["id"]>({
      query: (id) => ({
        method: "DELETE",
        url: APIConfigs.Templates.UPDATE_DELETE_TEMPLATE.replace(
          ":id",
          id.toString()
        ),
      }),
      transformResponse: mapDeleteTemplateResponse,
    }),
    updateTemplateData: builder.mutation<
      UpdateTemplateDataResponse,
      UpdateTemplateDataRequestArgs
    >({
      query: ({ id, ...templateDataToUpdate }) => ({
        method: "POST",
        url: APIConfigs.Templates.UPDATE_DELETE_TEMPLATE.replace(
          ":id",
          id.toString()
        ),
        body: getUpdateTemplateDataRequestBody(templateDataToUpdate),
      }),
      transformResponse: mapUpdateTemplateDataResponse,
    }),
    setAsDefaultTemplate: builder.mutation<
      SetAsDefaultTemplateDataResponse,
      Template["id"]
    >({
      query: (id) => ({
        method: "POST",
        url: APIConfigs.Templates.SET_AS_DEFAULT_TEMPLATE.replace(
          ":id",
          id.toString()
        ),
      }),
      transformResponse: mapSetAsDefaultTemplateDataResponse,
    }),
    setTemplateCaptureLead: builder.mutation<
      SetTemplateProfileOpensResponse,
      SetTemplateCaptureLeadRequestArgs
    >({
      query: ({ id, captureLead }) => ({
        method: "PUT",
        url: APIConfigs.Templates.UPDATE_DELETE_TEMPLATE.replace(
          ":id",
          id.toString()
        ),
        body: captureLead
          ? { capture_lead: Number(captureLead), open_direct: 0 }
          : { capture_lead: Number(captureLead) },
      }),
      transformResponse: mapSetTemplateCaptureLeadResponse,
    }),
    setTemplateOpenDirect: builder.mutation<
      SetTemplateProfileOpensResponse,
      SetTemplateOpenDirectRequestArgs
    >({
      query: ({ id, openDirect }) => ({
        method: "PUT",
        url: APIConfigs.Templates.UPDATE_DELETE_TEMPLATE.replace(
          ":id",
          id.toString()
        ),
        body: openDirect
          ? { open_direct: Number(openDirect), capture_lead: 0 }
          : { open_direct: Number(openDirect) },
      }),
      transformResponse: mapSetTemplateOpenDirectResponse,
    }),
    reorderTemplateLinks: builder.mutation<
      ReorderTemplateLinksResponse,
      ReorderTemplateLinksRequestArgs
    >({
      query: ({ templateId, templateLinkIds }) => ({
        method: "PUT",
        url: APIConfigs.Templates.REORDER_TEMPLATE_LINKS.replace(
          ":id",
          templateId.toString()
        ),
        body: {
          sequence: templateLinkIds.join(","),
        },
      }),
      transformResponse: mapReorderTemplateLinksResponse,
    }),
    updateTemplateLink: builder.mutation<
      UpdateTemplateLinkResponse,
      UpdateTemplateLinkRequestArgs
    >({
      query: ({ id, templateId, ...updates }) => ({
        method: "POST",
        url: APIConfigs.Templates.UPDATE_DELETE_TEMPLATE_LINK.replace(
          ":templateId",
          templateId.toString()
        ).replace(":linkId", id.toString()),
        body: getUpdateTemplateLinkRequestBody(updates),
      }),
      transformResponse: mapUpdateTemplateLinkResponse,
    }),
    createTemplateLink: builder.mutation<
      CreateTemplateLinkResponse,
      CreateTemplateLinkRequestArgs
    >({
      query: ({ templateId, ...newLink }) => ({
        method: "POST",
        url: APIConfigs.Templates.CREATE_TEMPLATE_LINK.replace(
          ":id",
          templateId.toString()
        ),
        body: getCreateTemplateLinkRequestBody(newLink),
      }),
      transformResponse: mapCreateTemplateLinkResponse,
    }),
    switchTemplateButtons: builder.mutation<
      SwitchTemplateButtonsResponse,
      SwitchTemplateButtonsRequestArgs
    >({
      query: ({ id, ...updates }) => ({
        method: "PUT",
        url: APIConfigs.Templates.UPDATE_DELETE_TEMPLATE.replace(
          ":id",
          id.toString()
        ),
        body: getSwitchTemplateButtonsRequestBody(updates),
      }),
      transformResponse: mapSwitchTemplateButtonsResponse,
    }),
    deleteTemplateLink: builder.mutation<
      DeleteTemplateLinkResponse,
      DeleteTemplateLinkRequestArgs
    >({
      query: ({ id, templateId }) => ({
        method: "DELETE",
        url: APIConfigs.Templates.UPDATE_DELETE_TEMPLATE_LINK.replace(
          ":templateId",
          templateId.toString()
        ).replace(":linkId", id.toString()),
      }),
      transformResponse: mapDeleteTemplateLinkResponse,
    }),
    assignTemplate: builder.mutation<
      AssignTemplateResponse,
      AssignTemplateRequestArgs
    >({
      query: ({ id, assigneeIds }) => ({
        method: "PUT",
        url: APIConfigs.Templates.ASSIGN_MEMBERS.replace(":id", id.toString()),
        body: {
          assignees_ids: assigneeIds,
        },
      }),
      transformResponse: mapAssignTemplateResponse,
    }),
    switchTemplateLocks: builder.mutation<
      SwitchTemplateLocksResponse,
      SwitchTemplateLocksRequestArgs
    >({
      query: ({ id, ...updates }) => ({
        method: "PUT",
        url: APIConfigs.Templates.UPDATE_DELETE_TEMPLATE.replace(
          ":id",
          id.toString()
        ),
        body: getSwitchTemplateLocksRequestBody(updates),
      }),
      transformResponse: mapSwitchTemplateLocksResponse,
    }),
    addTemplateLinksToContactCard: builder.mutation<
      AddTemplateLinksToContactCardResponse,
      AddTemplateLinksToContactCardRequestArgs
    >({
      query: ({ templateId, templateLinkIds, isAddedToContactCardBy }) => ({
        method: "POST",
        url: APIConfigs.ContactCard.ADD_TEMPLATE_LINKS.replace(
          ":templateId",
          templateId.toString()
        ),
        body: getAddTemplateLinksToContactCardRequestBody(
          templateLinkIds,
          isAddedToContactCardBy
        ),
      }),
      transformResponse: mapAddTemplateLinksToContactCardResponse,
    }),
  }),
});

export default templatesService;
export const {
  useReadTemplatesMutation,
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useUpdateTemplateDataMutation,
  useSetAsDefaultTemplateMutation,
  useSetTemplateCaptureLeadMutation,
  useSetTemplateOpenDirectMutation,
  useReorderTemplateLinksMutation,
  useUpdateTemplateLinkMutation,
  useCreateTemplateLinkMutation,
  useSwitchTemplateButtonsMutation,
  useDeleteTemplateLinkMutation,
  useAssignTemplateMutation,
  useSwitchTemplateLocksMutation,
  useAddTemplateLinksToContactCardMutation,
} = templatesService;
