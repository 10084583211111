import { faAddressCard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import type { Member } from "../features/members/membersTypes";
import type { Template } from "../features/templates/templatesTypes";
import { CheckboxX } from "../styles/mui-styled";
import Spinner from "./Spinner";

type MembersSelectListProps = {
  members: Array<
    Pick<Member, "id" | "username" | "email" | "fullName" | "profileImage"> & {
      templateName?: Template["name"];
    }
  >;
  title?: string;
  emptyListMessage?: string | ReactElement;
  loading?: boolean;
  onSelect?: (ids: Array<Member["id"]>) => void;
  danger?: boolean;
  className?: string;
};

function MembersSelectList({
  members,
  title = "Members",
  emptyListMessage = "No member found.",
  loading = false,
  onSelect,
  danger = false,
  className = "",
}: MembersSelectListProps) {
  const [selectedIds, setSelectedIds] = useState<Array<Member["id"]>>([]);

  useEffect(() => {
    setSelectedIds((prevState) =>
      prevState.filter((sid) => members.some((m) => m.id === sid))
    );
  }, [members]);

  function handleSelectAllClick(): void {
    if (selectedIds.length === members.length) {
      setSelectedIds([]);
      onSelect && onSelect([]);
    } else {
      setSelectedIds(members.map((m) => m.id));
      onSelect && onSelect(members.map((m) => m.id));
    }
  }

  function handleSingleClick(id: Member["id"]): void {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((sid) => sid !== id));
      onSelect && onSelect(selectedIds.filter((sid) => sid !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
      onSelect && onSelect([...selectedIds, id]);
    }
  }

  return (
    <Card className={`!shadow-none ${className}`}>
      <CardHeader
        avatar={
          <CheckboxX
            onClick={handleSelectAllClick}
            checked={
              members.length !== 0 && members.length === selectedIds.length
            }
            indeterminate={
              selectedIds.length !== 0 && selectedIds.length !== members.length
            }
            disabled={members.length === 0}
            inputProps={{
              "aria-label": "Select all members",
            }}
            className={danger ? "danger" : ""}
          />
        }
        title={title}
        subheader={`${selectedIds.length}/${members.length} selected`}
        classes={{
          title: "!font-sans !text-base !font-semibold",
          subheader: "!font-sans",
        }}
      />
      <Divider />
      {loading ? (
        <div className="relative h-96">
          <Spinner className="bg-transparent" />
        </div>
      ) : members.length > 0 ? (
        <List className="overflow-auto scrollbar-thin scrollbar-track-gray-50 scrollbar-thumb-gray-300 scrollbar-thumb-rounded">
          {members.map((aMember) => {
            const labelId = `checkbox-list-secondary-label-${aMember.id}`;
            return (
              <ListItem key={aMember.id} disablePadding>
                <ListItemButton
                  onClick={() => handleSingleClick(aMember.id)}
                  dense
                  className={
                    selectedIds.includes(aMember.id)
                      ? danger
                        ? "!bg-error-50"
                        : "!bg-fuchsia-50"
                      : ""
                  }
                >
                  <ListItemIcon className="!min-w-0">
                    <CheckboxX
                      size="small"
                      checked={selectedIds.includes(aMember.id)}
                      tabIndex={-1}
                      inputProps={{ "aria-labelledby": labelId }}
                      className={danger ? "danger" : ""}
                    />
                  </ListItemIcon>
                  <ListItemAvatar>
                    <Avatar
                      src={aMember.profileImage}
                      classes={{
                        root: "!h-12 !w-12 !bg-gray-100 !text-gray-400",
                      }}
                      aria-label={aMember.fullName || aMember.username}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    primary={aMember.fullName || aMember.username}
                    secondary={aMember.email}
                    classes={{
                      primary: "!font-sans !font-semibold",
                      secondary: "break-words !font-sans",
                    }}
                  />
                  <ListItemSecondaryAction>
                    {aMember.templateName && (
                      <Typography
                        component="button"
                        aria-label="Change Template"
                        noWrap
                        className={`relative -left-3 flex h-8 max-w-full items-center gap-2 rounded-2xl ${
                          selectedIds.includes(aMember.id)
                            ? danger
                              ? "!bg-error-100"
                              : "!bg-fuchsia-100"
                            : "bg-gray-100"
                        } px-3 !font-sans !text-sm text-gray-600`}
                      >
                        <FontAwesomeIcon
                          icon={faAddressCard}
                          className="text-base"
                        />
                        {aMember.templateName}
                      </Typography>
                    )}
                  </ListItemSecondaryAction>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      ) : typeof emptyListMessage === "object" ? (
        emptyListMessage
      ) : (
        <p className="flex h-96 flex-col items-center justify-center gap-2 text-center">
          {/* <strong className='text-sm text-gray-700'>No members found</strong> */}
          <small className="block w-72 text-xs text-gray-600">
            {emptyListMessage}
          </small>
        </p>
      )}
    </Card>
  );
}

export default MembersSelectList;
