import { useEffect } from "react";
import {
  getDevicesByAssignedTo,
  MemberDevice,
  removeDevices,
  setDevices,
  useReadDevicesQuery,
  useUnmapDevicesMutation,
} from "../features/devices";
import type { Member } from "../features/members/membersTypes";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import { FetchError, handleFetchError } from "../utils";

function useMember(id: Member["id"]) {
  const dispatch = useAppDispatch();

  const devices = useAppSelector((state: RootState) =>
    getDevicesByAssignedTo(state, id)
  );

  const {
    data: devicesRequest,
    isLoading: isReadDevicesLoading,
    error,
  } = useReadDevicesQuery();

  useEffect(() => {
    if (devicesRequest?.success) {
      dispatch(setDevices(devicesRequest.data));
    } else if (error) {
      handleFetchError(error as FetchError);
    }
  }, [devicesRequest, dispatch, error]);

  const [unmapDevices, { isLoading: isUnmapDevicesLoading }] =
    useUnmapDevicesMutation();

  async function unmapDevicesByIds(ids: Array<MemberDevice["id"]>) {
    try {
      const {
        success,
        message,
        data: unmappedDeviceIds,
      } = await unmapDevices(ids).unwrap();
      if (success) {
        dispatch(removeDevices(unmappedDeviceIds));
      }
      return { success, message };
    } catch (error) {
      return {
        success: false,
        message: "An error occurred while unmapping the device.",
      };
    }
  }

  return {
    devices,
    unmapDevices: unmapDevicesByIds,
    isLoading: isReadDevicesLoading || isUnmapDevicesLoading,
    isSuccess: devicesRequest?.success,
    message: devicesRequest?.message,
  };
}

export default useMember;
