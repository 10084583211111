import type {
  ButtonPropsToSync,
  MemberPropertiesToSync,
  SyncADConfigsCommon,
  SyncADConfigsFormData,
} from "./integrationsTypes";
import type { MemberLink } from "../links/linksTypes";
import { MemberDataLabels } from "../members/membersEnums";

export enum Platforms {
  HUBSPOT = "hubspot",
  ACTIVE_DIRECTORY = "azure-ad",
  SALESFORCE = "salesforce",
  DYNAMICS = "dynamics-crm",
}

export enum AutoSyncFrequencies {
  NONE = "none",
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  QUARTERLY = "quarterly",
}

export enum ADFieldLabels {
  EMAIL = "Email",
  FULL_NAME = "Display Name",
  JOB_TITLE = "Job Title",
  COMPANY_NAME = "Company Name",
  LOCATION = "Location",
  PROFILE_PHOTO = "Profile Picture",
  PHONE_BUSINESS = "Business Phone",
  PHONE_MOBILE = "Mobile Phone",
}

export const defaultSyncADConfigsCommon: SyncADConfigsCommon = {
  autoSyncFrequency: AutoSyncFrequencies.NONE,
};

export const defaultSyncADConfigs: SyncADConfigsFormData = {
  propsToSync: {
    profileImage: { sync: true, overwrite: true },
    fullName: { sync: true, overwrite: true },
    designation: { sync: true, overwrite: true },
    companyName: { sync: true, overwrite: true },
    companyAddress: { sync: true, overwrite: true },
    companyAddressButton: { sync: false, overwrite: false },
    phoneBusinessButton: { sync: false, overwrite: false },
    phoneMobileButton: { sync: false, overwrite: false },
  },
};

export const MemberTextADFieldLabelsMap = new Map<
  MemberPropertiesToSync,
  ADFieldLabels
>([
  ["profileImage", ADFieldLabels.PROFILE_PHOTO],
  ["fullName", ADFieldLabels.FULL_NAME],
  ["designation", ADFieldLabels.JOB_TITLE],
  ["companyName", ADFieldLabels.COMPANY_NAME],
  ["companyAddress", ADFieldLabels.LOCATION],
]);

export const MemberButtonADFieldLabelsMap = new Map<
  ButtonPropsToSync,
  ADFieldLabels
>([
  ["companyAddressButton", ADFieldLabels.LOCATION],
  ["phoneBusinessButton", ADFieldLabels.PHONE_BUSINESS],
  ["phoneMobileButton", ADFieldLabels.PHONE_MOBILE],
]);

export const MemberADFieldLabelsMap = new Map<
  MemberPropertiesToSync,
  ADFieldLabels
>([
  ...Array.from(MemberTextADFieldLabelsMap.entries()),
  ...Array.from(MemberButtonADFieldLabelsMap.entries()),
]);

export const MemberFieldLabelsMap = new Map<
  MemberPropertiesToSync,
  MemberDataLabels | MemberLink["id"] | undefined
>([
  ["profileImage", MemberDataLabels.PROFILE_PICTURE],
  ["fullName", MemberDataLabels.CARD_TITLE],
  ["designation", MemberDataLabels.JOB_TITLE],
  ["companyName", MemberDataLabels.COMPANY],
  ["companyAddress", MemberDataLabels.LOCATION],
  ["companyAddressButton", undefined],
  ["phoneBusinessButton", undefined],
  ["phoneMobileButton", undefined],
]);
