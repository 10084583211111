import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FloatingLabel, Spinner } from "../../components";
import { useAppDispatch } from "../../store";
import { FetchError, handleFetchError } from "../../utils";
import { useLogInMutation } from "./authService";
import { setAuthentication } from "./authSlice";

function LoginForm() {
  const inputEmailRef = useRef<HTMLInputElement>(null);
  const inputPasswordRef = useRef<HTMLInputElement>(null);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const navigateTo = useNavigate();
  const location = useLocation();
  const from =
    (location.state as { from: Location })?.from?.pathname || "/members";
  const dispatch = useAppDispatch();

  useEffect(() => {
    inputEmailRef.current?.focus();
  }, []);

  const [logIn, { isLoading: isLogInPending }] = useLogInMutation();

  async function handleFormSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!email) {
      toast.warn("Please enter email.");
      inputEmailRef.current?.focus();
      return;
    }

    if (!password) {
      toast.warn("Please enter password.");
      inputPasswordRef.current?.focus();
      return;
    }

    try {
      const {
        success,
        message,
        data: { isFirstLogin, accessToken },
      } = await logIn({
        email,
        password,
      }).unwrap();
      if (success) {
        if (isFirstLogin) {
          navigateTo("/reset-password", { state: { email }, replace: true });
        } else {
          dispatch(setAuthentication(accessToken));
          navigateTo(from, { replace: true });
        }

        setEmail("");
        setPassword("");
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  return (
    <>
      {isLogInPending && <Spinner fullScreen />}
      <ToastContainer />
      <section className="px-0 py-10 md:px-20 lg:px-10 xl:px-20">
        <div className="mb-8 flex items-center text-2xl font-semibold text-black">
          <h2 className="mr-3">Login</h2>
          <small className="border-l border-l-gray-400 pl-3 text-sm font-normal text-gray-400">
            Business Portal
          </small>
        </div>
        <form onSubmit={handleFormSubmit}>
          <fieldset className="flex flex-col">
            <FloatingLabel
              className="mb-8"
              onInputValueChange={(value: string) => setEmail(value)}
            >
              <input
                type="email"
                ref={inputEmailRef}
                name="email"
                placeholder="Email"
                value={email}
              />
            </FloatingLabel>
            <FloatingLabel
              className="mb-4"
              onInputValueChange={(value: string) => setPassword(value)}
            >
              <input
                type="password"
                ref={inputPasswordRef}
                name="password"
                placeholder="Password"
                value={password}
              />
            </FloatingLabel>
            <span className="mb-10 text-right">
              <Link
                to="/forgot-password"
                className="text-sm text-gray-500 hover:underline"
              >
                Forgot Password?
              </Link>
            </span>
            <button
              type="submit"
              className="relative rounded-full bg-fuchsia-900 px-8 py-3.5 text-center font-semibold text-white active:left-px active:top-px"
            >
              Login <FontAwesomeIcon icon={faRightToBracket} className="pl-2" />
            </button>
          </fieldset>
        </form>
      </section>
    </>
  );
}

export default LoginForm;
