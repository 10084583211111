import {
  faDownload,
  faFileCsv,
  faSignsPost,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DevTool } from "@hookform/devtools";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import Dragger from "antd/es/upload/Dragger";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { DialogX, MemberCSVMapping, SwitchX } from "../../components";
import AppConfigs from "../../configs/appConfigs";
import { getMappedAPIMemberKeys } from "../members/membersEnums";
import type {
  AddMembersByCSVFormData,
  AddMembersByCSVSubmitData,
} from "./addMembersTypes";

type ImportViaCSVProps = {
  onSubmit?: ({
    file,
    doSendInvites,
    mappedFields,
  }: AddMembersByCSVSubmitData) => void;
  inProgress?: boolean;
  isSuccess?: boolean;
  className?: string;
};

function ImportViaCSV({
  onSubmit,
  inProgress = false,
  isSuccess = false,
  className = "",
  ...props
}: ImportViaCSVProps) {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [mapCSVDialogOpen, setMapCSVDialogOpen] = useState<boolean>(false);
  const [csvHeaders, setCSVHeaders] = useState<string[]>([]);

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<AddMembersByCSVFormData>({
    defaultValues: {
      file: null,
      doSendInvites: true,
      mappedFields: new Map(),
    },
  });

  function addMembers({
    file,
    doSendInvites,
    mappedFields,
  }: AddMembersByCSVFormData): void {
    if (file && mappedFields.size && onSubmit) {
      onSubmit({
        file,
        doSendInvites,
        mappedFields,
      });
    }
  }

  async function handleDownloadClick() {
    setIsDownloading(true);
    const response = await fetch(
      `${AppConfigs.PUBLIC_FOLDER_URL}/static/docs/AddMee_Business_Portal_CSV_Upload_Template.csv`
    );
    const blob = await response.blob();
    const tempDownloadLink = document.createElement("a");
    tempDownloadLink.href = URL.createObjectURL(blob);
    tempDownloadLink.setAttribute(
      "download",
      "AddMee_Business_Portal_CSV_Upload_Template.csv"
    );
    document.body.appendChild(tempDownloadLink);
    tempDownloadLink.click();
    document.body.removeChild(tempDownloadLink);
    setIsDownloading(false);
  }

  function handleBeforeUpload(
    field: ControllerRenderProps<AddMembersByCSVFormData, "file">,
    file: File
  ): boolean {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function ({ meta: { fields } }, file) {
        setCSVHeaders(fields || []);
        setValue("mappedFields", new Map());
        field.onChange(file);
        setMapCSVDialogOpen(true);
      },
    });

    return false;
  }

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess, reset]);

  return (
    <div className={`flex flex-col gap-4 ${className}`} {...props}>
      <div>
        <h4 className="text-xl font-semibold text-black">Import Members</h4>
        <div className="grid items-start justify-between gap-4 xl:grid-cols-2 2xl:gap-6">
          <p className="grow text-sm text-gray-500">
            Download the CSV template, fill it up and upload it to add members
            to your team. If an AddMee user with that email already exists, the
            member will be pending until the user accepts the email invite.
          </p>
          <div className="w-full xl:w-64 2xl:w-96">
            <LoadingButton
              variant="outlined"
              startIcon={<FontAwesomeIcon icon={faDownload} />}
              onClick={handleDownloadClick}
              loading={isDownloading}
              loadingPosition="start"
              className="w-full !rounded-full !border-gray-200 !px-6 !py-3 !font-sans !text-sm !normal-case !text-black"
              classes={{
                loadingIndicator: "!left-10",
              }}
            >
              Download CSV Template
            </LoadingButton>
          </div>
        </div>
      </div>
      <div>
        <Controller
          name="file"
          control={control}
          render={({ field }) => (
            <Dragger
              accept=".csv"
              beforeUpload={(value) => handleBeforeUpload(field, value)}
              showUploadList={false}
            >
              <div className="relative flex flex-col items-center gap-4 px-4 py-4 !font-sans lg:px-7">
                <p className="inline-flex h-32 w-32 items-center justify-center rounded-full border bg-gray-200 text-4xl">
                  <FontAwesomeIcon icon={faFileCsv} />
                </p>
                {Boolean(field.value) && (
                  <p className="absolute top-28 h-6 min-w-32 max-w-full truncate rounded-full border border-gray-300 bg-white px-4 py-1 text-xs text-primary">
                    {field.value?.name}
                  </p>
                )}
                <p className="text-base">
                  <strong className="text-primary">Select CSV file</strong> to
                  upload
                  <span className="block text-sm">
                    or drag and drop it here
                  </span>
                </p>
                <p>
                  <small>
                    * Must be <b>CSV</b> file with maximum file size <b>50MB</b>
                  </small>
                </p>
              </div>
            </Dragger>
          )}
        />
        <DialogX
          open={mapCSVDialogOpen}
          onClose={() => setMapCSVDialogOpen(false)}
          fullScreen
          className="!max-w-2xl"
        >
          <Controller
            name="mappedFields"
            control={control}
            render={({ field }) => (
              <MemberCSVMapping
                propLabels={getMappedAPIMemberKeys()}
                csvHeaders={csvHeaders}
                mappedFields={field.value}
                onSave={(value) => {
                  field.onChange(value);
                  setMapCSVDialogOpen(false);
                }}
                onCancel={() => setMapCSVDialogOpen(false)}
                className="h-full overflow-auto"
              />
            )}
          />
        </DialogX>
      </div>
      <div className="flex flex-col justify-end gap-4 border-t border-gray-200 pt-8 xl:flex-row xl:items-center xl:pt-4">
        <div className="grow">
          <Controller
            name="doSendInvites"
            control={control}
            render={({ field }) => (
              <SwitchX
                checked={field.value}
                onChange={(_e, v) => field.onChange(v)}
                label="Send email invites"
              />
            )}
          />
        </div>
        <Button
          variant="outlined"
          startIcon={
            <FontAwesomeIcon icon={faSignsPost} className="!text-sm" />
          }
          onClick={() => setMapCSVDialogOpen(true)}
          disabled={!isDirty || !Boolean(csvHeaders?.length)}
          className={`!m-0 ${
            Boolean(csvHeaders.length) &&
            !Boolean(getValues("mappedFields").size) &&
            "animate-bounce"
          } !rounded-full !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case ${
            isDirty && Boolean(csvHeaders?.length) && "!text-black"
          }`}
        >
          Mapping
        </Button>
        <LoadingButton
          type="submit"
          startIcon={<FontAwesomeIcon icon={faUserPlus} className="!text-sm" />}
          loading={inProgress}
          loadingPosition="start"
          disabled={!isDirty || !Boolean(getValues("mappedFields").size)}
          classes={{
            root: `!rounded-full ${
              isDirty &&
              Boolean(getValues("mappedFields").size) &&
              "!bg-primary"
            } !px-6 !py-3 !font-sans !text-sm !normal-case !text-white`,
            disabled: "!bg-gray-300",
            loadingIndicator: "!left-5",
          }}
          onClick={handleSubmit(addMembers)}
        >
          Add Member(s)
        </LoadingButton>
      </div>
      <DevTool control={control} />
    </div>
  );
}

export default ImportViaCSV;
