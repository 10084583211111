import { Link, Navigate, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Logo } from "../assets/images/addmee_logo.svg";
// import Pixel from "../assets/images/pixel.png";
// import {
//   BritishCouncilLogo,
//   CocaColaLogo,
//   EtsyLogo,
//   FilaLogo,
//   GooglePayLogo,
//   HondaLogo,
//   IbmLogo,
//   MicrosoftLogo,
//   LenovoLogo,
//   MasterCardLogo,
// } from "../assets/images/brands";
import {
  AddMeeCard,
  AddMeeKeychain,
  AddMeeSticker,
  AddMeeTable,
} from "../assets/images/products";
import { useAppSelector } from "../store";
import { getIsAuthenticated } from "../features/authentication/authSlice";

function LayoutLogin() {
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="flex bg-neutral-700">
      <div className="flex grow flex-col overflow-hidden rounded-[1.25rem] bg-white lg:flex-row">
        <div className="flex min-h-screen flex-col justify-between px-20 py-10 lg:w-1/2">
          <div>
            <Link to="/">
              <Logo width={177} />
            </Link>
          </div>
          <Outlet />
          <div className="flex justify-between">
            <div className="text-gray-500">
              &copy; AddMee {new Date().getFullYear()}
            </div>
            <div>
              <Link to="mailto:business@addmee.de" className="text-gray-500">
                <FontAwesomeIcon icon={faEnvelope} className="pr-2" />
                business@addmee.de
              </Link>
            </div>
          </div>
        </div>
        <div className="relative flex flex-col items-center justify-between bg-gray-200 p-16 lg:w-1/2 lg:rounded-l-[3.75rem]">
          <div className="mb-14 flex flex-col items-center">
            <h3 className="mb-4 flex flex-col text-center text-2xl font-semibold text-gray-900">
              Trusted by
              <span className="mt-2 flex items-center text-xl">
                20,000+ Users
                {/* 5 Million+ Users
                <span className='mx-3 text-base'>and</span>
                200 Companies */}
              </span>
            </h3>
            <div className="flex">
              {[1, 2, 3, 4, 5].map((i) => (
                <FontAwesomeIcon
                  key={`star-${i}`}
                  icon={faStar}
                  className="mr-1.5 text-sm text-yellow-500 last:mr-0"
                />
              ))}
            </div>
          </div>
          {/* <div className='mx-5 mb-14 grid h-36 auto-rows-fr grid-cols-5 items-center gap-x-3 gap-y-3 self-stretch sm:gap-x-10 md:gap-x-14 lg:gap-x-6 xl:gap-x-14'>
            <BritishCouncilLogo />
            <CocaColaLogo />
            <EtsyLogo />
            <FilaLogo />
            <GooglePayLogo />
            <HondaLogo />
            <IbmLogo />
            <MicrosoftLogo />
            <LenovoLogo />
            <MasterCardLogo />
          </div> */}
          <div className="flex w-full flex-col items-center justify-between gap-y-4 sm:h-32 sm:flex-row md:h-44 lg:h-24 xl:h-36 xl:px-5 2xl:h-44">
            <img
              src={AddMeeSticker}
              alt="AddMee Sticker"
              className="aspect-square sm:h-1/2"
            />
            <img
              src={AddMeeCard}
              alt="AddMee Card"
              className="aspect-[317/211] sm:h-3/4"
            />
            <img
              src={AddMeeTable}
              alt="AddMee Table"
              className="aspect-square sm:h-full sm:p-1"
            />
            <img
              src={AddMeeKeychain}
              alt="AddMee Keychain"
              className="aspect-[105/283] sm:h-full"
            />
          </div>
          {/* <div className='flex min-h-[16.475rem] flex-grow items-end self-stretch'>
            <figure className='relative -right-7 flex aspect-[9/16] h-5/6 items-center justify-center overflow-hidden rounded-3xl bg-white shadow-inner'>
              <img src={Pixel} alt='Desktop View' className='w-full' />
            </figure>
            <figure className='ritems-center flex aspect-[16/9] h-full justify-center overflow-hidden rounded-3xl bg-white shadow-inner'>
              <img src={Pixel} alt='Desktop View' className='w-full' />
            </figure>
          </div> */}
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default LayoutLogin;
