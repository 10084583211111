import PhonePreview from "../../components/PhonePreview";
import APIConfigs from "../../configs/apiConfigs";
import type { Member } from "../members/membersTypes";

type AddDeviceProps = {
  member: Member;
  className?: string;
};

function AddDevice({ member, className, ...props }: AddDeviceProps) {
  return (
    <article
      className={`flex flex-col gap-6 rounded-3xl border border-gray-200 px-8 py-6 ${className}`}
      {...props}
    >
      <h4 className="text-lg font-semibold text-black">Add Device</h4>
      <PhonePreview>
        <div className="flex min-h-full flex-col items-center justify-center gap-8 rounded-[1.25rem] bg-gray-100 p-3 pt-12">
          <h5 className="text-center text-lg font-semibold text-black">
            {`Activate devices to ${
              member.firstName || member.fullName || member.username
            }'s profile`}
          </h5>
          <div className="flex rounded-xl bg-white p-8 shadow-2xl shadow-gray-900/20">
            <img
              src={`${APIConfigs.API_BASE_URL}/members/${member.id}/qrcode`}
              alt={`QR Code for ${
                member.firstName || member.fullName || member.username
              }'s profile`}
              className="aspect-square w-56"
            />
          </div>
          <div className="flex flex-col gap-6 text-center text-sm text-gray-700">
            <p>
              Scan the AddMee code with your phone to launch the activation flow
            </p>
            <small className="text-xs">Use your app to activate devices</small>
          </div>
        </div>
      </PhonePreview>
      <p className="flex flex-col items-center text-sm">
        <span>Don't have any devices?</span>
        <span>
          <a
            href="https://addmee-business.com/collections/frontpage"
            target="_blank"
            rel="noreferrer"
            className="font-semibold text-primary hover:underline"
          >
            Purchase
          </a>{" "}
          them here
        </span>
      </p>
    </article>
  );
}

export default AddDevice;
