import { faAngleLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FloatingLabel, Spinner } from "../../components";
import { FetchError, handleFetchError } from "../../utils";
import { useVerifyCodeMutation } from "./forgotPasswordService";

function VerifyCodeForm() {
  const [vCode, setVCode] = useState<string>("");

  const navigateTo = useNavigate();

  const location = useLocation();
  const [email, setEmail] = useState<string>(location.state?.email);

  const inputEmailRef = useRef<HTMLInputElement>(null);
  const inputVCodeRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (location.state?.email) {
      inputVCodeRef.current?.focus();
    } else {
      inputEmailRef.current?.focus();
    }
  }, [location.state?.email]);

  const [verifyCode, { isLoading: isVerifyCodePending }] =
    useVerifyCodeMutation();

  async function handleFormSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!email) {
      toast.warn("Please enter email.");
      inputEmailRef.current?.focus();
      return;
    }

    if (!vCode) {
      toast.warn("Please enter the verification code.");
      inputVCodeRef.current?.focus();
      return;
    }

    try {
      const {
        success,
        message,
        data: { email: emailToReset },
      } = await verifyCode({
        email,
        vcode: vCode,
      }).unwrap();
      if (success) {
        navigateTo("/reset-password", {
          state: { email: emailToReset },
        });
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  return (
    <>
      {isVerifyCodePending && <Spinner fullScreen />}
      <ToastContainer />
      <section className="px-0 py-10 md:px-20 lg:px-10 xl:px-20">
        <div className="mb-4 flex items-center text-2xl font-semibold text-black">
          <h2 className="mr-3">Verify Code</h2>
          <small className="border-l border-l-gray-400 pl-3 text-sm font-normal text-gray-400">
            Business Portal
          </small>
        </div>
        <p className="mb-8 text-base font-normal text-gray-500">
          Verification code has been sent to your email address.
        </p>
        <form onSubmit={handleFormSubmit}>
          <fieldset className="flex flex-col">
            <FloatingLabel
              className="mb-8"
              onInputValueChange={(value: string) => setEmail(value)}
            >
              <input
                type="email"
                ref={inputEmailRef}
                name="email"
                placeholder="Email"
                value={email}
                disabled={!!location.state?.email}
              />
            </FloatingLabel>
            <FloatingLabel
              className="mb-4"
              onInputValueChange={(value: string) => setVCode(value)}
            >
              <input
                type="text"
                ref={inputVCodeRef}
                name="vcode"
                placeholder="Verification Code"
                value={vCode}
              />
            </FloatingLabel>
            <span className="mb-10 text-right">
              <Link
                to="/login"
                className="inline-flex items-center text-sm text-gray-500 hover:underline"
              >
                <FontAwesomeIcon icon={faAngleLeft} className="pr-2" />
                Back to login
              </Link>
            </span>
            <button
              type="submit"
              className="relative rounded-full bg-fuchsia-900 px-8 py-3.5 text-center font-semibold text-white active:left-px active:top-px"
            >
              Verify Code
              <FontAwesomeIcon icon={faCheck} className="pl-2" />
            </button>
          </fieldset>
        </form>
      </section>
    </>
  );
}

export default VerifyCodeForm;
