import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, To, useLocation } from "react-router-dom";

export type NavigationItem = {
  name: string;
  path: To;
  icon: IconDefinition;
};

type NavigationProps = {
  items: NavigationItem[];
  className?: string;
};

function Navigation({ items, className, ...props }: NavigationProps) {
  const { pathname: currentPath } = useLocation();

  return (
    <nav className={`flex flex-col gap-2 ${className ?? ""}`} {...props}>
      {items.map((navItem, index) => (
        <Link
          to={navItem.path}
          key={index}
          className={`flex items-center justify-center gap-3 rounded-xl px-2 py-3 text-gray-600 hover:bg-slate-50 lg:justify-between lg:px-4 ${
            navItem.path !== "/" &&
            currentPath.includes(navItem.path.toString())
              ? "bg-primary-50 text-primary"
              : "bg-white"
          }`}
        >
          <FontAwesomeIcon icon={navItem.icon} />
          <span className='hidden grow lg:block'>{navItem.name}</span>
        </Link>
      ))}
    </nav>
  );
}

export default Navigation;
