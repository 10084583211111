import { createApi } from "@reduxjs/toolkit/query/react";
import APIConfigs from "../../configs/apiConfigs";
import { baseQueryWithReauth } from "../rtk-query/baseQuery";
import {
  getCreateADIntegrationRequestBody,
  getCreateDynamicsIntegrationRequestBody,
  getCreateHubSpotIntegrationRequestBody,
  getCreateSalesforceIntegrationRequestBody,
  getUpdateIntegrationRequestBody,
  getUpdateSyncedMembersRequestBody,
  mapCreateIntegrationResponse,
  mapReadADGroupsUsersResponse,
  mapReadIPlatformsResponse,
  mapReadIntegrationsResponse,
  mapReadSyncedMembersResponse,
  mapUpdateIntegrationResponse,
  mapUpdateSyncedMembersResponse,
} from "./integrationsMapper";
import type {
  CreateADIntegrationRequestArgs,
  CreateDynamicsIntegrationRequestArgs,
  CreateIntegrationResponse,
  CreateSalesforceIntegrationRequestArgs,
  DeleteIntegrationResponse,
  Integration,
  ReadADGroupsUsersResponse,
  ReadIPlatformsResponse,
  ReadIntegrationsResponse,
  ReadSyncedMembersResponse,
  UpdateIntegrationRequestArgs,
  UpdateIntegrationResponse,
  UpdateSyncedMembersRequestArgs,
  UpdateSyncedMembersResponse,
} from "./integrationsTypes";

const integrationsService = createApi({
  reducerPath: "integrationsService",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    readIPlatforms: builder.mutation<ReadIPlatformsResponse, void>({
      query: () => APIConfigs.Integrations.READ_IPLATFORMS,
      transformResponse: mapReadIPlatformsResponse,
    }),
    readIntegrations: builder.mutation<ReadIntegrationsResponse, void>({
      query: () => APIConfigs.Integrations.READ_INTEGRATIONS,
      transformResponse: mapReadIntegrationsResponse,
    }),
    createHubSpotIntegration: builder.mutation<
      CreateIntegrationResponse,
      string
    >({
      query: (code) => ({
        url: APIConfigs.Integrations.CREATE_INTEGRATION_HUBSPOT,
        method: "POST",
        body: getCreateHubSpotIntegrationRequestBody(code),
      }),
      transformResponse: mapCreateIntegrationResponse,
    }),
    createADIntegration: builder.mutation<
      CreateIntegrationResponse,
      CreateADIntegrationRequestArgs
    >({
      query: (args) => ({
        url: APIConfigs.Integrations.CREATE_INTEGRATION_ACTIVE_DIRECTORY,
        method: "POST",
        body: getCreateADIntegrationRequestBody(args),
      }),
      transformResponse: mapCreateIntegrationResponse,
    }),
    createSalesforceIntegration: builder.mutation<
      CreateIntegrationResponse,
      CreateSalesforceIntegrationRequestArgs
    >({
      query: (args) => ({
        url: APIConfigs.Integrations.CREATE_INTEGRATION_SALESFORCE,
        method: "POST",
        body: getCreateSalesforceIntegrationRequestBody(args),
      }),
      transformResponse: mapCreateIntegrationResponse,
    }),
    readADGroupsUsers: builder.mutation<
      ReadADGroupsUsersResponse,
      Integration["id"]
    >({
      query: (id) =>
        APIConfigs.ActiveDirectory.READ_AD_GROUPS_USERS.replace(
          ":id",
          id.toString()
        ),
      transformResponse: mapReadADGroupsUsersResponse,
    }),
    createDynamicsIntegration: builder.mutation<
      CreateIntegrationResponse,
      CreateDynamicsIntegrationRequestArgs
    >({
      query: (args) => ({
        url: APIConfigs.Integrations.CREATE_INTEGRATION_DYNAMICS,
        method: "POST",
        body: getCreateDynamicsIntegrationRequestBody(args),
      }),
      transformResponse: mapCreateIntegrationResponse,
    }),
    deleteIntegration: builder.mutation<
      DeleteIntegrationResponse,
      Integration["id"]
    >({
      query: (id) => ({
        url: APIConfigs.Integrations.UPATE_DELETE_INTEGRATION.replace(
          ":id",
          id.toString()
        ),
        method: "DELETE",
      }),
    }),
    updateIntegration: builder.mutation<
      UpdateIntegrationResponse,
      UpdateIntegrationRequestArgs
    >({
      query: ({ id, ...args }) => ({
        url: APIConfigs.Integrations.UPATE_DELETE_INTEGRATION.replace(
          ":id",
          id.toString()
        ),
        method: "PUT",
        body: getUpdateIntegrationRequestBody(args),
      }),
      transformResponse: mapUpdateIntegrationResponse,
    }),
    readSyncedMembers: builder.query<ReadSyncedMembersResponse, void>({
      query: () => APIConfigs.Integrations.READ_SYNCED_MEMBERS,
      transformResponse: mapReadSyncedMembersResponse,
    }),
    updateSyncedMembers: builder.mutation<
      UpdateSyncedMembersResponse,
      UpdateSyncedMembersRequestArgs
    >({
      query: ({ integrationId, ...args }) => ({
        url: APIConfigs.Integrations.UPDATE_SYNCED_MEMBERS.replace(
          ":id",
          integrationId.toString()
        ),
        method: "PUT",
        body: getUpdateSyncedMembersRequestBody(args),
      }),
      transformResponse: mapUpdateSyncedMembersResponse,
    }),
  }),
});

export default integrationsService;
export const {
  useReadIPlatformsMutation,
  useReadIntegrationsMutation,
  useCreateHubSpotIntegrationMutation,
  useCreateADIntegrationMutation,
  useReadADGroupsUsersMutation,
  useCreateSalesforceIntegrationMutation,
  useCreateDynamicsIntegrationMutation,
  useDeleteIntegrationMutation,
  useUpdateIntegrationMutation,
  useReadSyncedMembersQuery,
  useUpdateSyncedMembersMutation,
} = integrationsService;
