import { faAddressCard, faEye } from "@fortawesome/free-regular-svg-icons";
import {
  faHandshakeAlt,
  faLaptop,
  faLock,
  faMagnet,
  faPanorama,
  faPlusCircle,
  faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BusinessRoundedIcon from "@mui/icons-material/Business";
import {
  Avatar,
  Badge,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AzureLogoGray } from "../assets/images/brands";
import type {
  DropdownMenuItem,
  Member,
  MemberEditMenuInfo,
} from "../features/members/membersTypes";
import type { Template } from "../features/templates/templatesTypes";
import MemberEditMenu from "./MemberEditMenu";

interface MemberCardProps {
  member: Member & { isSyncedWithAD?: boolean };
  template?: Template;
  actions: DropdownMenuItem[];
  selected?: boolean;
  onSelectedChange?: (id: number, checked: boolean) => void;
  className?: string;
}

function MemberCard({
  member,
  template,
  actions,
  selected,
  onSelectedChange,
  className = "",
  ...props
}: MemberCardProps) {
  const [thisSelected, setThisSelected] = useState<boolean>(selected || false);

  const navigateTo = useNavigate();

  useEffect(() => {
    setThisSelected(selected || false);
  }, [selected]);

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    setThisSelected(checked);
    onSelectedChange && onSelectedChange(member.id, checked);
  }

  return (
    <div
      className={`relative rounded-2xl border bg-white p-4 ${
        thisSelected ? "border-black" : "border-white"
      } ${className}`}
      {...props}
    >
      <div className="mb-6">
        <div className="relative flex flex-col self-stretch">
          <div className="absolute inset-4 bottom-auto flex justify-end gap-2">
            {member.openDirect && (
              <FontAwesomeIcon icon={faHandshakeAlt} className="text-white" />
            )}
            {member.locked && (
              <FontAwesomeIcon icon={faLock} className="text-white" />
            )}
            {!member.isPublic && (
              <FontAwesomeIcon icon={faShieldHalved} className="text-white" />
            )}
            {member.isSyncedWithAD && (
              <AzureLogoGray className="h-4 fill-white" />
            )}
          </div>
          <div
            className="flex h-[9.5rem] items-center justify-center rounded-2xl bg-gray-100 bg-cover bg-center text-4xl text-gray-400"
            aria-label="Profile Banner"
            style={{
              backgroundImage: `url(${
                template?.profileBanner || member.profileBanner
              })`,
            }}
          >
            <FontAwesomeIcon
              icon={faPanorama}
              hidden={!!(template?.profileBanner || member.profileBanner)}
            />
          </div>
          <div className="relative -top-[3.125rem] flex items-end justify-between gap-6 pl-4">
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <Avatar
                  src={template?.companyLogo || member.companyLogo}
                  classes={{
                    root: "!h-10 !w-10 !border-4 !border-white !bg-gray-100 !text-gray-400",
                  }}
                  aria-label={template?.companyName || member.companyName}
                >
                  <BusinessRoundedIcon />
                </Avatar>
              }
            >
              <Avatar
                src={template?.profileImage || member.profileImage}
                classes={{
                  root: "!h-[6.25rem] !w-[6.25rem] !border-4 !border-white !bg-gray-100 !text-gray-400",
                }}
                aria-label={member.fullName || member.username}
              />
            </Badge>
            {template ? (
              <Typography
                component="button"
                onClick={() => navigateTo(`/templates/${template.id}/edit`)}
                aria-label="Change Template"
                noWrap
                className="z-20 flex h-8 items-center gap-2 rounded-2xl bg-gray-100 px-3 !font-sans !text-sm text-gray-600"
              >
                <FontAwesomeIcon icon={faAddressCard} className="text-base" />
                {template.name}
              </Typography>
            ) : (
              <Tooltip
                title="Set Template"
                placement="top"
                enterDelay={1000}
                enterNextDelay={2000}
                classes={{ tooltip: "!mb-0" }}
              >
                <IconButton
                  size="small"
                  onClick={() => navigateTo(`/templates`)}
                  aria-label="Set Template"
                  className="relative z-20 !p-2 !text-gray-600"
                >
                  <FontAwesomeIcon icon={faAddressCard} />
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    className="absolute right-1 top-1 rounded-full bg-white text-xs"
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        <div className="px-4 text-center">
          <div className="mb-1 min-h-[2.25rem] truncate text-3xl text-gray-900">
            <strong className="font-semibold">
              {member.fullName || member.username}
            </strong>
          </div>
          <div className="mb-1 min-h-[1.5rem] font-medium text-gray-900">
            {member.designation}
          </div>
          <div className="min-h-6 truncate text-gray-500">{member.email}</div>
        </div>
      </div>
      <div className="mb-6 flex items-center justify-center gap-[3.75rem] text-sm text-gray-500">
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faEye} aria-label="Number of views" />
          <span className="font-medium">{member.viewsCount}</span>
        </div>
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faMagnet} aria-label="Number of leads" />
          <span className="font-medium">{member.leadsCount}</span>
        </div>
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faLaptop} aria-label="Number of devices" />
          <span className="font-medium">{member.devicesCount}</span>
        </div>
      </div>
      <div className="flex h-9 items-end justify-between border-t border-gray-200">
        <FormControlLabel
          control={
            <Checkbox
              checked={thisSelected}
              onChange={handleChange}
              size="small"
              classes={{
                root: "!p-0",
                colorPrimary: "!text-gray-500",
                checked: "!text-gray-900",
              }}
            />
          }
          label="Select"
          classes={{
            root: "relative z-20 !m-0 gap-1",
            label: "!font-sans !text-sm text-gray-500",
          }}
        />
        {member.role && (
          <div className="mt-3 self-start rounded-full bg-gray-100 px-3 py-1 text-sm capitalize">
            {member.role}
          </div>
        )}
        <MemberEditMenu
          member={member as MemberEditMenuInfo}
          template={template}
          items={actions}
          className="relative z-20 !ml-[2.14125rem] aspect-square !min-w-[2rem] !rounded-full !border-none !p-0 !text-gray-500"
        />
      </div>
      <Link
        to={`/members/${member.id}/edit`}
        className="absolute inset-0 z-10 overflow-hidden -indent-96 text-transparent"
      >
        Customize
      </Link>
    </div>
  );
}

export default MemberCard;
