import Styled from "@emotion/styled";
import { faAddressCard } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeft,
  faArrowRightArrowLeft,
  faLinkSlash,
  faPlus,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SvgIcon,
  Tooltip,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Upload } from "antd";
import { RcFile } from "antd/es/upload";
import parse from "html-react-parser";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ConfirmationDialog,
  FloatingLabel,
  Spinner,
  SwitchX,
  TestLinkButton,
  WifiLinkValue,
} from "../../components";
import AppConfigs from "../../configs/appConfigs";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import {
  FetchError,
  getSVGString,
  handleFetchError,
  isDeepEqual,
} from "../../utils";
import { MemberDataLabels } from "../members/membersEnums";
import { LinkTypeCode } from "./linksEnums";
import {
  getLinkValuePlaceholder,
  getMemberLinkIcon,
  getUpdatedMemberLinkProps,
} from "./linksMapper";
import {
  useDeleteMemberLinksMutation,
  useReadLinkTypesMutation,
  useUpdateMemberLinkMutation,
} from "./linksService";
import {
  getLinkTypeByCode,
  removeLinks,
  setLinkCategories,
  setLinkTypes,
  updateLinks,
} from "./linksSlice";
import type {
  EditableMemberLinkProps,
  LinkToPreview,
  MemberLink,
  MemberLinkToEdit,
  UpdatedMemberLink,
} from "./linksTypes";

const UploadX = Styled(Upload)`
  width: auto !important;

  .ant-upload.ant-upload-select {
    background: none !important;
    border: none !important;
    border-radius: 0.5rem !important;
    height: 3.75rem !important;
    margin: 0 !important;
    overflow: hidden;
    width: 3.75rem !important;
  }
`;

type EditMemberLinkProps = {
  link: MemberLink;
  onBack?: () => void;
  onChange?: (linkToPreview: LinkToPreview) => void;
  onUpdate?: (links: UpdatedMemberLink[]) => void;
  onDelete?: (linkIds: Array<MemberLink["id"]>) => void;
  onCancel?: () => void;
  className?: string;
};

function EditMemberLink({
  link,
  onBack,
  onChange,
  onUpdate,
  onDelete,
  onCancel,
  className = "",
  ...props
}: EditMemberLinkProps) {
  const [deleteMemberLinksDialogShow, setDeleteMemberLinksDialogShow] =
    useState<boolean>(false);
  const initLinkToEdit = useMemo(
    () => ({
      id: link.id,
      linkTypeCode: link.linkTypeCode,
      iconSVG: link.iconSVG,
      title: link.title,
      value: link.value,
      isHighlighted: link.isHighlighted,
      userId: link.userId,
      globalId: link.globalId,
      iconFile: null,
    }),
    [link]
  );
  const [linkToEdit, setLinkToEdit] =
    useState<MemberLinkToEdit>(initLinkToEdit);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const inputLinkValueRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  const thisLinkType = useAppSelector((state: RootState) =>
    getLinkTypeByCode(state, link.linkTypeCode)
  );

  const [readLinkTypes, { isLoading: isReadLinkTypesPending }] =
    useReadLinkTypesMutation();

  useEffect(() => {
    if (thisLinkType === undefined) {
      (async () => {
        try {
          const {
            success,
            message,
            data: { linkCategories, linkTypes },
          } = await readLinkTypes().unwrap();
          if (success) {
            dispatch(setLinkCategories(linkCategories));
            dispatch(setLinkTypes(linkTypes));
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    }
  }, [thisLinkType, readLinkTypes, dispatch]);

  useEffect(() => {
    if (thisLinkType) {
      setIsUpdated(
        (!thisLinkType?.isIconEditable || !!linkToEdit.iconSVG) &&
          (!thisLinkType?.isTitleEditable || !!linkToEdit.title) &&
          (!thisLinkType?.isValueEditable || !!linkToEdit.value) &&
          !isDeepEqual(initLinkToEdit, linkToEdit)
      );
    }
  }, [thisLinkType, initLinkToEdit, linkToEdit]);

  useEffect(() => {
    if (linkToEdit) {
      onChange?.(linkToEdit);
    }
  }, [linkToEdit, onChange]);

  async function handleBeforeUpload(image: RcFile) {
    const {
      success,
      message,
      data: { iconUrl, iconObj },
    } = await getMemberLinkIcon(image);
    if (success) {
      setLinkToEdit({
        ...linkToEdit,
        iconSVG: getSVGString(iconUrl),
        iconFile: iconObj || null,
      });
    } else {
      toast.error(message);
    }

    return false;
  }

  function handleHighlightChange(
    _e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void {
    setLinkToEdit({ ...linkToEdit, isHighlighted: checked });
  }

  const [updateMemberLink, { isLoading: isUpdateMemberLinkPending }] =
    useUpdateMemberLinkMutation();

  async function handleSubmitClick() {
    if (thisLinkType?.isTitleEditable && !linkToEdit.title) {
      toast.error("Title is required!");
      return;
    }

    if (thisLinkType?.isValueEditable && !linkToEdit.value) {
      toast.error("Link is required!");
      return;
    }

    const updatedMemberLinkProps: EditableMemberLinkProps =
      getUpdatedMemberLinkProps(link, linkToEdit);

    if (Object.keys(updatedMemberLinkProps).length === 0) {
      onCancel?.();
      return;
    }

    try {
      const {
        success,
        message,
        data: { links: updatedMemberLinks },
      } = await updateMemberLink({
        id: linkToEdit.id,
        userId: linkToEdit.userId,
        ...updatedMemberLinkProps,
      }).unwrap();
      if (success) {
        dispatch(updateLinks(updatedMemberLinks));
        onUpdate?.(updatedMemberLinks);
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  const [deleteMemberLinks, { isLoading: isDeleteMemberLinksPending }] =
    useDeleteMemberLinksMutation();

  async function handleDeleteMemberLinksConfirm() {
    try {
      const {
        success,
        message,
        data: { linkIds: deletedLinkIds },
      } = await deleteMemberLinks({
        id: linkToEdit.id,
        userId: linkToEdit.userId,
        globalId: linkToEdit.globalId,
      }).unwrap();
      if (success) {
        dispatch(removeLinks(deletedLinkIds));
        setDeleteMemberLinksDialogShow(false);
        onDelete?.(deletedLinkIds);
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  function resetToDefaultIcon() {
    setLinkToEdit({
      ...linkToEdit,
      iconFile: null,
      iconSVG:
        thisLinkType?.iconSVGDefault ||
        thisLinkType?.iconSVGColorized ||
        linkToEdit.iconSVG,
    });
  }

  return (
    <div className={`relative flex flex-col gap-8 ${className}`} {...props}>
      <div className="flex items-center gap-4">
        <Button
          variant="outlined"
          onClick={() => {
            onChange?.(link);
            onBack?.();
          }}
          className="inline-flex cursor-pointer items-center gap-2 !rounded-full !border !border-gray-200 !bg-white !px-4 !py-2 !font-sans !text-xs !normal-case !text-black !no-underline"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <span className="font-semibold">Back</span>
        </Button>
        <DialogTitle className="!p-0 !font-sans !font-semibold text-black">
          {linkToEdit?.title}
        </DialogTitle>
        {link.templateId && (
          <Link
            to={`/templates/${link.templateId}/edit`}
            title="This button comes from a template."
            className="flex"
          >
            <FontAwesomeIcon
              icon={faAddressCard}
              className="text-base text-gray-600"
            />
          </Link>
        )}
      </div>
      <DialogContent className="!p-0">
        <div className="flex flex-col gap-8">
          <div className="flex items-center justify-between gap-8 rounded-2xl bg-gray-100 px-4 py-3">
            {link.templateId || !thisLinkType?.isIconEditable ? (
              <Avatar
                variant="rounded"
                className="!h-[3.75rem] !w-[3.75rem] !rounded-[0.658rem] !bg-transparent"
              >
                {link.iconSVG && (
                  <SvgIcon className="!h-full !w-full">
                    {parse(link.iconSVG)}
                  </SvgIcon>
                )}
              </Avatar>
            ) : (
              <div className="relative flex items-center gap-4">
                <UploadX
                  accept={AppConfigs.getAcceptedFileTypes(
                    MemberDataLabels.PROFILE_LINK
                  )}
                  name={linkToEdit.title}
                  listType="picture-circle"
                  showUploadList={false}
                  beforeUpload={handleBeforeUpload}
                  className="overflow-hidden rounded-[0.658rem]"
                >
                  {linkToEdit.iconSVG && (
                    <SvgIcon className="!h-full !w-full">
                      {parse(linkToEdit.iconSVG)}
                    </SvgIcon>
                  )}
                </UploadX>
                {![
                  thisLinkType.iconSVGDefault,
                  thisLinkType.iconSVGColorized,
                ].includes(linkToEdit.iconSVG) && (
                  <Tooltip
                    title="Click here to restore the original default button icon."
                    placement="top"
                    disableFocusListener
                    disableTouchListener
                    className="!absolute -top-4 left-10"
                    classes={{ tooltip: "!mb-0" }}
                  >
                    <IconButton onClick={resetToDefaultIcon}>
                      <ClearIcon
                        fontSize="small"
                        className="!h-5 !w-5 cursor-pointer rounded-full bg-white !p-0.5 text-black"
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <span className="w-60 text-sm font-medium text-gray-500">
                  Select photo here or drag and drop one in place of current
                </span>
              </div>
            )}
            <div className="flex items-center gap-8">
              <div className="flex items-center gap-4">
                {link.templateId && (
                  <Link
                    to={`/templates/${link.templateId}/edit`}
                    title={`This button comes from a template, and \ncan only be edited from the template.`}
                    className="flex"
                  >
                    <FontAwesomeIcon
                      icon={faAddressCard}
                      className="text-base text-gray-600"
                    />
                  </Link>
                )}
                <SwitchX
                  checked={linkToEdit.isHighlighted}
                  label="Highlight"
                  labelPlacement="end"
                  onChange={handleHighlightChange}
                  disabled={!!link.templateId}
                />
              </div>
              {!link.templateId && (
                <Button
                  classes={{
                    root: "!flex !items-center !justify-center !gap-2 !rounded-full !border !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !text-black !normal-case",
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  Add to other members
                </Button>
              )}
            </div>
          </div>
          {thisLinkType?.isValueEditable && (
            <div>
              {(link.templateId && !link.isUnique) || link.syncedWith ? (
                thisLinkType?.code === LinkTypeCode.WIFI ? (
                  <WifiLinkValue
                    name="link-value"
                    placeholder={getLinkValuePlaceholder(thisLinkType)}
                    value={link.value}
                    icon={faAddressCard}
                    iconLink={`/templates/${link.templateId}/edit`}
                    iconTitle={`This section comes from a template, \nand is unable to be edited.`}
                    disabled
                  />
                ) : (
                  <FloatingLabel
                    icon={
                      link.syncedWith ? faArrowRightArrowLeft : faAddressCard
                    }
                    iconLink={`/templates/${link.templateId}/edit`}
                    iconTitle={
                      link.syncedWith
                        ? `This section is synced with Active Directory, \nand is unable to be edited`
                        : `This section comes from a template, \nand is unable to be edited.`
                    }
                  >
                    <input
                      type="text"
                      name="link-value"
                      placeholder={`${getLinkValuePlaceholder(thisLinkType)}*`}
                      value={link.value}
                      disabled
                    />
                  </FloatingLabel>
                )
              ) : thisLinkType?.code === LinkTypeCode.WIFI ? (
                <WifiLinkValue
                  name="link-value"
                  placeholder={getLinkValuePlaceholder(thisLinkType)}
                  value={link.value}
                  onChange={(value: string) =>
                    setLinkToEdit({ ...linkToEdit, value })
                  }
                />
              ) : (
                <FloatingLabel
                  onInputValueChange={(value: string) =>
                    setLinkToEdit({ ...linkToEdit, value })
                  }
                >
                  <input
                    type="text"
                    ref={inputLinkValueRef}
                    name="link-value"
                    placeholder={`${getLinkValuePlaceholder(thisLinkType)}*`}
                    value={linkToEdit.value}
                  />
                </FloatingLabel>
              )}
            </div>
          )}
          {thisLinkType?.isTitleEditable && (
            <div>
              {link.templateId ? (
                <FloatingLabel
                  icon={faAddressCard}
                  iconLink={`/templates/${link.templateId}/edit`}
                  iconTitle={`This section comes from a template, \nand is unable to be edited.`}
                >
                  <input
                    type="text"
                    name="link-title"
                    placeholder="Button title"
                    value={link.title}
                    disabled
                  />
                </FloatingLabel>
              ) : (
                <FloatingLabel
                  onInputValueChange={(value: string) =>
                    setLinkToEdit({ ...linkToEdit, title: value })
                  }
                >
                  <input
                    type="text"
                    name="link-title"
                    placeholder="Button title"
                    value={linkToEdit.title}
                  />
                </FloatingLabel>
              )}
            </div>
          )}
          {thisLinkType?.description && (
            <p className="whitespace-pre-line text-sm font-medium text-gray-500">
              {thisLinkType.description}
            </p>
          )}
          {thisLinkType && (
            <div>
              <TestLinkButton
                linkType={thisLinkType}
                linkValue={thisLinkType.isValueEditable ? linkToEdit.value : ""}
              />
            </div>
          )}
        </div>
      </DialogContent>
      {(!link.templateId || (link.templateId && link.isUnique)) && (
        <DialogActions
          classes={{
            root: "gap-4 !border-t border-gray-200 !p-0 !pt-8 !justify-end",
          }}
        >
          <span className="grow">
            {!link.templateId && (
              <>
                <Button
                  variant="outlined"
                  onClick={() => setDeleteMemberLinksDialogShow(true)}
                  startIcon={<DeleteOutlinedIcon />}
                  className="!m-0 !rounded-full !border-error-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-error-500"
                >
                  Remove {linkToEdit.globalId && "All"}
                </Button>
                <ConfirmationDialog
                  title="Confirm Removal"
                  content={
                    linkToEdit.globalId
                      ? "Are you sure you want to remove this button from all the members?"
                      : "Are you sure you want to remove this button?"
                  }
                  confirmButtonIcon={
                    <FontAwesomeIcon
                      icon={
                        isDeleteMemberLinksPending ? faSpinner : faLinkSlash
                      }
                      className="!text-sm"
                    />
                  }
                  confirmButtonText="Remove"
                  onConfirm={handleDeleteMemberLinksConfirm}
                  onCancel={() => setDeleteMemberLinksDialogShow(false)}
                  open={deleteMemberLinksDialogShow}
                  danger
                />
                {isDeleteMemberLinksPending && (
                  <Spinner className="bg-white/50" />
                )}
              </>
            )}
          </span>
          <Button
            variant="outlined"
            onClick={() => {
              onChange?.(link);
              onCancel?.();
            }}
            className="!m-0 !rounded-full !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-black"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={handleSubmitClick}
            disabled={!isUpdated || isUpdateMemberLinkPending}
            classes={{
              root: `!rounded-full !px-6 !py-3 !font-semibold !text-white !normal-case !font-sans !text-sm !m-0 ${
                !isUpdated || isUpdateMemberLinkPending
                  ? "!bg-gray-300"
                  : "!bg-primary"
              }`,
            }}
          >
            Update {linkToEdit.globalId && "All"}
          </Button>
          {isUpdateMemberLinkPending && <Spinner className="bg-white/50" />}
        </DialogActions>
      )}
      {isReadLinkTypesPending && <Spinner className="bg-white/50" />}
    </div>
  );
}

export default EditMemberLink;
