import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import type { Template } from "../templates/templatesTypes";
import type { LinkTypeCode } from "./linksEnums";
import type {
  LinkCategory,
  LinksState,
  LinkType,
  MemberLink,
} from "./linksTypes";

const initialState: LinksState = {
  linkCategories: [],
  linkTypes: [],
  links: undefined,
};

const linksSlice = createSlice({
  name: "links",
  initialState,
  reducers: {
    setLinkCategories: (
      state: LinksState,
      action: PayloadAction<LinkCategory[]>
    ) => {
      state.linkCategories = action.payload;
    },
    setLinkTypes: (state: LinksState, action: PayloadAction<LinkType[]>) => {
      state.linkTypes = action.payload;
    },
    setLinks: (state: LinksState, action: PayloadAction<MemberLink[]>) => {
      state.links = action.payload;
    },
    resetLinks: (state: LinksState) => {
      state.links = undefined;
    },
    addNewLinks: (state: LinksState, action: PayloadAction<MemberLink[]>) => {
      state.links = state.links
        ? [...state.links, ...action.payload]
        : action.payload;
    },
    addNewLink: (state: LinksState, action: PayloadAction<MemberLink>) => {
      state.links = state.links
        ? [...state.links, action.payload]
        : [action.payload];
    },
    updateLinks: (
      state: LinksState,
      action: PayloadAction<Partial<MemberLink>[]>
    ) => {
      if (!state.links) return;
      action.payload.forEach((ap) => {
        const index = state.links?.findIndex((l) => l.id === ap.id);
        if (index !== undefined && state.links !== undefined) {
          state.links[index] = { ...state.links[index], ...ap };
        }
      });
    },
    updateLinkData: (
      state: LinksState,
      action: PayloadAction<Partial<MemberLink>>
    ) => {
      if (!state.links) return;
      const index = state.links?.findIndex((l) => l.id === action.payload.id);
      if (index !== undefined && state.links !== undefined) {
        state.links[index] = { ...state.links[index], ...action.payload };
      }
    },
    removeLinks: (
      state: LinksState,
      action: PayloadAction<Array<MemberLink["id"]>>
    ) => {
      state.links = state.links?.filter((l) => !action.payload.includes(l.id));
    },
    removeLinksByMemberIds: (
      state: LinksState,
      action: PayloadAction<Array<MemberLink["userId"]>>
    ) => {
      state.links = state.links?.filter(
        (l) => !action.payload.includes(l.userId)
      );
    },
    cleanUpTemplateLinks: (
      state: LinksState,
      action: PayloadAction<Pick<Template, "id" | "assigneeIds">>
    ) => {
      state.links = state.links?.filter(
        (l) =>
          l.templateId === null ||
          (l.templateId !== action.payload.id &&
            !action.payload.assigneeIds.includes(l.userId)) ||
          (l.templateId === action.payload.id &&
            action.payload.assigneeIds.includes(l.userId))
      );
    },
  },
});

export const {
  setLinkCategories,
  setLinkTypes,
  setLinks,
  resetLinks,
  addNewLinks,
  addNewLink,
  updateLinks,
  updateLinkData,
  removeLinks,
  removeLinksByMemberIds,
  cleanUpTemplateLinks,
} = linksSlice.actions;

export default linksSlice.reducer;

export const getLinkCategories = (state: RootState) =>
  state.links.linkCategories;
export const getLinkTypes = (state: RootState) => state.links.linkTypes;
export const getLinks = (state: RootState) => state.links.links;

export const getLinkTypeByCode = createSelector(
  [getLinkTypes, (_state: RootState, thisCode: LinkTypeCode) => thisCode],
  (linkTypes, thisCode) => linkTypes.find((lt) => lt.code === thisCode)
);

export const getLinksByMemberId = createSelector(
  [getLinks, (_state: RootState, id: number) => id],
  (links, id) => links?.filter((l) => l.userId === id)
);

export const getLinkById = createSelector(
  [getLinks, (_state: RootState, id: number) => id],
  (links, id) => links?.find((l) => l.id === id)
);
