import { Dialog, DialogProps, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export type DialogXProps = DialogProps & {
  onClose?: () => void;
};

function DialogX({
  open,
  onClose,
  children,
  fullScreen = false,
  className = "",
  ...props
}: DialogXProps) {
  return fullScreen ? (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="lg"
      scroll="paper"
      hideBackdrop
      // onBackdropClick={onClose}
      classes={{
        root: "bg-white/50 backdrop-blur",
        paper: `!shadow-gray-900/8 isolate !overflow-visible !rounded-2xl p-8 !shadow-lg ${className}`,
      }}
      {...props}
    >
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          classes={{
            root: "!absolute -right-4 -top-4 h-11 w-11 rounded-full !bg-black !text-white",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {children}
    </Dialog>
  ) : open ? (
    <div
      className={`absolute inset-0 flex items-center justify-center bg-white/50 p-3 backdrop-blur-sm ${className}`}
      {...props}
    >
      <div className="relative isolate flex flex-col gap-4 rounded-lg bg-white px-4  py-3 shadow-lg shadow-black/25">
        <IconButton
          aria-label="close"
          onClick={onClose}
          classes={{
            root: "!absolute -right-2 -top-2 h-8 w-8 rounded-full !bg-black !text-white",
          }}
        >
          <CloseIcon />
        </IconButton>
        {children}
      </div>
    </div>
  ) : (
    <></>
  );
}

export default DialogX;
