import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

function Unauthorized() {
  const navigateTo = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigateTo(-1);
    }, 5000);
  }, [navigateTo]);

  return (
    <section className="flex h-screen flex-col items-center justify-center">
      <div className="border border-gray-300 bg-gray-50 p-6 text-center text-gray-600">
        <h2 className="mb-1 text-xl font-bold">
          <FontAwesomeIcon icon={faWarning} /> Unauthorized
        </h2>
        <p>
          You are not supposed to be here.
          <small className="block">Redirecting in 5 seconds...</small>
        </p>
      </div>
    </section>
  );
}

export default Unauthorized;
