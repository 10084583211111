import { faAddressCard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import type { Template } from "../features/templates/templatesTypes";

type AvatarTemplateProps = {
  src: string;
  templateId: Template["id"];
  variant?: "avatar" | "banner";
  className?: string;
};

const AvatarTemplate = ({
  src,
  templateId,
  variant = "avatar",
  className = "",
  ...props
}: AvatarTemplateProps) => {
  const [avatarSrc, setAvatarSrc] = useState<string>(src);

  useEffect(() => {
    setAvatarSrc(src);
  }, [src]);

  const avatarVariant =
    variant === "avatar"
      ? "circular"
      : variant === "banner"
      ? "rounded"
      : "square";

  return (
    <div className={`relative flex flex-col ${className}`} {...props}>
      <div className="h-full w-full">
        <Avatar
          src={avatarSrc}
          variant={avatarVariant}
          classes={{
            root: "!h-full !w-full !bg-gray-100",
            rounded: "!rounded-2xl",
          }}
        />
      </div>
      <Link
        to={`/templates/${templateId}/edit`}
        title={`This section comes from a template, \nand is unable to be edited.`}
        className={`absolute -top-2 ${
          variant !== "banner" ? "right-0" : "-right-2"
        } flex h-8 w-8 items-center justify-center rounded-full border border-gray-200 bg-white text-sm text-black`}
      >
        <FontAwesomeIcon icon={faAddressCard} />
      </Link>
    </div>
  );
};

export default AvatarTemplate;
