import { faAngleLeft, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FloatingLabel, Spinner } from "../../components";
import { FetchError, handleFetchError } from "../../utils";
import { useForgotPasswordMutation } from "./forgotPasswordService";

function ForgotPasswordForm() {
  const [email, setEmail] = useState<string>("");

  const navigateTo = useNavigate();

  const inputEmailRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputEmailRef.current?.focus();
  }, []);

  const [forgotPassword, { isLoading: isForgotPasswordPending }] =
    useForgotPasswordMutation();

  async function handleFormSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!email) {
      toast.warn("Please enter your email.");
      inputEmailRef.current?.focus();
      return;
    }

    try {
      const {
        success,
        message,
        data: { email: emailToVerify },
      } = await forgotPassword({
        email,
      }).unwrap();
      if (success) {
        navigateTo("/verify-code", {
          state: { email: emailToVerify },
        });
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  return (
    <>
      {isForgotPasswordPending && <Spinner fullScreen />}
      <ToastContainer />
      <section className="px-0 py-10 md:px-20 lg:px-10 xl:px-20">
        <div className="mb-8 flex items-center text-2xl font-semibold text-black">
          <h2 className="mr-3">Forgot Password</h2>
          <small className="border-l border-l-gray-400 pl-3 text-sm font-normal text-gray-400">
            Business Portal
          </small>
        </div>
        <form onSubmit={handleFormSubmit}>
          <fieldset className="flex flex-col">
            <FloatingLabel
              className="mb-4"
              onInputValueChange={(value: string) => setEmail(value)}
            >
              <input
                type="email"
                ref={inputEmailRef}
                name="email"
                placeholder="Email"
                value={email}
              />
            </FloatingLabel>
            <span className="mb-10 text-right">
              <Link
                to="/login"
                className="inline-flex items-center text-sm text-gray-500 hover:underline"
              >
                <FontAwesomeIcon icon={faAngleLeft} className="pr-2" />
                Back to login
              </Link>
            </span>
            <button
              type="submit"
              className="relative rounded-full bg-fuchsia-900 px-8 py-3.5 text-center font-semibold text-white active:left-px active:top-px"
            >
              Send Verification Code
              <FontAwesomeIcon icon={faPaperPlane} className="pl-2" />
            </button>
          </fieldset>
        </form>
      </section>
    </>
  );
}

export default ForgotPasswordForm;
