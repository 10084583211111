import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  getStoredItem,
  removeStoredItem,
  setStoredItem,
} from "../../utils/storage";
import type { CurrentUserState, User } from "./usersTypes";

const currentUserStored = getStoredItem("currentUser");
const currentUser = currentUserStored
  ? (JSON.parse(currentUserStored) as User)
  : null;

const initialState: CurrentUserState = {
  currentUser: currentUser || null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setCurrentUser: (state: CurrentUserState, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
      setStoredItem("currentUser", JSON.stringify(action.payload));
    },
    removeCurrentUser: (state: CurrentUserState) => {
      state.currentUser = null;
      removeStoredItem("currentUser");
    },
  },
});

export const { setCurrentUser, removeCurrentUser } = usersSlice.actions;

export default usersSlice.reducer;

export const getCurrentUser = (state: RootState) => state.users.currentUser;
