const AuthConfigs = {
  HubSpot: {
    CLIENT_ID: `${process.env.REACT_APP_HUBSPOT_CLIENT_ID}`,
    CLIENT_SECRET: `${process.env.REACT_APP_HUBSPOT_CLIENT_SECRET}`,
    REDIRECT_URL: `${process.env.REACT_APP_HUBSPOT_REDIRECT_URL}`,
    SCOPE: `${process.env.REACT_APP_HUBSPOT_SCOPE}`,
    OAUTH_URL:
      `${process.env.REACT_APP_HUBSPOT_AUTHORITY}` +
      `?client_id=${process.env.REACT_APP_HUBSPOT_CLIENT_ID}` +
      `&redirect_uri=${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HUBSPOT_REDIRECT_URL}` +
      `&scope=${process.env.REACT_APP_HUBSPOT_SCOPE}`,
  },
  ActiveDirectory: {
    CLIENT_ID: `${process.env.REACT_APP_ACTIVE_DIRECTORY_CLIENT_ID}`,
    CLIENT_SECRET: `${process.env.REACT_APP_ACTIVE_DIRECTORY_CLIENT_SECRET}`,
    REDIRECT_URL: `${process.env.REACT_APP_ACTIVE_DIRECTORY_REDIRECT_URL}`,
    SCOPE: `${process.env.REACT_APP_ACTIVE_DIRECTORY_SCOPE}`,
    OAUTH_URL:
      `${process.env.REACT_APP_ACTIVE_DIRECTORY_AUTHORITY}` +
      `?client_id=${process.env.REACT_APP_ACTIVE_DIRECTORY_CLIENT_ID}` +
      `&redirect_uri=${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ACTIVE_DIRECTORY_REDIRECT_URL}` +
      // `&response_type=code` + `&response_mode=query` + `&prompt=consent` +
      `&scope=${process.env.REACT_APP_ACTIVE_DIRECTORY_SCOPE}`,
  },
  Salesforce: {
    CLIENT_ID: `${process.env.REACT_APP_SALESFORCE_CLIENT_ID}`,
    CLIENT_SECRET: `${process.env.REACT_APP_SALESFORCE_CLIENT_SECRET}`,
    REDIRECT_URL: `${process.env.REACT_APP_SALESFORCE_REDIRECT_URL}`,
    SCOPE: `${process.env.REACT_APP_SALESFORCE_SCOPE}`,
    OAUTH_URL:
      `${process.env.REACT_APP_SALESFORCE_AUTHORITY}` +
      `?response_type=code` +
      `&client_id=${process.env.REACT_APP_SALESFORCE_CLIENT_ID}` +
      `&redirect_uri=${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALESFORCE_REDIRECT_URL}` +
      `&scope=${process.env.REACT_APP_SALESFORCE_SCOPE}`,
  },
  Dynamics: {
    CLIENT_ID: `${process.env.REACT_APP_DYNAMICS_CLIENT_ID}`,
    CLIENT_SECRET: `${process.env.REACT_APP_DYNAMICS_CLIENT_SECRET}`,
    REDIRECT_URL: `${process.env.REACT_APP_DYNAMICS_REDIRECT_URL}`,
    SCOPE: `${process.env.REACT_APP_DYNAMICS_SCOPE}`,
    OAUTH_URL:
      `${process.env.REACT_APP_DYNAMICS_AUTHORITY}` +
      `?client_id=${process.env.REACT_APP_DYNAMICS_CLIENT_ID}` +
      `&redirect_uri=${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DYNAMICS_REDIRECT_URL}` +
      // `&response_type=code` + `&response_mode=query` +
      `&scope=${process.env.REACT_APP_DYNAMICS_SCOPE}`,
  },
};

export default AuthConfigs;
