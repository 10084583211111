import { useEffect } from "react";
import { toast } from "react-toastify";
import { useReadTemplatesMutation } from "../features/templates/templatesService";
import {
  getTemplateLinks,
  getTemplates,
  getTemplateSettings,
  setTemplateLinks,
  setTemplates,
  setTemplateSettings,
} from "../features/templates/templatesSlice";
import { useAppDispatch, useAppSelector } from "../store";
import { FetchError, handleFetchError } from "../utils";

function useTemplates() {
  const dispatch = useAppDispatch();

  const templates = useAppSelector(getTemplates);
  const templateLinks = useAppSelector(getTemplateLinks);
  const templateSettings = useAppSelector(getTemplateSettings);

  const [readTemplates, { isLoading: isReadTemplatesPending }] =
    useReadTemplatesMutation();

  useEffect(() => {
    if (!templates) {
      (async () => {
        try {
          const {
            success,
            message,
            data: { templates, templateLinks, templateSettings },
          } = await readTemplates().unwrap();
          if (success) {
            dispatch(setTemplates(templates));
            dispatch(setTemplateLinks(templateLinks));
            dispatch(setTemplateSettings(templateSettings));
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    }
  }, [templates, readTemplates, dispatch]);

  return {
    templates,
    templateLinks,
    templateSettings,
    isLoading: isReadTemplatesPending,
  };
}

export default useTemplates;
