import AddCardIcon from "@mui/icons-material/AddCard";
import Button from "@mui/material/Button/Button";
import { SyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "../components";
import {
  addTemplate,
  addTemplateLinks,
  addTemplateSettings,
  useCreateTemplateMutation,
} from "../features/templates";
import TemplateCard from "../features/templates/TemplateCard";
import useTemplates from "../hooks/useTemplates";
import { useAppDispatch } from "../store";
import { FetchError, handleFetchError } from "../utils";

function Templates() {
  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();

  const { templates, isLoading: isTemplatesLoading } = useTemplates();

  const [createTemplate, { isLoading: isCreateNewTemplatePending }] =
    useCreateTemplateMutation();

  async function handleAddTemplateClick(
    event: SyntheticEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();

    try {
      const {
        success,
        message,
        data: { newTemplate, newTemplateLinks, newTemplateSettings },
      } = await createTemplate(
        "Template " + (templates ? templates.length + 1 : 1)
      ).unwrap();
      if (success) {
        dispatch(addTemplate(newTemplate));
        dispatch(addTemplateLinks(newTemplateLinks));
        dispatch(addTemplateSettings(newTemplateSettings));
        navigateTo(`/templates/${newTemplate.id}/edit`);
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  if (isTemplatesLoading || isCreateNewTemplatePending) {
    return <Spinner fullScreen />;
  }

  return (
    <div className="relative flex h-full flex-col gap-14 overflow-hidden">
      <div className="flex flex-col items-center justify-between gap-4 p-8 pb-0 lg:flex-row xl:gap-8">
        <div className="flex items-center gap-2 text-3xl font-medium text-gray-900 lg:text-2xl xl:text-3xl">
          <h2 className="font-medium">Templates</h2>
          {templates?.length !== 0 && (
            <span className="font-semibold text-primary">
              ({templates?.length})
            </span>
          )}
        </div>
        <div className="flex items-center gap-2 md:gap-4 lg:gap-2 xl:gap-4">
          <Button
            id="add-template-button"
            variant="contained"
            disableElevation
            onClick={handleAddTemplateClick}
            startIcon={<AddCardIcon />}
            classes={{
              root: "!rounded-full !bg-primary !py-4 !px-4 !font-sans !text-xs !font-semibold !normal-case md:!px-8 md:!text-sm lg:!px-4 xl:!px-8",
            }}
          >
            Add Template
          </Button>
        </div>
      </div>
      {templates?.length === 0 ? (
        <div className="flex flex-1 flex-col items-center justify-center gap-4">
          <p className="flex flex-col gap-1 text-center">
            <strong className="text-gray-700">No Templates Created.</strong>
            <small className="text-sm text-gray-600">
              Create the first template for your team.
            </small>
          </p>
          <Button
            id="add-template-button-center"
            variant="contained"
            onClick={handleAddTemplateClick}
            startIcon={<AddCardIcon />}
            className="!rounded-full !bg-primary !px-4 !py-4 !font-sans !text-xs !font-semibold !normal-case md:!px-8 md:!text-sm"
          >
            Add Template
          </Button>
        </div>
      ) : (
        <div className="flex-1 overflow-auto">
          <div className="grid grid-cols-1 gap-8 px-8 pb-16 md:grid-cols-2 xl:grid-cols-3">
            {templates?.map((aTemplate, index) => (
              <TemplateCard key={"template-" + index} info={aTemplate} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Templates;
