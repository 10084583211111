import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { ReactNode } from "react";

type ConfirmationDialogProps = Omit<DialogProps, "title" | "content"> & {
  title: ReactNode;
  content?: ReactNode;
  confirmButtonIcon?: ReactNode;
  confirmButtonText?: string;
  onConfirm: () => void;
  cancelButtonText?: string;
  onCancel: () => void;
  danger?: boolean;
};

function ConfirmationDialog({
  title,
  content,
  confirmButtonIcon = null,
  confirmButtonText = "OK",
  onConfirm,
  cancelButtonText = "Cancel",
  onCancel,
  danger = false,
  children,
  className = "",
  ...props
}: ConfirmationDialogProps) {
  return (
    <Dialog
      classes={{
        paper: `!shadow-gray-900/8 isolate !overflow-visible !rounded-2xl !shadow-xl ${className}`,
      }}
      {...props}
    >
      <DialogTitle className="lead !font-sans !font-semibold text-black">
        {title}
      </DialogTitle>
      <DialogContent>
        {typeof content === "string" ? (
          <p className="text-sm text-gray-500">{content}</p>
        ) : (
          content
        )}
        {children}
      </DialogContent>
      <DialogActions
        classes={{ root: "gap-4 !border-t border-gray-200 !px-6 !pt-4 !pb-5" }}
      >
        <Button
          variant="outlined"
          autoFocus
          onClick={() => onCancel()}
          classes={{
            root: "!m-0 !rounded-full !border !border-gray-200 !bg-white !px-4 !py-2 !font-sans !text-sm !font-semibold !normal-case !leading-4 !text-black",
          }}
        >
          {cancelButtonText}
        </Button>
        <Button
          startIcon={confirmButtonIcon}
          variant="contained"
          disableElevation
          onClick={() => onConfirm()}
          classes={{
            root: "!m-0 !rounded-full !px-4 !py-2 !font-sans !text-sm !font-semibold !normal-case !leading-[1.125rem] !text-white",
          }}
          className={danger ? "!bg-error-400" : "!bg-primary"}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
