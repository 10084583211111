export enum EditTemplateSections {
  CONTENT = 0,
  ABOUT = 1,
  COLORS = 2,
  ASSIGNEES = 3,
}

export enum TemplateCustomColorsSections {
  SECTION = 0,
  PROFILE = 1,
  PROFILE_BORDER = 2,
  TEXT = 3,
  CONTROL_BUTTONS = 4,
  PROFILE_BUTTONS = 5,
}

export function getEditTemplateSectionLabel(
  value: EditTemplateSections
): string {
  return value === EditTemplateSections.ABOUT
    ? "About"
    : value === EditTemplateSections.COLORS
    ? "Colors"
    : value === EditTemplateSections.ASSIGNEES
    ? "Assignees"
    : "Content";
}

export function getEditTemplateHashTag(value: EditTemplateSections): string {
  return value === EditTemplateSections.ABOUT
    ? "#About"
    : value === EditTemplateSections.COLORS
    ? "#Colors"
    : value === EditTemplateSections.ASSIGNEES
    ? "#Assignees"
    : "#Content";
}

export function getEditTemplateSection(value: string): EditTemplateSections {
  return value === "#About"
    ? EditTemplateSections.ABOUT
    : value === "#Colors"
    ? EditTemplateSections.COLORS
    : value === "#Assignees"
    ? EditTemplateSections.ASSIGNEES
    : EditTemplateSections.CONTENT;
}
