import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LoadingButton } from "@mui/lab";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  ConfirmationDialog,
  FloatingLabel,
  ImageDropzone,
} from "../../components";
import AppConfigs from "../../configs/appConfigs";
import { useAppDispatch } from "../../store";
import { FetchError, handleFetchError, validateImage } from "../../utils";
import { MemberDataLabels } from "../members/membersEnums";
import {
  Template,
  TemplateUpdatedInfo,
  UpdateTemplateDataRequestArgs,
  editTemplateData,
  editTemplateSettings,
  getUpdatedTemplateData,
  useUpdateTemplateDataMutation,
} from "../templates";

type EditTemplateAboutProps = {
  template: Template;
  onChange?: (updatedTemplateInfo: TemplateUpdatedInfo) => void;
  changed?: boolean;
  onChangesSaved?: () => void;
  onChangesDiscarded?: () => void;
  className?: string;
};

function EditTemplateAbout({
  template,
  onChange,
  changed = false,
  onChangesSaved,
  onChangesDiscarded,
  className = "",
  ...props
}: EditTemplateAboutProps) {
  const initUpdatedTemplateInfo: TemplateUpdatedInfo = {
    name: template.name,
    nameRef: useRef<HTMLInputElement>(null),
    profileImageSrc: template.profileImage,
    profileBannerSrc: template.profileBanner,
    companyLogoSrc: template.companyLogo,
    companyName: template.companyName,
    companyNameRef: useRef<HTMLInputElement>(null),
    companyAddress: template.companyAddress,
    companyAddressRef: useRef<HTMLInputElement>(null),
    subtitle: template.subtitle ?? "",
    subtitleRef: useRef<HTMLInputElement>(null),
  };

  const [updatedTemplateInfo, setUpdatedTemplateInfo] =
    useState<TemplateUpdatedInfo>(initUpdatedTemplateInfo);
  const [showPendingChagesDialog, setShowPendingChagesDialog] =
    useState<boolean>(false);
  const [templateDataToUpdate, setTemplateDataToUpdate] =
    useState<UpdateTemplateDataRequestArgs>({ id: template.id });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (template) {
      setUpdatedTemplateInfo((prevState) => ({
        ...prevState,
        name: template.name,
        profileImageSrc: template.profileImage,
        profileImageObj: undefined,
        profileBannerSrc: template.profileBanner,
        profileBannerObj: undefined,
        companyLogoSrc: template.companyLogo,
        companyLogoObj: undefined,
        companyName: template.companyName,
        companyAddress: template.companyAddress,
        subtitle: template.subtitle,
      }));
    }
  }, [template]);

  useEffect(() => {
    onChange?.(updatedTemplateInfo);
    setTemplateDataToUpdate(
      getUpdatedTemplateData(updatedTemplateInfo, template)
    );
  }, [template, updatedTemplateInfo, onChange]);

  function handleImageDropzoneChange(
    newImage: File | null,
    newDataUrl: string,
    type: MemberDataLabels
  ): void {
    const { success, message } = newImage
      ? validateImage({
          file: newImage,
          accept: AppConfigs.getAcceptedFileTypes(type),
          maxSize: AppConfigs.getMaximumFileSize(type),
        })
      : { success: true, message: "" };

    if (!success) {
      toast.error(message);
      return;
    }

    switch (type) {
      case MemberDataLabels.PROFILE_PICTURE:
        setUpdatedTemplateInfo({
          ...updatedTemplateInfo,
          profileImageObj: newImage,
          profileImageSrc: newDataUrl,
        });
        break;
      case MemberDataLabels.COMPANY_LOGO:
        setUpdatedTemplateInfo({
          ...updatedTemplateInfo,
          companyLogoObj: newImage,
          companyLogoSrc: newDataUrl,
        });
        break;
      case MemberDataLabels.COVER_PHOTO:
        setUpdatedTemplateInfo({
          ...updatedTemplateInfo,
          profileBannerObj: newImage,
          profileBannerSrc: newDataUrl,
        });
        break;
    }
  }

  const [updateTemplateData, { isLoading: isUpdateTemplateDataPending }] =
    useUpdateTemplateDataMutation();

  async function handleUpdateTemplateDataClick() {
    if (Object.keys(templateDataToUpdate).length > 1) {
      try {
        const {
          success,
          message,
          data: {
            template: updatedTemplateData,
            templateSettings: updatedTemplateSettings,
          },
        } = await updateTemplateData(templateDataToUpdate).unwrap();
        if (success) {
          dispatch(editTemplateData(updatedTemplateData));
          dispatch(editTemplateSettings(updatedTemplateSettings));
          toast.success(message);
          setTemplateDataToUpdate({ id: template.id });
        } else {
          toast.error(message);
        }
      } catch (error) {
        handleFetchError(error as FetchError);
      }
    }
  }

  useEffect(() => {
    setShowPendingChagesDialog(changed);
  }, [changed]);

  function handlePendingChagesConfirm(): void {
    handleUpdateTemplateDataClick().then(() => {
      setShowPendingChagesDialog(false);
      onChangesSaved?.();
    });
  }

  function handlePendingChagesCancel(): void {
    setUpdatedTemplateInfo(initUpdatedTemplateInfo);
    setShowPendingChagesDialog(false);
    onChangesDiscarded?.();
  }

  return (
    <div className={`flex flex-col gap-8 ${className}`} {...props}>
      <div>
        <h4 className="text-xl font-semibold text-black">About</h4>
        <p className="text-sm text-gray-500">
          Any field or image left empty can be set and edited by the individual
          profile.
        </p>
      </div>
      <div className="flex flex-col gap-8">
        <div className="grid grid-cols-2 gap-8">
          <div>
            <FloatingLabel
              onInputValueChange={(value: string) =>
                setUpdatedTemplateInfo({
                  ...updatedTemplateInfo,
                  name: value,
                })
              }
            >
              <input
                type="text"
                ref={updatedTemplateInfo.nameRef}
                name="template-name"
                placeholder="Template Name"
                value={updatedTemplateInfo.name}
              />
            </FloatingLabel>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-center gap-4 lg:justify-between xl:gap-2 2xl:gap-4">
          <div className="flex flex-col items-center gap-3">
            <label className="flex items-center gap-2 text-xs text-gray-600">
              {MemberDataLabels.PROFILE_PICTURE}
              <InfoOutlinedIcon sx={{ width: 14, height: 14 }} />
            </label>
            <ImageDropzone
              accept={AppConfigs.getAcceptedFileTypes(
                MemberDataLabels.PROFILE_PICTURE
              )}
              src={updatedTemplateInfo.profileImageSrc}
              onChange={(newImage, newDataUrl) =>
                handleImageDropzoneChange(
                  newImage,
                  newDataUrl,
                  MemberDataLabels.PROFILE_PICTURE
                )
              }
              variant="avatar"
              maxWidth={AppConfigs.getMaximumWidth(
                MemberDataLabels.PROFILE_PICTURE
              )}
              className="aspect-square h-28"
            />
          </div>
          <div className="flex flex-col items-center gap-3">
            <label className="flex items-center gap-2 text-xs text-gray-600">
              {MemberDataLabels.COMPANY_LOGO}
              <InfoOutlinedIcon sx={{ width: 14, height: 14 }} />
            </label>
            <ImageDropzone
              accept={AppConfigs.getAcceptedFileTypes(
                MemberDataLabels.COMPANY_LOGO
              )}
              src={updatedTemplateInfo.companyLogoSrc}
              onChange={(newImage, newDataUrl) =>
                handleImageDropzoneChange(
                  newImage,
                  newDataUrl,
                  MemberDataLabels.COMPANY_LOGO
                )
              }
              variant="avatar"
              maxWidth={AppConfigs.getMaximumWidth(
                MemberDataLabels.COMPANY_LOGO
              )}
              className="aspect-square h-28"
            />
          </div>
          <div className="flex flex-col gap-3">
            <label className="flex items-center gap-2 text-xs text-gray-600">
              {MemberDataLabels.COVER_PHOTO}
              <InfoOutlinedIcon sx={{ width: 14, height: 14 }} />
            </label>
            <ImageDropzone
              accept={AppConfigs.getAcceptedFileTypes(
                MemberDataLabels.COVER_PHOTO
              )}
              src={updatedTemplateInfo.profileBannerSrc}
              onChange={(newImage, newDataUrl) =>
                handleImageDropzoneChange(
                  newImage,
                  newDataUrl,
                  MemberDataLabels.COVER_PHOTO
                )
              }
              variant="banner"
              maxWidth={AppConfigs.getMaximumWidth(
                MemberDataLabels.COVER_PHOTO
              )}
              className="aspect-[20/7] h-28"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-8">
          <div>
            <FloatingLabel
              onInputValueChange={(value: string) =>
                setUpdatedTemplateInfo({
                  ...updatedTemplateInfo,
                  companyName: value,
                })
              }
            >
              <input
                type="text"
                ref={updatedTemplateInfo.companyNameRef}
                name="company-name"
                placeholder={MemberDataLabels.COMPANY}
                value={updatedTemplateInfo.companyName}
              />
            </FloatingLabel>
          </div>
          <div>
            <FloatingLabel
              onInputValueChange={(value: string) =>
                setUpdatedTemplateInfo({
                  ...updatedTemplateInfo,
                  companyAddress: value,
                })
              }
            >
              <input
                type="text"
                ref={updatedTemplateInfo.companyAddressRef}
                name="company-address"
                placeholder={MemberDataLabels.LOCATION}
                value={updatedTemplateInfo.companyAddress}
              />
            </FloatingLabel>
          </div>
          <div className="col-span-2">
            <FloatingLabel
              onInputValueChange={(value: string) =>
                setUpdatedTemplateInfo({
                  ...updatedTemplateInfo,
                  subtitle: value,
                })
              }
            >
              <input
                type="text"
                ref={updatedTemplateInfo.subtitleRef}
                name="subtitle"
                placeholder={MemberDataLabels.SUBTITLE}
                value={updatedTemplateInfo.subtitle}
              />
            </FloatingLabel>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between gap-8 border-t border-gray-200 pt-8">
        <p className="text-sm text-gray-500">
          Any changes made will update all cards using this template.
        </p>
        <div className="flex items-center gap-8">
          <LoadingButton
            type="submit"
            startIcon={<FontAwesomeIcon icon={faSave} className="!text-sm" />}
            loading={isUpdateTemplateDataPending}
            loadingPosition="start"
            classes={{
              root: `!rounded-full ${
                Object.keys(templateDataToUpdate).length > 1 && "!bg-primary"
              } !px-6 !py-3 !font-sans !text-sm !normal-case !text-white`,
              disabled: "!bg-gray-300",
              loadingIndicator: "!left-5",
            }}
            disabled={Object.keys(templateDataToUpdate).length <= 1}
            onClick={handleUpdateTemplateDataClick}
          >
            Update
          </LoadingButton>
          <ConfirmationDialog
            title="Save Pending Changes?"
            content="There are pending changes. Do you want to update them?"
            confirmButtonIcon={
              <FontAwesomeIcon
                icon={isUpdateTemplateDataPending ? faSpinner : faSave}
                className="!text-sm"
              />
            }
            confirmButtonText="Update"
            onConfirm={handlePendingChagesConfirm}
            cancelButtonText="Discard"
            onCancel={handlePendingChagesCancel}
            open={showPendingChagesDialog}
          />
        </div>
      </div>
    </div>
  );
}

export default EditTemplateAbout;
