import SyncAltIcon from "@mui/icons-material/SyncAlt";
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  SvgIcon,
} from "@mui/material";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import type { LinkToSync } from "../features/add-members/addMembersTypes";
import { RadioGroupX } from "../styles/mui-styled";
import DialogX from "./DialogX";

type SelectLinkDialogProps = {
  links?: LinkToSync[];
  value?: LinkToSync["id"];
  onChange?: (value: LinkToSync["id"]) => void;
  open: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  className?: string;
};

function SelectLinkDialog({
  links,
  value,
  onChange,
  open,
  onClose,
  onCancel,
  className = "",
  ...props
}: SelectLinkDialogProps) {
  const [thisValue, setThisValue] = useState<LinkToSync["id"] | null>(
    value || null
  );

  useEffect(() => {
    setThisValue(value || null);
    return () => setThisValue(null);
  }, [value]);

  return (
    <DialogX
      open={open}
      onClose={onClose}
      fullScreen
      className={`relative flex flex-col gap-8 overflow-hidden ${className}`}
      {...props}
    >
      <div className="flex flex-col gap-0.5">
        <DialogTitle className="!p-0 !font-sans !font-semibold text-black">
          Sync Template Button
        </DialogTitle>
        <p className="text-sm text-gray-500">
          Choose which unique button of the selected template you want to to
          sync.
        </p>
      </div>
      <DialogContent className="!p-0">
        {!links ? (
          <p className="m-auto flex h-64 max-w-96 flex-col items-center justify-center gap-2 text-center">
            <strong className="text-sm text-gray-700">
              You haven't selected any template.
            </strong>
            <small className="block text-xs text-gray-600">
              You need to select a template, then select a unique button from it
              to sync with Active Directory.
            </small>
          </p>
        ) : links.length === 0 ? (
          <p className="m-auto flex h-64 max-w-96 flex-col items-center justify-center gap-2 text-center">
            <strong className="text-sm text-gray-700">
              This template does not have any such unique button to sync with
              this type of data.
            </strong>
            <small className="block text-xs text-gray-600">
              Add a unique button for this data to sync with Active Directory.
            </small>
          </p>
        ) : (
          <RadioGroupX
            value={thisValue}
            onChange={(e) => setThisValue(Number(e.target.value))}
            className="gap-3"
          >
            {links.map((aLink, index) => (
              <FormControlLabel
                key={"link-to-sync-" + index}
                value={aLink.id}
                control={<Radio size="small" />}
                label={
                  <>
                    <Avatar
                      alt={aLink.title}
                      variant="rounded"
                      className="!rounded-[0.439rem] !bg-transparent shadow-md"
                    >
                      {aLink.iconSVG && (
                        <SvgIcon className="!h-full !w-full">
                          {parse(aLink.iconSVG)}
                        </SvgIcon>
                      )}
                    </Avatar>
                    <strong className="grow font-semibold">
                      {aLink.title}
                    </strong>
                    {Boolean(aLink.syncedWith) && (
                      <small className="flex min-w-32 items-center gap-2 text-xs text-gray-400">
                        <SyncAltIcon fontSize="small" /> {aLink.syncedWith}
                      </small>
                    )}
                  </>
                }
                disabled={Boolean(aLink.syncedWith)}
                classes={{
                  root: `!m-0 rounded-2xl ${
                    Boolean(aLink.syncedWith) ? "bg-slate-50" : "bg-primary-50"
                  } p-3`,
                  label: "flex grow items-center gap-4",
                }}
              />
            ))}
          </RadioGroupX>
        )}
      </DialogContent>
      <DialogActions className="gap-4 !border-t border-gray-200 !p-0 !pt-4">
        <Button
          variant="outlined"
          onClick={() => onCancel?.()}
          className="!m-0 !rounded-full !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-black"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={() => thisValue && onChange?.(thisValue)}
          disabled={!thisValue}
          className={`!m-0 !rounded-full ${
            thisValue ? "!bg-primary" : "!bg-gray-300"
          } !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-white`}
        >
          Select
        </Button>
      </DialogActions>
    </DialogX>
  );
}

export default SelectLinkDialog;
