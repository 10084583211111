import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ReactNode } from "react";

type AlertDialogProps = {
  title: ReactNode;
  content?: string;
  confirmButtonIcon?: ReactNode;
  confirmButtonText?: string;
  onConfirm: () => void;
  show: boolean;
  danger?: boolean;
  children?: ReactNode;
  className?: string;
};

function AlertDialog({
  title,
  content,
  confirmButtonIcon = null,
  confirmButtonText = "OK",
  onConfirm,
  show = false,
  danger = false,
  children,
  className = "",
  ...props
}: AlertDialogProps) {
  return (
    <Dialog
      open={show}
      classes={{
        paper: `!shadow-gray-900/8 isolate !overflow-visible !rounded-2xl !shadow-xl ${className}`,
      }}
      {...props}
    >
      <DialogTitle className="lead !font-sans !font-semibold text-black">
        {title}
      </DialogTitle>
      <DialogContent>
        {content && <p className="text-sm text-gray-500">{content}</p>}
        {children}
      </DialogContent>
      <DialogActions
        classes={{ root: "gap-4 !border-t border-gray-200 !px-6 !pt-4 !pb-5" }}
      >
        <Button
          startIcon={confirmButtonIcon}
          variant="contained"
          disableElevation
          onClick={() => onConfirm()}
          classes={{
            root: "!m-0 !rounded-full !px-4 !py-2 !font-sans !text-sm !font-semibold !normal-case !leading-[1.125rem] !text-white",
          }}
          className={danger ? "!bg-error-400" : "!bg-primary"}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog;
