import { MemberDataLabels } from "../features/members/membersEnums";

const AppConfigs = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  BUSINESS_URL: process.env.REACT_APP_BUSINESS_URL,
  CARD_BASE_URL: process.env.REACT_APP_CARD_BASE_URL,

  PUBLIC_FOLDER_URL: process.env.PUBLIC_URL,

  getAcceptedFileTypes: (type: MemberDataLabels): string | undefined => {
    switch (type) {
      case MemberDataLabels.PROFILE_PICTURE:
        return (
          process.env.REACT_APP_PROFILE_PICTURE_ACCEPTED_FILETYPES ||
          "image/jpeg,image/png,image/webp" // .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .webp
        );
      case MemberDataLabels.COMPANY_LOGO:
        return (
          process.env.REACT_APP_COMPANY_LOGO_ACCEPTED_FILETYPES ||
          "image/jpeg,image/png,image/webp" // .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .webp
        );
      case MemberDataLabels.COVER_PHOTO:
        return (
          process.env.REACT_APP_COVER_PHOTO_ACCEPTED_FILETYPES ||
          "image/jpeg,image/png,image/webp" // .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .webp
        );
      case MemberDataLabels.PROFILE_LINK:
        return (
          process.env.REACT_APP_PROFILE_LINK_ACCEPTED_FILETYPES ||
          "image/jpeg,image/png,image/webp" // .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png, .webp
        );
    }
  },

  getMaximumWidth: (type: MemberDataLabels): number | undefined => {
    switch (type) {
      case MemberDataLabels.PROFILE_PICTURE:
        return Number(process.env.REACT_APP_PROFILE_PICTURE_MAX_WIDTH) || 320; // 320px
      case MemberDataLabels.COMPANY_LOGO:
        return Number(process.env.REACT_APP_COMPANY_LOGO_MAX_WIDTH) || 180; // 180px
      case MemberDataLabels.COVER_PHOTO:
        return Number(process.env.REACT_APP_COVER_PHOTO_MAX_WIDTH) || 1200; // 1200px
      case MemberDataLabels.PROFILE_LINK:
        return Number(process.env.REACT_APP_PROFILE_LINK_MAX_WIDTH) || 180; // 180px
    }
  },

  getMaximumFileSize: (type: MemberDataLabels): number | undefined => {
    switch (type) {
      case MemberDataLabels.PROFILE_PICTURE:
        return (
          Number(process.env.REACT_APP_PROFILE_PICTURE_MAX_SIZE) || 2097152 // 2MB
        );
      case MemberDataLabels.COMPANY_LOGO:
        return Number(process.env.REACT_APP_COMPANY_LOGO_MAX_SIZE) || 2097152; // 2MB
      case MemberDataLabels.COVER_PHOTO:
        return Number(process.env.REACT_APP_COVER_PHOTO_MAX_SIZE) || 5242880; // 5MB
      case MemberDataLabels.PROFILE_LINK:
        return Number(process.env.REACT_APP_PROFILE_LINK_MAX_SIZE) || 2097152; // 2MB
    }
  },
};

export default AppConfigs;
