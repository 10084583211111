import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faPalette } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  PhonePreview,
  Spinner,
  TabX,
  TabsX,
  tabPanelA11yProps,
} from "../components";
import EditTemplateAbout from "../features/edit-template/EditTemplateAbout";
import EditTemplateAssignees from "../features/edit-template/EditTemplateAssignees";
import EditTemplateColors from "../features/edit-template/EditTemplateColors";
import EditTemplateContent from "../features/edit-template/EditTemplateContent";
import EditTemplateHeader from "../features/edit-template/EditTemplateHeader";
import type { MemberLink } from "../features/links/linksTypes";
import type { MemberSettings } from "../features/members/membersTypes";
import { defaultColorsSettings } from "../features/settings/settingsEnums";
import {
  TemplateLink,
  TemplateUpdatedColors,
  TemplateUpdatedInfo,
  TemplateSwitches,
  getTemplateById,
  getTemplateLinksByTemplateId,
  getTemplateSettingsByTemplateId,
  EditTemplateSections,
  getEditTemplateHashTag,
  getEditTemplateSection,
  AppTemplate,
  getEditTemplateSectionLabel,
  Template,
} from "../features/templates";
import { UserMembership } from "../features/users/usersEnums";
import useTemplates from "../hooks/useTemplates";
import { RootState, useAppSelector } from "../store";
import AddMeeApp from "./addmee-app";

type EditTemplateRouteParams = {
  id: string;
};

function EditTemplate() {
  const [appTemplate, setAppTemplate] = useState<AppTemplate>();
  const [appTemplateLinks, setAppTemplateLinks] = useState<MemberLink[]>();
  const [appSettings, setAppSettings] = useState<MemberSettings>();
  const [isAboutChanged, setIsAboutChanged] = useState(false);
  const [isColorsChanged, setIsColorsChanged] = useState(false);

  const [tabIndex, setTabIndex] = useState<EditTemplateSections>(
    getEditTemplateSection(window.location.hash)
  );

  const { id } = useParams<EditTemplateRouteParams>();

  const { isLoading: isTemplatesLoading } = useTemplates();

  const thisTemplate = useAppSelector((state: RootState) =>
    getTemplateById(state, Number(id))
  );

  const thisTemplateLinks = useAppSelector((state: RootState) =>
    getTemplateLinksByTemplateId(state, Number(id))
  );

  const thisTemplateSettings = useAppSelector((state: RootState) =>
    getTemplateSettingsByTemplateId(state, Number(id))
  );

  useEffect(() => {
    if (thisTemplate) {
      setAppTemplate({
        ...thisTemplate,
        username: "",
        fullName: "",
        designation: "",
      });
    }
  }, [thisTemplate]);

  useEffect(() => {
    if (thisTemplateLinks && thisTemplateSettings) {
      setAppTemplateLinks(
        thisTemplateLinks.map((l) => ({
          ...l,
          membership: UserMembership.BASIC,
          userId: l.templateId,
          globalId: null,
          templateId: null,
          templateLinkId: null,
          syncedWith: null,
        }))
      );

      setAppSettings({
        colors: {
          id: 0,
          userId: thisTemplateSettings.templateId,
          ...thisTemplateSettings.colors,
        },
      });
    }
  }, [thisTemplateLinks, thisTemplateSettings]);

  const previewAboutChange = useCallback(
    (updatedTemplateInfo: TemplateUpdatedInfo) => {
      setAppTemplate((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            name: updatedTemplateInfo.name ?? prevState.name,
            profileImage:
              updatedTemplateInfo.profileImageSrc ?? prevState.profileImage,
            profileBanner:
              updatedTemplateInfo.profileBannerSrc ?? prevState.profileBanner,
            companyLogo:
              updatedTemplateInfo.companyLogoSrc ?? prevState.companyLogo,
            fullName: "",
            designation: "",
            companyName: updatedTemplateInfo.companyName,
            companyAddress: updatedTemplateInfo.companyAddress,
            subtitle: updatedTemplateInfo.subtitle,
          };
        }
      });
    },
    []
  );

  const previewColorsChange = useCallback(
    (
      updatedIsColorsCustomLocked: Template["isColorsCustomLocked"],
      updatedTemplateColors: TemplateUpdatedColors
    ) => {
      setAppTemplate((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            isColorsCustomLocked:
              updatedIsColorsCustomLocked !== undefined
                ? updatedIsColorsCustomLocked
                : prevState.isColorsCustomLocked,
          };
        }
      });
      setAppSettings((prevState) => {
        if (prevState) {
          return {
            colors: {
              ...updatedTemplateColors,
              id: prevState.colors.id,
              userId: prevState.colors.userId,
            },
          };
        }
      });
    },
    []
  );

  const previewContentChange = useCallback(
    (
      updatedTemplateSwitches: TemplateSwitches,
      updatedTemplateLinks: TemplateLink[]
    ) => {
      setAppTemplate((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            connectButton:
              updatedTemplateSwitches.connectButton ?? prevState.connectButton,
            saveContactButton:
              updatedTemplateSwitches.saveContactButton ??
              prevState.saveContactButton,
            captureLead:
              updatedTemplateSwitches.captureLead ?? prevState.captureLead,
            openDirect:
              updatedTemplateSwitches.openDirect ?? prevState.openDirect,
          };
        }
      });
      setAppTemplateLinks(
        updatedTemplateLinks.map((l) => ({
          ...l,
          membership: UserMembership.BASIC,
          userId: l.templateId,
          globalId: null,
          templateId: null,
          templateLinkId: null,
          syncedWith: null,
        }))
      );
    },
    []
  );

  function isTemplateAboutDataChanged(): boolean {
    return (
      appTemplate?.name !== thisTemplate?.name ||
      appTemplate?.profileImage !== thisTemplate?.profileImage ||
      appTemplate?.profileBanner !== thisTemplate?.profileBanner ||
      appTemplate?.companyLogo !== thisTemplate?.companyLogo ||
      appTemplate?.companyName !== thisTemplate?.companyName ||
      appTemplate?.companyAddress !== thisTemplate?.companyAddress ||
      appTemplate?.subtitle !== thisTemplate?.subtitle
    );
  }

  function isTemplateColorsDataChanged(): boolean {
    return (
      appTemplate?.isColorsCustomLocked !==
        thisTemplate?.isColorsCustomLocked ||
      appSettings?.colors.profileColor !==
        thisTemplateSettings?.colors.profileColor ||
      appSettings?.colors.sectionColor !==
        thisTemplateSettings?.colors.sectionColor ||
      appSettings?.colors.borderColor !==
        thisTemplateSettings?.colors.borderColor ||
      appSettings?.colors.textColor !==
        thisTemplateSettings?.colors.textColor ||
      appSettings?.colors.controlButtonTextColor !==
        thisTemplateSettings?.colors.controlButtonTextColor ||
      appSettings?.colors.controlButtonBgColor !==
        thisTemplateSettings?.colors.controlButtonBgColor ||
      appSettings?.colors.profileButtonIconColor !==
        thisTemplateSettings?.colors.profileButtonIconColor ||
      appSettings?.colors.profileButtonBgColor !==
        thisTemplateSettings?.colors.profileButtonBgColor ||
      appSettings?.colors.colorLinkIcons !==
        thisTemplateSettings?.colors.colorLinkIcons
    );
  }

  function handleTabXChange(
    _event: SyntheticEvent<Element, Event>,
    value: EditTemplateSections
  ): void {
    if (value !== EditTemplateSections.ABOUT && isTemplateAboutDataChanged()) {
      setIsAboutChanged(true);
    } else if (
      value !== EditTemplateSections.COLORS &&
      isTemplateColorsDataChanged()
    ) {
      setIsColorsChanged(true);
    } else {
      setIsAboutChanged(false);
      setIsColorsChanged(false);
      setTabIndex(value);
      window.location.hash = getEditTemplateHashTag(value);
    }
  }

  useEffect(() => {
    const handleHashChange = () => {
      setTabIndex(getEditTemplateSection(window.location.hash));
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  if (isTemplatesLoading) {
    return <Spinner fullScreen />;
  }

  if (!thisTemplate) {
    return <Navigate to="/templates" replace />;
  }

  return (
    <div className="relative p-8 pt-[7.25rem]">
      <EditTemplateHeader
        template={thisTemplate}
        className="absolute inset-0 bottom-auto"
      />
      <div className="flex flex-col gap-8 rounded-2xl bg-white px-7 py-8">
        <TabsX
          name={`Edit ${thisTemplate.name} Template`}
          value={tabIndex}
          onChange={handleTabXChange}
        >
          <TabX
            icon={<GridViewOutlinedIcon />}
            label={getEditTemplateSectionLabel(EditTemplateSections.CONTENT)}
            index={EditTemplateSections.CONTENT}
          />
          <TabX
            icon={<FontAwesomeIcon icon={faUser} />}
            label={getEditTemplateSectionLabel(EditTemplateSections.ABOUT)}
            index={EditTemplateSections.ABOUT}
          />
          <TabX
            icon={<FontAwesomeIcon icon={faPalette} />}
            label={getEditTemplateSectionLabel(EditTemplateSections.COLORS)}
            index={EditTemplateSections.COLORS}
          />
          <TabX
            icon={<GroupAddOutlinedIcon />}
            label={getEditTemplateSectionLabel(EditTemplateSections.ASSIGNEES)}
            index={EditTemplateSections.ASSIGNEES}
          />
        </TabsX>
        <div className="flex flex-col gap-7 xl:flex-row">
          <div className="grow">
            {tabIndex === EditTemplateSections.CONTENT && (
              <EditTemplateContent
                template={thisTemplate}
                links={thisTemplateLinks || []}
                onChange={previewContentChange}
                {...tabPanelA11yProps(EditTemplateSections.CONTENT)}
              />
            )}
            {tabIndex === EditTemplateSections.ABOUT && (
              <EditTemplateAbout
                template={thisTemplate}
                onChange={previewAboutChange}
                changed={isAboutChanged}
                onChangesSaved={() => setIsAboutChanged(false)}
                onChangesDiscarded={() => setIsAboutChanged(false)}
                {...tabPanelA11yProps(EditTemplateSections.ABOUT)}
              />
            )}
            {tabIndex === EditTemplateSections.COLORS && (
              <EditTemplateColors
                template={thisTemplate}
                colors={thisTemplateSettings?.colors || defaultColorsSettings}
                onChange={previewColorsChange}
                changed={isColorsChanged}
                onChangesSaved={() => setIsColorsChanged(false)}
                onChangesDiscarded={() => setIsColorsChanged(false)}
                {...tabPanelA11yProps(EditTemplateSections.COLORS)}
              />
            )}
            {tabIndex === EditTemplateSections.ASSIGNEES && (
              <EditTemplateAssignees
                templateId={thisTemplate.id}
                assigneeIds={thisTemplate.assigneeIds}
                {...tabPanelA11yProps(EditTemplateSections.ABOUT)}
              />
            )}
          </div>
          <div className="flex flex-col gap-6 rounded-3xl border border-gray-200 px-8 py-6">
            <div className="flex items-center justify-between gap-6">
              <h4 className="text-lg font-semibold text-black">Preview</h4>
            </div>
            <PhonePreview
              mutableData={{
                id: thisTemplate?.id,
                captureLead: thisTemplate?.captureLead,
                openDirect: thisTemplate?.openDirect,
              }}
            >
              {appTemplate && appTemplateLinks && appSettings && (
                <AddMeeApp
                  member={appTemplate}
                  links={appTemplateLinks}
                  settings={appSettings}
                />
              )}
            </PhonePreview>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditTemplate;
