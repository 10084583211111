import { faAngleUp, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, AvatarGroup } from "@mui/material";
import { Button, Dropdown } from "antd";
import { cloneElement, MouseEvent, useEffect } from "react";
import { toast } from "react-toastify";
import {
  DropdownMenuItem,
  SelectedMember,
  getMemberHashTags,
  setMemberHashTags,
  useReadMemberHashTagsMutation,
} from "../features/members";
import { useAppDispatch, useAppSelector } from "../store";
import { FetchError, handleFetchError } from "../utils";
import Spinner from "./Spinner";

type SelectionBarProps = {
  selectedMembers: SelectedMember[];
  actions?: DropdownMenuItem[];
  onClose?: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

function SelectionBar({
  selectedMembers,
  actions,
  onClose,
  className,
}: SelectionBarProps) {
  const dispatch = useAppDispatch();

  const memberHashTags = useAppSelector(getMemberHashTags);

  const [readMemberHashTags] = useReadMemberHashTagsMutation();

  useEffect(() => {
    if (memberHashTags === undefined) {
      (async () => {
        try {
          const {
            success,
            message,
            data: { allHashTags },
          } = await readMemberHashTags().unwrap();
          if (success) {
            dispatch(setMemberHashTags(allHashTags));
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    }
  }, [memberHashTags, readMemberHashTags, dispatch]);

  if (memberHashTags === undefined) {
    return <Spinner fullScreen />;
  }

  return (
    <div
      className={`z-20 flex items-center justify-between rounded-t-2xl bg-black px-6 py-4 text-sm text-white xl:gap-12 2xl:gap-20 ${
        className ?? ""
      }`}
    >
      <div className="flex items-center gap-2">
        <label className="hidden lg:block">
          Selected ({selectedMembers.length}):
        </label>
        <AvatarGroup
          max={4}
          classes={{
            avatar:
              "!h-8 !w-8 !border-2 !border-black !bg-white !font-sans !text-xs font-semibold !text-black",
          }}
        >
          {selectedMembers.map((m) => (
            <Avatar
              key={m.id}
              alt={m.fullName}
              src={m.profileImage}
              classes={{
                root: "!h-8 !w-8 !border-2 !border-black !bg-gray-100 !text-gray-400",
              }}
            />
          ))}
        </AvatarGroup>
      </div>
      {actions?.length && (
        <div className="flex items-center xl:gap-8 2xl:gap-12">
          {actions.map((anAction, index) =>
            anAction.children?.length ? (
              <Dropdown
                key={index}
                menu={{
                  items: anAction.children
                    // Skip if any of the following condition is true
                    ?.filter(
                      (anItem) =>
                        !(
                          (anItem.key === "ShowConnectButton" &&
                            selectedMembers.every(
                              (s) =>
                                s.connectButton ||
                                s.assignedTemplate?.isControlButtonsLocked
                            )) ||
                          (anItem.key === "HideConnectButton" &&
                            selectedMembers.every(
                              (s) =>
                                !s.connectButton ||
                                s.assignedTemplate?.isControlButtonsLocked
                            )) ||
                          (anItem.key === "Lock" &&
                            selectedMembers.every((s) => s.locked)) ||
                          (anItem.key === "Unlock" &&
                            selectedMembers.every((s) => !s.locked)) ||
                          (anItem.key === "Public" &&
                            selectedMembers.every((s) => s.isPublic)) ||
                          (anItem.key === "Private" &&
                            selectedMembers.every((s) => !s.isPublic)) ||
                          (anItem.key === "ADSyncSettings" &&
                            selectedMembers.every((s) => !s.isSyncedWithAD)) ||
                          (anItem.key === "ShowSaveContactButton" &&
                            selectedMembers.every(
                              (s) =>
                                s.saveContactButton ||
                                s.assignedTemplate?.isControlButtonsLocked
                            )) ||
                          (anItem.key === "HideSaveContactButton" &&
                            selectedMembers.every(
                              (s) =>
                                !s.saveContactButton ||
                                s.assignedTemplate?.isControlButtonsLocked
                            )) ||
                          (anItem.key === "AddHashTag" &&
                            selectedMembers.every((s) =>
                              memberHashTags.map((m) => m.id).includes(s.id)
                            )) ||
                          (anItem.key === "ClearHashTags" &&
                            selectedMembers.every(
                              (s) =>
                                !memberHashTags.map((m) => m.id).includes(s.id)
                            )) ||
                          (anItem.key === "SetProfilePicture" &&
                            selectedMembers.every(
                              (s) => s.assignedTemplate?.profileImage
                            )) ||
                          (anItem.key === "SetCoverPhoto" &&
                            selectedMembers.every(
                              (s) => s.assignedTemplate?.profileBanner
                            )) ||
                          (anItem.key === "SetCompanyLogo" &&
                            selectedMembers.every(
                              (s) => s.assignedTemplate?.companyLogo
                            ))
                        )
                    )
                    .map((anItem, index) => ({
                      key: index,
                      label:
                        anItem.type === "divider" ? (
                          <hr className="mx-1" />
                        ) : (
                          <span
                            className={`group relative block hover:bg-slate-50 ${
                              anItem.className || ""
                            }`}
                          >
                            <Button
                              onClick={() =>
                                anItem.onClick?.(
                                  selectedMembers.map((s) => s.id)
                                )
                              }
                              className="flex h-auto w-full cursor-pointer items-center justify-start gap-3 border-none px-6 py-3.5 font-sans text-sm text-gray-500 shadow-none group-hover:!text-primary"
                            >
                              {anItem.icon && anItem.classes?.icon
                                ? cloneElement(anItem.icon, {
                                    className: [
                                      anItem.icon?.props.className,
                                      anItem.classes?.icon,
                                    ].join(" "),
                                  })
                                : anItem.icon}
                              <span
                                className={`text-xs font-semibold text-gray-900 group-hover:text-inherit ${
                                  anItem.classes?.name || ""
                                }`}
                              >
                                {anItem.name}
                              </span>
                            </Button>
                            {anItem.type === "uploader" && (
                              <input
                                type="file"
                                accept={anItem.acceptedFileTypes}
                                onChange={(e) =>
                                  anItem.onClick?.(
                                    selectedMembers.map((s) => s.id),
                                    (e.target as HTMLInputElement).files
                                  )
                                }
                                className="absolute inset-0 cursor-pointer opacity-0"
                              />
                            )}
                          </span>
                        ),
                    })),
                }}
                placement="topRight"
                trigger={["click"]}
                dropdownRender={(menu) =>
                  cloneElement(menu as React.ReactElement, {
                    className:
                      "addmee-menu-edit !rounded-xl bg-white !px-0 !py-2 shadow-xl !mb-4",
                  })
                }
              >
                <strong
                  className={`flex cursor-pointer items-center gap-2 border-none px-3 py-1.5 font-normal text-white hover:!text-white xl:px-4 ${
                    anAction.className || ""
                  }`}
                >
                  <span>{anAction.name}</span>
                  {anAction.icon || <FontAwesomeIcon icon={faAngleUp} />}
                </strong>
              </Dropdown>
            ) : (
              <Button
                key={index}
                onClick={() =>
                  anAction.onClick?.(selectedMembers.map((s) => s.id))
                }
                className={`flex cursor-pointer items-center gap-2 border-none px-3 py-1.5 font-normal text-white hover:!text-white xl:px-4 ${
                  anAction.className || ""
                }`}
              >
                <span>{anAction.name}</span>
                {anAction.icon || <FontAwesomeIcon icon={faAngleUp} />}
              </Button>
            )
          )}
        </div>
      )}
      <div>
        {onClose && (
          <button
            title="Close selection bar"
            onClick={onClose}
            className="h-8 w-8 rounded-full border border-white bg-black"
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        )}
      </div>
    </div>
  );
}

export default SelectionBar;
