import { faAddressCard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Badge,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import type { Member } from "../features/members/membersTypes";
import type { Template } from "../features/templates/templatesTypes";
import Spinner from "./Spinner";

type MembersSelectListCardProps = {
  members: Array<Member & { templateName?: Template["name"] }>;
  title?: string;
  emptyListMessage?: string | ReactElement;
  loading?: boolean;
  onSelect?: (ids: Array<Member["id"]>) => void;
  className?: string;
};

function MembersSelectListCard({
  members,
  title = "Members",
  emptyListMessage = "No member found.",
  loading = false,
  onSelect,
  className = "",
}: MembersSelectListCardProps) {
  const [selectedIds, setSelectedIds] = useState<Array<Member["id"]>>([]);

  useEffect(() => {
    setSelectedIds((prevState) =>
      prevState.filter((sid) => members.some((m) => m.id === sid))
    );
  }, [members]);

  function handleSelectAllClick(): void {
    if (selectedIds.length === members.length) {
      setSelectedIds([]);
      onSelect && onSelect([]);
    } else {
      setSelectedIds(members.map((m) => m.id));
      onSelect && onSelect(members.map((m) => m.id));
    }
  }

  function handleSingleClick(id: Member["id"]): void {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((sid) => sid !== id));
      onSelect && onSelect(selectedIds.filter((sid) => sid !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
      onSelect && onSelect([...selectedIds, id]);
    }
  }

  return (
    <Card className={className}>
      <CardHeader
        avatar={
          <Checkbox
            onClick={handleSelectAllClick}
            checked={
              members.length !== 0 && members.length === selectedIds.length
            }
            indeterminate={
              selectedIds.length !== 0 && selectedIds.length !== members.length
            }
            disabled={members.length === 0}
            inputProps={{
              "aria-label": "Select all members",
            }}
          />
        }
        title={title}
        subheader={`${selectedIds.length}/${members.length} selected`}
        classes={{
          title: "!font-sans !text-base !font-semibold",
          subheader: "!font-sans",
        }}
      />
      <Divider />
      {loading ? (
        <div className="relative h-96">
          <Spinner className="bg-transparent" />
        </div>
      ) : members.length > 0 ? (
        <List className="h-96 overflow-auto scrollbar-thin scrollbar-track-gray-50 scrollbar-thumb-gray-300 scrollbar-thumb-rounded">
          {members.map((aMember) => {
            const labelId = `checkbox-list-secondary-label-${aMember.id}`;
            return (
              <ListItem key={aMember.id} disablePadding>
                <ListItemButton
                  onClick={() => handleSingleClick(aMember.id)}
                  dense
                >
                  <ListItemIcon className="!min-w-0">
                    <Checkbox
                      size="small"
                      edge="start"
                      checked={selectedIds.includes(aMember.id)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemAvatar>
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      badgeContent={
                        aMember.templateName && (
                          <IconButton
                            size="small"
                            title={`${aMember.fullName} is already assigned to the ${aMember.templateName} Template.`}
                            className="!bg-gray-100 !text-xs !text-gray-600"
                          >
                            <FontAwesomeIcon icon={faAddressCard} />
                          </IconButton>
                        )
                      }
                    >
                      <Avatar
                        alt={aMember.fullName || aMember.username}
                        src={aMember.profileImage}
                        classes={{
                          root: "!bg-gray-100 !text-gray-400",
                        }}
                      />
                    </Badge>
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    primary={aMember.fullName || aMember.username}
                    secondary={aMember.email}
                    classes={{
                      primary: "!font-sans !font-semibold",
                      secondary: "break-words !font-sans",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      ) : typeof emptyListMessage === "object" ? (
        emptyListMessage
      ) : (
        <p className="flex h-96 flex-col items-center justify-center gap-2 text-center">
          {/* <strong className='text-sm text-gray-700'>No members found</strong> */}
          <small className="block w-72 text-xs text-gray-600">
            {emptyListMessage}
          </small>
        </p>
      )}
    </Card>
  );
}

export default MembersSelectListCard;
