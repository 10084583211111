import React, { cloneElement, ReactElement, ReactNode, useState } from "react";
import { Button, Menu } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type DropdownXProps = {
  name: string;
  children: ReactNode[];
};

function DropdownX({ name, children, ...props }: DropdownXProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isShown = Boolean(anchorEl);

  const showDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const hideDropdown = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="add-members-button"
        aria-controls={isShown ? "add-members-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isShown ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={showDropdown}
        endIcon={<KeyboardArrowDownIcon />}
        classes={{
          root: "!rounded-full !bg-primary !py-4 !px-4 !font-sans !text-xs !font-semibold !normal-case md:!px-8 md:!text-sm lg:!px-4 xl:!px-8",
        }}
        {...props}
      >
        {name}
      </Button>
      <Menu
        id="add-members-menu"
        anchorEl={anchorEl}
        open={isShown}
        onClose={hideDropdown}
        MenuListProps={{
          "aria-labelledby": "add-members-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: -12,
          horizontal: "right",
        }}
        classes={{
          paper: "w-72 !rounded-xl",
        }}
      >
        {children.map(
          (aChild: ReactNode, index: number) =>
            React.isValidElement(aChild) &&
            cloneElement(aChild as ReactElement<{ onClick: () => void }>, {
              key: index,
              onClick: () => {
                hideDropdown();
                aChild.props.onClick();
              },
            })
        )}
      </Menu>
    </>
  );
}

export default DropdownX;
