import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { MenuItem } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { TextFieldX } from "../styles/mui-styled";
import FloatingLabel from "./FloatingLabel";

type WifiLinkValueProps = {
  name?: string;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
  icon?: IconDefinition;
  iconLink?: string;
  iconTitle?: string;
  disabled?: boolean;
  className?: string;
};

function WifiLinkValue({
  name,
  placeholder = "",
  value = "",
  onChange,
  icon,
  iconLink,
  iconTitle,
  disabled = false,
  className = "",
  ...props
}: WifiLinkValueProps) {
  const [wifiEncryption, setWifiEncryption] = useState<string>("");
  const [wifiSSID, setWifiSSID] = useState<string>("");
  const [wifiPassword, setWifiPassword] = useState<string>("");

  const onChangeRef = useRef(onChange);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  useEffect(() => {
    if (value) {
      setWifiEncryption(
        decodeURIComponent(value.match(/T:([^;]+);/)?.[1] || "")
      );
      setWifiSSID(decodeURIComponent(value.match(/S:([^;]+);/)?.[1] || ""));
      setWifiPassword(decodeURIComponent(value.match(/P:([^;]+);/)?.[1] || ""));
    }
  }, [value]);

  useEffect(() => {
    if (
      wifiEncryption &&
      wifiSSID &&
      (wifiEncryption === "NONE" || (wifiEncryption !== "NONE" && wifiPassword))
    ) {
      onChangeRef.current?.(
        `WIFI:S:${encodeURIComponent(wifiSSID)};T:${wifiEncryption};P:${
          wifiEncryption !== "NONE" ? encodeURIComponent(wifiPassword) : ""
        };H:false;`.trim()
      );
    } else {
      onChangeRef.current?.("");
    }
  }, [wifiSSID, wifiEncryption, wifiPassword]);

  return (
    <div className={`grid grid-cols-3 gap-4 ${className}`} {...props}>
      <input type="hidden" name={name} value={value} />
      {disabled ? (
        <FloatingLabel icon={icon} iconLink={iconLink} iconTitle={iconTitle}>
          <input
            type="text"
            name="link-wifi-encryption"
            placeholder={`${placeholder || "Select"} encryption*`}
            value={wifiEncryption}
            disabled
          />
        </FloatingLabel>
      ) : (
        <TextFieldX
          select
          name="link-wifi-encryption"
          label={`${placeholder || "Select"} encryption*`}
          value={wifiEncryption}
          onChange={(e) => setWifiEncryption(e.target.value)}
        >
          <MenuItem value="NONE">None</MenuItem>
          <MenuItem value="WPA">WPA/WPA2</MenuItem>
          <MenuItem value="WEP">WEP</MenuItem>
        </TextFieldX>
      )}
      <FloatingLabel
        icon={icon}
        iconLink={iconLink}
        iconTitle={iconTitle}
        onInputValueChange={setWifiSSID}
        className={wifiEncryption === "NONE" ? "col-span-2" : ""}
      >
        <input
          type="text"
          name="link-wifi-ssid"
          placeholder={`${placeholder || "Enter"} SSID*`}
          autoComplete="username" // To prevent autofill
          value={wifiSSID}
          disabled={disabled}
        />
      </FloatingLabel>
      {wifiEncryption !== "NONE" && (
        <FloatingLabel
          icon={icon}
          iconLink={iconLink}
          iconTitle={iconTitle}
          onInputValueChange={setWifiPassword}
        >
          <input
            type="password"
            name="link-wifi-password"
            placeholder={`${placeholder || "Enter"} password*`}
            autoComplete="new-password" // To prevent autofill
            value={wifiPassword}
            disabled={disabled}
          />
        </FloatingLabel>
      )}
    </div>
  );
}

export default WifiLinkValue;
