import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import { Fragment, isValidElement } from "react";
import { ReactElement, cloneElement } from "react";

type MoreOptionsItemProps = {
  icon?: ReactElement;
  label?: string;
  danger?: boolean;
  onClick?: (files?: FileList | null) => void;
  className?: string;
  type?: "divider" | "uploader";
  acceptedFileTypes?: string;
};

function MoreOptionsItem({
  icon,
  label,
  danger = false,
  onClick,
  className = "",
  type,
  acceptedFileTypes,
  ...props
}: MoreOptionsItemProps) {
  return <></>;
}

type MoreOptionsMenuProps = {
  icon?: ReactElement;
  children:
    | ReactElement<MoreOptionsItemProps>
    | ReactElement<MoreOptionsItemProps>[];
  className?: string;
};

function MoreOptionsMenu({
  icon,
  children,
  className = "",
  ...props
}: MoreOptionsMenuProps) {
  children = Array.isArray(children) ? children : [children];
  children = children.filter(
    (child) => !(isValidElement(child) && child.type === Fragment)
  );

  return (
    <Dropdown
      menu={{
        items: children?.map((option, index) => ({
          key: "more-options-item-" + index,
          label:
            option.props.type === "divider" ? (
              <hr className="mx-1" />
            ) : (
              <span
                className={`group relative block hover:bg-slate-50 ${option.props.className}`}
              >
                <Button
                  onClick={() =>
                    option.props.type !== "uploader" && option.props.onClick?.()
                  }
                  className={`flex h-auto w-full cursor-pointer items-center justify-start gap-3 border-none px-6 py-3.5 font-sans text-sm shadow-none ${
                    option.props.danger
                      ? "text-error-400 group-hover:!text-error-500"
                      : "text-gray-500 group-hover:!text-primary"
                  }`}
                >
                  {option.props.icon}
                  <span className={`text-xs font-semibold`}>
                    {option.props.label}
                  </span>
                </Button>
                {option.props.type === "uploader" && (
                  <input
                    type="file"
                    accept={option.props.acceptedFileTypes}
                    onChange={(e) =>
                      option.props.onClick?.(
                        (e.target as HTMLInputElement).files
                      )
                    }
                    className="absolute inset-0 cursor-pointer opacity-0"
                  />
                )}
              </span>
            ),
        })),
      }}
      placement="topRight"
      trigger={["click"]}
      dropdownRender={(menu) =>
        cloneElement(menu as ReactElement, {
          className:
            "addmee-menu-edit !rounded-xl bg-white !px-0 !py-2 shadow-xl",
        })
      }
    >
      <IconButton
        aria-label="settings"
        classes={{
          root: `aspect-square min-w-[2rem] !rounded-full !p-0 ${className}`,
        }}
        sx={{
          border: Number(
            className.split(" ").findIndex((c) => /(!?)border/.test(c)) > -1
          ),
        }}
        {...props}
      >
        {icon || <MoreVertIcon className="!text-base" />}
      </IconButton>
    </Dropdown>
  );
}

export default MoreOptionsMenu;
export { MoreOptionsItem };
