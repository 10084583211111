import Avatar from "@mui/material/Avatar";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { MouseEvent, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import DialogX from "./DialogX";
import ImageCropper from "./ImageCropper";

type ImageDropzoneProps = {
  accept?: string;
  src?: string;
  onChange?: (file: File | null, dataUrl: string) => void;
  variant?: "avatar" | "banner";
  maxWidth?: number;
  className?: string;
};

function ImageDropzone({
  accept,
  src,
  onChange,
  variant,
  maxWidth,
  className,
  ...props
}: ImageDropzoneProps) {
  const [avatarSrc, setAvatarSrc] = useState<string>();
  const [uploadedImage, setUploadedImage] = useState<File>();
  const [imageCropperDialogOpen, setImageCropperDialogOpen] =
    useState<boolean>(false);

  useEffect(() => {
    setAvatarSrc(src);
  }, [src]);

  const avatarVariant =
    variant === "avatar"
      ? "circular"
      : variant === "banner"
      ? "rounded"
      : "square";

  const onDrop = useCallback(async function (acceptedFiles: File[]) {
    if (acceptedFiles.length) {
      setUploadedImage(acceptedFiles[0]);
      setImageCropperDialogOpen(true);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: accept
      ?.split(",")
      .reduce((acc: { [mimeType: string]: string[] }, mimetype) => {
        acc[mimetype] = [];
        return acc;
      }, {}),
    multiple: false,
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((aRejectedFile) => {
        aRejectedFile.errors.forEach((anError) => {
          if (anError.code === "file-invalid-type") {
            toast.error("This file format is not yet supported.");
          }
        });
      });
    },
    onError(err: Error) {
      console.error(err);
    },
  });

  function handleClear(event: MouseEvent<SVGSVGElement>): void {
    onChange?.(null, "");
  }

  function handleImageCropperCancel(): void {
    setUploadedImage(undefined);
    setImageCropperDialogOpen(false);
  }

  function handleCroppedImage(file: File): void {
    const croppedImageUrl = URL.createObjectURL(file);
    onChange?.(file, croppedImageUrl);
    handleImageCropperCancel();
  }

  return (
    <div className={`relative flex flex-col ${className ?? ""}`} {...props}>
      <div {...getRootProps()} className="h-full w-full">
        <input {...getInputProps()} />
        {!avatarSrc || isDragActive ? (
          <Avatar
            variant={avatarVariant}
            classes={{
              root: "!h-full !w-full flex-col gap-2 !bg-gray-100 pb-2 !font-sans !text-[10px] !text-gray-600",
              rounded: "!rounded-2xl",
            }}
          >
            <FileUploadOutlinedIcon fontSize="small" />
            <strong className="font-semibold">Tap to Upload</strong>
            <span>&nbsp;OR&nbsp;</span>
            <strong className="font-semibold">Drag & Drop</strong>
          </Avatar>
        ) : (
          <Avatar
            src={avatarSrc}
            variant={avatarVariant}
            classes={{
              root: "!h-full !w-full !bg-gray-100 !text-gray-100",
              rounded: "!rounded-2xl",
            }}
          />
        )}
      </div>
      {!!avatarSrc && (
        <CloseOutlinedIcon
          fontSize="small"
          onClick={handleClear}
          classes={{
            root: `absolute -top-2 ${
              variant !== "banner" ? "right-0" : "-right-2"
            } !h-8 !w-8 cursor-pointer rounded-full border border-gray-200 bg-white !p-2 text-black`,
          }}
        />
      )}
      <DialogX
        open={imageCropperDialogOpen}
        onClose={handleImageCropperCancel}
        fullScreen
        className="h-5/6 !max-w-5xl"
      >
        {uploadedImage && (
          <ImageCropper
            src={uploadedImage}
            aspect={variant === "avatar" ? 1 : 30 / 11}
            cropShape={variant === "avatar" ? "round" : "rect"}
            maxWidth={maxWidth}
            onCrop={handleCroppedImage}
            onCancel={handleImageCropperCancel}
          />
        )}
      </DialogX>
    </div>
  );
}

export default ImageDropzone;
