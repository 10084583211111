import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DroppableProps,
} from "react-beautiful-dnd";

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

type DragSortListItemProps = {
  item: any;
  index: number;
  children: ReactElement[];
  className?: string;
  draggable?: boolean;
  disabled?: boolean;
};

export function DragSortListItem({
  item,
  index,
  children,
  className,
  draggable,
  disabled,
  ...props
}: DragSortListItemProps) {
  return (
    <Draggable
      draggableId={item.id.toString()}
      index={index}
      isDragDisabled={!draggable}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`flex items-center justify-between gap-5 rounded-2xl ${
            disabled ? "" : "bg-gray-50"
          } p-3 ${className ?? ""}`}
          {...props}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
}

type DragSortListMemoizedProps = {
  children: ReactNode;
};

const DragSortListMemoized = React.memo(function DragSortListMemoized({
  children,
}: DragSortListMemoizedProps) {
  return <>{children}</>;
});

function StrictModeDroppable({ children, ...props }: DroppableProps) {
  const [enabled, setEnabled] = useState<boolean>(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
}

type DragSortListProps = {
  list: any[];
  onDrop: (reorderedList: any[]) => void;
  children: ReactNode;
  className?: string;
};

function DragSortList({
  list,
  onDrop,
  children,
  className,
  ...props
}: DragSortListProps) {
  function onDragEnd({ destination, source }: DropResult) {
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    const reorderedList: any[] = reorder(list, source.index, destination.index);

    onDrop(reorderedList);
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId='list'>
          {(provided) => (
            <div
              ref={provided.innerRef}
              className={`flex flex-col gap-3 ${className ?? ""}`}
              {...provided.droppableProps}
              {...props}
            >
              <DragSortListMemoized>{children}</DragSortListMemoized>
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </div>
  );
}

export default DragSortList;
