import { sha256 } from "js-sha256";

// Generates a random string of the specified length
function generateRandomString(length: number): string {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

// Encodes a string using base64 URL encoding.
function base64UrlEncode(str: string): string {
  return str.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

// Creates a code challenge from the given code verifier.
function createCodeChallenge(codeVerifier: string): string {
  // Create a SHA256 hash of the code verifier
  const hash = sha256(codeVerifier);
  const bytes = new Uint8Array(Math.ceil(hash.length / 2));
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = parseInt(hash.substring(i * 2, i * 2 + 2), 16);
  }
  const base64Hash = btoa(String.fromCharCode(...Array.from(bytes)));

  // Base64-URL encode the hash
  return base64UrlEncode(base64Hash);
}

// Generate a random code verifier
export const codeVerifier = generateRandomString(128);

// Create a code challenge from the code verifier
export const codeChallenge = createCodeChallenge(codeVerifier);
