import type {
  GetProcessedImageOptions,
  ValidateImageArgs,
  ValidateImageResponse,
} from "./utilsTypes";

export function getProcessedImage(
  imageFile: File,
  options?: GetProcessedImageOptions
): Promise<File> {
  return new Promise((resolve, reject) => {
    // Create a FileReader to read the image file
    const reader = new FileReader();

    // Handle the file reading process
    reader.addEventListener("load", (e) => {
      // Create an image element
      const img = new Image();

      // Handle image loading
      img.addEventListener("load", () => {
        // Setting the Options for the Image
        const maxWidth = options?.maxWidth || img.naturalWidth;
        const aspect = options?.aspect || 1;
        const transparency = options?.transparency || false;

        // Create a canvas element
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set the canvas size to Maximum Width of the Profile Link Image in the App Configs
        canvas.width = maxWidth;
        canvas.height = maxWidth / aspect;

        if (!transparency) {
          // Draw the white background
          if (ctx) ctx.fillStyle = "white";
          ctx?.fillRect(0, 0, canvas.width, canvas.height);
        }

        // Calculate the aspect ratio of the image
        const aspectRatio = img.width / img.height;

        // Calculate the new size for the image to fit within the canvas
        let newWidth, newHeight;
        if (aspectRatio > 1) {
          newWidth = canvas.width;
          newHeight = canvas.height / aspectRatio;
        } else {
          newWidth = canvas.width * aspectRatio;
          newHeight = canvas.height;
        }

        // Calculate the position to center the image on the canvas
        const x = (canvas.width - newWidth) / 2;
        const y = (canvas.height - newHeight) / 2;

        // Draw the resized image on the canvas
        ctx?.drawImage(img, x, y, newWidth, newHeight);

        // Convert the canvas to a Image Blob
        canvas.toBlob(
          (blob) => {
            if (blob) {
              const fileExtensionIndex =
                imageFile.name.lastIndexOf(".") !== -1
                  ? imageFile.name.lastIndexOf(".")
                  : imageFile.name.length;

              const fileNameParts = [
                imageFile.name.slice(0, fileExtensionIndex),
                imageFile.name.slice(fileExtensionIndex),
              ];

              // Pass the data URL to the callback function
              resolve(
                new File(
                  [blob],
                  fileNameParts.join(`_${new Date().getTime()}`),
                  { type: imageFile.type }
                )
              );
            }
          },
          imageFile.type,
          1
        );
      });

      // Set the image source to the data URL obtained from reading the file
      img.src = e.target?.result as string;
    });

    // Handle the error during file reading process
    reader.addEventListener("error", (error) => {
      reject(error);
    });

    // Read the image file as a data URL
    reader.readAsDataURL(imageFile);
  });
}

export function getBase64Url(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    // Create a FileReader to read the file
    const reader = new FileReader();

    // Handle the file reading process
    reader.addEventListener("load", (e) => {
      // Return the Base64 data URL obtained from reading the file
      resolve(e.target?.result as string);
    });

    // Handle the error during file reading process
    reader.addEventListener("error", (error) => {
      reject(error);
    });

    // Read the file as a data URL
    reader.readAsDataURL(file);
  });
}

export function validateImage({
  file,
  accept,
  minSize = 1024,
  maxSize,
}: ValidateImageArgs): ValidateImageResponse {
  let success = true;
  let message = "";

  if (!file.type.includes("image/")) {
    success = false;
    message = "This is not a valid image file!";
  } else if (!accept?.includes(file.type)) {
    success = false;
    message = "This image format is not yet supported.";
  } else if (minSize && file.size < minSize) {
    success = false;
    message = `Image too small. It must be greater than ${minSize / 1024} KB!`;
  } else if (maxSize && file.size > maxSize) {
    success = false;
    message = `Image too large. It must be smaller than ${
      maxSize / 1024 / 1024
    } MB!`;
  }

  return { success, message };
}

export function getSVGString(imageUrl?: string | null): string {
  return Boolean(imageUrl)
    ? `<svg><image href="${imageUrl || ""}" class="h-full w-full" /></svg>`
    : "";
}
