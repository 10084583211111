import { FormControlLabel, Switch } from "@mui/material";
import { ChangeEvent } from "react";

type MUISwitchProps = {
  name?: string;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  hidden?: boolean;
  disabled?: boolean;
  className?: string;
};

function MUISwitch({
  name,
  checked = false,
  onChange,
  hidden = false,
  disabled = false,
  className = "",
  ...props
}: MUISwitchProps) {
  return (
    <Switch
      name={name}
      checked={checked}
      onChange={onChange}
      classes={{
        root: `${hidden && "!hidden"} !h-5 !w-9 !rounded-full !border ${
          checked
            ? "!border-primary !bg-primary"
            : "!border-neutral-300 !bg-gray-300"
        } !p-px ${disabled && "opacity-50"} ${className}`,
        track: "!bg-transparent",
        switchBase: "!p-0",
        thumb: "!h-[1.125rem] !w-[1.125rem] !bg-white",
        checked: "!translate-x-4",
      }}
      hidden={hidden}
      disabled={disabled}
      {...props}
    />
  );
}

type SwitchXProps = {
  name?: string;
  label?: string;
  labelPlacement?: "start" | "end" | "top" | "bottom";
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled?: boolean;
  hidden?: boolean;
  className?: string;
};

function SwitchX({
  name,
  label = "",
  labelPlacement = "end",
  checked = false,
  onChange,
  hidden = false,
  disabled = false,
  className = "",
  ...props
}: SwitchXProps) {
  if (!label) {
    return (
      <MUISwitch
        name={name}
        checked={checked}
        onChange={onChange}
        className={`${className} ${hidden && "!hidden"}`}
        hidden={hidden}
        disabled={disabled}
        {...props}
      />
    );
  }

  return (
    <FormControlLabel
      name={name?.concat("-label")}
      control={
        <MUISwitch
          name={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
      classes={{
        root: `!m-0 gap-3 ${className ?? ""} ${hidden ? "!hidden" : ""}`,
        label: "!font-sans !text-sm !font-medium !text-gray-700",
      }}
      hidden={hidden}
      disabled={disabled}
      {...props}
    />
  );
}

export default SwitchX;
