import { Button, IconButton, Slider } from "@mui/material";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import ZoomOutOutlinedIcon from "@mui/icons-material/ZoomOutOutlined";
import { RefObject, useEffect, useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import SwitchX from "./SwitchX";

type ImageCropperProps = {
  src: File | string;
  aspect?: number;
  cropShape?: "rect" | "round";
  maxWidth?: number;
  onCrop?: (file: File) => void;
  onCancel?: () => void;
  className?: string;
};

function ImageCropper({
  src,
  aspect = 1,
  cropShape = "rect",
  maxWidth,
  onCrop,
  onCancel,
  className = "",
  ...props
}: ImageCropperProps) {
  const [uploadedImageRef, setUploadedImageRef] =
    useState<RefObject<HTMLImageElement> | null>(null);
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageType, setImageType] = useState<string>();
  const [imageNameParts, setImageNameParts] = useState<string[]>([]);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
  const [transparency, setTransparency] = useState<boolean>(false);

  useEffect(() => {
    const fileNameWithExtension =
      typeof src === "string" ? src.split("/").pop() || "image.png" : src.name;

    const fileExtensionIndex =
      fileNameWithExtension.lastIndexOf(".") !== -1
        ? fileNameWithExtension.lastIndexOf(".")
        : fileNameWithExtension.length;
    setImageNameParts([
      fileNameWithExtension.slice(0, fileExtensionIndex),
      fileNameWithExtension.slice(fileExtensionIndex),
    ]);

    if (typeof src === "string") {
      setImageUrl(src);
      setImageType("image/" + (src.split(".").pop() || "png"));
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result as string);
        setImageType(src.type);
      };
      reader.readAsDataURL(src);
    }
  }, [src]);

  function handleCropComplete(
    _croppedArea: Area,
    croppedAreaPixels: Area
  ): void {
    setCroppedAreaPixels(croppedAreaPixels);
  }

  async function getCroppedImage(
    uploadedImage: HTMLImageElement,
    croppedAreaPixels: Area
  ): Promise<File> {
    const croppedCanvas = document.createElement("canvas");

    const croppedContext = croppedCanvas.getContext("2d");
    if (!croppedContext) {
      return Promise.reject("No Context of the Cropped Canvas is found.");
    }

    // Set dimensions of the Cropped Canvas based on Aspect Ratio
    croppedCanvas.width = maxWidth
      ? croppedAreaPixels.width > maxWidth
        ? maxWidth
        : croppedAreaPixels.width
      : croppedAreaPixels.width;
    croppedCanvas.height = croppedCanvas.width / aspect;

    if (!transparency) {
      croppedContext.fillStyle = "white";
      croppedContext.fillRect(0, 0, croppedCanvas.width, croppedCanvas.height);
    }

    // Draw the cropped image onto the Cropped Canvas
    croppedContext.drawImage(
      uploadedImage,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedCanvas.width,
      croppedCanvas.height
    );

    // As Base64 string
    // return croppedCanvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
      croppedCanvas.toBlob((blob) => {
        if (blob) {
          resolve(
            new File([blob], imageNameParts.join(`_${new Date().getTime()}`), {
              type: imageType,
            })
          );
        } else {
          return reject("No file is found.");
        }
      }, imageType);
    });
  }

  async function savedCroppedImage() {
    try {
      if (uploadedImageRef?.current && croppedAreaPixels) {
        const croppedImage = await getCroppedImage(
          uploadedImageRef.current,
          croppedAreaPixels
        );
        onCrop?.(croppedImage);
        cancelImageCropping();
      }
    } catch (e) {
      console.error(e);
    }
  }

  function cancelImageCropping() {
    setUploadedImageRef(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(undefined);
    onCancel?.();
  }

  return (
    <div className={`flex h-full flex-col gap-4 ${className}`} {...props}>
      <div className="relative h-4/5">
        <Cropper
          setImageRef={setUploadedImageRef}
          image={imageUrl}
          aspect={aspect}
          cropShape={cropShape}
          crop={crop}
          onCropChange={setCrop}
          zoom={zoom}
          onZoomChange={setZoom}
          zoomWithScroll
          onCropComplete={handleCropComplete}
        />
      </div>
      <div className="flex w-4/5 items-center gap-2 self-center">
        <IconButton
          aria-label="Zoom Out"
          onClick={() => setZoom(zoom - 0.1)}
          disabled={zoom <= 1}
          className={`${zoom > 1 ? "!text-black" : "!text-gray-300"}`}
        >
          <ZoomOutOutlinedIcon />
        </IconButton>
        <span className="grow leading-none">
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom Slider"
            onChange={(_e, zoom) => setZoom(Number(zoom))}
            classes={{
              thumb: "!bg-primary",
              track: "!bg-primary !border-primary",
              rail: "!bg-primary",
            }}
          />
        </span>
        <IconButton
          aria-label="Zoom In"
          onClick={() => setZoom(zoom + 0.1)}
          disabled={zoom >= 3}
          className={`${zoom < 3 ? "!text-black" : "!text-gray-300"}`}
        >
          <ZoomInOutlinedIcon />
        </IconButton>
      </div>
      <div className="flex items-center justify-end gap-4 border-t border-gray-200 pt-8">
        <div className="grow">
          <SwitchX
            checked={transparency}
            label="Save as transparent image"
            labelPlacement="end"
            onChange={(_e, c) => setTransparency(c)}
          />
        </div>
        <Button
          variant="outlined"
          onClick={cancelImageCropping}
          className="!m-0 !rounded-full !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-black"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={savedCroppedImage}
          classes={{
            root: "!m-0 !rounded-full !bg-primary !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-white",
          }}
        >
          Add Image
        </Button>
      </div>
    </div>
  );
}

export default ImageCropper;
