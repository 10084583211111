import { createApi } from "@reduxjs/toolkit/query/react";
import APIConfigs from "../../configs/apiConfigs";
import { baseQueryWithReauth } from "../rtk-query/baseQuery";
import { mapReadDevicesResponse } from "./devicesMapper";
import type {
  MemberDevice,
  ReadDevicesResponse,
  UnmapDevicesResponse,
} from "./devicesTypes";

const devicesService = createApi({
  reducerPath: "devicesService",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    readDevices: builder.query<ReadDevicesResponse, void>({
      query: () => APIConfigs.Devices.READ_DEVICES,
      transformResponse: mapReadDevicesResponse,
    }),
    unmapDevices: builder.mutation<
      UnmapDevicesResponse,
      Array<MemberDevice["id"]>
    >({
      query: (ids) => ({
        url: APIConfigs.Devices.UNMAP_DEVICES,
        method: "PUT",
        body: { ids },
      }),
    }),
  }),
});

export default devicesService;
export const { useReadDevicesQuery, useUnmapDevicesMutation } = devicesService;
