import Styled from "@emotion/styled";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClearIcon from "@mui/icons-material/Clear";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SvgIcon,
  Tooltip,
} from "@mui/material";
import Upload, { RcFile } from "antd/es/upload";
import parse from "html-react-parser";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  FloatingLabel,
  SwitchX,
  TestLinkButton,
  WifiLinkValue,
} from "../../components";
import AppConfigs from "../../configs/appConfigs";
import { getSVGString } from "../../utils/images";
import { MemberDataLabels } from "../members/membersEnums";
import { LinkTypeCode } from "./linksEnums";
import { getLinkValuePlaceholder, getMemberLinkIcon } from "./linksMapper";
import type { LinkType, NewMemberLink } from "./linksTypes";

const UploadX = Styled(Upload)`
  width: auto !important;

  .ant-upload.ant-upload-select {
    background: none !important;
    border: none !important;
    border-radius: 0.5rem !important;
    height: 3.75rem !important;
    margin: 0 !important;
    overflow: hidden;
    width: 3.75rem !important;
  }
`;

type AddMemberLinkProps = {
  linkType: LinkType;
  onBack?: () => void;
  onAdd?: (link: NewMemberLink) => void;
  onCancel?: () => void;
  className?: string;
};

function AddMemberLink({
  linkType,
  onBack,
  onAdd,
  onCancel,
  className = "",
  ...props
}: AddMemberLinkProps) {
  const newLink: NewMemberLink = {
    linkTypeCode: linkType.code,
    iconSVG: linkType.iconSVGDefault,
    title: linkType.title,
    value: "",
    isHighlighted: false,
    isGlobal: false,
    iconFile: null,
  };
  const [linkToAdd, setLinkToAdd] = useState<NewMemberLink>(newLink);
  const [tootlTipOpen, setTooltipOpen] = useState<boolean>(false);
  const [isAddable, setIsAddable] = useState<boolean>(false);

  const inputLinkValueRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputLinkValueRef.current?.focus();
  }, []);

  useEffect(() => {
    setIsAddable(
      (!linkType.isIconEditable || !!linkToAdd.iconSVG) &&
        (!linkType.isTitleEditable || !!linkToAdd.title) &&
        (!linkType.isValueEditable || !!linkToAdd.value)
    );
  }, [linkType, linkToAdd]);

  async function handleBeforeUpload(image: RcFile) {
    const {
      success,
      message,
      data: { iconUrl, iconObj },
    } = await getMemberLinkIcon(image);
    if (success) {
      setLinkToAdd({
        ...linkToAdd,
        iconSVG: getSVGString(iconUrl),
        iconFile: iconObj || null,
      });
    } else {
      toast.error(message);
    }

    return false;
  }

  function handleHighlightChange(
    _e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void {
    setLinkToAdd({ ...linkToAdd, isHighlighted: checked });
  }

  function handleGlobalChange(
    _e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void {
    setLinkToAdd({ ...linkToAdd, isGlobal: checked });
  }

  function resetToDefaultIcon() {
    setLinkToAdd({
      ...linkToAdd,
      iconFile: null,
      iconSVG: linkType.iconSVGDefault || linkType.iconSVGColorized,
    });
  }

  return (
    <div className={`flex flex-col gap-8 ${className}`} {...props}>
      <div className="flex items-center gap-4">
        <Button
          variant="outlined"
          onClick={() => onBack?.()}
          className="inline-flex cursor-pointer items-center gap-2 !rounded-full !border !border-gray-200 !bg-white !px-4 !py-2 !font-sans !text-xs !normal-case !text-black !no-underline"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <span className="font-semibold">Back</span>
        </Button>
        <DialogTitle className="!p-0 !font-sans !font-semibold text-black">
          Add {linkType?.title}
        </DialogTitle>
      </div>
      <DialogContent className="!p-0">
        <div className="flex flex-col gap-8">
          <div className="flex items-center justify-between gap-8 rounded-2xl bg-gray-100 px-4 py-3">
            {linkType.code === LinkTypeCode.SOS || !linkType.isIconEditable ? (
              <Avatar
                variant="rounded"
                className="!h-[3.75rem] !w-[3.75rem] !rounded-[0.658rem] !bg-transparent"
              >
                {linkType.iconSVGDefault && (
                  <SvgIcon className="!h-full !w-full">
                    {parse(linkType.iconSVGDefault)}
                  </SvgIcon>
                )}
              </Avatar>
            ) : (
              <div className="relative flex items-center gap-4">
                <UploadX
                  accept={AppConfigs.getAcceptedFileTypes(
                    MemberDataLabels.PROFILE_LINK
                  )}
                  name={linkToAdd.title}
                  listType="picture-circle"
                  showUploadList={false}
                  beforeUpload={handleBeforeUpload}
                  className="overflow-hidden rounded-[0.658rem]"
                >
                  {linkToAdd.iconSVG && (
                    <SvgIcon className="!h-full !w-full">
                      {parse(linkToAdd.iconSVG)}
                    </SvgIcon>
                  )}
                </UploadX>
                {![linkType.iconSVGDefault, linkType.iconSVGColorized].includes(
                  linkToAdd.iconSVG
                ) && (
                  <Tooltip
                    title="Click here to restore the original default button icon."
                    placement="top"
                    disableFocusListener
                    disableTouchListener
                    className="!absolute -top-4 left-10"
                    classes={{ tooltip: "!mb-0" }}
                  >
                    <IconButton onClick={resetToDefaultIcon}>
                      <ClearIcon
                        fontSize="small"
                        className="!h-5 !w-5 cursor-pointer rounded-full bg-white !p-0.5 text-black"
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <span className="w-60 text-sm font-medium text-gray-500">
                  Select photo here or drag and drop one in place of current
                </span>
              </div>
            )}
            <SwitchX
              checked={linkToAdd.isHighlighted}
              label="Highlight"
              labelPlacement="end"
              onChange={handleHighlightChange}
            />
          </div>
          {linkType.isValueEditable &&
            (linkType.code === LinkTypeCode.WIFI ? (
              <WifiLinkValue
                name="link-value"
                placeholder={`${getLinkValuePlaceholder(linkType)}`}
                value={linkToAdd.value}
                onChange={(value: string) =>
                  setLinkToAdd({ ...linkToAdd, value })
                }
              />
            ) : (
              <div>
                <FloatingLabel
                  onInputValueChange={(value: string) =>
                    setLinkToAdd({ ...linkToAdd, value })
                  }
                >
                  <input
                    type="text"
                    ref={inputLinkValueRef}
                    name="link-value"
                    placeholder={`${getLinkValuePlaceholder(linkType)}*`}
                    value={linkToAdd.value}
                  />
                </FloatingLabel>
              </div>
            ))}
          {linkType.isTitleEditable && (
            <div>
              <FloatingLabel
                onInputValueChange={(value: string) =>
                  setLinkToAdd({ ...linkToAdd, title: value })
                }
              >
                <input
                  type="text"
                  name="link-title"
                  placeholder="Button title"
                  value={linkToAdd.title}
                />
              </FloatingLabel>
            </div>
          )}
          {linkType.description && (
            <p className="whitespace-pre-line text-sm font-medium text-gray-500">
              {linkType.description}
            </p>
          )}
          <div>
            <TestLinkButton linkType={linkType} linkValue={linkToAdd.value} />
          </div>
        </div>
      </DialogContent>
      <DialogActions
        classes={{ root: "gap-4 !border-t border-gray-200 !p-0 !pt-8" }}
      >
        <div className="grow">
          <div className="flex items-center">
            <SwitchX
              checked={linkToAdd.isGlobal}
              label="Add to all members"
              labelPlacement="end"
              onChange={handleGlobalChange}
            />
            <Tooltip
              title="If this function is enabled, the button will be inserted in all AddMee profiles. As soon as any change is made in any profile, the change is automatically applied to all profiles."
              placement="top"
              open={tootlTipOpen}
              onClose={() => setTooltipOpen(false)}
              disableHoverListener
              classes={{ tooltip: "!mb-0" }}
            >
              <IconButton onClick={() => setTooltipOpen(!tootlTipOpen)}>
                <HelpOutlineIcon className="!text-base !text-gray-500" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <Button
          variant="outlined"
          onClick={() => onCancel?.()}
          className="!m-0 !rounded-full !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-black"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={() => onAdd?.(linkToAdd)}
          disabled={!isAddable}
          classes={{
            root: `!rounded-full !px-6 !py-3 !font-semibold !text-white !normal-case !font-sans !text-sm !m-0 ${
              isAddable ? "!bg-primary" : "!bg-gray-300"
            }`,
          }}
        >
          Add button
        </Button>
      </DialogActions>
    </div>
  );
}

export default AddMemberLink;
