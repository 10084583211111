import { createApi } from "@reduxjs/toolkit/query/react";
import APIConfigs from "../../configs/apiConfigs";
import { baseQueryWithReauth } from "../rtk-query/baseQuery";
import type {
  ForgotPasswordRequestArgs,
  ForgotPasswordResponse,
  ResetPasswordRequestArgs,
  ResetPasswordResponse,
  VerifyCodeRequestArgs,
  VerifyCodeResponse,
} from "./forgotPasswordTypes";

const forgotPasswordService = createApi({
  reducerPath: "forgotPasswordService",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    forgotPassword: builder.mutation<
      ForgotPasswordResponse,
      ForgotPasswordRequestArgs
    >({
      query: ({ email }) => ({
        method: "POST",
        url: APIConfigs.ForgotPassword.FORGOT_PASSWORD,
        body: { email },
      }),
    }),
    verifyCode: builder.mutation<VerifyCodeResponse, VerifyCodeRequestArgs>({
      query: ({ email, vcode }: VerifyCodeRequestArgs) => ({
        method: "POST",
        url: APIConfigs.ForgotPassword.VERIFY_CODE,
        body: { email, vcode },
      }),
    }),
    resetPassword: builder.mutation<
      ResetPasswordResponse,
      ResetPasswordRequestArgs
    >({
      query: ({ email, password, password_confirmation }) => ({
        method: "POST",
        url: APIConfigs.ForgotPassword.RESET_PASSWORD,
        body: { email, password, password_confirmation },
      }),
    }),
  }),
});

export default forgotPasswordService;
export const {
  useForgotPasswordMutation,
  useVerifyCodeMutation,
  useResetPasswordMutation,
} = forgotPasswordService;
