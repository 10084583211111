import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Avatar,
  Collapse,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  Member,
  getMembers,
  setMemberSettings,
  setMembers,
  useGetAllMembersMutation,
} from "../features/members";
import { useAppDispatch, useAppSelector } from "../store";
import { FetchError, handleFetchError } from "../utils";
import FloatingLabel from "./FloatingLabel";

type MembersDropdownListProps = {
  selected: Member;
  onSelect?: (memberId: number) => void;
  className?: string;
};

function MembersDropdownList({
  selected,
  onSelect,
  className,
  ...props
}: MembersDropdownListProps) {
  const [isSelectMemberExpanded, setSelectMemberExpanded] =
    useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [filteredMembers, setFilteredMembers] = useState<Member[]>([]);

  const dispatch = useAppDispatch();
  const searchMembersRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const members = useAppSelector(getMembers);

  const [getAllMembers] = useGetAllMembersMutation();

  useEffect(() => {
    if (!members) {
      (async () => {
        try {
          const {
            success,
            message,
            data: { members: allMembers, settings },
          } = await getAllMembers().unwrap();
          if (success) {
            dispatch(setMembers(allMembers));
            dispatch(setMemberSettings(settings));
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    } else if (searchKeyword.length >= 3) {
      setFilteredMembers(
        members.filter(
          (m) =>
            m.fullName.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            m.username.toLowerCase().includes(searchKeyword.toLowerCase()) ||
            m.email.toLowerCase().includes(searchKeyword.toLowerCase())
        )
      );
    } else {
      setFilteredMembers(members);
    }

    return () => {
      setFilteredMembers([]);
    };
  }, [members, searchKeyword, dispatch, getAllMembers]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      // Closes the dropdown ifuser click outside of the dropdown
      setSelectMemberExpanded(false);
    }
  };

  useEffect(() => {
    // Adds event listener to detect clicks outside of the dropdown
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleans up the event listener when the component is unmounted
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleKeyUpOnSearch(e: KeyboardEvent<HTMLInputElement>): void {
    if (e.key === "Enter" || searchKeyword.length >= 3) {
      setSearchKeyword(searchKeyword);
    }
  }

  return (
    <List
      ref={dropdownRef}
      component="div"
      aria-label="Select a member to edit"
      disablePadding
      classes={{ root: className }}
      {...props}
    >
      <ListItemButton
        alignItems="flex-start"
        onClick={() => setSelectMemberExpanded(!isSelectMemberExpanded)}
        classes={{ root: "gap-3 !px-6 !py-0" }}
      >
        <ListItemAvatar classes={{ root: "!mt-0 !min-w-[3.125rem]" }}>
          <Avatar
            src={selected.profileImage}
            alt={selected.fullName || selected.username}
            classes={{ root: "!h-[3.125rem] !w-[3.125rem]" }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              <strong className="grow overflow-hidden text-ellipsis whitespace-nowrap font-sans text-lg">
                {selected.fullName || selected.username}
              </strong>
              {isSelectMemberExpanded ? (
                <ExpandLess classes={{ root: "!text-3xl" }} />
              ) : (
                <ExpandMore classes={{ root: "!text-3xl" }} />
              )}
            </>
          }
          secondary={selected.email}
          classes={{
            root: "!my-0",
            primary: "!flex",
            secondary:
              "overflow-hidden text-ellipsis whitespace-nowrap !font-sans !text-sm !text-gray-500",
          }}
        />
      </ListItemButton>
      <Collapse
        in={isSelectMemberExpanded}
        timeout="auto"
        unmountOnExit
        classes={{
          root: "relative z-20 rounded-xl bg-white py-2 shadow-xl",
        }}
      >
        <div className="flex px-6 py-2">
          <FloatingLabel
            size="small"
            onInputValueChange={setSearchKeyword}
            onSearchClick={setSearchKeyword}
          >
            <input
              type="search"
              ref={searchMembersRef}
              name="search-members"
              placeholder="Search"
              value={searchKeyword}
              onKeyUp={handleKeyUpOnSearch}
            />
          </FloatingLabel>
        </div>
        <List classes={{ root: "max-h-96 overflow-auto" }}>
          {filteredMembers.map((aMember: Member) => (
            <ListItemButton
              key={aMember.id}
              component="li"
              onClick={() => {
                setSelectMemberExpanded(false);
                onSelect && onSelect(aMember.id);
              }}
              classes={{ root: "gap-3 !px-6" }}
            >
              <ListItemAvatar classes={{ root: "!mt-0 !min-w-[2.25rem]" }}>
                <Avatar
                  src={aMember.profileImage}
                  alt={aMember.fullName || aMember.username}
                  classes={{ root: "!h-9 !w-9" }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={aMember.fullName || aMember.username}
                classes={{
                  root: "!my-0",
                  primary: "!flex max-h-9 overflow-hidden !font-sans !text-sm",
                }}
              />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </List>
  );
}

export default MembersDropdownList;
