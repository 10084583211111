import { faAddressCard, faSave } from "@fortawesome/free-regular-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LoadingButton } from "@mui/lab";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  AvatarTemplate,
  ConfirmationDialog,
  FloatingLabel,
  ImageDropzone,
} from "../../components";
import AppConfigs from "../../configs/appConfigs";
import { useAppDispatch } from "../../store";
import { FetchError, handleFetchError, validateImage } from "../../utils";
import type { MemberUpdatedInfo } from "../card-customization/cardCustomizationTypes";
import {
  Member,
  MemberDataLabels,
  UpdateMemberDataRequestArgs,
  editMember,
  getUpdatedMemberData,
  useUpdateMemberDataMutation,
} from "../members";
import type { Template } from "../templates/templatesTypes";

type EditMemberProfileProps = {
  member: Member;
  template?: Pick<
    Template,
    | "id"
    | "profileImage"
    | "companyLogo"
    | "profileBanner"
    | "companyAddress"
    | "companyName"
    | "subtitle"
  >;
  onChange?: (updatedMemberInfo: MemberUpdatedInfo) => void;
  changed?: boolean;
  onChangesSaved?: () => void;
  onChangesDiscarded?: () => void;
  className?: string;
};

function EditMemberProfile({
  member,
  template,
  onChange,
  changed = false,
  onChangesSaved,
  onChangesDiscarded,
  className = "",
  ...props
}: EditMemberProfileProps) {
  const initUpdatedMemberInfo = {
    profileImageSrc: member.profileImage,
    profileBannerSrc: member.profileBanner,
    companyLogoSrc: member.companyLogo,
    fullName: member.fullName,
    fullNameRef: useRef<HTMLInputElement>(null),
    designation: member.designation,
    designationRef: useRef<HTMLInputElement>(null),
    companyName: member.companyName,
    companyNameRef: useRef<HTMLInputElement>(null),
    companyAddress: member.companyAddress,
    companyAddressRef: useRef<HTMLInputElement>(null),
    subtitle: member.subtitle ?? "",
    subtitleRef: useRef<HTMLInputElement>(null),
  };

  const [updatedMemberInfo, setUpdatedMemberInfo] = useState<MemberUpdatedInfo>(
    initUpdatedMemberInfo
  );
  const [showPendingChagesDialog, setShowPendingChagesDialog] =
    useState<boolean>(false);
  const [thisTemplate, setThisTemplate] =
    useState<EditMemberProfileProps["template"]>();
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (member) {
      setUpdatedMemberInfo((prevState) => ({
        ...prevState,
        profileImageSrc: member.profileImage,
        profileImageObj: undefined,
        profileBannerSrc: member.profileBanner,
        profileBannerObj: undefined,
        companyLogoSrc: member.companyLogo,
        companyLogoObj: undefined,
        fullName: member.fullName,
        designation: member.designation,
        companyName: member.companyName,
        companyAddress: member.companyAddress,
        subtitle: member.subtitle,
      }));
    }
  }, [member]);

  useEffect(() => {
    setThisTemplate(template);
  }, [template]);

  useEffect(() => {
    if (thisTemplate) {
      onChange?.({
        ...updatedMemberInfo,
        profileImageSrc:
          thisTemplate.profileImage || updatedMemberInfo.profileImageSrc,
        companyLogoSrc:
          thisTemplate.companyLogo || updatedMemberInfo.companyLogoSrc,
        profileBannerSrc:
          thisTemplate.profileBanner || updatedMemberInfo.profileBannerSrc,
        companyAddress:
          thisTemplate.companyAddress || updatedMemberInfo.companyAddress,
        companyName: thisTemplate.companyName || updatedMemberInfo.companyName,
        subtitle: thisTemplate.subtitle || updatedMemberInfo.subtitle,
      });
    } else {
      onChange?.(updatedMemberInfo);
    }
  }, [thisTemplate, updatedMemberInfo, onChange]);

  function handleImageDropzoneChange(
    newImage: File | null,
    newDataUrl: string,
    type: MemberDataLabels
  ): void {
    const { success, message } = newImage
      ? validateImage({
          file: newImage,
          accept: AppConfigs.getAcceptedFileTypes(type),
          maxSize: AppConfigs.getMaximumFileSize(type),
        })
      : { success: true, message: "" };

    if (!success) {
      toast.error(message);
      return;
    }

    switch (type) {
      case MemberDataLabels.PROFILE_PICTURE:
        setUpdatedMemberInfo({
          ...updatedMemberInfo,
          profileImageObj: newImage,
          profileImageSrc: newDataUrl,
        });
        break;
      case MemberDataLabels.COMPANY_LOGO:
        setUpdatedMemberInfo({
          ...updatedMemberInfo,
          companyLogoObj: newImage,
          companyLogoSrc: newDataUrl,
        });
        break;
      case MemberDataLabels.COVER_PHOTO:
        setUpdatedMemberInfo({
          ...updatedMemberInfo,
          profileBannerObj: newImage,
          profileBannerSrc: newDataUrl,
        });
        break;
    }
  }

  useEffect(() => {
    const memberDataToUpdate: UpdateMemberDataRequestArgs =
      getUpdatedMemberData(updatedMemberInfo, member);
    setUnsavedChanges(Object.keys(memberDataToUpdate).length > 1);
  }, [updatedMemberInfo, member]);

  const [updateMemberData, { isLoading: isUpdateMemberDataPending }] =
    useUpdateMemberDataMutation();

  async function handleAboutSubmit() {
    const memberDataToUpdate: UpdateMemberDataRequestArgs =
      getUpdatedMemberData(updatedMemberInfo, member);
    if (Object.keys(memberDataToUpdate).length > 1) {
      try {
        const {
          success,
          message,
          data: { member: updatedMember },
        } = await updateMemberData(memberDataToUpdate).unwrap();
        if (success && updatedMember) {
          dispatch(editMember(updatedMember));
          toast.success(message);
        } else {
          toast.error(message);
        }
      } catch (error) {
        handleFetchError(error as FetchError);
      }
    }
  }

  useEffect(() => {
    setShowPendingChagesDialog(changed);
  }, [changed]);

  function handlePendingChagesConfirm(): void {
    handleAboutSubmit().then(() => {
      setShowPendingChagesDialog(false);
      onChangesSaved?.();
    });
  }

  function handlePendingChagesCancel(): void {
    setUpdatedMemberInfo(initUpdatedMemberInfo);
    setShowPendingChagesDialog(false);
    onChangesDiscarded?.();
  }

  return (
    <div className={`flex flex-col gap-7 ${className}`} {...props}>
      <div className="flex flex-col gap-8">
        <h4 className="text-xl font-semibold text-black">About</h4>
        <div className="flex flex-col gap-8">
          <div className="flex justify-between gap-4">
            <div className="flex flex-col items-center gap-3">
              <label className="flex items-center gap-2 text-xs text-gray-600">
                {MemberDataLabels.PROFILE_PICTURE}
                <InfoOutlinedIcon sx={{ width: 14, height: 14 }} />
              </label>
              {thisTemplate?.profileImage ? (
                <AvatarTemplate
                  src={thisTemplate.profileImage}
                  templateId={thisTemplate.id}
                  variant="avatar"
                  className="aspect-square h-28"
                />
              ) : (
                <ImageDropzone
                  accept={AppConfigs.getAcceptedFileTypes(
                    MemberDataLabels.PROFILE_PICTURE
                  )}
                  src={updatedMemberInfo.profileImageSrc}
                  onChange={(newImage, newDataUrl) =>
                    handleImageDropzoneChange(
                      newImage,
                      newDataUrl,
                      MemberDataLabels.PROFILE_PICTURE
                    )
                  }
                  variant="avatar"
                  maxWidth={AppConfigs.getMaximumWidth(
                    MemberDataLabels.PROFILE_PICTURE
                  )}
                  className="aspect-square h-28"
                />
              )}
            </div>
            <div className="flex flex-col items-center gap-3">
              <label className="flex items-center gap-2 text-xs text-gray-600">
                {MemberDataLabels.COMPANY_LOGO}
                <InfoOutlinedIcon sx={{ width: 14, height: 14 }} />
              </label>
              {thisTemplate?.companyLogo ? (
                <AvatarTemplate
                  src={thisTemplate.companyLogo}
                  templateId={thisTemplate.id}
                  variant="avatar"
                  className="aspect-square h-28"
                />
              ) : (
                <ImageDropzone
                  accept={AppConfigs.getAcceptedFileTypes(
                    MemberDataLabels.COMPANY_LOGO
                  )}
                  src={updatedMemberInfo.companyLogoSrc}
                  onChange={(newImage, newDataUrl) =>
                    handleImageDropzoneChange(
                      newImage,
                      newDataUrl,
                      MemberDataLabels.COMPANY_LOGO
                    )
                  }
                  variant="avatar"
                  maxWidth={AppConfigs.getMaximumWidth(
                    MemberDataLabels.COMPANY_LOGO
                  )}
                  className="aspect-square h-28"
                />
              )}
            </div>
            <div className="flex flex-col gap-3">
              <label className="flex items-center gap-2 text-xs text-gray-600">
                {MemberDataLabels.COVER_PHOTO}
                <InfoOutlinedIcon sx={{ width: 14, height: 14 }} />
              </label>
              {thisTemplate?.profileBanner ? (
                <AvatarTemplate
                  src={thisTemplate.profileBanner}
                  templateId={thisTemplate.id}
                  variant="banner"
                  className="aspect-[20/7] h-28"
                />
              ) : (
                <ImageDropzone
                  accept={AppConfigs.getAcceptedFileTypes(
                    MemberDataLabels.COVER_PHOTO
                  )}
                  src={updatedMemberInfo.profileBannerSrc}
                  onChange={(newImage, newDataUrl) =>
                    handleImageDropzoneChange(
                      newImage,
                      newDataUrl,
                      MemberDataLabels.COVER_PHOTO
                    )
                  }
                  variant="banner"
                  maxWidth={AppConfigs.getMaximumWidth(
                    MemberDataLabels.COVER_PHOTO
                  )}
                  className="aspect-[20/7] h-28"
                />
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8">
            <div>
              <FloatingLabel
                onInputValueChange={(value: string) =>
                  setUpdatedMemberInfo({
                    ...updatedMemberInfo,
                    fullName: value,
                  })
                }
              >
                <input
                  type="text"
                  ref={updatedMemberInfo.fullNameRef}
                  name="full-name"
                  placeholder={MemberDataLabels.CARD_TITLE}
                  value={updatedMemberInfo.fullName}
                />
              </FloatingLabel>
            </div>
            <div>
              {thisTemplate?.companyAddress ? (
                <FloatingLabel
                  icon={faAddressCard}
                  iconLink={`/templates/${thisTemplate.id}/edit`}
                  iconTitle={`This section comes from a template, \nand is unable to be edited.`}
                >
                  <input
                    type="text"
                    name="company-address"
                    placeholder={MemberDataLabels.LOCATION}
                    value={thisTemplate.companyAddress}
                    disabled
                  />
                </FloatingLabel>
              ) : (
                <FloatingLabel
                  onInputValueChange={(value: string) =>
                    setUpdatedMemberInfo({
                      ...updatedMemberInfo,
                      companyAddress: value,
                    })
                  }
                >
                  <input
                    type="text"
                    ref={updatedMemberInfo.companyAddressRef}
                    name="company-address"
                    placeholder={MemberDataLabels.LOCATION}
                    value={updatedMemberInfo.companyAddress}
                  />
                </FloatingLabel>
              )}
            </div>
            <div>
              <FloatingLabel
                onInputValueChange={(value: string) =>
                  setUpdatedMemberInfo({
                    ...updatedMemberInfo,
                    designation: value,
                  })
                }
              >
                <input
                  type="text"
                  ref={updatedMemberInfo.designationRef}
                  name="designation"
                  placeholder={MemberDataLabels.JOB_TITLE}
                  value={updatedMemberInfo.designation}
                />
              </FloatingLabel>
            </div>
            <div>
              {thisTemplate?.companyName ? (
                <FloatingLabel
                  icon={faAddressCard}
                  iconLink={`/templates/${thisTemplate.id}/edit`}
                  iconTitle={`This section comes from a template, \nand is unable to be edited.`}
                >
                  <input
                    type="text"
                    name="company-name"
                    placeholder={MemberDataLabels.COMPANY}
                    value={thisTemplate.companyName}
                    disabled
                  />
                </FloatingLabel>
              ) : (
                <FloatingLabel
                  onInputValueChange={(value: string) =>
                    setUpdatedMemberInfo({
                      ...updatedMemberInfo,
                      companyName: value,
                    })
                  }
                >
                  <input
                    type="text"
                    ref={updatedMemberInfo.companyNameRef}
                    name="company-name"
                    placeholder={MemberDataLabels.COMPANY}
                    value={updatedMemberInfo.companyName}
                  />
                </FloatingLabel>
              )}
            </div>
            <div className="col-span-2">
              {thisTemplate?.subtitle ? (
                <FloatingLabel
                  icon={faAddressCard}
                  iconLink={`/templates/${thisTemplate.id}/edit`}
                  iconTitle={`This section comes from a template, \nand is unable to be edited.`}
                >
                  <input
                    type="text"
                    name="subtitle"
                    placeholder={MemberDataLabels.SUBTITLE}
                    value={thisTemplate.subtitle}
                    disabled
                  />
                </FloatingLabel>
              ) : (
                <FloatingLabel
                  onInputValueChange={(value: string) =>
                    setUpdatedMemberInfo({
                      ...updatedMemberInfo,
                      subtitle: value,
                    })
                  }
                >
                  <input
                    type="text"
                    ref={updatedMemberInfo.subtitleRef}
                    name="subtitle"
                    placeholder={MemberDataLabels.SUBTITLE}
                    value={updatedMemberInfo.subtitle}
                  />
                </FloatingLabel>
              )}
            </div>
          </div>
          <div className="flex items-center justify-end gap-8 border-t border-gray-200 pt-8">
            <LoadingButton
              type="submit"
              startIcon={<FontAwesomeIcon icon={faSave} className="!text-sm" />}
              loading={isUpdateMemberDataPending}
              loadingPosition="start"
              disabled={!unsavedChanges}
              classes={{
                root: `!rounded-full ${
                  unsavedChanges ? "!bg-primary" : ""
                } !px-6 !py-3 !font-sans !text-sm !normal-case !text-white`,
                disabled: "!bg-gray-300",
                loadingIndicator: "!left-5",
              }}
              onClick={handleAboutSubmit}
            >
              Update
            </LoadingButton>
          </div>
          <ConfirmationDialog
            title="Save Pending Changes?"
            content="There are pending changes. Do you want to update them?"
            confirmButtonIcon={
              <FontAwesomeIcon
                icon={isUpdateMemberDataPending ? faSpinner : faSave}
                className="!text-sm"
              />
            }
            confirmButtonText="Update"
            onConfirm={handlePendingChagesConfirm}
            cancelButtonText="Discard"
            onCancel={handlePendingChagesCancel}
            open={showPendingChagesDialog}
          />
        </div>
      </div>
    </div>
  );
}

export default EditMemberProfile;
