import {
  faBorderAll,
  faFilter,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { MouseEvent, useRef, useState } from "react";
import { ViewAs } from "../features/members/membersEnums";
import FloatingLabel from "./FloatingLabel";

type MembersListControlsProps = {
  onViewChange?: (view: ViewAs) => void;
  onSearch?: (keyword: string) => void;
};

function MembersListControls({
  onViewChange,
  onSearch,
}: MembersListControlsProps) {
  const [view, setView] = useState<ViewAs>(ViewAs.GRID);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const searchMembersRef = useRef<HTMLInputElement>(null);

  function handleViewChange(_e: MouseEvent, newView: ViewAs) {
    newView && setView(newView);
    newView && onViewChange && onViewChange(newView);
  }

  return (
    <section className="flex items-center gap-2 md:gap-4 lg:gap-2 xl:gap-4">
      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={handleViewChange}
        aria-label="View results in Grid/List view"
        className="h-[3.25rem] !rounded-full border border-gray-200 bg-white"
      >
        <ToggleButton
          value={ViewAs.GRID}
          aria-label="Grid View"
          className="w-[3.25rem] !rounded-full !border-0 !text-black aria-pressed:scale-y-[1.0416] aria-pressed:!bg-black aria-pressed:!text-white"
        >
          <FontAwesomeIcon icon={faBorderAll} />
        </ToggleButton>
        <ToggleButton
          value={ViewAs.LIST}
          aria-label="List View"
          className="w-[3.25rem] !rounded-full !border-0 !text-black aria-pressed:scale-y-[1.0416] aria-pressed:!bg-black aria-pressed:!text-white"
        >
          <FontAwesomeIcon icon={faList} />
        </ToggleButton>
      </ToggleButtonGroup>
      <div>
        <button className="h-[3.25rem] w-[3.25rem] rounded-full border border-gray-200 bg-white text-sm text-black">
          <FontAwesomeIcon icon={faFilter} />
        </button>
      </div>
      <FloatingLabel
        onInputValueChange={setSearchKeyword}
        onSearchClick={onSearch}
        className="w-48 md:w-auto"
      >
        <input
          type="search"
          ref={searchMembersRef}
          name="search-members"
          placeholder="Search"
          value={searchKeyword}
          onKeyUp={() => onSearch?.(searchKeyword)}
        />
      </FloatingLabel>
    </section>
  );
}

export default MembersListControls;
