import { createApi } from "@reduxjs/toolkit/query/react";
import APIConfigs from "../../configs/apiConfigs";
// eslint-disable-next-line
import { baseQueryWithReauth } from "../rtk-query/baseQuery";
import { mapLoginResponse } from "./authMapper";
import type {
  LoginRequestArgs,
  LoginResponse,
  LogoutResponse,
} from "./authTypes";

const authService = createApi({
  reducerPath: "authService",
  baseQuery: baseQueryWithReauth,
  endpoints: (_builder) => ({}),
});

const authenticateUser = authService.injectEndpoints({
  endpoints: (builder) => ({
    logIn: builder.mutation<LoginResponse, LoginRequestArgs>({
      query: ({ email, password }) => ({
        method: "POST",
        url: APIConfigs.Authentication.LOGIN,
        body: { email, password },
      }),
      transformResponse: mapLoginResponse,
    }),
    logOut: builder.mutation<LogoutResponse, void>({
      query: () => ({
        method: "GET",
        url: APIConfigs.Authentication.LOGOUT,
      }),
    }),
  }),
});

export default authService;
export const { useLogInMutation, useLogOutMutation } = authenticateUser;
