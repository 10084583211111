import { useEffect } from "react";
import { toast } from "react-toastify";
import { removeLinksByMemberIds } from "../features/links";
import {
  editMembers,
  getMembers,
  Member,
  removeMembers,
  removeMemberSettingsByMemberIds,
  setMembers,
  setMemberSettings,
  useDeleteMembersMutation,
  useGetAllMembersMutation,
} from "../features/members";
import { useUpdateMembersRoleMutation } from "../features/settings/settingsServices";
import { removeTemplateAssignees } from "../features/templates/templatesSlice";
import { UserRole } from "../features/users/usersEnums";
import { useAppDispatch, useAppSelector } from "../store";
import { FetchError, handleFetchError } from "../utils";

type UseMembersProps = {
  filters?: { [K in keyof Member]?: Member[K] | Array<Member[K]> };
  keyword?: string;
};

function useMembers(props?: UseMembersProps) {
  const { filters, keyword } = props || {};

  const dispatch = useAppDispatch();

  const members = useAppSelector(getMembers);

  const [getAllMembers, { isLoading: isGetAllMembersPending }] =
    useGetAllMembersMutation();

  useEffect(() => {
    if (!members) {
      (async () => {
        try {
          const {
            success,
            message,
            data: { members: allMembers, settings },
          } = await getAllMembers().unwrap();
          if (success) {
            dispatch(setMembers(allMembers));
            dispatch(setMemberSettings(settings));
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    }
  }, [members, getAllMembers, dispatch]);

  const [deleteMembers, { isLoading: isDeleteMembersPending }] =
    useDeleteMembersMutation();

  async function deleteMembersByIds(idsToDelete: Array<Member["id"]>) {
    if (idsToDelete.length) {
      try {
        const { success, message } = await deleteMembers({
          ids: idsToDelete,
          reason: "Deleted by Admin.",
        }).unwrap();
        if (success) {
          dispatch(removeMembers(idsToDelete));
          dispatch(removeLinksByMemberIds(idsToDelete));
          dispatch(removeMemberSettingsByMemberIds(idsToDelete));
          dispatch(removeTemplateAssignees(idsToDelete));
        }
        return { success, message };
      } catch (error) {
        return {
          success: false,
          message: "An error occurred while deleting members.",
        };
      }
    } else {
      return { success: false, message: "No Member to delete." };
    }
  }

  const [updateMembersRole, { isLoading: isUpdateMembersRolePending }] =
    useUpdateMembersRoleMutation();

  async function updateMembersRolesByIds(
    ids: Array<Member["id"]>,
    role: UserRole
  ) {
    if (ids.length) {
      try {
        const {
          success,
          message,
          data: updatedMembers,
        } = await updateMembersRole({ ids, role }).unwrap();
        if (success) {
          dispatch(editMembers(updatedMembers));
        }
        return { success, message };
      } catch (error) {
        return {
          success: false,
          message: "An error occurred while updating members' role.",
        };
      }
    } else {
      return { success: false, message: "No Member to delete." };
    }
  }

  function getFilteredMembers(
    members?: Member[],
    filters?: { [K in keyof Member]?: Member[K] | Array<Member[K]> },
    keyword?: string
  ): Member[] | undefined {
    filters = filters && Object.keys(filters).length > 0 ? filters : undefined;
    keyword = keyword && keyword.length >= 3 ? keyword : undefined;

    if (!members || (!filters && !keyword)) {
      return members;
    } else if (filters && !keyword) {
      return members.filter((m) => {
        if (!filters) return true;
        return Object.entries(filters).every(([key, value]) => {
          if (Array.isArray(value)) {
            return value.includes(m[key as keyof Member] as never);
          }
          return m[key as keyof Member] === value;
        });
      });
    } else if (!filters && keyword) {
      return members.filter((m) => {
        if (!keyword) return true;
        return (
          m.fullName?.toLowerCase().includes(keyword.toLowerCase()) ||
          m.username?.toLowerCase().includes(keyword.toLowerCase()) ||
          m.email?.toLowerCase().includes(keyword.toLowerCase())
        );
      });
    } else if (filters && keyword) {
      return members.filter((m) => {
        if (!keyword || !filters) return true;
        return (
          Object.entries(filters).every(([key, value]) => {
            if (Array.isArray(value)) {
              return value.includes(m[key as keyof Member] as never);
            }
            return m[key as keyof Member] === value;
          }) &&
          (m.fullName?.toLowerCase().includes(keyword.toLowerCase()) ||
            m.username?.toLowerCase().includes(keyword.toLowerCase()) ||
            m.email?.toLowerCase().includes(keyword.toLowerCase()))
        );
      });
    }
  }

  return {
    members: getFilteredMembers(members, filters, keyword),
    deleteMembers: deleteMembersByIds,
    addMembersAsAdmin: (ids: Array<Member["id"]>) =>
      updateMembersRolesByIds(ids, UserRole.BUSINESS_ADMIN),
    removeMembersAsAdmin: (ids: Array<Member["id"]>) =>
      updateMembersRolesByIds(ids, UserRole.BUSINESS_USER),
    isLoading:
      isGetAllMembersPending ||
      isDeleteMembersPending ||
      isUpdateMembersRolePending,
  };
}

export default useMembers;
