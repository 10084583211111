import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { ConfirmationDialog, Spinner } from "../../components";
import APIConfigs from "../../configs/apiConfigs";
import useMember from "../../hooks/useMember";
import type { MemberDevice } from "../devices/devicesTypes";
import type { Member } from "../members/membersTypes";

type ManageDevicesProps = {
  member: Member;
  devices?: MemberDevice[];
  className?: string;
};

function ManageDevices({
  member,
  devices,
  className,
  ...props
}: ManageDevicesProps) {
  const [showConfirmationDialog, setConfirmationDialog] =
    useState<boolean>(false);
  const [deviceIdToUnmap, setDeviceIdToUnmap] = useState<MemberDevice["id"]>();

  const { unmapDevices, isLoading } = useMember(member.id);

  function confirmUnmapDevice(id: any) {
    setDeviceIdToUnmap(id);
    setConfirmationDialog(true);
  }

  async function handleConfirmUnmapDevice() {
    if (!deviceIdToUnmap) return;
    setConfirmationDialog(false);

    const { success, message } = await unmapDevices([deviceIdToUnmap]);
    if (success) {
      toast.success(message);
    } else {
      toast.error(message);
    }

    setDeviceIdToUnmap(undefined);
  }

  function handleCancelUnmapDevice() {
    setDeviceIdToUnmap(undefined);
    setConfirmationDialog(false);
  }

  return !devices ? (
    <div className="relative h-2/3">
      <Spinner className="bg-transparent" />
    </div>
  ) : devices.length > 0 ? (
    <section className={`flex flex-col gap-8 ${className ?? ""}`} {...props}>
      <div>
        <h4 className="text-xl font-semibold text-black">Manage Devices</h4>
        <p className="text-sm text-gray-500">
          The devices assigned to this member are listed below. You can unmap
          any device.
        </p>
      </div>
      <List className="flex flex-col gap-4 !p-0">
        {devices.map((aDevice) => (
          <ListItem
            key={`member-device-${aDevice.id}`}
            secondaryAction={
              <IconButton
                aria-label="Unmap Device"
                onClick={() => confirmUnmapDevice(aDevice.id)}
              >
                <DeleteOutlinedIcon className="!text-xl text-error-500" />
              </IconButton>
            }
            className="!items-start !justify-between gap-3 rounded-2xl bg-gray-50 !p-4"
          >
            <ListItemAvatar>
              <Avatar
                variant="rounded"
                alt={aDevice.name}
                src={aDevice.productImage}
                className="!h-[3.375rem] !w-[3.375rem] !rounded-[0.658rem]"
              />
            </ListItemAvatar>
            <div className="grow py-1">
              <Typography className="!font-sans !text-sm text-gray-400">
                <strong
                  aria-label="Product Name"
                  className="text-base font-semibold text-black"
                >
                  {aDevice.name}
                </strong>
                {aDevice.name && aDevice.serialNumber && " \u2014 "}
                <span aria-label="Serial Number">{aDevice.serialNumber}</span>
              </Typography>
              <Typography className="!font-sans !text-sm text-gray-400">
                <span aria-label="Product Type" className="text-gray-800">
                  {aDevice.productType}
                </span>
                {aDevice.productType && aDevice.activatedOn && ` | `}
                <span aria-label="Activation Date">
                  {new Date(aDevice.activatedOn).toLocaleString("en-US", {
                    dateStyle: "long",
                  })}
                </span>
              </Typography>
              {/* {(aDevice.lastScannedOn || aDevice.lastScannedAt) && (
                <Typography className="!font-sans">
                  <small className="text-xs text-gray-300">
                    {`This device was last scanned ${
                      aDevice.lastScannedOn
                        ? `on ${new Date(aDevice.lastScannedOn).toLocaleString(
                            "en-US",
                            {
                              dateStyle: "long",
                              timeStyle: "short",
                              hour12: true,
                            }
                          )}`
                        : ""
                    } ${
                      aDevice.lastScannedAt ? `at ${aDevice.lastScannedAt}` : ""
                    }`}
                  </small>
                </Typography>
              )} */}
            </div>
            {isLoading && aDevice.id === deviceIdToUnmap && (
              <Spinner className="rounded-2xl bg-error-300/30" />
            )}
          </ListItem>
        ))}
      </List>
      <ConfirmationDialog
        title="Confirm Deletion"
        content="Are you sure you want to unmap this device? This action cannot be undone."
        confirmButtonText="Confirm"
        onConfirm={handleConfirmUnmapDevice}
        onCancel={handleCancelUnmapDevice}
        open={showConfirmationDialog}
        danger
      />
    </section>
  ) : (
    <div className={`flex flex-col gap-8 ${className ?? ""}`} {...props}>
      <h4 className="text-xl font-semibold text-black">Add Devices</h4>
      <div className="flex flex-col items-center justify-center gap-8 rounded-[1.25rem] bg-gray-100 p-16">
        <h5 className="text-xl font-semibold text-black">
          {`Activate devices to ${
            member.firstName || member.fullName || member.username
          }'s profile`}
        </h5>
        <div className="flex rounded-xl bg-white p-8 shadow-2xl shadow-gray-900/20">
          <img
            src={`${APIConfigs.API_BASE_URL}/members/${member.id}/qrcode`}
            alt={`QR Code for ${
              member.firstName || member.fullName || member.username
            }'s profile`}
            className="aspect-square w-56"
          />
        </div>
        <div className="flex w-80 flex-col gap-6 text-center text-gray-700">
          <p>
            Scan the AddMee code with your phone to launch the activation flow
          </p>
          <p>Use your app to activate devices</p>
        </div>
      </div>
    </div>
  );
}

export default ManageDevices;
