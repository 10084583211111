import {
  IconDefinition,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-regular-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ChangeEvent,
  cloneElement,
  LabelHTMLAttributes,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";

interface FloatingLabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  size?: "small" | "medium" | "large";
  icon?: IconDefinition;
  iconLink?: string;
  iconTitle?: string;
  children: ReactElement | ReactElement[];
  onInputValueChange?: (changedValue: string) => void;
  onSearchClick?: (keyword: string) => void;
}

function FloatingLabel({
  size = "medium",
  icon,
  iconLink,
  iconTitle,
  children,
  onInputValueChange,
  onSearchClick,
  className = "",
  ...props
}: FloatingLabelProps) {
  const [clonedValue, setClonedValue] = useState<string>("");
  const [clonedType, setClonedType] = useState<string>("text");
  const [focus, setFocus] = useState<boolean>(false);

  children = !Array.isArray(children) ? [children] : children;
  const inputElIndex: number = children.findIndex((c) => c.type === "input");
  const inputEl: ReactElement = children[inputElIndex];
  const otherEls: ReactElement[] = children.filter(
    (c, i) => i !== inputElIndex
  );

  useEffect(() => {
    setClonedValue(inputEl?.props?.value);
  }, [inputEl?.props?.value]);

  useEffect(() => {
    setClonedType(inputEl?.props?.type);
  }, [inputEl?.props?.type]);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setClonedValue(e.target.value);
    onInputValueChange?.(e.target.value);
  }

  return (
    <label
      className={`relative flex rounded-full bg-white ${className}`}
      {...props}
    >
      <span
        className={`absolute ${
          size === "small" ? "left-4" : "left-6"
        } transition-all ${
          focus || clonedValue
            ? "-top-2.5 z-20 bg-white px-2 py-0.5 text-xs text-gray-600"
            : `${
                size === "small" ? "top-2" : "top-4"
              } z-0 text-sm text-gray-400`
        }`}
      >
        {inputEl?.props?.placeholder}
      </span>
      {inputEl &&
        cloneElement(inputEl, {
          className: `relative z-10 ${
            size === "small" ? "h-9" : "h-[3.25rem]"
          } w-full rounded-full border-2 border-gray-200 bg-transparent ${
            size === "small" ? "px-4 py-2" : "px-6 py-4"
          } text-sm text-black disabled:text-gray-500 placeholder-transparent focus-visible:border-gray-600 outline-0 ${
            inputEl?.props?.type === "password" && "pr-12"
          } ${inputEl?.props?.className}`,
          type: clonedType,
          name: inputEl?.props?.name,
          value: clonedValue,
          onChange: handleChange,
          onFocus: () => setFocus(true),
          onBlur: () => setFocus(false),
        })}
      {inputEl?.props?.type === "password" && (
        <>
          <FontAwesomeIcon
            icon={faEye}
            className={`absolute ${
              size === "small" ? "right-4 top-3" : "right-6 top-5"
            } z-20 cursor-pointer text-sm text-black ${
              clonedType === "text" && "hidden"
            }`}
            onClick={() => setClonedType("text")}
          />
          <FontAwesomeIcon
            icon={faEyeSlash}
            className={`absolute ${
              size === "small" ? "right-4 top-3" : "right-6 top-5"
            } z-20 cursor-pointer text-sm text-black ${
              clonedType === "password" && "hidden"
            }`}
            onClick={() => setClonedType("password")}
          />
        </>
      )}
      {inputEl?.props?.type === "search" && (
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className={`absolute ${
            size === "small" ? "right-4 top-3" : "right-6 top-5"
          } z-20 cursor-pointer text-sm text-black`}
          onClick={() => onSearchClick && onSearchClick(clonedValue)}
        />
      )}
      {icon &&
        inputEl?.props?.type !== "password" &&
        inputEl?.props?.type !== "search" &&
        (iconLink ? (
          <Link
            to={iconLink}
            title={iconTitle}
            className={`absolute ${
              size === "small" ? "right-4 top-3" : "right-6 top-5"
            } z-20 flex`}
          >
            <FontAwesomeIcon icon={icon} className="text-sm text-black" />
          </Link>
        ) : (
          <FontAwesomeIcon
            icon={icon}
            title={iconTitle}
            className={`absolute ${
              size === "small" ? "right-4 top-3" : "right-6 top-5"
            } z-20 cursor-pointer text-sm text-black`}
          />
        ))}
      {otherEls}
    </label>
  );
}

export default FloatingLabel;
