import { faGear, faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DevTool } from "@hookform/devtools";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import HelpIcon from "@mui/icons-material/Help";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  MissingMembersDialog,
  Spinner,
  SwitchX,
  SyncADConfigsDialog,
  SyncADMembers,
} from "../../components";
import useIntegrations from "../../hooks/useIntegrations";
import { RootState, useAppSelector } from "../../store";
import { FormControlMultiSelect } from "../../styles/mui-styled";
import { FetchError, handleFetchError } from "../../utils";
import ActiveDirectoryIntegration from "../integrations/ActiveDirectoryIntegration";
import {
  ADGroup,
  ADUser,
  MemberButtonADFieldLabelsMap,
  Platforms,
  defaultSyncADConfigs,
  getIntegrationsByPlatformName,
  getPlatformByName,
  useReadADGroupsUsersMutation,
} from "../integrations";
import type { Member } from "../members/membersTypes";
import type { Template } from "../templates/templatesTypes";
import useTemplates from "../../hooks/useTemplates";
import { useKeepMissingUsersMutation } from "./addMembersService";
import type {
  AddMembersByADFormData,
  SyncADSubmitData,
} from "./addMembersTypes";

type SyncActiveDirectoryProps = {
  templateId?: Template["id"];
  onSubmit?: (data: SyncADSubmitData, onSuccess?: Function) => void;
  inProgress?: boolean;
  isSuccess?: boolean;
  className?: string;
};

function SyncActiveDirectory({
  templateId,
  onSubmit,
  inProgress = false,
  isSuccess = false,
  className = "",
  ...props
}: SyncActiveDirectoryProps) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogOpenFlag, setDialogOpenFlag] = useState<boolean>(false);
  const [adGroups, setADGroups] = useState<ADGroup[]>([]);
  const [adSelectedGroups, setAdSelectedGroups] = useState<ADGroup[]>([]);
  const [adUsers, setADUsers] = useState<ADUser[]>([]);
  const [filteredADUsers, setFilteredADUsers] = useState<ADUser[]>([]);
  const [missingMemberIdsToDelete, setMissingMemberIdsToDelete] = useState<
    Array<Member["id"]>
  >([]);

  const { isLoading: isIntegrationsLoading } = useIntegrations();
  const { templateLinks, isLoading: isTemplatesLoading } = useTemplates();

  const activeDirectoryPlatform = useAppSelector((state: RootState) =>
    getPlatformByName(state, Platforms.ACTIVE_DIRECTORY)
  );

  const activeDirectoryIntegration = useAppSelector(
    (state: RootState) =>
      getIntegrationsByPlatformName(state, Platforms.ACTIVE_DIRECTORY)?.[0]
  );

  const [readADGroupsUsers, { isLoading: isReadADGroupsUsersLoading }] =
    useReadADGroupsUsersMutation();

  useEffect(() => {
    if (activeDirectoryIntegration) {
      (async function () {
        try {
          const {
            success,
            message,
            data: {
              adGroups: thisADGroups,
              adUsers: thisADUsers,
              missingMemberIds,
            },
          } = await readADGroupsUsers(activeDirectoryIntegration.id).unwrap();
          if (success) {
            setADGroups(thisADGroups);
            setADUsers(thisADUsers);
            if (missingMemberIds?.length) {
              setMissingMemberIdsToDelete(missingMemberIds);
            }
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    }
  }, [activeDirectoryIntegration, readADGroupsUsers]);

  useEffect(() => {
    if (adSelectedGroups.length) {
      setFilteredADUsers(
        adUsers.filter(
          (aUser) =>
            !aUser.memberId &&
            aUser.groupIds?.some((aGroupId) =>
              adSelectedGroups.some((group) => group.id === aGroupId)
            )
        )
      );
    } else {
      setFilteredADUsers(adUsers.filter((aUser) => !aUser.memberId));
    }
  }, [adSelectedGroups, adUsers]);

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<AddMembersByADFormData>({
    defaultValues: {
      selectedADUserIds: [],
      settings: defaultSyncADConfigs,
      doSendInvites: true,
    },
  });

  useEffect(() => {
    if (templateId) {
      MemberButtonADFieldLabelsMap.forEach((_, aMemberProp) => {
        setValue(
          `settings.propsToSync.${aMemberProp}.templateButtonId`,
          undefined,
          { shouldDirty: true }
        );
      });
    }
  }, [templateId, setValue]);

  function handleSelectionChange(
    rowSelectionModel: GridRowSelectionModel
  ): void {
    setValue("selectedADUserIds", rowSelectionModel as Array<ADUser["id"]>, {
      shouldDirty: true,
    });
  }

  function handleSyncNowSubmit({
    selectedADUserIds,
    settings,
    doSendInvites,
  }: AddMembersByADFormData) {
    if (!activeDirectoryIntegration || !onSubmit) return;

    onSubmit(
      {
        integrationId: activeDirectoryIntegration.id,
        selectedADUsers: adUsers.filter((aUser) =>
          selectedADUserIds.includes(aUser.id)
        ),
        settings,
        doSendInvites,
      },
      () => {
        reset(getValues());
      }
    );
  }

  const [keepMissingUsers] = useKeepMissingUsersMutation();

  async function keepAllMissingMembers(memberIdsToKeep: Array<Member["id"]>) {
    if (!activeDirectoryIntegration || memberIdsToKeep.length < 1) return;

    try {
      const { success, message } = await keepMissingUsers({
        integrationId: activeDirectoryIntegration.id,
        memberIdsToKeep,
      }).unwrap();
      if (success) {
        setMissingMemberIdsToDelete([]);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  return (
    <div className={`flex min-h-full flex-col gap-4 ${className}`} {...props}>
      <div className="flex flex-col">
        <h4 className="text-xl font-semibold text-black">
          Sync with Active Directory
        </h4>
        <p className="text-sm text-gray-500">
          Sync Microsoft Active Directory Members to AddMee. Only the AD Members
          with an Email or Other emails are listed below.
        </p>
      </div>
      {isTemplatesLoading ||
      isIntegrationsLoading ||
      isReadADGroupsUsersLoading ? (
        <Spinner className="relative h-full grow rounded-3xl bg-gray-50/50" />
      ) : activeDirectoryIntegration ? (
        <>
          <MissingMembersDialog
            ids={missingMemberIdsToDelete}
            open={missingMemberIdsToDelete.length > 0}
            onDelete={() => setMissingMemberIdsToDelete([])}
            onKeepAll={keepAllMissingMembers}
            onClose={() => setMissingMemberIdsToDelete([])}
            className="!max-w-3xl"
          />
          <div className="relative flex flex-col gap-4">
            <FormControlMultiSelect className="gap-2">
              <Autocomplete
                multiple
                id="sync-groups"
                options={adGroups}
                value={adSelectedGroups}
                onChange={(_, v) => setAdSelectedGroups(v)}
                disableCloseOnSelect
                getOptionLabel={(option) => option.displayName}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selected}
                          classes={{
                            colorPrimary: "!text-gray-500",
                            checked: "!text-gray-900",
                          }}
                        />
                      }
                      label={option.displayName}
                    />
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by Group(s)"
                    placeholder="Favorites"
                  />
                )}
              />
            </FormControlMultiSelect>
            <SyncADMembers
              adUsers={filteredADUsers}
              onSelectionChange={handleSelectionChange}
            />
            <p className="flex items-center text-gray-400">
              <HelpIcon fontSize="small" className="mr-2" />
              <small>
                <b>1.</b> Select AD Users
              </small>
              <ArrowRightIcon />
              <small>
                <b>2.</b> Configure
              </small>
              <ArrowRightIcon />
              <small>
                <b>3.</b> Sync Now
              </small>
            </p>
          </div>
          <div className="flex flex-col items-center justify-end gap-4 sm:flex-row">
            <div className="grow">
              <Controller
                name="doSendInvites"
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <SwitchX
                    name={name}
                    checked={value}
                    onChange={onChange}
                    label="Send email invites"
                  />
                )}
              />
            </div>
            <Button
              variant="outlined"
              startIcon={<FontAwesomeIcon icon={faGear} className="!text-sm" />}
              onClick={() => {
                setDialogOpen(true);
                setDialogOpenFlag(true);
              }}
              className={`!m-0 ${
                !dialogOpenFlag &&
                getValues().selectedADUserIds.length !== 0 &&
                "animate-bounce"
              } !rounded-full !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-black`}
            >
              Configure
            </Button>
            <SyncADConfigsDialog
              links={
                templateId
                  ? templateLinks?.filter(
                      (tl) => tl.templateId === templateId && tl.isUnique
                    )
                  : undefined
              }
              data={getValues("settings")}
              onSave={(data) => {
                setValue("settings", data, { shouldDirty: true });
                setDialogOpen(false);
              }}
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
            />
            <LoadingButton
              type="submit"
              startIcon={
                <FontAwesomeIcon icon={faRotate} className="!text-sm" />
              }
              loading={inProgress}
              loadingPosition="start"
              disabled={getValues().selectedADUserIds.length === 0 || !isDirty}
              onClick={handleSubmit(handleSyncNowSubmit)}
              classes={{
                root: `!rounded-full ${
                  getValues().selectedADUserIds.length > 0 &&
                  isDirty &&
                  "!bg-primary"
                } !px-6 !py-3 !font-sans !text-sm !normal-case !text-white`,
                disabled: "!bg-gray-300",
                loadingIndicator: "!left-5",
              }}
            >
              Sync Now
            </LoadingButton>
          </div>
        </>
      ) : (
        <>
          {activeDirectoryPlatform && (
            <ActiveDirectoryIntegration
              platform={activeDirectoryPlatform}
              className="w-1/2 border border-gray-200"
            />
          )}
        </>
      )}
      <DevTool control={control} />
    </div>
  );
}

export default SyncActiveDirectory;
