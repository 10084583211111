import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { Member } from "../features/members/membersTypes";
import useMembers from "../hooks/useMembers";
import useTemplates from "../hooks/useTemplates";
import theme from "../styles/mui-styled";
import DialogX, { DialogXProps } from "./DialogX";
import MembersSelectList from "./MembersSelectList";

type MissingMembersDialogProps = DialogXProps & {
  ids: Array<Member["id"]>;
  onDelete?: (ids: Array<Member["id"]>) => void;
  onKeepAll?: (ids: Array<Member["id"]>) => void;
  onClose?: () => void;
};

function MissingMembersDialog({
  ids = [],
  onDelete,
  onKeepAll,
  className = "",
  ...props
}: MissingMembersDialogProps) {
  const [selectedMemberIds, setSelectedMemberIds] = useState<
    Array<Member["id"]>
  >([]);

  const {
    members,
    deleteMembers,
    isLoading: isMembersLoading,
  } = useMembers({
    filters: { id: ids },
  });
  const { templates, isLoading: isTemplatesLoading } = useTemplates();

  async function deleteSelectedMembers() {
    const { success, message } = await deleteMembers(selectedMemberIds);
    if (success) {
      toast.success(message);
      onDelete?.(selectedMemberIds);
    } else {
      toast.error(message);
    }
  }

  return (
    <DialogX
      className={`relative flex flex-col overflow-hidden ${className}`}
      fullScreen
      {...props}
    >
      <div className="flex flex-col gap-0.5">
        <DialogTitle className="!p-0 !font-sans !font-semibold text-black">
          Missing Members in Active Directory
        </DialogTitle>
        <p className="text-sm text-gray-500">
          The following members are missing in the Active Directory. Please
          select the members you want to delete. All the rest will be kept as
          unsynced members.
        </p>
      </div>
      <DialogContent className="flex flex-col !p-0">
        <MembersSelectList
          members={
            members?.map((m) => ({
              ...m,
              templateName: templates?.find((t) => t.assigneeIds.includes(m.id))
                ?.name,
            })) || []
          }
          title="Select All"
          emptyListMessage="No member found missing."
          onSelect={setSelectedMemberIds}
          loading={(!members && isMembersLoading) || isTemplatesLoading}
          danger
          className="flex flex-col"
        />
      </DialogContent>
      <DialogActions className="gap-4 !border-t border-gray-200 !p-0 !pt-4">
        <Button
          variant="outlined"
          onClick={() => onKeepAll?.(ids)}
          className="!m-0 !rounded-full !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-black"
        >
          Keep All
        </Button>
        <LoadingButton
          type="submit"
          startIcon={<GroupRemoveIcon />}
          loadingPosition="start"
          loading={members && isMembersLoading}
          onClick={deleteSelectedMembers}
          disabled={!selectedMemberIds.length}
          classes={theme.LoadingButtonXDanger}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </DialogX>
  );
}

export default MissingMembersDialog;
