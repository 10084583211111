import {
  faAddressCard,
  // faBell,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import {
  // faChartLine,
  faGear,
  // faLaptop,
  faNetworkWired,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import packageJson from "../../package.json";
import { ReactComponent as Logo } from "../assets/images/addmee_logo.svg";
import LogoMini from "../assets/images/addmee_logo_mini.png";
import Navigation, { NavigationItem } from "../components/Navigation";
import { getCurrentUser } from "../features/users/usersSlice";
import { useAppSelector } from "../store";

type NavigationMenuProps = {
  onLogOut?: () => void;
  className?: string;
};

function NavigationMenu({
  onLogOut,
  className,
  ...props
}: NavigationMenuProps) {
  const currentUser = useAppSelector(getCurrentUser);

  const navItems: NavigationItem[] = [
    { path: "/members", name: "Members", icon: faUser },
    { path: "/templates", name: "Templates", icon: faAddressCard },
    // { path: "/", name: "Devices", icon: faLaptop },
    // { path: "/", name: "Insights", icon: faChartLine },
    { path: "/integrations", name: "Integrations", icon: faNetworkWired },
    // { path: "/", name: "Notifications", icon: faBell },
    { path: "/settings", name: "Settings", icon: faGear },
  ];

  return (
    <header
      className={`relative flex flex-col justify-between gap-12 bg-white px-3 py-8 lg:px-6 ${
        className ?? ""
      }`}
      {...props}
    >
      <Link to="/" className="flex w-full max-w-[8.5rem]">
        <Logo className="hidden lg:block" />
        <img src={LogoMini} alt="AddMee" className="block grow lg:hidden" />
      </Link>
      <code className="absolute bottom-0 left-0 text-xs text-gray-400">
        v{packageJson.version}
      </code>
      <Navigation items={navItems} className="grow" />
      <div className="flex flex-col justify-between gap-2 border-t border-gray-200 pt-4 lg:flex-row lg:items-center lg:gap-0">
        <Link
          to="/"
          className="flex grow gap-3 hover:rounded-xl hover:bg-slate-50"
        >
          <Avatar
            src={currentUser?.profileImage}
            className="flex h-10 w-10 items-center justify-center hover:scale-110"
          />
          <span className="grid-col hidden pr-3 text-sm text-gray-600 lg:grid">
            <strong className="truncate font-semibold">
              {currentUser?.firstName}
            </strong>
            <span className="truncate capitalize">{currentUser?.role}</span>
          </span>
        </Link>
        {onLogOut && (
          <button
            onClick={onLogOut}
            className="block p-2 text-error-400 hover:rounded-xl hover:bg-slate-50 lg:px-3"
            title="Log out"
          >
            <FontAwesomeIcon icon={faRightFromBracket} />
          </button>
        )}
      </div>
    </header>
  );
}

export default NavigationMenu;
