import type {
  GetBusinessProfileAPIResponse,
  GetBusinessProfileResponse,
} from "../users/usersTypes";
import type { LoginAPIResponse, LoginResponse } from "./authTypes";

export function mapLoginResponse(response: LoginAPIResponse): LoginResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      isFirstLogin: Boolean(response.data.user.first_login),
      accessToken: response.data.access_token,
    },
  };
}

export function mapGetBusinessProfileResponse(
  response: GetBusinessProfileAPIResponse
): GetBusinessProfileResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      user: {
        id: response.data.user.id,
        email: response.data.user.email,
        firstName:
          response.data.user.first_name ??
          response.data.user.last_name ??
          response.data.user.username,
        profileImage: response.data.user.profile_image,
        role: response.data.user.role,
      },
    },
  };
}
