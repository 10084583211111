import { faArrowLeft, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreditCardOffOutlinedIcon from "@mui/icons-material/CreditCardOffOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AlertDialog,
  ConfirmationDialog,
  MoreOptionsItem,
  MoreOptionsMenu,
} from "../../components";
import { useAppDispatch } from "../../store";
import { FetchError, handleFetchError } from "../../utils";
import { removeLinks } from "../links/linksSlice";
import {
  Template,
  removeTemplate,
  useDeleteTemplateMutation,
} from "../templates";

type EditTemplateHeaderProps = {
  template: Template;
  className?: string;
};

function EditTemplateHeader({
  template,
  className = "",
  ...props
}: EditTemplateHeaderProps) {
  const [deleteTemplateDialogShow, setDeleteTemplateDialogShow] =
    useState<boolean>(false);
  const [defaultTemplateDialogShow, setDefaultTemplateDialogShow] =
    useState<boolean>(false);

  const navigateTo = useNavigate();
  const dispatch = useAppDispatch();

  const [deleteTemplate, { isLoading: isDeleteTemplatePending }] =
    useDeleteTemplateMutation();

  async function handleDeleteTemplateConfirm() {
    try {
      const {
        success,
        message,
        data: { removedTemplateId, removedMemberLinkIds },
      } = await deleteTemplate(Number(template.id)).unwrap();
      if (success) {
        dispatch(removeTemplate(removedTemplateId));
        dispatch(removeLinks(removedMemberLinkIds));
        navigateTo("/templates");
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }

    setDeleteTemplateDialogShow(false);
  }

  return (
    <div className={`flex h-[7.25rem] gap-6 p-8 ${className}`} {...props}>
      <Link
        to="/templates"
        aria-label="Go back to Templates list page"
        className="flex w-[7.5rem] items-center justify-center gap-2 rounded-full border border-gray-200 bg-white text-sm text-black"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        <span className="font-semibold">Back</span>
      </Link>
      <div className="flex grow border-l border-gray-200">
        <div className="ml-6 flex items-center gap-3">
          <Avatar
            src={template.profileImage}
            alt={template.name}
            classes={{ root: "!h-[3.125rem] !w-[3.125rem]" }}
          />
          <strong className="max-w-[10.5rem] grow overflow-hidden text-ellipsis whitespace-nowrap text-lg md:max-w-[18.5rem] lg:max-w-sm xl:max-w-2xl 2xl:max-w-4xl">
            {template.name}
          </strong>
        </div>
      </div>
      <MoreOptionsMenu
        icon={<MoreVertIcon className="!text-xl" />}
        className="border !border-gray-200 !bg-white !text-black"
      >
        <MoreOptionsItem
          icon={<CreditCardOffOutlinedIcon className="!text-base" />}
          label="Remove Template"
          onClick={() =>
            template.isDefault
              ? setDefaultTemplateDialogShow(true)
              : setDeleteTemplateDialogShow(true)
          }
          danger
        />
      </MoreOptionsMenu>
      <AlertDialog
        title="This is the Default Template!"
        content="You need to set another template as default before removing this template."
        onConfirm={() => setDefaultTemplateDialogShow(false)}
        show={defaultTemplateDialogShow}
      />
      <ConfirmationDialog
        title="Confirm Removal"
        content="Are you sure you want to remove this template?"
        confirmButtonIcon={
          isDeleteTemplatePending ? (
            <FontAwesomeIcon icon={faSpinner} className="!text-sm" />
          ) : (
            <CreditCardOffOutlinedIcon className="!text-sm" />
          )
        }
        confirmButtonText="Remove"
        onConfirm={handleDeleteTemplateConfirm}
        onCancel={() => setDeleteTemplateDialogShow(false)}
        open={deleteTemplateDialogShow}
        danger
      />
    </div>
  );
}

export default EditTemplateHeader;
