import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import type {
  Member,
  MemberColorsSettings,
  MemberHashTag,
  MemberSettings,
  MembersState,
} from "./membersTypes";

const initialState: MembersState = {
  members: undefined,
  colors: [],
  hashTags: undefined,
};

const membersSlice = createSlice({
  name: "members",
  initialState,
  reducers: {
    setMembers: (state: MembersState, action: PayloadAction<Member[]>) => {
      state.members = action.payload;
    },
    resetMembers: (state: MembersState) => {
      state.members = undefined;
      state.hashTags = undefined;
    },
    addMembers: (state: MembersState, action: PayloadAction<Member[]>) => {
      state.members = state.members
        ? [...state.members, ...action.payload]
        : [...action.payload];
    },
    editMembers: (
      state: MembersState,
      action: PayloadAction<(Pick<Member, "id"> & Partial<Member>)[]>
    ) => {
      state.members = state.members?.map((m) => {
        const updates = action.payload.find((u) => u.id === m.id);
        return updates ? { ...m, ...updates } : m;
      });
    },
    editMember: (
      state: MembersState,
      action: PayloadAction<Pick<Member, "id"> & Partial<Member>>
    ) => {
      state.members = state.members?.map((m) =>
        m.id === action.payload.id ? { ...m, ...action.payload } : m
      );
    },
    setMemberSettings: (
      state: MembersState,
      action: PayloadAction<MemberSettings[]>
    ) => {
      state.colors = action.payload.map((s) => s.colors);
    },
    resetMemberSettings: (state: MembersState) => {
      state.colors = [];
    },
    addNewMemberSettings: (
      state: MembersState,
      action: PayloadAction<MemberSettings[]>
    ) => {
      state.colors = [...state.colors, ...action.payload.map((s) => s.colors)];
    },
    editMemberSettings: (
      state: MembersState,
      action: PayloadAction<MemberSettings[]>
    ) => {
      state.colors = state.colors?.map((c) => {
        const updates = action.payload.find(
          (u) => u.colors.id === c.id
        )?.colors;
        return updates ? { ...c, ...updates } : c;
      });
    },
    setMemberColorsSettings: (
      state: MembersState,
      action: PayloadAction<MemberColorsSettings>
    ) => {
      const newColorsSettings = action.payload;
      const colorsSettingsIndex = state.colors.findIndex(
        (c) => c.id === newColorsSettings.id
      );
      if (colorsSettingsIndex < 0) {
        state.colors = [...state.colors, newColorsSettings];
      } else {
        state.colors[colorsSettingsIndex] = newColorsSettings;
      }
    },
    editMemberColorsSettings: (
      state: MembersState,
      action: PayloadAction<Partial<MemberColorsSettings>>
    ) => {
      const { id, ...updates } = action.payload;
      const index = state.colors.findIndex((c) => c.id === id);
      if (index > -1) {
        state.colors[index] = { ...state.colors[index], ...updates };
      }
    },
    removeMemberSettingsByMemberIds: (
      state: MembersState,
      action: PayloadAction<Array<MemberColorsSettings["userId"]>>
    ) => {
      state.colors = state.colors.filter(
        (s) => !action.payload.includes(s.userId)
      );
    },
    removeMembers: (
      state: MembersState,
      action: PayloadAction<Array<Member["id"]>>
    ) => {
      state.members = state.members?.filter(
        (m) => !action.payload.includes(m.id)
      );
    },
    setMemberHashTags: (
      state: MembersState,
      action: PayloadAction<MemberHashTag[]>
    ) => {
      state.hashTags = action.payload;
    },
    editMemberHashTag: (
      state: MembersState,
      action: PayloadAction<MemberHashTag>
    ) => {
      state.hashTags = state.hashTags?.map((t) =>
        t.id === action.payload.id ? action.payload : t
      );
    },
    editMemberHashTags: (
      state: MembersState,
      action: PayloadAction<MemberHashTag[]>
    ) => {
      state.hashTags = state.hashTags?.map((t) => {
        const updates = action.payload.find((u) => u.id === t.id);
        return updates ? { ...t, ...updates } : t;
      });
    },
    removeMemberHashTags: (
      state: MembersState,
      action: PayloadAction<Array<Member["id"]>>
    ) => {
      state.hashTags = state.hashTags?.filter(
        (t) => !action.payload.includes(t.id)
      );
    },
    addMemberHashTags: (
      state: MembersState,
      action: PayloadAction<MemberHashTag[]>
    ) => {
      // Filter out any HashTags that already exist
      action.payload = action.payload.filter(
        (p) => !state.hashTags?.find((t) => t.id === p.id)
      );
      state.hashTags = state.hashTags
        ? [...state.hashTags, ...action.payload]
        : [...action.payload];
    },
  },
});

export const {
  setMembers,
  resetMembers,
  addMembers,
  editMembers,
  editMember,
  setMemberSettings,
  resetMemberSettings,
  addNewMemberSettings,
  editMemberSettings,
  setMemberColorsSettings,
  editMemberColorsSettings,
  removeMemberSettingsByMemberIds,
  removeMembers,
  setMemberHashTags,
  editMemberHashTag,
  editMemberHashTags,
  removeMemberHashTags,
  addMemberHashTags,
} = membersSlice.actions;

export default membersSlice.reducer;

export const getMembers = (state: RootState) => state.members.members;

export const getMemberById = createSelector(
  [getMembers, (state: RootState, id: number) => id],
  (members, id) => members?.find((m) => m.id === id)
);

export const getMemberColorsSettings = (state: RootState) =>
  state.members.colors;

export const getMemberColorsSettingsByMemberId = createSelector(
  [getMemberColorsSettings, (state: RootState, memberId: number) => memberId],
  (colors, memberId) => colors.find((c) => c.userId === memberId)
);

export const getMemberHashTags = (state: RootState) => state.members.hashTags;

export const getHashTagByUserId = createSelector(
  [getMemberHashTags, (_state: RootState, userId: Member["id"]) => userId],
  (hashTags, userId) =>
    hashTags ? hashTags.find((t) => t.userId === userId) || null : undefined
);
