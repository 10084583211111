import { faAddressCard } from "@fortawesome/free-regular-svg-icons";
import {
  faGrip,
  faPen,
  faPlus,
  faUsersLine,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Button, FormGroup, SvgIcon } from "@mui/material";
import parse from "html-react-parser";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {
  ContactCardButton,
  DialogX,
  DragSortList,
  DragSortListItem,
  EditContactCard,
  Spinner,
  SwitchX,
} from "../../components";
import { useAppDispatch } from "../../store";
import { FetchError, handleFetchError } from "../../utils";
import {
  AddedToContactCardBy,
  LinkToPreview,
  LinkType,
  MemberLink,
  NewMemberLink,
  addNewLinks,
  setLinks,
  updateLinks,
  useAddMemberLinksToContactCardMutation,
  useCreateNewLinkMutation,
  useReadMemberLinksMutation,
  useReorderMemberLinksMutation,
  useUpdateMemberLinkMutation,
} from "../links";
import SelectLinkType from "../links/SelectLinkType";
import AddMemberLink from "../links/AddMemberLink";
import EditMemberLink from "../links/EditMemberLink";
import {
  Member,
  editMember,
  editMembers,
  useSetCaptureLeadMutation,
  useSetOpenDirectMutation,
  useSwitchConnectButtonMutation,
  useSwitchSaveContactButtonMutation,
} from "../members";
import type { Template } from "../templates/templatesTypes";

type EditCardContentProps = {
  member: Member;
  memberLinks: MemberLink[];
  template?: Pick<
    Template,
    | "id"
    | "isControlButtonsLocked"
    | "connectButton"
    | "saveContactButton"
    | "isProfileOpensLocked"
    | "captureLead"
    | "openDirect"
  >;
  onChange?: (updatedMemberLinks: MemberLink[]) => void;
  className?: string;
};

function EditCardContent({
  member,
  memberLinks,
  template,
  onChange,
  className = "",
  ...props
}: EditCardContentProps) {
  const [thisConnetButton, setThisConnetButton] = useState<boolean>(false);
  const [thisSaveContactButton, setThisSaveContactButton] =
    useState<boolean>(false);
  const [thisCaptureLead, setThisCaptureLead] = useState<boolean>(false);
  const [thisOpenDirect, setThisOpenDirect] = useState<boolean>(false);
  const [thisLinks, setThisLinks] = useState<MemberLink[]>([]);
  const [addLinkDialogOpen, setAddLinkDialogOpen] = useState<boolean>(false);
  const [linkTypeToAdd, setLinkTypeToAdd] = useState<LinkType>();
  const [linkToEdit, setLinkToEdit] = useState<MemberLink>();
  const [editContactCardDialogOpen, setEditContactCardDialogOpen] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const [setCaptureLead] = useSetCaptureLeadMutation();

  const handleCaptureLeadChange = useCallback(
    async function (_e: ChangeEvent<HTMLInputElement>, checked: boolean) {
      setThisCaptureLead(checked);
      if (checked) {
        setThisOpenDirect(false);
      }

      try {
        const {
          success,
          message,
          data: { member: updatedMember },
        } = await setCaptureLead({
          id: member.id,
          captureLead: checked,
        }).unwrap();
        if (success) {
          dispatch(editMember(updatedMember));
        } else {
          setThisCaptureLead(!checked);
          if (checked) {
            setThisOpenDirect(true);
          }
          toast.error(message);
        }
      } catch (error) {
        setThisCaptureLead(!checked);
        if (checked) {
          setThisOpenDirect(true);
        }
        handleFetchError(error as FetchError);
      }
    },
    [dispatch, member.id, setCaptureLead]
  );

  useEffect(() => {
    if (template?.isControlButtonsLocked) {
      setThisConnetButton(template.connectButton);
      setThisSaveContactButton(template.saveContactButton);
    } else {
      setThisConnetButton(member.connectButton);
      setThisSaveContactButton(member.saveContactButton);
    }

    if (template?.isProfileOpensLocked) {
      setThisCaptureLead(template.captureLead);
      setThisOpenDirect(template.openDirect);
    } else {
      setThisCaptureLead((prevState) => {
        if (prevState && !member.connectButton) {
          handleCaptureLeadChange({} as ChangeEvent<HTMLInputElement>, false);
          return false;
        }
        return member.captureLead;
      });
      setThisOpenDirect(member.openDirect);
    }

    setLinkToEdit(undefined);
  }, [
    handleCaptureLeadChange,
    member.captureLead,
    member.connectButton,
    member.openDirect,
    member.saveContactButton,
    template?.captureLead,
    template?.connectButton,
    template?.isControlButtonsLocked,
    template?.isProfileOpensLocked,
    template?.openDirect,
    template?.saveContactButton,
  ]);

  useEffect(() => {
    setThisLinks(memberLinks.slice().sort((a, b) => a.sequence - b.sequence));
  }, [memberLinks]);

  const [reorderMemberLinks, { isLoading: isReorderMemberLinksPending }] =
    useReorderMemberLinksMutation();

  const reOrderTheseLinks = useCallback(
    async function (reorderedLinks: MemberLink[]) {
      dispatch(
        updateLinks(
          reorderedLinks.map((rl, index) => ({ id: rl.id, sequence: index }))
        )
      );

      try {
        const {
          success,
          message,
          data: { links },
        } = await reorderMemberLinks({
          memberId: member.id,
          links: reorderedLinks,
        }).unwrap();
        if (success) {
          dispatch(updateLinks(links));
        } else {
          dispatch(
            updateLinks(
              reorderedLinks.map(({ id, sequence }) => ({ id, sequence }))
            )
          );
          toast.error(message);
        }
      } catch (error) {
        dispatch(
          updateLinks(
            reorderedLinks.map(({ id, sequence }) => ({ id, sequence }))
          )
        );
        handleFetchError(error as FetchError);
      }
    },
    [reorderMemberLinks, member.id, dispatch]
  );

  const [setOpenDirect] = useSetOpenDirectMutation();

  const handleOpenDirectChange = useCallback(
    async function (checked: boolean) {
      setThisOpenDirect(checked);
      if (checked) {
        setThisCaptureLead(false);

        // ReOrder the Buttons of this Member, if No Link is marked to DirectOpen
        if (thisLinks.length > 0) {
          const directOpenLink = thisLinks.findIndex((l) => l.sequence === 0);
          if (directOpenLink === -1) {
            reOrderTheseLinks(thisLinks);
          }
        }
      }

      try {
        const {
          success,
          message,
          data: { member: updatedMember },
        } = await setOpenDirect({
          id: member.id,
          openDirect: checked,
        }).unwrap();
        if (success) {
          dispatch(editMember(updatedMember));
        } else {
          setThisOpenDirect(!checked);
          if (checked) {
            setThisCaptureLead(true);
          }
          toast.error(message);
        }
      } catch (error) {
        setThisOpenDirect(!checked);
        if (checked) {
          setThisCaptureLead(true);
        }
        handleFetchError(error as FetchError);
      }
    },
    [thisLinks, reOrderTheseLinks, setOpenDirect, member.id, dispatch]
  );

  useEffect(() => {
    if (thisOpenDirect && thisLinks.length === 0) {
      handleOpenDirectChange(false);
    }
  }, [thisOpenDirect, thisLinks.length, handleOpenDirectChange]);

  const [updateMemberLink] = useUpdateMemberLinkMutation();

  async function handleLinkVisibleChange(
    id: MemberLink["id"],
    checked: boolean
  ) {
    dispatch(updateLinks([{ id, visible: checked }]));

    try {
      const {
        success,
        message,
        data: { links: updatedMemberLinks },
      } = await updateMemberLink({
        id,
        userId: member.id,
        visible: checked,
      }).unwrap();

      if (success) {
        dispatch(updateLinks(updatedMemberLinks));
      } else {
        dispatch(updateLinks([{ id, visible: !checked }]));
        toast.error(message ?? "Failed to update the profile link");
      }
    } catch (error) {
      dispatch(updateLinks([{ id, visible: !checked }]));
      handleFetchError(error as FetchError);
    }
  }

  function handleMakeDirectClick(id: number): void {
    const currentIndex = thisLinks.findIndex((link) => link.id === id);
    const [movedLink] = thisLinks.splice(currentIndex, 1);
    thisLinks.splice(0, 0, movedLink);

    reOrderTheseLinks(thisLinks);
  }

  const openAddLinkDialog = () => {
    setLinkTypeToAdd(undefined);
    setAddLinkDialogOpen(true);
  };

  const [createNewLink, { isLoading: isCreateNewLinkPending }] =
    useCreateNewLinkMutation();

  async function addToLinks(newLink: NewMemberLink) {
    try {
      const {
        success,
        message,
        data: { links: newLinks },
      } = await createNewLink({
        ...newLink,
        userId: member.id,
      }).unwrap();
      if (success) {
        dispatch(addNewLinks(newLinks));
        setAddLinkDialogOpen(false);
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  function editThisLink(aLink: MemberLink): void {
    setLinkToEdit(aLink);
  }

  const previewLinkChange = useCallback(
    (linkToPreview: LinkToPreview) => {
      onChange?.(
        thisLinks.map((l) =>
          l.id === linkToPreview.id ? { ...l, ...linkToPreview } : l
        )
      );
    },
    [thisLinks, onChange]
  );

  const [readMemberLinks] = useReadMemberLinksMutation();

  // ReOrder the Buttons of this Member, when a Link is deleted
  async function reOrderLinksOnDelete(linkIds: Array<MemberLink["id"]>) {
    const currentIndex = thisLinks.findIndex((link) =>
      linkIds.includes(link.id)
    );
    thisLinks.splice(currentIndex, 1);

    if (linkIds.length === 1) {
      reOrderTheseLinks(thisLinks);
    } else {
      try {
        const {
          success,
          message,
          data: { links: allMemberLinks },
        } = await readMemberLinks().unwrap();
        if (success) {
          dispatch(setLinks(allMemberLinks));
        } else {
          toast.error(message);
        }
      } catch (error) {
        handleFetchError(error as FetchError);
      }
    }

    setLinkToEdit(undefined);
  }

  const [switchConnectButton] = useSwitchConnectButtonMutation();

  async function handleConnetChange(
    _e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    setThisConnetButton(checked);

    try {
      const {
        success,
        message,
        data: { members: updatedMembers },
      } = await switchConnectButton({
        ids: [member.id],
        show: checked,
      }).unwrap();
      if (success) {
        dispatch(editMembers(updatedMembers));
      } else {
        setThisConnetButton(!checked);
        toast.error(message);
      }
    } catch (error) {
      setThisConnetButton(!checked);
      handleFetchError(error as FetchError);
    }
  }

  const [switchSaveContactButton] = useSwitchSaveContactButtonMutation();

  async function handleSaveContactChange(
    _e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    setThisSaveContactButton(checked);

    try {
      const {
        success,
        message,
        data: { members: updatedMembers },
      } = await switchSaveContactButton({
        ids: [member.id],
        show: checked,
      }).unwrap();
      if (success) {
        dispatch(editMembers(updatedMembers));
      } else {
        setThisSaveContactButton(!checked);
        toast.error(message);
      }
    } catch (error) {
      setThisSaveContactButton(!checked);
      handleFetchError(error as FetchError);
    }
  }

  const [
    addMemberLinksToContactCard,
    { isLoading: isAddMemberLinksToContactCardLoading },
  ] = useAddMemberLinksToContactCardMutation();

  async function saveContactCard(linkIdsToAdd: Array<MemberLink["id"]>) {
    try {
      const {
        success,
        message,
        data: updatedMemberLinks,
      } = await addMemberLinksToContactCard({
        userId: member.id,
        memberLinkIds: linkIdsToAdd,
        isAddedToContactCardBy: AddedToContactCardBy.MEMBER,
      }).unwrap();
      if (success) {
        dispatch(updateLinks(updatedMemberLinks));
        toast.success(message);
        setEditContactCardDialogOpen(false);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
      setEditContactCardDialogOpen(false);
    }
  }

  return !linkToEdit ? (
    <div className={`flex flex-col gap-4 ${className}`} {...props}>
      <h4 className="text-xl font-semibold text-black">Content</h4>
      <div className="mb-4 flex items-center justify-between gap-11">
        <FormGroup
          classes={{
            root: "relative h-11 !flex-row gap-10 rounded-full border border-gray-200 bg-white px-6 py-3",
          }}
        >
          <legend className="absolute -top-2.5 left-6 z-20 bg-white px-2 py-0.5 text-xs text-gray-600">
            Control Buttons
          </legend>
          <SwitchX
            checked={thisConnetButton}
            onChange={handleConnetChange}
            label="Connect"
            labelPlacement="start"
            disabled={template?.isControlButtonsLocked}
          />
          <SwitchX
            checked={thisSaveContactButton}
            onChange={handleSaveContactChange}
            label="Save Contact"
            labelPlacement="start"
            disabled={template?.isControlButtonsLocked}
          />
        </FormGroup>
        <FormGroup
          classes={{
            root: "relative h-11 !flex-row gap-10 rounded-full border border-gray-200 bg-white px-6 py-3",
          }}
        >
          <legend className="absolute -top-2.5 left-6 z-20 bg-white px-2 py-0.5 text-xs text-gray-600">
            When the profile opens
          </legend>
          <SwitchX
            checked={thisCaptureLead}
            onChange={handleCaptureLeadChange}
            label="Lead Capture"
            labelPlacement="start"
            disabled={template?.isProfileOpensLocked || !thisConnetButton}
          />
          <SwitchX
            checked={thisOpenDirect}
            onChange={(_e, c) => handleOpenDirectChange(c)}
            label="OneShare"
            labelPlacement="start"
            disabled={template?.isProfileOpensLocked || thisLinks.length === 0}
          />
        </FormGroup>
      </div>
      <div>
        <ContactCardButton
          links={thisLinks.filter(
            (l) =>
              l.isAddedToContactCardBy !== AddedToContactCardBy.NONE &&
              l.visible
          )}
          onClick={() => setEditContactCardDialogOpen(true)}
        />
        <DialogX
          open={editContactCardDialogOpen}
          onClose={() => setEditContactCardDialogOpen(false)}
          fullScreen
          className="!max-w-2xl"
        >
          <EditContactCard
            links={thisLinks.filter((l) => l.visible)}
            onSave={saveContactCard}
            onCancel={() => setEditContactCardDialogOpen(false)}
            loading={isAddMemberLinksToContactCardLoading}
          />
        </DialogX>
      </div>
      <div className="mb-4 flex items-center justify-between gap-11">
        <h5 className="text-lg font-semibold text-black">
          Links and Contact Information
        </h5>
        <div>
          <Button
            id="add-links-contact-info"
            variant="contained"
            disableElevation
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            classes={{
              root: "!rounded-full !bg-primary !py-3 !px-6 !font-sans !text-sm !font-semibold !normal-case",
              startIcon: "!text-xs font-normal",
            }}
            onClick={openAddLinkDialog}
          >
            Add New
          </Button>
          <DialogX
            open={addLinkDialogOpen}
            onClose={() => setAddLinkDialogOpen(false)}
            fullScreen
            className="!max-w-5xl"
          >
            {!linkTypeToAdd ? (
              <SelectLinkType
                onSelect={setLinkTypeToAdd}
                className="h-[37rem]"
              />
            ) : (
              <>
                <AddMemberLink
                  linkType={linkTypeToAdd}
                  onBack={() => setLinkTypeToAdd(undefined)}
                  onAdd={addToLinks}
                  onCancel={() => setAddLinkDialogOpen(false)}
                />
                {isCreateNewLinkPending && <Spinner fullScreen />}
              </>
            )}
          </DialogX>
        </div>
      </div>
      {thisLinks.length === 0 ? (
        <p className="flex h-64 flex-col items-center justify-center gap-2 text-center">
          <strong className="text-sm text-gray-700">
            This member doesn't have any linked content.
          </strong>
          <small className="block w-72 text-xs text-gray-600">
            Add buttons to contact information, websites, payment methods,
            social networks and more.
          </small>
        </p>
      ) : (
        <div className="relative">
          {isReorderMemberLinksPending && <Spinner className="bg-white/50" />}
          <DragSortList list={thisLinks} onDrop={reOrderTheseLinks}>
            {thisLinks.map((aLink, index) => (
              <DragSortListItem
                key={aLink.id}
                item={aLink}
                index={index}
                draggable={!thisOpenDirect}
                disabled={thisOpenDirect && aLink.sequence !== 0}
              >
                <FontAwesomeIcon
                  icon={faGrip}
                  className={`text-sm font-black text-gray-400 ${
                    thisOpenDirect && aLink.sequence !== 0 ? "opacity-50" : ""
                  }`}
                />
                <span
                  onClick={() => editThisLink(aLink)}
                  className={`flex grow items-center gap-4 ${
                    thisOpenDirect && aLink.sequence !== 0 ? "opacity-50" : ""
                  }`}
                >
                  <Avatar
                    alt={aLink.title}
                    variant="rounded"
                    className="!rounded-[0.439rem] !bg-transparent shadow-md"
                  >
                    {aLink.iconSVG && (
                      <SvgIcon className="!h-full !w-full">
                        {parse(aLink.iconSVG)}
                      </SvgIcon>
                    )}
                  </Avatar>
                  <strong className="font-semibold">{aLink.title}</strong>
                </span>
                {aLink.globalId ? (
                  <FontAwesomeIcon
                    icon={faUsersLine}
                    title={`This is a common link for all members, edits made \nto this button will be reflected in all members.`}
                    className="text-gray-600"
                  />
                ) : aLink.templateId ? (
                  <Link
                    to={`/templates/${template?.id}/edit`}
                    title={
                      aLink.isUnique
                        ? `This button comes from a template, \nhowever edits made to this button are permitted.`
                        : `This button comes from a template, and \ncan only be edited from the template.`
                    }
                    className="relative flex items-center text-gray-600"
                  >
                    <FontAwesomeIcon icon={faAddressCard} />
                    {aLink.isUnique && (
                      <FontAwesomeIcon
                        icon={faPen}
                        className="absolute -right-1 -top-0.5 text-xs"
                      />
                    )}
                  </Link>
                ) : (
                  <></>
                )}
                <span>
                  {!thisOpenDirect && (
                    <SwitchX
                      checked={aLink.visible}
                      onChange={(_e, checked) =>
                        handleLinkVisibleChange(aLink.id, checked)
                      }
                      disabled={Boolean(aLink.templateId)}
                    />
                  )}
                  {thisOpenDirect && aLink.sequence !== 0 && (
                    <Button
                      variant="outlined"
                      classes={{
                        root: "!rounded-full !border !border-gray-200 !px-4 !py-2 !font-sans !text-xs !font-semibold !normal-case !text-black",
                      }}
                      onClick={() => handleMakeDirectClick(aLink.id)}
                    >
                      Set as OneShare
                    </Button>
                  )}
                </span>
              </DragSortListItem>
            ))}
          </DragSortList>
        </div>
      )}
    </div>
  ) : (
    <EditMemberLink
      link={linkToEdit}
      onBack={() => setLinkToEdit(undefined)}
      onChange={previewLinkChange}
      onUpdate={() => setLinkToEdit(undefined)}
      onDelete={reOrderLinksOnDelete}
      onCancel={() => setLinkToEdit(undefined)}
    />
  );
}

export default EditCardContent;
