import { faSave } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LoadingButton from "@mui/lab/LoadingButton";
import { ReactElement, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ConfirmationDialog,
  FloatingLabel,
  MembersSelectListCard,
} from "../../components";
import { useAppDispatch, useAppSelector } from "../../store";
import { FetchError, handleFetchError } from "../../utils";
import { AddMembersBySections } from "../add-members/addMembersEnums";
import {
  addNewLinks,
  cleanUpTemplateLinks,
  getLinks,
  setLinks,
  useReadMemberLinksMutation,
} from "../links";
import {
  Member,
  getMembers,
  setMemberSettings,
  setMembers,
  useGetAllMembersMutation,
} from "../members";
import {
  Template,
  editTemplateAssignees,
  getTemplateAssignees,
  getTemplateById,
  useAssignTemplateMutation,
} from "../templates";

type EditTemplateAssigneesProps = {
  templateId: Template["id"];
  assigneeIds: Template["assigneeIds"];
  className?: string;
};

function EditTemplateAssignees({
  templateId,
  assigneeIds,
  className = "",
  ...props
}: EditTemplateAssigneesProps) {
  const [assignedMembers, setAssignedMembers] = useState<Member[]>([]);
  const [unassignedMembers, setUnassignedMembers] = useState<
    Array<Member & { templateName?: Template["name"] }>
  >([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredAssigned, setFilteredAssigned] = useState<Member[]>([]);
  const [filteredUnassigned, setFilteredUnassigned] = useState<Member[]>([]);
  const [idsToAssign, setIdsToAssign] = useState<Array<Member["id"]>>([]);
  const [idsToUnassign, setIdsToUnassign] = useState<Array<Member["id"]>>([]);
  const [confirmationDialogShow, setConfirmationDialogShow] = useState(false);

  const dispatch = useAppDispatch();

  const searchMembersRef = useRef<HTMLInputElement>(null);

  const members = useAppSelector(getMembers);
  const templateAssignees = useAppSelector(getTemplateAssignees);

  const thisTemplate = useAppSelector((state) =>
    getTemplateById(state, templateId)
  );

  const [getAllMembers, { isLoading: isGetAllMembersPending }] =
    useGetAllMembersMutation();

  useEffect(() => {
    if (!members) {
      (async () => {
        try {
          const {
            success,
            message,
            data: { members: allMembers, settings },
          } = await getAllMembers().unwrap();
          if (success) {
            dispatch(setMembers(allMembers));
            dispatch(setMemberSettings(settings));
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    } else {
      setAssignedMembers(members.filter((m) => assigneeIds.includes(m.id)));
      setUnassignedMembers(
        members
          .filter((m) => !assigneeIds.includes(m.id))
          .map((m) => ({
            ...m,
            templateName: templateAssignees.find((aTemplate) =>
              aTemplate.assigneeIds.includes(m.id)
            )?.templateName,
          }))
      );
    }

    return () => {
      setAssignedMembers([]);
      setUnassignedMembers([]);
    };
  }, [members, getAllMembers, dispatch, assigneeIds, templateAssignees]);

  const memberLinks = useAppSelector(getLinks);

  const [readMemberLinks, { isLoading: isReadMemberLinksPending }] =
    useReadMemberLinksMutation();

  useEffect(() => {
    if (!memberLinks) {
      (async () => {
        try {
          const {
            success,
            message,
            data: { links: allMemberLinks },
          } = await readMemberLinks().unwrap();
          if (success) {
            dispatch(setLinks(allMemberLinks));
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    }
  }, [memberLinks, readMemberLinks, dispatch]);

  useEffect(() => {
    if (searchKeyword.length >= 1) {
      const searchedAssigned = assignedMembers.filter(
        (m) =>
          m.fullName.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          m.username.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          m.email.toLowerCase().includes(searchKeyword.toLowerCase())
      );
      const searchedUnassigned = unassignedMembers.filter(
        (m) =>
          m.fullName.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          m.username.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          m.email.toLowerCase().includes(searchKeyword.toLowerCase())
      );

      setFilteredAssigned(searchedAssigned);
      setFilteredUnassigned(searchedUnassigned);

      setIdsToAssign((prevState) =>
        prevState.filter((sid) => searchedUnassigned.some((m) => m.id === sid))
      );
      setIdsToUnassign((prevState) =>
        prevState.filter((sid) => searchedAssigned.some((m) => m.id === sid))
      );
    } else {
      setFilteredAssigned(assignedMembers);
      setFilteredUnassigned(unassignedMembers);

      setIdsToAssign((prevState) =>
        prevState.filter((sid) => unassignedMembers.some((m) => m.id === sid))
      );
      setIdsToUnassign((prevState) =>
        prevState.filter((sid) => assignedMembers.some((m) => m.id === sid))
      );
    }
  }, [searchKeyword, assignedMembers, unassignedMembers]);

  function onSearch(keyword: string): void {
    setSearchKeyword(keyword);
  }

  function handleAssignClick() {
    const alreadyAssignedIds = idsToAssign.filter((id) =>
      templateAssignees.some((aTemplate) => aTemplate.assigneeIds.includes(id))
    );

    if (alreadyAssignedIds.length > 0) {
      setConfirmationDialogShow(true);
    } else {
      moveToAssigned();
    }
  }

  function moveToAssigned(): void {
    setUnassignedMembers(
      unassignedMembers.filter((m) => !idsToAssign.includes(m.id))
    );
    setAssignedMembers(
      assignedMembers
        .concat(unassignedMembers.filter((m) => idsToAssign.includes(m.id)))
        .sort((a, b) => a.id - b.id)
    );
    setIdsToAssign([]);
  }

  function moveToUnassigned(): void {
    setAssignedMembers(
      assignedMembers.filter((m) => !idsToUnassign.includes(m.id))
    );
    setUnassignedMembers(
      unassignedMembers
        .concat(assignedMembers.filter((m) => idsToUnassign.includes(m.id)))
        .sort((a, b) => a.id - b.id)
    );
    setIdsToUnassign([]);
  }

  function getEmptyListMessage(
    membersCount: number,
    membersType: string
  ): ReactElement {
    return membersCount !== 0 && searchKeyword.length >= 1 ? (
      <p className="flex h-96 flex-col items-center justify-center gap-2 text-center">
        <strong className="text-sm text-gray-700">No Member Found.</strong>
        <small className="block w-72 text-xs text-gray-600">
          Try seaching for something else.
        </small>
      </p>
    ) : (
      <p className="flex h-96 flex-col items-center justify-center gap-2 text-center">
        <strong className="text-sm text-gray-700">
          No {membersType} Member.
        </strong>
        <small className="block w-72 text-xs text-gray-600">
          Try adding some to the template
        </small>
      </p>
    );
  }

  const [assignTemplate, { isLoading: isAssignTemplatePending }] =
    useAssignTemplateMutation();

  async function handleUpdateTemplateDataClick() {
    try {
      const {
        success,
        message,
        data: { assignedTemplate, clonedMemberLinks },
      } = await assignTemplate({
        id: templateId,
        assigneeIds: assignedMembers.map((m) => m.id),
      }).unwrap();
      if (success) {
        dispatch(editTemplateAssignees(assignedTemplate));
        dispatch(cleanUpTemplateLinks(assignedTemplate));
        dispatch(addNewLinks(clonedMemberLinks));
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  return (
    <div className={`flex flex-col gap-8 ${className}`} {...props}>
      <div className="flex justify-between">
        <h4 className="text-xl font-semibold text-black">Assignees</h4>
        <FloatingLabel
          size="small"
          onInputValueChange={setSearchKeyword}
          onSearchClick={onSearch}
          className="w-48 md:w-auto"
        >
          <input
            type="search"
            ref={searchMembersRef}
            name="search-members"
            placeholder="Search members"
            value={searchKeyword}
            onKeyUp={() => onSearch?.(searchKeyword)}
          />
        </FloatingLabel>
      </div>
      <div className="grid grid-cols-[1fr_auto_1fr] gap-4">
        <MembersSelectListCard
          members={filteredAssigned}
          title="Assigned to Template"
          loading={isGetAllMembersPending || isReadMemberLinksPending}
          emptyListMessage={getEmptyListMessage(
            assignedMembers.length,
            "Assigned"
          )}
          onSelect={setIdsToUnassign}
        />
        <div className="w-14 self-center">
          <IconButton
            size="large"
            onClick={handleAssignClick}
            disabled={idsToAssign.length === 0}
            aria-label="Assign to Template"
          >
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            size="large"
            onClick={moveToUnassigned}
            disabled={idsToUnassign.length === 0}
            aria-label="Unassign from Template"
          >
            <ArrowForwardIcon fontSize="inherit" />
          </IconButton>
        </div>
        <MembersSelectListCard
          members={filteredUnassigned}
          title="Not Assigned to Template"
          loading={isGetAllMembersPending || isReadMemberLinksPending}
          emptyListMessage={getEmptyListMessage(
            unassignedMembers.length,
            "Unassigned"
          )}
          onSelect={setIdsToAssign}
        />
        <ConfirmationDialog
          title="Move Already Assigned Member(s)?"
          content={
            <p className="text-sm text-gray-500">
              {idsToAssign.length === 1 ? (
                <>
                  <strong>
                    {
                      unassignedMembers.find((u) => u.id === idsToAssign[0])
                        ?.fullName
                    }
                  </strong>{" "}
                  is already assigned to the{" "}
                  <strong>
                    {
                      unassignedMembers.find((u) => u.id === idsToAssign[0])
                        ?.templateName
                    }
                  </strong>{" "}
                  Template. If you proceed, you will replace the already
                  assigned Template by the <strong>{thisTemplate?.name}</strong>{" "}
                  Template. Do you want to proceed?
                </>
              ) : (
                <>
                  Multiple members are already assigned to another Template. If
                  you proceed, you will replace the already assigned Template by
                  the <strong>{thisTemplate?.name}</strong> Template. Do you
                  want to proceed?
                </>
              )}
            </p>
          }
          confirmButtonText="Yes"
          onConfirm={() => {
            moveToAssigned();
            setConfirmationDialogShow(false);
          }}
          cancelButtonText="No"
          onCancel={() => setConfirmationDialogShow(false)}
          open={confirmationDialogShow}
        />
      </div>
      <div className="flex items-center justify-between gap-8 border-t border-gray-200 pt-8">
        <p className="text-sm text-gray-500">
          Don't see a member on the list?{" "}
          <Link
            to="/members/new"
            state={{ addBy: AddMembersBySections.EMAILS }}
            className="font-semibold text-primary"
          >
            Add Members
          </Link>
        </p>
        <div className="flex items-center gap-8">
          <LoadingButton
            type="submit"
            startIcon={<FontAwesomeIcon icon={faSave} className="!text-sm" />}
            loading={isAssignTemplatePending}
            loadingPosition="start"
            classes={{
              root: `!rounded-full ${
                (assignedMembers.length !== assigneeIds.length ||
                  !assignedMembers.every((m) => assigneeIds.includes(m.id))) &&
                "!bg-primary"
              } !px-6 !py-3 !font-sans !text-sm !normal-case !text-white`,
              disabled: "!bg-gray-300",
              loadingIndicator: "!left-5",
            }}
            disabled={
              assignedMembers.length === assigneeIds.length &&
              assignedMembers.every((m) => assigneeIds.includes(m.id))
            }
            onClick={handleUpdateTemplateDataClick}
          >
            Update
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}

export default EditTemplateAssignees;
