import { Tabs } from "@mui/material";
import { ReactNode } from "react";

type TabsXProps = {
  name?: string | undefined;
  value?: any;
  onChange?:
    | ((event: React.SyntheticEvent<Element, Event>, value: any) => void)
    | undefined;
  children: ReactNode;
  className?: string;
};

function TabsX({
  name,
  value,
  onChange,
  children,
  className = "",
  ...props
}: TabsXProps) {
  return (
    <Tabs
      value={value}
      onChange={onChange}
      aria-label={name}
      classes={{
        root: `!min-h-[2.75rem] rounded-full border border-gray-200 bg-gray-50 ${className}`,
        flexContainer: "-m-px !grid !min-h-[2.75rem] grid-flow-col",
        indicator: "!h-full !rounded-full !bg-black",
      }}
      {...props}
    >
      {children}
    </Tabs>
  );
}

export default TabsX;
