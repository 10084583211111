import { faBatteryFull } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SignalCellularAltRoundedIcon from "@mui/icons-material/SignalCellularAltRounded";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import WifiIcon from "@mui/icons-material/Wifi";
import { ReactNode, useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";

type PhonePreviewProps = {
  children?: ReactNode;
  mutableData?: any;
  className?: string;
};

function PhonePreview({
  children,
  mutableData,
  className = "",
  ...props
}: PhonePreviewProps) {
  const [isScrolled, setIsScrolled] = useState<boolean>();
  const [mutableDataStr, setMutableDataStr] = useState<string>();
  const [scrolledOnce, setScrolledOnce] = useState<boolean>(false);

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setMutableDataStr(JSON.stringify(mutableData));
  }, [mutableData]);

  useEffect(() => {
    if (mutableDataStr) {
      setIsScrolled(false);
      scrollContainerRef.current?.scrollTo(0, 0);
    }
  }, [mutableDataStr]);

  function onAppScroll(appContainer: HTMLDivElement | null): void {
    if (appContainer?.scrollTop && appContainer?.scrollTop > 8) {
      setIsScrolled(true);
      setScrolledOnce(true);
    } else {
      setIsScrolled(false);
    }
  }

  return (
    <div
      className={`relative self-center rounded-[2.75rem] bg-gray-200 p-3 shadow-inner before:absolute before:-left-0.5 before:top-[18.18%] before:h-5 before:w-0.5 before:bg-gray-300 before:shadow-inner after:absolute after:-right-0.5 after:top-[27%] after:h-16 after:w-0.5 after:bg-gray-300 after:shadow-inner ${className}`}
      {...props}
    >
      <div
        className={`overflow-hidden rounded-[2.375rem] before:absolute before:-left-0.5 before:top-1/4 before:h-9 before:w-0.5 before:bg-gray-300 before:shadow-inner after:absolute after:-left-0.5 after:top-1/3 after:h-9 after:w-0.5 after:bg-gray-300 after:shadow-inner ${
          !children && "bg-black"
        }`}
      >
        <ScrollContainer
          horizontal={false}
          ignoreElements='.ignore-scroll'
          className='relative aspect-[9/19.5] w-64'
          innerRef={scrollContainerRef}
          onScroll={() => onAppScroll(scrollContainerRef.current)}
        >
          {children}
        </ScrollContainer>
      </div>
      <div
        className={`absolute inset-x-3 top-3 flex items-center justify-between rounded-t-[2.375rem] px-6 pb-2 pt-3 ${
          children ? "text-black" : "text-white"
        } ${isScrolled && "bg-white"}`}
      >
        <div className='w-14 text-center text-xs font-semibold'>9:41</div>
        <div
          className={`h-5 w-[4.5rem] rounded-full ${
            children ? "bg-black" : "border border-gray-200 bg-white"
          }`}
        ></div>
        <div className='flex w-14 items-center justify-end gap-1 text-sm'>
          <SignalCellularAltRoundedIcon classes={{ root: "!text-sm" }} />
          <WifiIcon classes={{ root: "!text-sm" }} />
          <FontAwesomeIcon icon={faBatteryFull} />
        </div>
      </div>
      {!scrolledOnce && (
        <div className='absolute left-1/2 top-3/4'>
          <TouchAppIcon className='-translate-x-1/2 translate-y-8 animate-[scroll-demo_1.5s_linear_infinite] !text-4xl text-yellow-500' />
        </div>
      )}
    </div>
  );
}

export default PhonePreview;
