import { faAddressCard } from "@fortawesome/free-regular-svg-icons";
import { faGrip, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
// import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";
import {
  Avatar,
  Button,
  FormGroup,
  IconButton,
  SvgIcon,
  Tooltip,
} from "@mui/material";
import parse from "html-react-parser";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  ContactCardButton,
  DialogX,
  DragSortList,
  DragSortListItem,
  EditContactCard,
  Spinner,
  SwitchX,
} from "../../components";
import { useAppDispatch } from "../../store";
import { FetchError, handleFetchError } from "../../utils";
import {
  NewTemplateLink,
  Template,
  TemplateLink,
  TemplateSwitches,
  addNewTemplateLink,
  editTemplateData,
  editTemplateLink,
  editTemplateLinks,
  useAddTemplateLinksToContactCardMutation,
  useCreateTemplateLinkMutation,
  useReorderTemplateLinksMutation,
  useSetTemplateCaptureLeadMutation,
  useSetTemplateOpenDirectMutation,
  useSwitchTemplateButtonsMutation,
  useSwitchTemplateLocksMutation,
  useUpdateTemplateLinkMutation,
} from "../templates";
import {
  AddedToContactCardBy,
  LinkToPreview,
  LinkType,
  addNewLinks,
  updateLinks,
} from "../links";
import SelectLinkType from "../links/SelectLinkType";
import AddTemplateLink from "./AddTemplateLink";
import EditTemplateLink from "./EditTemplateLink";

type EditTemplateContentProps = {
  template: Template;
  links: TemplateLink[];
  onChange?: (
    updatedTemplateSwitches: TemplateSwitches,
    updatedTemplateLinks: TemplateLink[]
  ) => void;
  className?: string;
};

function EditTemplateContent({
  template,
  links,
  onChange,
  className = "",
  ...props
}: EditTemplateContentProps) {
  const [thisTemplateSwitches, setThisTemplateSwitches] =
    useState<TemplateSwitches>({
      connectButton: true,
      saveContactButton: true,
      captureLead: false,
      openDirect: false,
    });
  const [controlButtonsLocked, setControlButtonsLocked] = useState<boolean>(
    template.isControlButtonsLocked
  );
  const [profileOpensLocked, setProfileOpensLocked] = useState<boolean>(
    template.isProfileOpensLocked
  );
  const [thisLinks, setThisLinks] = useState<TemplateLink[]>([]);
  const [addLinkDialogOpen, setAddLinkDialogOpen] = useState<boolean>(false);
  const [linkTypeToAdd, setLinkTypeToAdd] = useState<LinkType>();
  const [linkToEdit, setLinkToEdit] = useState<TemplateLink>();
  const [editContactCardDialogOpen, setEditContactCardDialogOpen] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const [setTemplateCaptureLead] = useSetTemplateCaptureLeadMutation();

  const handleCaptureLeadChange = useCallback(
    async function (_e: ChangeEvent<HTMLInputElement>, checked: boolean) {
      setThisTemplateSwitches((prev) => ({ ...prev, captureLead: checked }));
      if (checked) {
        setThisTemplateSwitches((prev) => ({ ...prev, openDirect: false }));
      }

      try {
        const {
          success,
          message,
          data: { updatedTemplate },
        } = await setTemplateCaptureLead({
          id: template.id,
          captureLead: checked,
        }).unwrap();
        if (success) {
          dispatch(
            editTemplateData(
              updatedTemplate.captureLead
                ? {
                    id: updatedTemplate.id,
                    captureLead: updatedTemplate.captureLead,
                    openDirect: updatedTemplate.openDirect,
                  }
                : {
                    id: updatedTemplate.id,
                    captureLead: updatedTemplate.captureLead,
                  }
            )
          );
        } else {
          setThisTemplateSwitches((prev) => ({
            ...prev,
            captureLead: !checked,
          }));
          if (checked) {
            setThisTemplateSwitches((prev) => ({ ...prev, openDirect: true }));
          }
          toast.error(message);
        }
      } catch (error) {
        setThisTemplateSwitches((prev) => ({ ...prev, captureLead: !checked }));
        if (checked) {
          setThisTemplateSwitches((prev) => ({ ...prev, openDirect: true }));
        }
        handleFetchError(error as FetchError);
      }
    },
    [dispatch, setTemplateCaptureLead, template.id]
  );

  useEffect(() => {
    setThisTemplateSwitches((prevState) => {
      if (prevState.captureLead && !template.connectButton) {
        handleCaptureLeadChange({} as ChangeEvent<HTMLInputElement>, false);
      }
      return {
        ...prevState,
        connectButton: template.connectButton,
        saveContactButton: template.saveContactButton,
        captureLead: template.connectButton ? template.captureLead : false,
        openDirect: template.openDirect,
      };
    });

    setLinkToEdit(undefined);
  }, [
    handleCaptureLeadChange,
    template.captureLead,
    template.connectButton,
    template.openDirect,
    template.saveContactButton,
  ]);

  useEffect(() => {
    onChange?.(thisTemplateSwitches, thisLinks);
  }, [thisTemplateSwitches, thisLinks, onChange]);

  useEffect(() => {
    setThisLinks(links.slice().sort((a, b) => a.sequence - b.sequence));
  }, [links]);

  const [setTemplateOpenDirect] = useSetTemplateOpenDirectMutation();

  async function handleOpenDirectChange(
    _e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    setThisTemplateSwitches((prev) => ({ ...prev, openDirect: checked }));
    if (checked) {
      setThisTemplateSwitches((prev) => ({ ...prev, captureLead: false }));
      // ReOrder the Buttons of this Template, if No Link is marked to DirectOpen
      if (thisLinks.length > 0) {
        const directOpenLink = thisLinks.findIndex((l) => l.sequence === 0);
        if (directOpenLink === -1) {
          reOrderTheseLinks(thisLinks);
        }
      }
    }

    try {
      const {
        success,
        message,
        data: { updatedTemplate },
      } = await setTemplateOpenDirect({
        id: template.id,
        openDirect: checked,
      }).unwrap();
      if (success) {
        dispatch(
          editTemplateData(
            updatedTemplate.openDirect
              ? {
                  id: updatedTemplate.id,
                  openDirect: updatedTemplate.openDirect,
                  captureLead: updatedTemplate.captureLead,
                }
              : {
                  id: updatedTemplate.id,
                  openDirect: updatedTemplate.openDirect,
                }
          )
        );
      } else {
        setThisTemplateSwitches((prev) => ({ ...prev, openDirect: !checked }));
        if (checked) {
          setThisTemplateSwitches((prev) => ({ ...prev, captureLead: true }));
        }
        toast.error(message);
      }
    } catch (error) {
      setThisTemplateSwitches((prev) => ({ ...prev, openDirect: !checked }));
      if (checked) {
        setThisTemplateSwitches((prev) => ({ ...prev, captureLead: true }));
      }
      handleFetchError(error as FetchError);
    }
  }

  const [reorderTemplateLinks, { isLoading: isReorderTemplateLinksPending }] =
    useReorderTemplateLinksMutation();

  async function reOrderTheseLinks(reorderedLinks: TemplateLink[]) {
    dispatch(
      editTemplateLinks(
        reorderedLinks.map((rl, index) => ({ id: rl.id, sequence: index }))
      )
    );

    try {
      const {
        success,
        message,
        data: { reorderedTemplateLinks },
      } = await reorderTemplateLinks({
        templateId: template.id,
        templateLinkIds: reorderedLinks.map((rl) => rl.id),
      }).unwrap();

      if (success) {
        dispatch(editTemplateLinks(reorderedTemplateLinks));
      } else {
        dispatch(
          editTemplateLinks(
            reorderedLinks.map(({ id, sequence }) => ({ id, sequence }))
          )
        );
        toast.error(message);
      }
    } catch (error) {
      dispatch(
        editTemplateLinks(
          reorderedLinks.map(({ id, sequence }) => ({ id, sequence }))
        )
      );
      handleFetchError(error as FetchError);
    }
  }

  const [updateTemplateLink] = useUpdateTemplateLinkMutation();

  async function handleLinkVisibleChange(
    id: TemplateLink["id"],
    checked: boolean
  ) {
    dispatch(editTemplateLink({ id, visible: checked }));

    try {
      const {
        success,
        message,
        data: { updatedTemplateLink, updatedMemberLinks },
      } = await updateTemplateLink({
        id,
        templateId: template.id,
        visible: checked,
      }).unwrap();

      if (success) {
        dispatch(editTemplateLink(updatedTemplateLink));
        dispatch(updateLinks(updatedMemberLinks));
      } else {
        dispatch(editTemplateLink({ id, visible: !checked }));
        toast.error(message);
      }
    } catch (error) {
      dispatch(editTemplateLink({ id, visible: !checked }));
      handleFetchError(error as FetchError);
    }
  }

  function handleMakeDirectClick(id: number): void {
    const currentIndex = thisLinks.findIndex((link) => link.id === id);
    const [movedLink] = thisLinks.splice(currentIndex, 1);
    thisLinks.splice(0, 0, movedLink);

    reOrderTheseLinks(thisLinks);
  }

  const openAddLinkDialog = () => {
    setLinkTypeToAdd(undefined);
    setAddLinkDialogOpen(true);
  };

  const [createTemplateLink, { isLoading: isCreateTemplateLinkPending }] =
    useCreateTemplateLinkMutation();

  async function addToLinks(newLink: NewTemplateLink) {
    try {
      const {
        success,
        message,
        data: { newTemplateLink, clonedMemberLinks },
      } = await createTemplateLink({
        templateId: template.id,
        ...newLink,
      }).unwrap();
      if (success) {
        dispatch(addNewTemplateLink(newTemplateLink));
        dispatch(addNewLinks(clonedMemberLinks));
        setAddLinkDialogOpen(false);
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  function editThisLink(aLink: TemplateLink): void {
    setLinkToEdit(aLink);
  }

  const previewLinkChange = useCallback(
    (linkToPreview: LinkToPreview) => {
      onChange?.(
        thisTemplateSwitches,
        thisLinks.map((l) =>
          l.id === linkToPreview.id ? { ...l, ...linkToPreview } : l
        )
      );
    },
    [thisTemplateSwitches, thisLinks, onChange]
  );

  const [switchTemplateButtons] = useSwitchTemplateButtonsMutation();

  async function handleConnetChange(
    _e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    setThisTemplateSwitches((prev) => ({ ...prev, connectButton: checked }));

    try {
      const {
        success,
        message,
        data: { updatedTemplate },
      } = await switchTemplateButtons({
        id: template.id,
        connectButton: checked,
      }).unwrap();
      if (success) {
        dispatch(editTemplateData(updatedTemplate));
      } else {
        setThisTemplateSwitches((prev) => ({
          ...prev,
          connectButton: !checked,
        }));
        toast.error(message);
      }
    } catch (error) {
      setThisTemplateSwitches((prev) => ({ ...prev, connectButton: !checked }));
      handleFetchError(error as FetchError);
    }
  }

  async function handleSaveContactChange(
    _e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    setThisTemplateSwitches((prev) => ({
      ...prev,
      saveContactButton: checked,
    }));

    try {
      const {
        success,
        message,
        data: { updatedTemplate },
      } = await switchTemplateButtons({
        id: template.id,
        saveContactButton: checked,
      }).unwrap();
      if (success) {
        dispatch(editTemplateData(updatedTemplate));
      } else {
        setThisTemplateSwitches((prev) => ({
          ...prev,
          saveContactButton: !checked,
        }));
        toast.error(message);
      }
    } catch (error) {
      setThisTemplateSwitches((prev) => ({
        ...prev,
        saveContactButton: !checked,
      }));
      handleFetchError(error as FetchError);
    }
  }

  const [switchTemplateLocks] = useSwitchTemplateLocksMutation();

  async function toggleControlButtonsLock() {
    setControlButtonsLocked((prev) => !prev);

    try {
      const {
        success,
        message,
        data: { updatedTemplate },
      } = await switchTemplateLocks({
        id: template.id,
        isControlButtonsLocked: !controlButtonsLocked,
      }).unwrap();

      if (success) {
        dispatch(editTemplateData(updatedTemplate));
        toast.success(message);
      } else {
        setControlButtonsLocked(controlButtonsLocked);
        toast.error(message);
      }
    } catch (error) {
      setControlButtonsLocked(controlButtonsLocked);
      handleFetchError(error as FetchError);
    }
  }

  async function toggleProfileOpensLock() {
    setProfileOpensLocked((prev) => !prev);

    try {
      const {
        success,
        message,
        data: { updatedTemplate },
      } = await switchTemplateLocks({
        id: template.id,
        isProfileOpensLocked: !profileOpensLocked,
      }).unwrap();

      if (success) {
        dispatch(editTemplateData(updatedTemplate));
        toast.success(message);
      } else {
        setProfileOpensLocked(profileOpensLocked);
        toast.error(message);
      }
    } catch (error) {
      setProfileOpensLocked(profileOpensLocked);
      handleFetchError(error as FetchError);
    }
  }

  const [
    addTemplateLinksToContactCard,
    { isLoading: isAddTemplateLinksToContactCardLoading },
  ] = useAddTemplateLinksToContactCardMutation();

  async function saveContactCard(linkIdsToAdd: Array<TemplateLink["id"]>) {
    try {
      const {
        success,
        message,
        data: { updatedTemplateLinks, updatedClonedMemberLinks },
      } = await addTemplateLinksToContactCard({
        templateId: template.id,
        templateLinkIds: linkIdsToAdd,
        isAddedToContactCardBy: AddedToContactCardBy.TEMPLATE,
      }).unwrap();
      if (success) {
        dispatch(editTemplateLinks(updatedTemplateLinks));
        dispatch(updateLinks(updatedClonedMemberLinks));
        toast.success(message);
        setEditContactCardDialogOpen(false);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
      setEditContactCardDialogOpen(false);
    }
  }

  return !linkToEdit ? (
    <div className={`flex flex-col gap-4 ${className}`} {...props}>
      <h4 className="text-xl font-semibold text-black">Content</h4>
      <div className="mb-4 flex flex-col items-center justify-between gap-4 lg:flex-row xl:flex-col 2xl:flex-row">
        <FormGroup className="relative h-11 !flex-row items-center gap-8 rounded-full border border-gray-200 bg-white pl-6 pr-px">
          <legend className="absolute -top-2.5 left-6 z-20 bg-white px-2 py-0.5 text-xs text-gray-600">
            Control Buttons
          </legend>
          <div className="flex gap-10">
            <SwitchX
              checked={thisTemplateSwitches.connectButton}
              onChange={handleConnetChange}
              label="Connect"
              labelPlacement="start"
            />
            <SwitchX
              checked={thisTemplateSwitches.saveContactButton}
              onChange={handleSaveContactChange}
              label="Save Contact"
              labelPlacement="start"
            />
          </div>
          <Tooltip
            title={`The Assignees can${
              controlButtonsLocked ? "not" : ""
            } change the setting of the "Connect" and "Save Contact" control button.`}
            placement="top"
            classes={{ popper: "!mb-0 !max-w-[15rem]" }}
          >
            <IconButton onClick={toggleControlButtonsLock} className="!p-2.5">
              {controlButtonsLocked ? (
                <LockIcon fontSize="small" />
              ) : (
                <LockOpenIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </FormGroup>
        <FormGroup className="relative h-11 !flex-row items-center gap-8 rounded-full border border-gray-200 bg-white pl-px pr-6">
          <legend className="absolute -top-2.5 left-[4.5rem] z-20 bg-white px-2 py-0.5 text-xs text-gray-600">
            When the profile opens
          </legend>
          <Tooltip
            title={
              profileOpensLocked
                ? "The Assignees will not be able to toggle the Lead Capture and OneShare."
                : "The Assignees can toggle the Lead Capture and OneShare."
            }
            placement="top"
            classes={{ popper: "!mb-0 !max-w-[13.5rem]" }}
          >
            <IconButton onClick={toggleProfileOpensLock} className="!p-2.5">
              {profileOpensLocked ? (
                <LockIcon fontSize="small" />
              ) : (
                <LockOpenIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
          <div className="flex gap-10">
            <SwitchX
              checked={thisTemplateSwitches.captureLead}
              onChange={handleCaptureLeadChange}
              label="Lead Capture"
              labelPlacement="start"
              disabled={!thisTemplateSwitches.connectButton}
            />
            <SwitchX
              checked={thisTemplateSwitches.openDirect}
              onChange={handleOpenDirectChange}
              label="OneShare"
              labelPlacement="start"
            />
          </div>
        </FormGroup>
      </div>
      <div>
        <ContactCardButton
          links={thisLinks.filter(
            (l) =>
              l.isAddedToContactCardBy === AddedToContactCardBy.TEMPLATE &&
              l.visible
          )}
          onClick={() => setEditContactCardDialogOpen(true)}
        />
        <DialogX
          open={editContactCardDialogOpen}
          onClose={() => setEditContactCardDialogOpen(false)}
          fullScreen
          className="!max-w-2xl"
        >
          <EditContactCard
            links={thisLinks.filter((l) => l.visible)}
            onSave={saveContactCard}
            onCancel={() => setEditContactCardDialogOpen(false)}
            loading={isAddTemplateLinksToContactCardLoading}
            forTemplate
          />
        </DialogX>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between gap-11">
          <h5 className="text-lg font-semibold text-black">
            Links and Contact Information
          </h5>
          <div>
            <Button
              id="add-links-contact-info"
              variant="contained"
              disableElevation
              startIcon={<FontAwesomeIcon icon={faPlus} />}
              classes={{
                root: "!rounded-full !bg-primary !py-3 !px-6 !font-sans !text-sm !font-semibold !normal-case",
                startIcon: "!text-xs font-normal",
              }}
              onClick={openAddLinkDialog}
            >
              Add New
            </Button>
            <DialogX
              open={addLinkDialogOpen}
              onClose={() => setAddLinkDialogOpen(false)}
              fullScreen
              className="!max-w-5xl"
            >
              {!linkTypeToAdd && (
                <SelectLinkType
                  onSelect={setLinkTypeToAdd}
                  className="h-[37rem]"
                />
              )}
              {!!linkTypeToAdd && (
                <AddTemplateLink
                  linkType={linkTypeToAdd}
                  onBack={() => setLinkTypeToAdd(undefined)}
                  onAdd={addToLinks}
                  onCancel={() => setAddLinkDialogOpen(false)}
                />
              )}
              {isCreateTemplateLinkPending && <Spinner fullScreen />}
            </DialogX>
          </div>
        </div>
        {thisLinks.length === 0 ? (
          <p className="flex h-64 flex-col items-center justify-center gap-2 text-center">
            <strong className="text-sm text-gray-700">
              This template does not have any linked content.
            </strong>
            <small className="block w-72 text-xs text-gray-600">
              Add buttons to contact information, websites, payment methods,
              social networks and more.
            </small>
          </p>
        ) : (
          <div className="relative">
            {isReorderTemplateLinksPending && (
              <Spinner className="bg-white/50" />
            )}
            <DragSortList list={thisLinks} onDrop={reOrderTheseLinks}>
              {thisLinks.map((aLink, index) => (
                <DragSortListItem
                  key={aLink.id}
                  item={aLink}
                  index={index}
                  draggable={!thisTemplateSwitches.openDirect}
                  disabled={
                    thisTemplateSwitches.openDirect && aLink.sequence !== 0
                  }
                >
                  <FontAwesomeIcon
                    icon={faGrip}
                    className={`text-sm font-black text-gray-400 ${
                      thisTemplateSwitches.openDirect && aLink.sequence !== 0
                        ? "opacity-50"
                        : ""
                    }`}
                  />
                  <span
                    onClick={() => editThisLink(aLink)}
                    className={`flex grow items-center gap-4 ${
                      thisTemplateSwitches.openDirect && aLink.sequence !== 0
                        ? "opacity-50"
                        : ""
                    }`}
                  >
                    <Avatar
                      alt={aLink.title}
                      variant="rounded"
                      className="!rounded-[0.439rem] !bg-transparent shadow-md"
                    >
                      {aLink.iconSVG && (
                        <SvgIcon className="!h-full !w-full">
                          {parse(aLink.iconSVG)}
                        </SvgIcon>
                      )}
                    </Avatar>
                    <strong className="font-semibold">{aLink.title}</strong>
                    {/* {aLink.isAddedToContactCardBy === AddedToContactCardBy.TEMPLATE && (
                      <Tooltip
                        title={`This button will be added to the Contact Card of all 
                                assignees. The assignee will not be able to remove it.`}
                        placement="top"
                        classes={{ tooltip: "!mb-0" }}
                      >
                        <IconButton>
                          <RecentActorsOutlinedIcon className="!text-xl !text-gray-500" />
                        </IconButton>
                      </Tooltip>
                    )} */}
                  </span>
                  <>
                    {aLink.isUnique && (
                      <span
                        title="The assignees can add and edit this button."
                        className="relative flex items-center text-gray-600"
                      >
                        <FontAwesomeIcon icon={faAddressCard} />
                        <FontAwesomeIcon
                          icon={faPen}
                          className="absolute -right-1 -top-0.5 text-xs"
                        />
                      </span>
                    )}
                    {!thisTemplateSwitches.openDirect && (
                      <SwitchX
                        checked={aLink.visible}
                        onChange={(_e, checked) =>
                          handleLinkVisibleChange(aLink.id, checked)
                        }
                      />
                    )}
                    {thisTemplateSwitches.openDirect &&
                      aLink.sequence !== 0 && (
                        <Button
                          variant="outlined"
                          classes={{
                            root: "!rounded-full !border !border-gray-200 !px-4 !py-2 !font-sans !text-xs !font-semibold !normal-case !text-black",
                          }}
                          onClick={() => handleMakeDirectClick(aLink.id)}
                        >
                          Set as OneShare
                        </Button>
                      )}
                  </>
                </DragSortListItem>
              ))}
            </DragSortList>
          </div>
        )}
      </div>
    </div>
  ) : (
    <EditTemplateLink
      link={linkToEdit}
      onBack={() => setLinkToEdit(undefined)}
      onChange={previewLinkChange}
      onUpdate={() => setLinkToEdit(undefined)}
      onRemove={() => setLinkToEdit(undefined)}
      onCancel={() => setLinkToEdit(undefined)}
    />
  );
}

export default EditTemplateContent;
