import {
  Avatar,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  SvgIcon,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import parse from "html-react-parser";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { FloatingLabel, Spinner } from "../../components";
import { useAppDispatch, useAppSelector } from "../../store";
import { FetchError, handleFetchError } from "../../utils";
import {
  UserMembership,
  UserRole,
  getCurrentUser,
  setCurrentUser,
  useGetBusinessProfileMutation,
} from "../users";
import { useReadLinkTypesMutation } from "./linksService";
import {
  getLinkCategories,
  getLinkTypes,
  setLinkCategories,
  setLinkTypes,
} from "./linksSlice";
import type { LinkCategory, LinkType } from "./linksTypes";

type SelectLinkTypeProps = {
  onSelect?: (link: LinkType) => void;
  onGoPro?: () => void;
  className?: string;
};

function SelectLinkType({
  onSelect,
  onGoPro,
  className,
  ...props
}: SelectLinkTypeProps) {
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [filteredLinkCategories, setFilteredLinkCategories] = useState<
    LinkCategory[]
  >([]);
  const [filteredLinkTypes, setFilteredLinkTypes] = useState<LinkType[]>([]);

  const searchButtonsRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(getCurrentUser);
  const linkCategories: LinkCategory[] = useAppSelector(getLinkCategories);
  const linkTypes: LinkType[] = useAppSelector(getLinkTypes);

  const [getBusinessProfile] = useGetBusinessProfileMutation();

  useEffect(() => {
    if (!currentUser) {
      (async () => {
        try {
          const {
            success,
            message,
            data: { user: thisAdmin },
          } = await getBusinessProfile().unwrap();
          if (success) {
            dispatch(setCurrentUser(thisAdmin));
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    }
  }, [currentUser, dispatch, getBusinessProfile]);

  const [readLinkTypes, { isLoading: isReadLinkTypesPending }] =
    useReadLinkTypesMutation();

  useEffect(() => {
    if (linkTypes.length === 0) {
      (async () => {
        try {
          const {
            success,
            message,
            data: {
              linkCategories: allLinkCategories,
              linkTypes: allLinkTypes,
            },
          } = await readLinkTypes().unwrap();
          if (success) {
            dispatch(setLinkCategories(allLinkCategories));
            dispatch(setLinkTypes(allLinkTypes));
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    } else {
      setFilteredLinkCategories(linkCategories);
      setFilteredLinkTypes(linkTypes);
    }
  }, [linkCategories, linkTypes, readLinkTypes, dispatch]);

  function onSearch(keyword: string): void {
    setSearchKeyword(keyword);
    const fLTs: LinkType[] = linkTypes.filter((lt) =>
      lt.title.toLowerCase().includes(keyword.toLowerCase())
    );
    setFilteredLinkTypes(fLTs);
    setFilteredLinkCategories(
      linkCategories.filter(
        (lc) => fLTs.filter((flt) => flt.categoryId === lc.id).length > 0
      )
    );
  }

  function handleKeyUpOnSearch(e: KeyboardEvent<HTMLInputElement>): void {
    // if (!!onSearch && (e.key === "Enter" || searchKeyword.length >= 3))
    onSearch(searchKeyword);
  }

  return (
    <div className={`flex flex-col gap-8 ${className ?? ""}`} {...props}>
      <div className="flex items-center justify-between gap-8">
        <div className="flex flex-col gap-0.5">
          <DialogTitle className="!p-0 !font-sans !font-semibold text-black">
            Add content
          </DialogTitle>
          <p className="text-sm text-gray-500">
            Select from our wide variety of buttons below.
          </p>
        </div>
        <FloatingLabel
          size="small"
          onInputValueChange={setSearchKeyword}
          onSearchClick={onSearch}
        >
          <input
            type="search"
            ref={searchButtonsRef}
            name="search-buttons"
            placeholder="Search"
            value={searchKeyword}
            onKeyUp={handleKeyUpOnSearch}
          />
        </FloatingLabel>
      </div>
      <DialogContent className="!p-0">
        {isReadLinkTypesPending && (
          <Spinner className="bg-transparent filter" />
        )}
        {filteredLinkTypes.length > 0 && (
          <List classes={{ root: "flex flex-col gap-8 !p-0" }}>
            {filteredLinkCategories?.map((lc) => (
              <ListItem
                key={lc.id}
                classes={{ root: "flex flex-col !p-0 !items-stretch gap-2" }}
              >
                <ListSubheader
                  component="strong"
                  classes={{
                    root: "!p-0 !font-sans !text-black !font-semibold !leading-normal !pb-2",
                  }}
                >
                  {lc.title}
                </ListSubheader>
                <List
                  classes={{
                    root: "grid grid-cols-3 gap-4 !p-0",
                  }}
                >
                  {filteredLinkTypes
                    .filter((lt) => lt.categoryId === lc.id)
                    .map((lt) => (
                      <ListItem key={lt.id} classes={{ root: "!p-0" }}>
                        <ListItemButton
                          onClick={() =>
                            currentUser?.role !== UserRole.BUSINESS_OWNER &&
                            currentUser?.role !== UserRole.BUSINESS_ADMIN &&
                            lt.membership !== UserMembership.BASIC
                              ? onGoPro?.()
                              : onSelect?.(lt)
                          }
                          classes={{
                            root: `gap-4 !rounded-2xl ${
                              currentUser?.role !== UserRole.BUSINESS_OWNER &&
                              currentUser?.role !== UserRole.BUSINESS_ADMIN &&
                              lt.membership !== UserMembership.BASIC
                                ? "!border-primary"
                                : "!border-gray-200"
                            } !bg-gray-100 !px-3 !py-4`,
                          }}
                          sx={{ border: 1 }}
                        >
                          <ListItemAvatar
                            classes={{ root: "!min-w-0 drop-shadow-lg" }}
                          >
                            <Avatar
                              variant="rounded"
                              alt={lt.title}
                              className="!h-[3.375rem] !w-[3.375rem] !rounded-[0.658rem] !bg-transparent"
                            >
                              {lt.iconSVGDefault && (
                                <SvgIcon className="!h-full !w-full">
                                  {parse(lt.iconSVGDefault)}
                                </SvgIcon>
                              )}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={lt.title}
                            classes={{
                              primary: "!font-sans !font-semibold text-black",
                            }}
                          />
                          <IconButton
                            edge="end"
                            aria-label="add"
                            classes={{
                              root: "!bg-white !text-black !p-0 !m-0 !border !border-gray-200 !rounded-full w-8 h-8",
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </ListItemButton>
                        {currentUser?.role !== UserRole.BUSINESS_OWNER &&
                          currentUser?.role !== UserRole.BUSINESS_ADMIN &&
                          lt.membership !== UserMembership.BASIC && (
                            <ListItemSecondaryAction
                              classes={{ root: "!top-0 !left-4 !flex" }}
                            >
                              <span className="rounded-full bg-primary px-3 py-px text-xs font-semibold text-white">
                                Pro
                              </span>
                            </ListItemSecondaryAction>
                          )}
                      </ListItem>
                    ))}
                </List>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
    </div>
  );
}

export default SelectLinkType;
