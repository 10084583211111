import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faPanorama, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BusinessRoundedIcon from "@mui/icons-material/Business";
import CreditCardOffOutlinedIcon from "@mui/icons-material/CreditCardOffOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../store";
import type { Template } from "./templatesTypes";
import {
  AlertDialog,
  ConfirmationDialog,
  MoreOptionsItem,
  MoreOptionsMenu,
} from "../../components";
import { removeLinks } from "../links/linksSlice";
import {
  useDeleteTemplateMutation,
  useSetAsDefaultTemplateMutation,
} from "./templatesService";
import { removeTemplate, setDefaultTemplate } from "./templatesSlice";
import { FetchError, handleFetchError } from "../../utils";
import { EditTemplateSections, getEditTemplateHashTag } from "./templatesEnums";

type TemplateCardProps = {
  info: Template;
  className?: string;
};

function TemplateCard({ info, className = "", ...props }: TemplateCardProps) {
  const [deleteTemplateDialogShow, setDeleteTemplateDialogShow] =
    useState<boolean>(false);
  const [defaultTemplateDialogShow, setDefaultTemplateDialogShow] =
    useState<boolean>(false);

  const navigateTo = useNavigate();
  const dispatch = useAppDispatch();

  function manageMembers(): void {
    const assigneesHashTag = getEditTemplateHashTag(
      EditTemplateSections.ASSIGNEES
    );
    navigateTo("/templates/" + info.id + "/edit" + assigneesHashTag);
  }

  const [deleteTemplate, { isLoading: isDeleteTemplatePending }] =
    useDeleteTemplateMutation();

  async function handleDeleteTemplateConfirm() {
    try {
      const {
        success,
        message,
        data: { removedTemplateId, removedMemberLinkIds },
      } = await deleteTemplate(info.id).unwrap();
      if (success) {
        dispatch(removeTemplate(removedTemplateId));
        dispatch(removeLinks(removedMemberLinkIds));
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }

    setDeleteTemplateDialogShow(false);
  }

  const [setAsDefaultTemplate] = useSetAsDefaultTemplateMutation();

  async function setThisTemplateAsDefault() {
    try {
      const {
        success,
        message,
        data: { defaultTemplateId },
      } = await setAsDefaultTemplate(info.id).unwrap();
      if (success) {
        dispatch(setDefaultTemplate(defaultTemplateId));
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  return (
    <div
      className={`relative flex flex-col gap-6 rounded-2xl bg-white p-4 ${className}`}
      {...props}
    >
      <div className="flex grow flex-col items-center">
        <div className="relative flex flex-col items-center self-stretch">
          <div
            className="flex h-[9.5rem] items-center justify-center self-stretch rounded-2xl bg-gray-100 bg-cover bg-center text-4xl text-gray-400"
            aria-label="Profile Banner"
            style={{ backgroundImage: `url(${info.profileBanner})` }}
          >
            <FontAwesomeIcon icon={faPanorama} hidden={!!info.profileBanner} />
          </div>
          <div className="relative -top-[3.125rem] flex items-end">
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <Avatar
                  src={info.companyLogo}
                  classes={{
                    root: "!h-10 !w-10 !border-4 !border-white !bg-gray-100 !text-gray-400",
                  }}
                  aria-label={info.companyName}
                >
                  <BusinessRoundedIcon />
                </Avatar>
              }
            >
              <Avatar
                src={info.profileImage}
                classes={{
                  root: "!h-[6.25rem] !w-[6.25rem] !border-4 !border-white !bg-gray-100 !text-gray-400",
                }}
                aria-label={info.name}
              />
            </Badge>
          </div>
        </div>
        <div className="flex flex-col items-center gap-1">
          <div className="flex items-center justify-center gap-3">
            <strong className="min-h-[2.25rem] max-w-[11rem] truncate text-3xl font-semibold text-gray-900">
              {info.name}
            </strong>
            {info.isDefault && (
              <div className="rounded-2xl bg-gray-100 px-3 py-1">Default</div>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-[3.75rem] text-sm text-gray-500">
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faUser} aria-label="Number of leads" />
          <span className="font-medium">{info.assigneeIds.length} Members</span>
        </div>
      </div>
      <div className="flex h-9 items-end justify-end border-t border-gray-200">
        <MoreOptionsMenu className="relative z-20 !text-gray-500">
          {!info.isDefault ? (
            <MoreOptionsItem
              icon={<BookmarkBorderOutlinedIcon className="!text-base" />}
              label="Set as Default"
              onClick={setThisTemplateAsDefault}
            />
          ) : (
            <></>
          )}
          <MoreOptionsItem
            icon={<GroupAddOutlinedIcon className="!text-base" />}
            label={
              info.assigneeIds.length ? "Manage Members" : "Assign Members"
            }
            onClick={manageMembers}
          />
          <MoreOptionsItem type="divider" />
          <MoreOptionsItem
            icon={<CreditCardOffOutlinedIcon className="!text-base" />}
            label="Remove Template"
            onClick={() =>
              info.isDefault
                ? setDefaultTemplateDialogShow(true)
                : setDeleteTemplateDialogShow(true)
            }
            danger
          />
        </MoreOptionsMenu>
        <AlertDialog
          title="This is the Default Template!"
          content="You need to set another template as default before removing this template."
          onConfirm={() => setDefaultTemplateDialogShow(false)}
          show={defaultTemplateDialogShow}
        />
        <ConfirmationDialog
          title="Confirm Removal"
          content="Are you sure you want to remove this template?"
          confirmButtonIcon={
            isDeleteTemplatePending ? (
              <FontAwesomeIcon icon={faSpinner} className="!text-sm" />
            ) : (
              <CreditCardOffOutlinedIcon className="!text-sm" />
            )
          }
          confirmButtonText="Remove"
          onConfirm={handleDeleteTemplateConfirm}
          onCancel={() => setDeleteTemplateDialogShow(false)}
          open={deleteTemplateDialogShow}
          danger
        />
      </div>
      <Link
        to={`/templates/${info.id}/edit`}
        className="absolute inset-0 z-10 overflow-hidden -indent-96 text-transparent"
      >
        Edit Template
      </Link>
    </div>
  );
}

export default TemplateCard;
