import { faAddressCard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BusinessRoundedIcon from "@mui/icons-material/Business";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import { Avatar, Badge, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { MemberDataLabels } from "../features/members/membersEnums";
import type {
  DropdownMenuItem,
  Member,
  MemberEditMenuInfo,
} from "../features/members/membersTypes";
import type { Template } from "../features/templates/templatesTypes";
import MemberEditMenu from "./MemberEditMenu";

type MemberRow = {
  member: Member;
  template?: Template;
};

const membersGridColumnsInit: GridColDef[] = [
  {
    field: "member",
    headerName: "Member",
    flex: 1,
    sortable: false,
    renderCell: ({
      row: { member, template },
    }: GridRenderCellParams<MemberRow>) => (
      <div className="flex gap-6">
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <Avatar
              src={template?.companyLogo || member.companyLogo}
              classes={{
                root: "!h-[1.875rem] !w-[1.875rem] !border-2 !border-white !bg-gray-100 !text-gray-400",
              }}
              aria-label={template?.companyName || member.companyName}
            >
              <BusinessRoundedIcon />
            </Avatar>
          }
          classes={{
            badge: "!bottom-2.5",
          }}
        >
          <Avatar
            src={template?.profileImage || member.profileImage}
            classes={{
              root: "!h-[3.3125rem] !w-[3.3125rem] !bg-gray-100 !text-gray-400",
            }}
            aria-label={member.fullName || member.username}
          />
        </Badge>
        <span className="flex flex-col justify-center">
          <span>
            <strong>{member.fullName || member.username}</strong>
            {/* {true && (
              <small className='text-sm font-medium text-gray-500'>(me)</small>
            )} */}
          </span>
          <span className="text-xs font-semibold text-gray-600">
            {member.designation}
          </span>
        </span>
      </div>
    ),
  },
  {
    field: "role",
    headerName: "Role",
    sortable: false,
    renderCell: (params: GridRenderCellParams<MemberRow>) => (
      <span className="relative -left-3 rounded-2xl bg-gray-100 px-3 py-1 capitalize text-gray-700">
        {params.row.member.role}
      </span>
    ),
  },
  {
    field: "email",
    headerName: MemberDataLabels.EMAIL,
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams<MemberRow>) =>
      params.row.member.email,
  },
  {
    field: "template",
    headerName: "Template",
    flex: 1,
    maxWidth: 200,
    sortable: false,
    renderCell: (params: GridRenderCellParams<MemberRow>) =>
      params.row.template ? (
        <Link
          to={`/templates/${params.row.template.id}/edit`}
          className="max-w-full"
        >
          <Typography
            component="button"
            aria-label="Change Template"
            noWrap
            className="relative -left-3 flex h-8 max-w-full items-center gap-2 rounded-2xl bg-gray-100 px-3 !font-sans !text-sm text-gray-600"
          >
            <FontAwesomeIcon icon={faAddressCard} className="text-base" />
            {params.row.template.name}
          </Typography>
        </Link>
      ) : (
        <Link to={`/templates`}>
          <IconButton
            size="small"
            aria-label="Set Template"
            className="relative -left-2 !text-gray-600"
          >
            <DashboardCustomizeIcon />
          </IconButton>
        </Link>
      ),
  },
  {
    field: "viewsCount",
    headerName: "Views",
    type: "number",
    width: 110,
    renderCell: (params: GridRenderCellParams<MemberRow>) =>
      params.row.member.viewsCount,
  },
  {
    field: "leadsCount",
    headerName: "Leads",
    type: "number",
    width: 110,
    renderCell: (params: GridRenderCellParams<MemberRow>) =>
      params.row.member.leadsCount,
  },
  {
    field: "devicesCount",
    headerName: "Devices",
    type: "number",
    width: 110,
    renderCell: (params: GridRenderCellParams<MemberRow>) =>
      params.row.member.devicesCount,
  },
];

function addToMembersGridColumns(actions: DropdownMenuItem[]): GridColDef[] {
  return [
    ...membersGridColumnsInit,
    {
      field: "actions",
      headerName: "",
      sortable: false,
      width: 88,
      renderCell: (params: GridRenderCellParams<MemberRow>) => (
        <MemberEditMenu
          member={params.row.member as MemberEditMenuInfo}
          template={params.row.template}
          items={actions}
          className="relative z-20 !aspect-square w-[3.25rem] !min-w-0 !rounded-full !border-none !p-0 !text-gray-500"
        />
      ),
    },
  ];
}

type MembersListViewProps = {
  members: Member[];
  templates?: Template[];
  actions?: DropdownMenuItem[];
  selectedMembers?: GridRowSelectionModel;
  onSelectionChange?: (
    rowSelectionModel: GridRowSelectionModel,
    _details: GridCallbackDetails
  ) => void;
};

function MembersListView({
  members,
  templates,
  actions,
  selectedMembers,
  onSelectionChange,
}: MembersListViewProps) {
  const [membersGridColumns] = useState<GridColDef[]>(
    actions?.length ? addToMembersGridColumns(actions) : membersGridColumnsInit
  );

  const navigateTo = useNavigate();

  function editMember(params: GridCellParams<MemberRow>): void {
    if (!["__check__", "template", "actions"].includes(params.field)) {
      navigateTo(`/members/${params.row.member.id}/edit`);
    }
  }

  return (
    <DataGrid
      getRowId={(row) => row.member.id}
      rows={
        members.map((m) => ({
          member: m,
          template: templates?.find((t) => t.assigneeIds.includes(m.id)),
        })) as MemberRow[]
      }
      columns={membersGridColumns}
      checkboxSelection
      loading={!members}
      getRowHeight={() => "auto"}
      getRowSpacing={(_params) => ({
        bottom: 8,
      })}
      disableRowSelectionOnClick
      hideFooter
      autoHeight
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      showColumnVerticalBorder={false}
      showCellVerticalBorder={false}
      columnBuffer={0}
      columnThreshold={0}
      classes={{
        root: "!border-0",
        main: "!overflow-visible",
        virtualScroller: "!overflow-visible",
        columnHeaders: "!border-0",
        columnHeader: "!px-1 !py-2.5 xl:!px-6",
        columnHeaderCheckbox: "!pl-0 !pr-0 xl:!pl-0 xl:!pr-0",
        columnHeaderTitle: "font-sans text-sm !font-normal text-gray-600",
        row: "cursor-pointer rounded-2xl border border-white bg-white hover:!bg-primary-50",
        cell: "truncate !border-0 !px-1 !py-2.5 font-sans text-sm text-gray-900 xl:!px-6",
        checkboxInput: "!text-gray-500",
      }}
      sx={{
        "& .MuiDataGrid-row.Mui-selected": {
          backgroundColor: "#ffffff",
          borderColor: "#000000",
        },
        "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
          color: "#111827 !important",
        },
      }}
      columnHeaderHeight={40}
      rowSelectionModel={selectedMembers}
      onRowSelectionModelChange={onSelectionChange}
      onCellClick={editMember}
    />
  );
}

export default MembersListView;
