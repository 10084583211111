import { cloneElement, ReactElement } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";

type DropdownItemXProps = {
  icon: ReactElement;
  label: string;
  description: string;
  onClick?: () => void;
};

function DropdownItemX({
  icon,
  label,
  description,
  onClick,
  ...props
}: DropdownItemXProps) {
  return (
    <MenuItem
      onClick={onClick}
      classes={{
        root: "group gap-3 !py-3 !px-5 !text-gray-900 hover:!bg-primary-50 hover:!text-primary",
      }}
      {...props}
    >
      <ListItemIcon className="h-10 w-10 items-center justify-center rounded !bg-gray-100 fill-gray-500 !text-gray-500 group-hover:!bg-primary/10 group-hover:fill-primary group-hover:!text-primary">
        {cloneElement(icon as React.ReactElement, {
          classes: { root: "!text-sm" },
        })}
      </ListItemIcon>
      <ListItemText
        primary={label}
        secondary={description}
        classes={{
          primary: "!font-sans !text-xs !font-semibold",
          secondary: "!font-sans !text-[0.625rem] !text-gray-900 text-wrap",
        }}
      />
    </MenuItem>
  );
}

export default DropdownItemX;
