import { createApi } from "@reduxjs/toolkit/query/react";
import APIConfigs from "../../configs/apiConfigs";
import { mapGetBusinessProfileResponse } from "../authentication/authMapper";
// eslint-disable-next-line
import { baseQueryWithReauth } from "../rtk-query/baseQuery";
import type { GetBusinessProfileResponse } from "./usersTypes";

const usersService = createApi({
  reducerPath: "usersService",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getBusinessProfile: builder.mutation<GetBusinessProfileResponse, void>({
      query: () => ({
        method: "GET",
        url: APIConfigs.Authentication.BUSINESS_PROFILE,
      }),
      transformResponse: mapGetBusinessProfileResponse,
    }),
  }),
});

export default usersService;
export const { useGetBusinessProfileMutation } = usersService;
