export enum UserRole {
  BUSINESS_OWNER = "owner", // This is the BP Owner when creating a new BP via Admin Panel.
  BUSINESS_ADMIN = "admin", // Same Admin rights as the "Owner", but doesn’t own the BP.
  BUSINESS_USER = "user", // Normal Business User of the BP
}

export enum UserMembership {
  BASIC = 0,
  ADVANCED = 1,
  PRO = 2,
}
