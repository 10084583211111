import { createApi } from "@reduxjs/toolkit/query/react";
import APIConfigs from "../../configs/apiConfigs";
import { baseQueryWithReauth } from "../rtk-query/baseQuery";
import { mapGetSettingsResponse } from "./settingsHelpers";
import {
  GetSettingsResponse,
  UpdateMembersRoleRequestArgs,
  UpdateMembersRoleResponse,
} from "./settingsTypes";

const settingsService = createApi({
  reducerPath: "settingsService",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getSettings: builder.query<GetSettingsResponse, void>({
      query: () => APIConfigs.Settings.READ_SETTINGS,
      transformResponse: mapGetSettingsResponse,
    }),
    updateMembersRole: builder.mutation<
      UpdateMembersRoleResponse,
      UpdateMembersRoleRequestArgs
    >({
      query: ({ ids, role }) => ({
        url: APIConfigs.Settings.UPDATE_MEMBERS_ROLE,
        method: "PUT",
        body: { ids, role },
      }),
    }),
  }),
});

export default settingsService;
export const { useGetSettingsQuery, useUpdateMembersRoleMutation } =
  settingsService;
