import { Suspense, lazy } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Spinner from "./components/Spinner";
import { UserRole } from "./features/users/usersEnums";
import LayoutLogin from "./layouts/LayoutLogin";
import LayoutProtected from "./layouts/LayoutProtected";
import {
  AddMembers,
  CardCustomization,
  EditTemplate,
  ForgotPassword,
  Login,
  Members,
  ResetPassword,
  Templates,
  Unauthorized,
  VerifyCode,
} from "./pages";
import "./styles/main.scss";

const OAuthRedirect = lazy(
  () => import("./features/integrations/OAuthRedirect")
);
const Integrations = lazy(() => import("./pages/integrations"));
const Settings = lazy(() => import("./pages/settings"));

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LayoutLogin />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify-code" element={<VerifyCode />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>
        <Route
          element={
            <LayoutProtected
              allowedRoles={[UserRole.BUSINESS_OWNER, UserRole.BUSINESS_ADMIN]}
            />
          }
        >
          <Route path="/members" element={<Members />} />
          <Route path="/members/new" element={<AddMembers />} />
          <Route path="/members/:id/edit" element={<CardCustomization />} />
          <Route path="/templates" element={<Templates />} />
          <Route path="/templates/:id/edit" element={<EditTemplate />} />
          <Route
            path="/integrations"
            element={
              <Suspense fallback={<Spinner fullScreen />}>
                <Integrations />
              </Suspense>
            }
          />
          <Route
            path="/integrations/:platform/oauth-redirect"
            element={
              <Suspense fallback={<Spinner fullScreen />}>
                <OAuthRedirect />
              </Suspense>
            }
          />
          <Route
            path="/settings"
            element={
              <Suspense fallback={<Spinner fullScreen />}>
                <Settings />
              </Suspense>
            }
          />
        </Route>
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<Navigate to="/members" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
