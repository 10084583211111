import { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import {
  useReadIntegrationsMutation,
  useReadIPlatformsMutation,
  useReadSyncedMembersQuery,
} from "../features/integrations/integrationsService";
import {
  getIntegrations,
  getIPlatforms,
  getSyncedMembers,
  setIntegrations,
  setIPlatforms,
  setSyncedMembers,
} from "../features/integrations/integrationsSlice";
import type {
  Integration,
  IPlatform,
  SyncedMember,
} from "../features/integrations/integrationsTypes";
import { useAppDispatch, useAppSelector } from "../store";
import { FetchError, handleFetchError } from "../utils";

type UseIntegrationsReturnType = {
  platforms: IPlatform[] | undefined;
  integrations: Integration[] | undefined;
  syncedMembers: SyncedMember[] | undefined;
  isLoading: boolean;
};

function useIntegrations(): UseIntegrationsReturnType {
  const dispatch = useAppDispatch();

  const platforms = useAppSelector(getIPlatforms);
  const integrations = useAppSelector(getIntegrations);
  const syncedMembers = useAppSelector(getSyncedMembers);

  const [readIPlatforms, { isLoading: isReadIPlatformsPending }] =
    useReadIPlatformsMutation();

  const getAllPlatforms = useCallback(
    async function () {
      try {
        const { success, message, data } = await readIPlatforms().unwrap();
        if (success) {
          dispatch(setIPlatforms(data));
        } else {
          toast.error(message);
        }
      } catch (error) {
        handleFetchError(error as FetchError);
      }
    },
    [readIPlatforms, dispatch]
  );

  useEffect(() => {
    if (platforms === undefined) {
      getAllPlatforms();
    }
  }, [platforms, getAllPlatforms]);

  const [readIntegrations, { isLoading: isReadIntegrationsPending }] =
    useReadIntegrationsMutation();

  const getAllIntegrations = useCallback(
    async function () {
      try {
        const { success, message, data } = await readIntegrations().unwrap();
        if (success) {
          dispatch(setIntegrations(data));
        } else {
          toast.error(message);
        }
      } catch (error) {
        handleFetchError(error as FetchError);
      }
    },
    [readIntegrations, dispatch]
  );

  useEffect(() => {
    if (integrations === undefined) {
      getAllIntegrations();
    }
  }, [integrations, getAllIntegrations]);

  const { data: readSyncedMembersResponse } = useReadSyncedMembersQuery();

  useEffect(() => {
    if (
      platforms?.length &&
      integrations?.length &&
      syncedMembers === undefined &&
      readSyncedMembersResponse
    ) {
      const {
        success,
        message,
        data: allSyncedMembers,
      } = readSyncedMembersResponse;
      if (success) {
        dispatch(setSyncedMembers(allSyncedMembers));
      } else if (message) {
        toast.error(message);
      }
    }
  }, [
    platforms?.length,
    integrations?.length,
    syncedMembers,
    readSyncedMembersResponse,
    dispatch,
  ]);

  return {
    platforms,
    integrations,
    syncedMembers,
    isLoading: isReadIPlatformsPending || isReadIntegrationsPending,
  };
}

export default useIntegrations;
