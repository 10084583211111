import {
  faAddressCard,
  faContactBook,
  faSave,
} from "@fortawesome/free-regular-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SvgIcon,
  Tooltip,
} from "@mui/material";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AddedToContactCardBy } from "../features/links/linksEnums";
import type { MemberLink } from "../features/links/linksTypes";
import type { TemplateLink } from "../features/templates/templatesTypes";
import theme from "../styles/mui-styled";
import SwitchX from "./SwitchX";

type ContactCardForm = Record<string, boolean>;

type ContactCardLink = Pick<
  MemberLink | TemplateLink,
  | "id"
  | "iconSVG"
  | "title"
  | "isAddedToContactCardBy"
  | "templateId"
  | "isUnique"
>;

type EditContactCardProps = {
  links: ContactCardLink[];
  onSave?: (linkIdsToAdd: Array<(MemberLink | TemplateLink)["id"]>) => void;
  onCancel?: () => void;
  loading?: boolean;
  forTemplate?: boolean;
  className?: string;
};

function EditContactCard({
  links,
  onSave,
  onCancel,
  loading = false,
  forTemplate = false,
  className = "",
  ...props
}: EditContactCardProps) {
  const [thisLinks, setThisLinks] = useState<ContactCardLink[]>([]);
  const [tootlTipOpen, setTooltipOpen] = useState<boolean>(false);

  const {
    control: contactCardControl,
    formState: { isDirty },
    handleSubmit,
  } = useForm<ContactCardForm>({
    defaultValues: links.reduce((acc, link) => {
      acc[`isAddedToContactCardBy-${link.id}`] =
        link.isAddedToContactCardBy !== AddedToContactCardBy.NONE;
      return acc;
    }, {} as ContactCardForm),
  });

  useEffect(() => {
    setThisLinks(
      links.map((link) => ({
        id: link.id,
        iconSVG: link.iconSVG,
        title: link.title,
        isAddedToContactCardBy: link.isAddedToContactCardBy,
        templateId: link.templateId,
        isUnique: link.isUnique,
      }))
    );
  }, [links]);

  function addToContactCard(data: Record<string, boolean>): void {
    // Get the ids of all the Buttons that are added to the contact card
    let linkIdsToAdd = Object.entries(data)
      .filter(([, value]) => value === true)
      .map(([key]) => Number(key.split("-")[1]));

    // Filter out the Buttons in Members that are added from the template
    if (!forTemplate) {
      const linkIdsAddedFromTemplate = thisLinks
        .filter(
          (link) =>
            link.isAddedToContactCardBy === AddedToContactCardBy.TEMPLATE
        )
        .map((link) => link.id);

      linkIdsToAdd = linkIdsToAdd.filter(
        (linkId) => !linkIdsAddedFromTemplate.includes(linkId)
      );
    }

    onSave?.(linkIdsToAdd);
  }

  return (
    <div
      className={`relative flex flex-col gap-8 overflow-hidden ${
        className ?? ""
      }`}
      {...props}
    >
      <FontAwesomeIcon
        icon={faContactBook}
        className="absolute right-0 top-14 -translate-y-1/2 -rotate-12 text-9xl text-primary-50"
      />
      <div className="flex flex-col gap-0.5">
        <DialogTitle className="!p-0 !font-sans !font-semibold text-black">
          Contact Card
        </DialogTitle>
        <p className="text-sm text-gray-500">
          Choose which buttons to include in your contact Card.
          <Tooltip
            title={
              forTemplate
                ? `You can select buttons from the template to be part of the 
                  assignee's contact card. If the lock is enabled, the single 
                  assignee cannot change this setting.`
                : `You can select buttons to be part of the contact card of the 
                  member, including the buttons from the template if 
                  assigned.`
            }
            placement="top"
            open={tootlTipOpen}
            onClose={() => setTooltipOpen(false)}
            disableHoverListener
            classes={{ tooltip: "!mb-0" }}
          >
            <IconButton onClick={() => setTooltipOpen(!tootlTipOpen)}>
              <HelpOutlineIcon className="!text-base !text-gray-500" />
            </IconButton>
          </Tooltip>
        </p>
      </div>
      <DialogContent className="!p-0">
        {thisLinks.length === 0 ? (
          <p className="flex h-64 flex-col items-center justify-center gap-2 text-center">
            <strong className="text-sm text-gray-700">
              This template does not have any linked content.
            </strong>
            <small className="block w-72 text-xs text-gray-600">
              Add buttons to contact information, websites, payment methods,
              social networks and more.
            </small>
          </p>
        ) : (
          <div className={`flex flex-col gap-3 ${className}`} {...props}>
            {thisLinks
              .sort(
                (a, b) =>
                  (a.isAddedToContactCardBy === AddedToContactCardBy.NONE
                    ? 1
                    : -1) -
                  (b.isAddedToContactCardBy === AddedToContactCardBy.NONE
                    ? 1
                    : -1)
              )
              .map((aLink, index) => (
                <div
                  key={"contact-card-button-" + index}
                  className="flex items-center justify-between gap-5 rounded-2xl bg-gray-50 p-3"
                >
                  <span className="flex grow items-center gap-4">
                    <Avatar
                      alt={aLink.title}
                      variant="rounded"
                      className="!rounded-[0.439rem] !bg-transparent shadow-md"
                    >
                      {aLink.iconSVG && (
                        <SvgIcon className="!h-full !w-full">
                          {parse(aLink.iconSVG)}
                        </SvgIcon>
                      )}
                    </Avatar>
                    <strong className="font-semibold">{aLink.title}</strong>
                  </span>
                  {!forTemplate &&
                    (aLink.isAddedToContactCardBy ===
                      AddedToContactCardBy.TEMPLATE ||
                      Boolean(aLink.templateId)) && (
                      <Tooltip
                        title={
                          aLink.isAddedToContactCardBy ===
                          AddedToContactCardBy.TEMPLATE
                            ? `This button is added from the template. You can't remove 
                                it from the contact card here.`
                            : aLink.isUnique
                            ? `This button comes from a template, \nhowever edits made to this button are permitted.`
                            : `This button comes from a template, and \ncan only be edited from the template.`
                        }
                        placement="top"
                        classes={{ tooltip: "!mb-0" }}
                      >
                        <IconButton className="relative">
                          <FontAwesomeIcon
                            icon={faAddressCard}
                            className={`text-base ${
                              aLink.isAddedToContactCardBy ===
                              AddedToContactCardBy.TEMPLATE
                                ? "text-gray-400"
                                : "text-gray-600"
                            }`}
                          />
                          {aLink.isUnique && (
                            <FontAwesomeIcon
                              icon={faPen}
                              className={`absolute right-1 top-1.5 text-xs ${
                                aLink.isAddedToContactCardBy ===
                                AddedToContactCardBy.TEMPLATE
                                  ? "text-gray-400"
                                  : "text-gray-600"
                              }`}
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                  <Controller
                    name={`isAddedToContactCardBy-${aLink.id}`}
                    control={contactCardControl}
                    render={({ field: { value, onChange } }) => (
                      <SwitchX
                        checked={value}
                        onChange={onChange}
                        disabled={
                          !forTemplate &&
                          aLink.isAddedToContactCardBy ===
                            AddedToContactCardBy.TEMPLATE
                        }
                      />
                    )}
                  />
                </div>
              ))}
          </div>
        )}
      </DialogContent>
      <DialogActions className="gap-4 !border-t border-gray-200 !p-0 !pt-4">
        <Button
          variant="outlined"
          onClick={() => onCancel?.()}
          classes={{
            root: "!rounded-full !bg-white !px-6 !py-3 !font-semibold !text-black !normal-case !font-sans !text-sm !m-0 !border-gray-200",
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          startIcon={<FontAwesomeIcon icon={faSave} className="!text-sm" />}
          loadingPosition="start"
          loading={loading}
          disabled={!isDirty}
          onClick={handleSubmit(addToContactCard)}
          classes={theme.LoadingButtonX}
        >
          Save Changes
        </LoadingButton>
      </DialogActions>
    </div>
  );
}

export default EditContactCard;
