import dayjs, { Dayjs } from "dayjs";
import { getBoolean, getRGBAString } from "../../utils";
import type { MemberUpdatedInfo } from "../card-customization/cardCustomizationTypes";
import { SettingsColorsDefaults } from "../settings/settingsEnums";
import { UserRole } from "../users/usersEnums";
import type {
  APIMember,
  CreateMemberHashTagsAPIResponse,
  CreateMemberHashTagsResponse,
  DeleteMemberHashTagsAPIResponse,
  DeleteMemberHashTagsResponse,
  GetAllMembersAPIResponse,
  GetAllMembersResponse,
  GetMemberColorsSettingsAPIResponse,
  GetMemberColorsSettingsResponse,
  Member,
  MemberCustomColors,
  MemberHashTag,
  MemberSettings,
  ReadMemberHashTagsAPIResponse,
  ReadMemberHashTagsResponse,
  SetCaptureLeadAPIResponse,
  SetCaptureLeadResponse,
  SetOpenDirectAPIResponse,
  SetOpenDirectResponse,
  SwitchConnectButtonAPIResponse,
  SwitchDirectOpenAPIResponse,
  SwitchMembersVisibilityAPIResponse,
  SwitchSaveContactButtonAPIResponse,
  UpdateMemberAPIResponse,
  UpdateMemberColorsSettingsAPIResponse,
  UpdateMemberColorsSettingsRequestArgs,
  UpdateMemberColorsSettingsResponse,
  UpdateMemberDataAPIResponse,
  UpdateMemberDataRequestArgs,
  UpdateMemberDataResponse,
  UpdateMemberHashTagAPIResponse,
  UpdateMemberHashTagResponse,
  UpdateMemberResponse,
  UpdateMembersAPIResponse,
  UpdateMembersResponse,
} from "./membersTypes";

export function mapGetAllMembersResponse(
  response: GetAllMembersAPIResponse
): GetAllMembersResponse {
  const settings: MemberSettings[] = [];

  const members: Member[] = response.data?.members.map((m) => {
    if (m.settings) {
      settings.push({
        colors: {
          id: m.settings.colors.id,
          userId: m.id,
          sectionColor:
            getRGBAString(m.settings.colors.section_color ?? "") ||
            SettingsColorsDefaults.SECTION_COLOR,
          profileColor:
            getRGBAString(m.settings.colors.bg_color ?? "") ||
            SettingsColorsDefaults.PROFILE_COLOR,
          borderColor:
            getRGBAString(m.settings.colors.photo_border_color ?? "") ||
            SettingsColorsDefaults.BORDER_COLOR,
          textColor:
            getRGBAString(m.settings.colors.text_color ?? "") ||
            SettingsColorsDefaults.TEXT_COLOR,
          controlButtonTextColor:
            getRGBAString(m.settings.colors.control_button_text_color ?? "") ||
            SettingsColorsDefaults.CONTROL_BUTTON_TEXT_COLOR,
          controlButtonBgColor:
            getRGBAString(m.settings.colors.control_button_color ?? "") ||
            SettingsColorsDefaults.CONTROL_BUTTON_BG_COLOR,
          profileButtonIconColor:
            getRGBAString(m.settings.colors.profile_button_icon_color ?? "") ||
            SettingsColorsDefaults.PROFILE_BUTTON_ICON_COLOR,
          profileButtonBgColor:
            getRGBAString(m.settings.colors.profile_button_bg_color ?? "") ||
            SettingsColorsDefaults.PROFILE_BUTTON_BG_COLOR,
          colorLinkIcons:
            getBoolean(m.settings.colors.color_link_icons) ||
            Boolean(SettingsColorsDefaults.COLOR_LINK_ICONS),
        },
      });
    }

    return {
      id: m.id,
      username: m.username,
      email: m.email,
      membership: m.is_pro,
      firstName: m.first_name ?? "",
      fullName: [m.first_name, m.last_name].join(" ").trim(),
      designation: m.designation ?? "",
      companyName: m.company_name ?? "",
      companyAddress: m.company_address ?? "",
      subtitle: m.bio ?? "",
      profileBanner: m.profile_banner ?? "",
      profileImage: m.profile_image ?? "",
      companyLogo: m.company_logo ?? "",
      viewsCount: m.views_count ?? 0,
      leadsCount: m.leads_count ?? 0,
      devicesCount: m.devices_count ?? 0,
      locked: !Boolean(m.is_editable),
      isPublic: Boolean(m.is_public),
      openDirect: Boolean(m.open_direct),
      captureLead: Boolean(m.capture_lead),
      connectButton: Boolean(m.connect_button),
      saveContactButton: Boolean(m.save_contact_button),
      role: m.role ?? UserRole.BUSINESS_USER,
    };
  });

  return {
    success: response.success,
    message: response.message,
    data: {
      members,
      settings,
    },
  };
}

export function mapLockUnlockMembersResponse(
  response: UpdateMembersAPIResponse
): UpdateMembersResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      members: response.data.map((aData) => ({
        id: aData.id,
        locked: !Boolean(aData.is_editable),
      })),
    },
  };
}

export function mapSwitchMembersVisibilityResponse(
  response: SwitchMembersVisibilityAPIResponse
): UpdateMembersResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      members: response.data.map((aData) => ({
        id: aData.id,
        isPublic: Boolean(aData.is_public),
      })),
    },
  };
}

export function mapSwitchDirectOpenResponse(
  response: SwitchDirectOpenAPIResponse
): UpdateMembersResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      members: response.data.map((aData) => ({
        id: aData.id,
        openDirect: Boolean(aData.open_direct),
        captureLead: Boolean(aData.capture_lead),
      })),
    },
  };
}

export function mapResetPasswordResponse(
  response: UpdateMembersAPIResponse
): UpdateMembersResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      members: response.data.map((aData) => ({
        id: aData.id,
      })),
    },
  };
}

export function mapSetProfilePictureResponse(
  response: UpdateMembersAPIResponse
): UpdateMembersResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      members: response.data.map((aData) => ({
        id: aData.id,
        profileImage: aData.profile_image ?? "",
      })),
    },
  };
}

export function mapSetProfileBannerResponse(
  response: UpdateMembersAPIResponse
): UpdateMembersResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      members: response.data.map((aData) => ({
        id: aData.id,
        profileBanner: aData.profile_banner ?? "",
      })),
    },
  };
}

export function mapSetCompanyLogoResponse(
  response: UpdateMembersAPIResponse
): UpdateMembersResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      members: response.data.map((aData) => ({
        id: aData.id,
        companyLogo: aData.company_logo ?? "",
      })),
    },
  };
}

export function mapSetCaptureLeadResponse(
  response: SetCaptureLeadAPIResponse
): SetCaptureLeadResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      member:
        response.data.profile.open_direct !== undefined
          ? {
              id: response.data.profile.id,
              captureLead: Boolean(response.data.profile.capture_lead),
              openDirect: Boolean(response.data.profile.open_direct),
            }
          : {
              id: response.data.profile.id,
              captureLead: Boolean(response.data.profile.capture_lead),
            },
    },
  };
}

export function mapSetOpenDirectResponse(
  response: SetOpenDirectAPIResponse
): SetOpenDirectResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      member:
        response.data.profile.capture_lead !== undefined
          ? {
              id: response.data.profile.id,
              openDirect: Boolean(response.data.profile.open_direct),
              captureLead: Boolean(response.data.profile.capture_lead),
            }
          : {
              id: response.data.profile.id,
              openDirect: Boolean(response.data.profile.open_direct),
            },
    },
  };
}

export function getUpdatedMemberData(
  newMemberData: MemberUpdatedInfo,
  oldMemberData: Member
) {
  const updatedMemberData: UpdateMemberDataRequestArgs = {
    id: oldMemberData.id,
  };

  if (
    newMemberData.profileImageObj !== undefined &&
    !(
      oldMemberData.profileImage === "" &&
      newMemberData.profileImageObj === null
    )
  ) {
    updatedMemberData.profileImageObj = newMemberData.profileImageObj;
  }
  if (
    newMemberData.companyLogoObj !== undefined &&
    !(oldMemberData.companyLogo === "" && newMemberData.companyLogoObj === null)
  ) {
    updatedMemberData.companyLogoObj = newMemberData.companyLogoObj;
  }
  if (
    newMemberData.profileBannerObj !== undefined &&
    !(
      oldMemberData.profileBanner === "" &&
      newMemberData.profileBannerObj === null
    )
  ) {
    updatedMemberData.profileBannerObj = newMemberData.profileBannerObj;
  }

  if (newMemberData.fullName !== oldMemberData.fullName) {
    updatedMemberData.fullName = newMemberData.fullName;
  }
  if (newMemberData.designation !== oldMemberData.designation) {
    updatedMemberData.designation = newMemberData.designation;
  }
  if (newMemberData.companyName !== oldMemberData.companyName) {
    updatedMemberData.companyName = newMemberData.companyName;
  }
  if (newMemberData.companyAddress !== oldMemberData.companyAddress) {
    updatedMemberData.companyAddress = newMemberData.companyAddress;
  }
  if (newMemberData.subtitle !== oldMemberData.subtitle) {
    updatedMemberData.subtitle = newMemberData.subtitle;
  }

  return updatedMemberData;
}

export function mapUpdateMemberDataResponse(
  response: UpdateMemberDataAPIResponse
): UpdateMemberDataResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      member: response.data.profile
        ? mapToMember(response.data.profile)
        : undefined,
    },
  };
}

export function getUpdateMemberDataRequestBody(
  memberDataToUpdate: Omit<UpdateMemberDataRequestArgs, "id">,
  options?: { contentType?: "form-data" | "json" }
): FormData | Partial<APIMember> {
  const formData = new FormData();
  const apiMember: Partial<APIMember> = {};

  if (memberDataToUpdate.fullName !== undefined) {
    const [firstName, ...restOfName] = memberDataToUpdate.fullName.split(" ");
    formData.append("first_name", firstName);
    formData.append("last_name", restOfName.join(" "));
    apiMember.first_name = firstName;
    apiMember.last_name = restOfName.join(" ");
  }

  if (memberDataToUpdate.designation !== undefined) {
    formData.append("designation", memberDataToUpdate.designation);
    apiMember.designation = memberDataToUpdate.designation;
  }

  if (memberDataToUpdate.companyName !== undefined) {
    formData.append("company_name", memberDataToUpdate.companyName);
    apiMember.company_name = memberDataToUpdate.companyName;
  }

  if (memberDataToUpdate.companyAddress !== undefined) {
    formData.append("company_address", memberDataToUpdate.companyAddress);
    apiMember.company_address = memberDataToUpdate.companyAddress;
  }

  if (memberDataToUpdate.email !== undefined) {
    formData.append("email", memberDataToUpdate.email);
    apiMember.email = memberDataToUpdate.email;
  }

  if (memberDataToUpdate.subtitle !== undefined) {
    formData.append("bio", memberDataToUpdate.subtitle);
    apiMember.bio = memberDataToUpdate.subtitle;
  }

  if (memberDataToUpdate.profileBannerObj !== undefined) {
    formData.append(
      "profile_banner",
      memberDataToUpdate.profileBannerObj ?? ""
    );
  }
  if (memberDataToUpdate.profileBanner !== undefined) {
    apiMember.profile_banner = memberDataToUpdate.profileBanner;
  }

  if (memberDataToUpdate.profileImageObj !== undefined) {
    formData.append("profile_image", memberDataToUpdate.profileImageObj ?? "");
  }
  if (memberDataToUpdate.profileImage !== undefined) {
    apiMember.profile_image = memberDataToUpdate.profileImage;
  }

  if (memberDataToUpdate.companyLogoObj !== undefined) {
    formData.append("company_logo", memberDataToUpdate.companyLogoObj ?? "");
  }
  if (memberDataToUpdate.companyLogo !== undefined) {
    apiMember.company_logo = memberDataToUpdate.companyLogo;
  }

  if (memberDataToUpdate.locked !== undefined) {
    formData.append(
      "is_editable",
      Number(!memberDataToUpdate.locked).toString()
    );
    apiMember.is_editable = memberDataToUpdate.locked ? 0 : 1;
  }

  if (memberDataToUpdate.isPublic !== undefined) {
    formData.append(
      "is_public",
      Number(memberDataToUpdate.isPublic).toString()
    );
    apiMember.is_public = memberDataToUpdate.isPublic ? 1 : 0;
  }

  if (memberDataToUpdate.openDirect !== undefined) {
    formData.append(
      "open_direct",
      Number(memberDataToUpdate.openDirect).toString()
    );
    apiMember.open_direct = memberDataToUpdate.openDirect ? 1 : 0;
  }

  if (memberDataToUpdate.captureLead !== undefined) {
    formData.append(
      "capture_lead",
      Number(memberDataToUpdate.captureLead).toString()
    );
    apiMember.capture_lead = memberDataToUpdate.captureLead ? 1 : 0;
  }

  if (memberDataToUpdate.role !== undefined) {
    formData.append("role", memberDataToUpdate.role);
    apiMember.role = memberDataToUpdate.role;
  }

  if (memberDataToUpdate.viewsCount !== undefined) {
    formData.append("views_count", memberDataToUpdate.viewsCount.toString());
    apiMember.views_count = memberDataToUpdate.viewsCount;
  }

  if (memberDataToUpdate.leadsCount !== undefined) {
    formData.append("leads_count", memberDataToUpdate.leadsCount.toString());
    apiMember.leads_count = memberDataToUpdate.leadsCount;
  }

  if (memberDataToUpdate.devicesCount !== undefined) {
    formData.append(
      "devices_count",
      memberDataToUpdate.devicesCount.toString()
    );
    apiMember.devices_count = memberDataToUpdate.devicesCount;
  }

  return options?.contentType === "json" ? apiMember : formData;
}

export function mapToAPIMember(member: Partial<Member>): Partial<APIMember> {
  return getUpdateMemberDataRequestBody(member, {
    contentType: "json",
  }) as Partial<APIMember>;
}

export function mapToMember(
  apiMember: Partial<APIMember> & { id: APIMember["id"] }
): Partial<Member> & { id: Member["id"] } {
  const member: Partial<Member> & { id: Member["id"] } = {
    id: apiMember.id,
  };

  if (apiMember.first_name !== undefined || apiMember.last_name !== undefined) {
    member.fullName = [apiMember.first_name, apiMember.last_name]
      .join(" ")
      .trim();
  }

  if (apiMember.designation !== undefined) {
    member.designation = apiMember.designation ?? "";
  }

  if (apiMember.company_name !== undefined) {
    member.companyName = apiMember.company_name ?? "";
  }

  if (apiMember.company_address !== undefined) {
    member.companyAddress = apiMember.company_address ?? "";
  }

  if (apiMember.bio !== undefined) {
    member.subtitle = apiMember.bio ?? "";
  }

  if (apiMember.profile_banner !== undefined) {
    member.profileBanner = apiMember.profile_banner ?? "";
  }

  if (apiMember.profile_image !== undefined) {
    member.profileImage = apiMember.profile_image ?? "";
  }

  if (apiMember.company_logo !== undefined) {
    member.companyLogo = apiMember.company_logo ?? "";
  }

  if (apiMember.views_count !== undefined) {
    member.viewsCount = apiMember.views_count;
  }

  if (apiMember.leads_count !== undefined) {
    member.leadsCount = apiMember.leads_count;
  }

  if (apiMember.devices_count !== undefined) {
    member.devicesCount = apiMember.devices_count;
  }

  if (apiMember.is_editable !== undefined) {
    member.locked = !Boolean(apiMember.is_editable);
  }

  if (apiMember.is_public !== undefined) {
    member.isPublic = Boolean(apiMember.is_public);
  }

  if (apiMember.open_direct !== undefined) {
    member.openDirect = Boolean(apiMember.open_direct);
  }

  if (apiMember.capture_lead !== undefined) {
    member.captureLead = Boolean(apiMember.capture_lead);
  }

  if (apiMember.connect_button !== undefined) {
    member.connectButton = Boolean(apiMember.connect_button);
  }

  if (apiMember.save_contact_button !== undefined) {
    member.saveContactButton = Boolean(apiMember.save_contact_button);
  }

  if (apiMember.role !== undefined) {
    member.role = apiMember.role;
  }

  return member;
}

export function mapUpdateMemberResponse(
  response: UpdateMemberAPIResponse
): UpdateMemberResponse {
  return {
    success: response.success,
    message: response.message,
    data: mapToMember(response.data),
  };
}

export function mapGetMemberColorsSettingsResponse(
  response: GetMemberColorsSettingsAPIResponse
): GetMemberColorsSettingsResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      settings: {
        colors: !response.data.settings
          ? response.data.settings
          : {
              id: response.data.settings.id,
              userId: response.data.settings.user_id,
              sectionColor:
                getRGBAString(response.data.settings.section_color ?? "") ||
                SettingsColorsDefaults.SECTION_COLOR,
              profileColor:
                getRGBAString(response.data.settings.bg_color ?? "") ||
                SettingsColorsDefaults.PROFILE_COLOR,
              borderColor:
                getRGBAString(
                  response.data.settings.photo_border_color ?? ""
                ) || SettingsColorsDefaults.BORDER_COLOR,
              textColor:
                getRGBAString(response.data.settings.text_color ?? "") ||
                SettingsColorsDefaults.TEXT_COLOR,
              controlButtonTextColor:
                getRGBAString(
                  response.data.settings.control_button_text_color ?? ""
                ) || SettingsColorsDefaults.CONTROL_BUTTON_TEXT_COLOR,
              controlButtonBgColor:
                getRGBAString(
                  response.data.settings.control_button_color ?? ""
                ) || SettingsColorsDefaults.CONTROL_BUTTON_BG_COLOR,
              profileButtonIconColor:
                getRGBAString(
                  response.data.settings.profile_button_icon_color ?? ""
                ) || SettingsColorsDefaults.PROFILE_BUTTON_ICON_COLOR,
              profileButtonBgColor:
                getRGBAString(
                  response.data.settings.profile_button_bg_color ?? ""
                ) || SettingsColorsDefaults.PROFILE_BUTTON_BG_COLOR,
              colorLinkIcons:
                getBoolean(response.data.settings.color_link_icons) ||
                Boolean(SettingsColorsDefaults.COLOR_LINK_ICONS),
            },
      },
    },
  };
}

export function getUpdatedColorsSettings(
  newColorsSettings: MemberCustomColors,
  oldColorsSettings: MemberCustomColors
): Partial<MemberCustomColors> {
  const updatedColorsSettings: Partial<MemberCustomColors> = {};

  if (newColorsSettings.sectionColor !== oldColorsSettings.sectionColor) {
    updatedColorsSettings.sectionColor = newColorsSettings.sectionColor;
  }
  if (newColorsSettings.profileColor !== oldColorsSettings.profileColor) {
    updatedColorsSettings.profileColor = newColorsSettings.profileColor;
  }
  if (newColorsSettings.borderColor !== oldColorsSettings.borderColor) {
    updatedColorsSettings.borderColor = newColorsSettings.borderColor;
  }
  if (newColorsSettings.textColor !== oldColorsSettings.textColor) {
    updatedColorsSettings.textColor = newColorsSettings.textColor;
  }
  if (
    newColorsSettings.controlButtonTextColor !==
    oldColorsSettings.controlButtonTextColor
  ) {
    updatedColorsSettings.controlButtonTextColor =
      newColorsSettings.controlButtonTextColor;
  }
  if (
    newColorsSettings.controlButtonBgColor !==
    oldColorsSettings.controlButtonBgColor
  ) {
    updatedColorsSettings.controlButtonBgColor =
      newColorsSettings.controlButtonBgColor;
  }
  if (
    newColorsSettings.profileButtonIconColor !==
    oldColorsSettings.profileButtonIconColor
  ) {
    updatedColorsSettings.profileButtonIconColor =
      newColorsSettings.profileButtonIconColor;
  }
  if (
    newColorsSettings.profileButtonBgColor !==
    oldColorsSettings.profileButtonBgColor
  ) {
    updatedColorsSettings.profileButtonBgColor =
      newColorsSettings.profileButtonBgColor;
  }
  if (newColorsSettings.colorLinkIcons !== oldColorsSettings.colorLinkIcons) {
    updatedColorsSettings.colorLinkIcons = newColorsSettings.colorLinkIcons;
  }

  return updatedColorsSettings;
}

export function getUpdateMemberColorsSettingsFormData(
  theme: UpdateMemberColorsSettingsRequestArgs
): FormData {
  const formData = new FormData();
  formData.append("user_id", theme.userId.toString());
  if (!!theme.sectionColor)
    formData.append("section_color", theme.sectionColor);
  if (!!theme.profileColor) formData.append("bg_color", theme.profileColor);
  if (!!theme.borderColor)
    formData.append("photo_border_color", theme.borderColor);
  if (!!theme.textColor) formData.append("text_color", theme.textColor);
  if (!!theme.controlButtonTextColor)
    formData.append("control_button_text_color", theme.controlButtonTextColor);
  if (!!theme.controlButtonBgColor)
    formData.append("control_button_color", theme.controlButtonBgColor);
  if (!!theme.profileButtonIconColor)
    formData.append("profile_button_icon_color", theme.profileButtonIconColor);
  if (!!theme.profileButtonBgColor)
    formData.append("profile_button_bg_color", theme.profileButtonBgColor);
  if (theme.colorLinkIcons !== undefined)
    formData.append(
      "color_link_icons",
      Number(theme.colorLinkIcons).toString()
    );
  return formData;
}

export function mapUpdateMemberColorsSettingsResponse(
  response: UpdateMemberColorsSettingsAPIResponse
): UpdateMemberColorsSettingsResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      colors: {
        id: response.data.profile.id,
        userId: response.data.profile.user_id,
        sectionColor:
          getRGBAString(response.data.profile.section_color ?? "") ||
          SettingsColorsDefaults.SECTION_COLOR,
        profileColor:
          getRGBAString(response.data.profile.bg_color ?? "") ||
          SettingsColorsDefaults.PROFILE_COLOR,
        borderColor:
          getRGBAString(response.data.profile.photo_border_color ?? "") ||
          SettingsColorsDefaults.BORDER_COLOR,
        textColor:
          getRGBAString(response.data.profile.text_color ?? "") ||
          SettingsColorsDefaults.TEXT_COLOR,
        controlButtonTextColor:
          getRGBAString(
            response.data.profile.control_button_text_color ?? ""
          ) || SettingsColorsDefaults.CONTROL_BUTTON_TEXT_COLOR,
        controlButtonBgColor:
          getRGBAString(response.data.profile.control_button_color ?? "") ||
          SettingsColorsDefaults.CONTROL_BUTTON_BG_COLOR,
        profileButtonIconColor:
          getRGBAString(
            response.data.profile.profile_button_icon_color ?? ""
          ) || SettingsColorsDefaults.PROFILE_BUTTON_ICON_COLOR,
        profileButtonBgColor:
          getRGBAString(response.data.profile.profile_button_bg_color ?? "") ||
          SettingsColorsDefaults.PROFILE_BUTTON_BG_COLOR,
        colorLinkIcons:
          getBoolean(response.data.profile.color_link_icons) ||
          Boolean(SettingsColorsDefaults.COLOR_LINK_ICONS),
      },
    },
  };
}

export function mapSwitchConnectButtonResponse(
  response: SwitchConnectButtonAPIResponse
): UpdateMembersResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      members: response.data.map((aData) => ({
        id: aData.id,
        connectButton: Boolean(aData.connect_button),
      })),
    },
  };
}

export function mapSwitchSaveContactButtonResponse(
  response: SwitchSaveContactButtonAPIResponse
): UpdateMembersResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      members: response.data.map((aData) => ({
        id: aData.id,
        saveContactButton: Boolean(aData.save_contact_button),
      })),
    },
  };
}

export function mapReadMemberHashTagsResponse(
  response: ReadMemberHashTagsAPIResponse
): ReadMemberHashTagsResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      allHashTags: response.data.hashtags.map((aHashTag) => ({
        id: aHashTag.id,
        userId: aHashTag.userId,
        fromDate: aHashTag.fromDate,
        toDate: aHashTag.toDate,
        value: aHashTag.value,
      })),
    },
  };
}

export function getUpdatedMemberHashTagData(
  oldData: Pick<MemberHashTag, "fromDate" | "toDate" | "value"> | null,
  newData: { fromDate: Dayjs | null; toDate: Dayjs | null; value: string }
): Partial<MemberHashTag> {
  const savedFromDate = oldData?.fromDate ? dayjs(oldData.fromDate) : null;
  const savedToDate = oldData?.toDate ? dayjs(oldData.toDate) : null;

  const updatedData: Partial<MemberHashTag> = {};

  if (newData.fromDate?.isValid() && !savedFromDate?.isSame(newData.fromDate)) {
    updatedData.fromDate = newData.fromDate.toISOString();
  }
  if (newData.toDate?.isValid() && !savedToDate?.isSame(newData.toDate)) {
    updatedData.toDate = newData.toDate.toISOString();
  }
  if (newData.value && oldData?.value !== newData.value) {
    updatedData.value = newData.value;
  }

  return updatedData;
}

export function mapUpdateMemberHashTagResponse(
  response: UpdateMemberHashTagAPIResponse
): UpdateMemberHashTagResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      updatedHashTag: {
        id: response.data.id,
        userId: response.data.userId,
        fromDate: response.data.fromDate,
        toDate: response.data.toDate,
        value: response.data.value,
      },
    },
  };
}

export function mapDeleteMemberHashTagsResponse(
  response: DeleteMemberHashTagsAPIResponse
): DeleteMemberHashTagsResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      deletedHashTagIds: response.data.hashtags,
    },
  };
}

export function mapCreateMemberHashTagsResponse(
  response: CreateMemberHashTagsAPIResponse
): CreateMemberHashTagsResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      newHashTags: response.data.hashtags.map((aHashTag) => ({
        id: aHashTag.id,
        userId: aHashTag.userId,
        fromDate: aHashTag.fromDate,
        toDate: aHashTag.toDate,
        value: aHashTag.value,
      })),
    },
  };
}
