import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../components/Spinner";
import {
  getIsAuthenticated,
  removeAuthentication,
  useLogOutMutation,
} from "../features/authentication";
import { resetDevices } from "../features/devices/devicesSlice";
import { resetIntegrations } from "../features/integrations/integrationsSlice";
import { resetLinks } from "../features/links/linksSlice";
import {
  resetMemberSettings,
  resetMembers,
} from "../features/members/membersSlice";
import { resetSettings } from "../features/settings/settingsSlice";
import { resetTemplates } from "../features/templates/templatesSlice";
import {
  UserRole,
  getCurrentUser,
  removeCurrentUser,
  setCurrentUser,
  useGetBusinessProfileMutation,
} from "../features/users";
import { useAppDispatch, useAppSelector } from "../store";
import { FetchError, handleFetchError } from "../utils";
import NavigationMenu from "./NavigationMenu";

type LayoutProtectedProps = {
  allowedRoles: UserRole[];
};

function LayoutProtected({ allowedRoles }: LayoutProtectedProps) {
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const currentUser = useAppSelector(getCurrentUser);

  const dispatch = useAppDispatch();

  const [getBusinessProfile] = useGetBusinessProfileMutation();

  useEffect(() => {
    if (isAuthenticated && !currentUser) {
      (async () => {
        try {
          const {
            success,
            message,
            data: { user: thisAdmin },
          } = await getBusinessProfile().unwrap();
          if (success) {
            dispatch(setCurrentUser(thisAdmin));
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    }
  }, [isAuthenticated, currentUser, dispatch, getBusinessProfile]);

  const [logOut, { isLoading: isLogOutPending }] = useLogOutMutation();

  async function handleLogOut() {
    try {
      const { success, message } = await logOut().unwrap();
      if (success) {
        dispatch(removeAuthentication());
        dispatch(removeCurrentUser());
        dispatch(resetMembers());
        dispatch(resetLinks());
        dispatch(resetMemberSettings());
        dispatch(resetTemplates());
        dispatch(resetIntegrations());
        dispatch(resetSettings());
        dispatch(resetDevices());
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (!currentUser) {
    return <Spinner fullScreen />;
  } else if (!allowedRoles?.includes(currentUser?.role)) {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  return (
    <>
      {isLogOutPending && <Spinner fullScreen />}
      <ToastContainer bodyClassName="font-sans text-sm" />
      <div className="flex h-screen lg:grid lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7">
        <NavigationMenu onLogOut={handleLogOut} className="max-w-[15rem]" />
        <main className="grow overflow-auto bg-gray-50 lg:col-span-4 xl:col-span-5 2xl:col-span-6">
          <Outlet />
        </main>
      </div>
    </>
  );
}

export default LayoutProtected;
