import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import type { Member } from "../members/membersTypes";
import type {
  Template,
  TemplateLink,
  TemplateSettings,
  TemplatesState,
  UpdateTemplateLinkPayload,
} from "./templatesTypes";

const initialState: TemplatesState = {
  templates: undefined,
  templateLinks: undefined,
  templateSettings: undefined,
};

const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    resetTemplates: (state: TemplatesState) => {
      state.templates = undefined;
      state.templateLinks = undefined;
      state.templateSettings = undefined;
    },
    setTemplates: (
      state: TemplatesState,
      action: PayloadAction<Template[]>
    ) => {
      state.templates = action.payload;
    },
    setTemplateLinks: (
      state: TemplatesState,
      action: PayloadAction<TemplateLink[]>
    ) => {
      state.templateLinks = action.payload;
    },
    setTemplateSettings: (
      state: TemplatesState,
      action: PayloadAction<TemplateSettings[]>
    ) => {
      state.templateSettings = action.payload;
    },
    addTemplate: (state: TemplatesState, action: PayloadAction<Template>) => {
      state.templates = state.templates
        ? [...state.templates, action.payload]
        : [action.payload];
    },
    addTemplateLinks: (
      state: TemplatesState,
      action: PayloadAction<TemplateLink[]>
    ) => {
      state.templateLinks = state.templateLinks
        ? [...state.templateLinks, ...action.payload]
        : [...action.payload];
    },
    addTemplateSettings: (
      state: TemplatesState,
      action: PayloadAction<TemplateSettings>
    ) => {
      state.templateSettings = state.templateSettings
        ? [...state.templateSettings, action.payload]
        : [action.payload];
    },
    removeTemplate: (
      state: TemplatesState,
      action: PayloadAction<Template["id"]>
    ) => {
      state.templates = state.templates?.filter((t) => t.id !== action.payload);
      state.templateLinks = state.templateLinks?.filter(
        (tl) => tl.templateId !== action.payload
      );
      state.templateSettings = state.templateSettings?.filter(
        (ts) => ts.templateId !== action.payload
      );
    },
    editTemplate: (state: TemplatesState, action: PayloadAction<Template>) => {
      state.templates = state.templates?.map((t) =>
        t.id === action.payload.id ? action.payload : t
      );
    },
    editTemplateData: (
      state: TemplatesState,
      action: PayloadAction<Pick<Template, "id"> & Partial<Template>>
    ) => {
      state.templates = state.templates?.map((t) =>
        t.id === action.payload.id ? { ...t, ...action.payload } : t
      );
    },
    editTemplateSettings: (
      state: TemplatesState,
      action: PayloadAction<TemplateSettings>
    ) => {
      state.templateSettings = state.templateSettings?.map((ts) =>
        ts.templateId === action.payload.templateId ? action.payload : ts
      );
    },
    setDefaultTemplate: (
      state: TemplatesState,
      action: PayloadAction<Template["id"]>
    ) => {
      state.templates = state.templates?.map((t) => ({
        ...t,
        isDefault: t.id === action.payload,
      }));
    },
    editTemplateLinks: (
      state: TemplatesState,
      action: PayloadAction<UpdateTemplateLinkPayload[]>
    ) => {
      state.templateLinks = state.templateLinks?.map((tl) => {
        const updates = action.payload.find((tl2) => tl2.id === tl.id);
        return updates ? { ...tl, ...updates } : tl;
      });
    },
    editTemplateLink: (
      state: TemplatesState,
      action: PayloadAction<UpdateTemplateLinkPayload>
    ) => {
      state.templateLinks = state.templateLinks?.map((tl) =>
        tl.id === action.payload.id ? { ...tl, ...action.payload } : tl
      );
    },
    addNewTemplateLink: (
      state: TemplatesState,
      action: PayloadAction<TemplateLink>
    ) => {
      state.templateLinks = state.templateLinks
        ? [...state.templateLinks, action.payload]
        : [action.payload];
    },
    removeTemplateLink: (
      state: TemplatesState,
      action: PayloadAction<TemplateLink["id"]>
    ) => {
      state.templateLinks = state.templateLinks?.filter(
        (tl) => tl.id !== action.payload
      );
    },
    editTemplateAssignees: (
      state: TemplatesState,
      action: PayloadAction<Pick<Template, "id" | "assigneeIds">>
    ) => {
      state.templates = state.templates?.map((t) => ({
        ...t,
        assigneeIds:
          t.id === action.payload.id
            ? action.payload.assigneeIds
            : t.assigneeIds.filter(
                (aid) => !action.payload.assigneeIds.includes(aid)
              ),
      }));
    },
    removeTemplateAssignees: (
      state: TemplatesState,
      action: PayloadAction<Array<Member["id"]>>
    ) => {
      state.templates = state.templates?.map((t) => ({
        ...t,
        assigneeIds: t.assigneeIds.filter(
          (aid) => !action.payload.includes(aid)
        ),
      }));
    },
    addMembersToDefaultTemplate: (
      state: TemplatesState,
      action: PayloadAction<Array<Member["id"]>>
    ) => {
      state.templates = state.templates?.map((t) => ({
        ...t,
        assigneeIds: t.isDefault
          ? [...t.assigneeIds, ...action.payload]
          : t.assigneeIds,
      }));
    },
  },
});

export const {
  resetTemplates,
  setTemplates,
  setTemplateLinks,
  setTemplateSettings,
  addTemplate,
  addTemplateLinks,
  addTemplateSettings,
  removeTemplate,
  editTemplate,
  editTemplateData,
  editTemplateSettings,
  setDefaultTemplate,
  editTemplateLinks,
  editTemplateLink,
  addNewTemplateLink,
  removeTemplateLink,
  editTemplateAssignees,
  removeTemplateAssignees,
  addMembersToDefaultTemplate,
} = templatesSlice.actions;

export default templatesSlice.reducer;

export const getTemplates = (state: RootState) => state.templates.templates;
export const getTemplateLinks = (state: RootState) =>
  state.templates.templateLinks;
export const getTemplateSettings = (state: RootState) =>
  state.templates.templateSettings;

export const getTemplateById = createSelector(
  [getTemplates, (state: RootState, id: Template["id"]) => id],
  (templates, id) =>
    templates ? templates.find((t) => t.id === id) || null : undefined
);

export const getTemplateByAssigneeId = createSelector(
  [getTemplates, (state: RootState, assigneeId: Member["id"]) => assigneeId],
  (templates, assigneeId) =>
    templates
      ? templates.find((t) => t.assigneeIds.includes(assigneeId)) || null
      : undefined
);

export const getTemplateLinksByTemplateId = createSelector(
  [getTemplateLinks, (state: RootState, id: Template["id"]) => id],
  (templateLinks, id) => templateLinks?.filter((tl) => tl.templateId === id)
);

export const getTemplateSettingsByTemplateId = createSelector(
  [getTemplateSettings, (state: RootState, id: Template["id"]) => id],
  (templateSettings, id) => templateSettings?.find((ts) => ts.templateId === id)
);

export const getTemplateLinksByAssigneeId = createSelector(
  [
    getTemplates,
    getTemplateLinks,
    (state: RootState, assigneeId: Member["id"]) => assigneeId,
  ],
  (templates, templateLinks, assigneeId) =>
    templateLinks?.filter(
      (tl) =>
        tl.templateId ===
        templates?.find((t) => t.assigneeIds.includes(assigneeId))?.id
    )
);

export const getTemplateSettingsByAssigneeId = createSelector(
  [
    getTemplates,
    getTemplateSettings,
    (state: RootState, assigneeId: Member["id"]) => assigneeId,
  ],
  (templates, templateSettings, assigneeId) =>
    templateSettings
      ? templateSettings.find(
          (ts) =>
            ts.templateId ===
            templates?.find((t) => t.assigneeIds.includes(assigneeId))?.id
        ) || null
      : undefined
);

export const getTemplateAssignees = createSelector(
  [getTemplates],
  (templates) =>
    templates?.map((aTemplate) => ({
      templateName: aTemplate.name,
      assigneeIds: aTemplate.assigneeIds,
    })) || []
);
