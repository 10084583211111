import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faBroom, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ConfirmationDialog, Spinner } from "../../components";
import { useAppDispatch } from "../../store";
import { DatePickerX, TextFieldX } from "../../styles/mui-styled";
import { FetchError, handleFetchError } from "../../utils";
import {
  Member,
  MemberHashTag,
  addMemberHashTags,
  editMemberHashTag,
  editMemberHashTags,
  getUpdatedMemberHashTagData,
  removeMemberHashTags,
  useCreateMemberHashTagsMutation,
  useDeleteMemberHashTagsMutation,
  useUpdateMemberHashTagMutation,
} from "../members";

type SetHashTagProps = {
  memberIds: Array<Member["id"]>;
  hashTag?: MemberHashTag | null;
  onAdd?: () => void;
  className?: string;
};

function SetHashTag({
  memberIds,
  hashTag,
  onAdd,
  className = "",
  ...props
}: SetHashTagProps) {
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const [hashTagValue, setHashTagValue] = useState<MemberHashTag["value"]>("");
  const [clearDialogShow, setClearDialogShow] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setFromDate(hashTag?.fromDate ? dayjs(hashTag?.fromDate) : null);
    setToDate(hashTag?.toDate ? dayjs(hashTag?.toDate) : null);
    handleHashTagValueChange(hashTag?.value || "");
  }, [hashTag]);

  const handleHashTagValueChange = (changedValue: string) => {
    setHashTagValue(
      changedValue.replace(/\s+/g, " ").replace(/[^#a-zA-Z0-9äöüÄÖÜß\s]/g, "")
    );
  };

  function getFromMinDate(): Dayjs {
    let todayDate = dayjs(new Date());

    if (hashTag?.fromDate && dayjs(hashTag?.fromDate).isBefore(todayDate)) {
      return dayjs(hashTag?.fromDate);
    }

    return todayDate;
  }

  function isHashTagDataChanged(): boolean {
    if (!fromDate || !toDate || !hashTagValue) {
      return false;
    }

    return (
      Object.keys(
        getUpdatedMemberHashTagData(hashTag || null, {
          fromDate,
          toDate,
          value: hashTagValue,
        })
      ).length > 0
    );
  }

  const [updateMemberHashTag, { isLoading: isUpdateMemberHashTagPending }] =
    useUpdateMemberHashTagMutation();

  async function handleUpdateClick(): Promise<void> {
    const updatedHashTagData = getUpdatedMemberHashTagData(hashTag || null, {
      fromDate,
      toDate,
      value: hashTagValue,
    });

    if (Object.keys(updatedHashTagData).length > 0) {
      try {
        if (hashTag) {
          const {
            success,
            message,
            data: { updatedHashTag },
          } = await updateMemberHashTag({
            id: hashTag.id,
            ...updatedHashTagData,
          }).unwrap();
          if (success) {
            dispatch(editMemberHashTag(updatedHashTag));
          } else {
            toast.error(message);
          }
        }
      } catch (error) {
        handleFetchError(error as FetchError);
      }
    }
  }

  function handleClearClick() {
    if (hashTag) {
      setClearDialogShow(true);
    } else {
      setFromDate(null);
      setToDate(null);
      setHashTagValue("");
    }
  }

  const [deleteMemberHashTags, { isLoading: isDeleteMemberHashTagsPending }] =
    useDeleteMemberHashTagsMutation();

  async function handleClearConfirm(): Promise<void> {
    try {
      if (hashTag) {
        const {
          success,
          message,
          data: { deletedHashTagIds },
        } = await deleteMemberHashTags([hashTag.id]).unwrap();
        if (success) {
          dispatch(removeMemberHashTags(deletedHashTagIds));
          setClearDialogShow(false);
        } else {
          toast.error(message);
        }
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  const [createMemberHashTags, { isLoading: isCreateMemberHashTagsPending }] =
    useCreateMemberHashTagsMutation();

  async function handleAddClick() {
    try {
      if (fromDate && toDate && hashTagValue) {
        const {
          success,
          message,
          data: { newHashTags },
        } = await createMemberHashTags({
          userIds: memberIds,
          fromDate: fromDate.toISOString(),
          toDate: toDate.toISOString(),
          value: hashTagValue,
        }).unwrap();
        if (success) {
          // Update HashTags if already exists
          dispatch(editMemberHashTags(newHashTags));
          // Add HashTags if not already exists
          dispatch(addMemberHashTags(newHashTags));
          onAdd?.();
        } else {
          toast.error(message);
        }
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  return (
    <div className={`flex flex-col gap-8 ${className}`} {...props}>
      <div>
        <h4 className="text-xl font-semibold text-black">Set a HashTag</h4>
        <p className="text-sm text-gray-500">
          Set hashtags that will be saved in your contact's phone book when they
          save your contact card. This way, they can find you in the phone book
          among many other contacts by searching for the hashtags. Particularly
          useful at networking events, e.g:
          <strong className="ml-1 font-medium">#FairXY2024</strong>
          <strong className="ml-1 font-medium">#MyCoreCompetence</strong>
        </p>
      </div>
      <div className="flex flex-col gap-8">
        <div className="grid grid-cols-2 gap-8">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePickerX
              label="Activate From"
              value={fromDate}
              onChange={(v) => setFromDate(v as Dayjs)}
              minDate={getFromMinDate()}
            />
            <DatePickerX
              label="Activate To"
              value={toDate}
              onChange={(v) => setToDate(v as Dayjs)}
              minDate={fromDate || dayjs(new Date())}
            />
          </LocalizationProvider>
          <div className="col-span-2">
            <TextFieldX
              name="hashtag-value"
              label="Use HashTags"
              value={hashTagValue}
              onChange={(e) => handleHashTagValueChange(e.target.value)}
              helperText="Only letters, numbers and space are allowed. No special characters."
              fullWidth
            />
          </div>
        </div>
        <div className="flex items-center justify-end gap-4 border-t border-gray-200 pt-8">
          <Button
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faBroom} />}
            onClick={handleClearClick}
            className="!m-0 !rounded-full !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-black"
          >
            Clear
          </Button>
          <ConfirmationDialog
            title="Clear HashTag?"
            content="Are you sure you want to clear the hashtag for this member?"
            confirmButtonIcon={
              <FontAwesomeIcon
                icon={isDeleteMemberHashTagsPending ? faSpinner : faBroom}
                className="!text-sm"
              />
            }
            confirmButtonText="Clear HashTag"
            onConfirm={handleClearConfirm}
            onCancel={() => setClearDialogShow(false)}
            open={clearDialogShow}
            danger
          />
          {isDeleteMemberHashTagsPending && <Spinner className="bg-white/50" />}
          <LoadingButton
            type="submit"
            startIcon={<FontAwesomeIcon icon={faSave} className="!text-sm" />}
            loading={
              isUpdateMemberHashTagPending || isCreateMemberHashTagsPending
            }
            loadingPosition="start"
            disabled={!isHashTagDataChanged()}
            classes={{
              root: `!rounded-full ${
                isHashTagDataChanged() ? "!bg-primary" : ""
              } !px-6 !py-3 !font-sans !text-sm !normal-case !text-white`,
              disabled: "!bg-gray-300",
              loadingIndicator: "!left-5",
            }}
            onClick={hashTag ? handleUpdateClick : handleAddClick}
          >
            {hashTag ? "Update" : "Add"}
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}

export default SetHashTag;
