import SvgIcon from "@mui/material/SvgIcon";
import parse from "html-react-parser";
import { IPlatform } from "./integrationsTypes";

export type IntegrationCardProps = {
  platform: IPlatform;
  children?: JSX.Element;
  className?: string;
};

function IntegrationCard({
  platform,
  children,
  className = "",
  ...props
}: IntegrationCardProps) {
  return (
    <div
      className={`flex flex-col items-center gap-3 rounded-xl bg-white p-6 ${className}`}
      {...props}
    >
      <div className="mb-1 flex items-center justify-center rounded-xl border border-gray-200 p-4">
        <SvgIcon className="!h-[3.25rem] !w-[3.25rem]">
          {parse(platform.iconSvg)}
        </SvgIcon>
      </div>
      <div className="justify-center text-2xl font-semibold text-gray-800">
        {platform.title}
      </div>
      <div className="mb-3 grow text-center text-sm text-gray-800">
        {platform.description}
      </div>
      <div className="self-stretch">{children}</div>
    </div>
  );
}

export default IntegrationCard;
