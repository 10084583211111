import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { ReactElement, useEffect, useState } from "react";
import { SelectX as FormControl } from "../styles/mui-styled";

type FloatingSelectProps = {
  id?: string;
  label?: string;
  defaultValue?: unknown;
  value?: unknown;
  onChange?: (value: unknown) => void;
  displayEmpty?: boolean;
  disabled?: boolean;
  error?: string;
  size?: "small" | "medium" | "large";
  className?: string;
  children?: ReactElement | ReactElement[];
};

function FloatingSelect({
  id,
  label,
  defaultValue = "",
  value = "",
  onChange,
  displayEmpty,
  disabled = false,
  error,
  size,
  className = "",
  children,
  ...props
}: FloatingSelectProps) {
  const [selectedValue, setSelectedValue] = useState<unknown>(
    value || defaultValue
  );

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  function handleSelectChange(e: SelectChangeEvent<unknown>): void {
    setSelectedValue(e.target.value);
    onChange && onChange(e.target.value);
  }

  return (
    <FormControl
      size={size === "small" ? size : undefined}
      className={className}
      error={Boolean(error)}
      aria-errormessage={error}
      {...props}
    >
      <InputLabel id={id && id + "-label"}>{label}</InputLabel>
      <Select
        labelId={id && id + "-label"}
        id={id}
        value={selectedValue}
        label={label}
        onChange={handleSelectChange}
        displayEmpty={displayEmpty}
        disabled={disabled}
      >
        {children &&
          React.Children.map(children, (child, index) =>
            React.cloneElement(child, {
              key: index,
              className: `${child.props.className || ""} !font-sans ${
                size === "small" && "!text-sm"
              }`,
            })
          )}
      </Select>
    </FormControl>
  );
}

export default FloatingSelect;
