import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import addMembersService from "./features/add-members/addMembersService";
import authService from "./features/authentication/authService";
import authReducer from "./features/authentication/authSlice";
import devicesService from "./features/devices/devicesService";
import devicesReducer from "./features/devices/devicesSlice";
import forgotPasswordService from "./features/forgot-password/forgotPasswordService";
import integrationsService from "./features/integrations/integrationsService";
import integrationsReducer from "./features/integrations/integrationsSlice";
import linksService from "./features/links/linksService";
import linksReducer from "./features/links/linksSlice";
import membersService from "./features/members/membersService";
import membersReducer from "./features/members/membersSlice";
import settingsService from "./features/settings/settingsServices";
import settingsReducer from "./features/settings/settingsSlice";
import templatesService from "./features/templates/templatesService";
import templatesReducer from "./features/templates/templatesSlice";
import usersService from "./features/users/usersService";
import usersReducer from "./features/users/usersSlice";

const store = configureStore({
  reducer: {
    [authService.reducerPath]: authService.reducer,
    auth: authReducer,
    [usersService.reducerPath]: usersService.reducer,
    users: usersReducer,
    [forgotPasswordService.reducerPath]: forgotPasswordService.reducer,
    [membersService.reducerPath]: membersService.reducer,
    members: membersReducer,
    [addMembersService.reducerPath]: addMembersService.reducer,
    [integrationsService.reducerPath]: integrationsService.reducer,
    integrations: integrationsReducer,
    [linksService.reducerPath]: linksService.reducer,
    links: linksReducer,
    [settingsService.reducerPath]: settingsService.reducer,
    settings: settingsReducer,
    [templatesService.reducerPath]: templatesService.reducer,
    templates: templatesReducer,
    [devicesService.reducerPath]: devicesService.reducer,
    devices: devicesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authService.middleware)
      .concat(usersService.middleware)
      .concat(forgotPasswordService.middleware)
      .concat(membersService.middleware)
      .concat(addMembersService.middleware)
      .concat(integrationsService.middleware)
      .concat(linksService.middleware)
      .concat(settingsService.middleware)
      .concat(templatesService.middleware)
      .concat(devicesService.middleware),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
