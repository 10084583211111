import { getBoolean, getRGBAString, getSVGString } from "../../utils";
import { AddedToContactCardBy } from "../links/linksEnums";
import { SettingsColorsDefaults } from "../settings/settingsEnums";
import type {
  AddTemplateLinksToContactCardAPIResponse,
  AddTemplateLinksToContactCardResponse,
  AssignTemplateAPIResponse,
  AssignTemplateResponse,
  CreateNewTemplateAPIResponse,
  CreateNewTemplateResponse,
  CreateTemplateLinkAPIResponse,
  CreateTemplateLinkResponse,
  DeleteTemplateAPIResponse,
  DeleteTemplateLinkAPIResponse,
  DeleteTemplateLinkResponse,
  DeleteTemplateResponse,
  EditableTemplateLinkProps,
  NewTemplateLink,
  ReadTemplatesAPIResponse,
  ReadTemplatesResponse,
  ReorderTemplateLinksAPIResponse,
  ReorderTemplateLinksResponse,
  SetAsDefaultTemplateDataAPIResponse,
  SetAsDefaultTemplateDataResponse,
  SetTemplateProfileOpensAPIResponse,
  SetTemplateProfileOpensResponse,
  SwitchTemplateButtonsAPIResponse,
  SwitchTemplateButtonsRequestBody,
  SwitchTemplateButtonsResponse,
  SwitchTemplateLocksAPIResponse,
  SwitchTemplateLocksRequestArgs,
  SwitchTemplateLocksRequestBody,
  SwitchTemplateLocksResponse,
  SwitchedTemplateButtons,
  Template,
  TemplateLink,
  TemplateLinkToEdit,
  TemplateSettings,
  TemplateUpdatedColors,
  TemplateUpdatedInfo,
  UpdateTemplateDataAPIResponse,
  UpdateTemplateDataRequestArgs,
  UpdateTemplateDataResponse,
  UpdateTemplateLinkAPIResponse,
  UpdateTemplateLinkRequestArgs,
  UpdateTemplateLinkResponse,
} from "./templatesTypes";

export function getCreateNewTemplateRequestBody(
  name: Template["name"]
): FormData {
  var formdata = new FormData();

  formdata.append("name", name);

  return formdata;
}

export function mapCreateNewTemplateResponse(
  response: CreateNewTemplateAPIResponse
): CreateNewTemplateResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      newTemplate: {
        id: response.data.template.id,
        name: response.data.template.name,
        userId: response.data.template.user_id,
        companyName: response.data.template.company_name || "",
        companyAddress: response.data.template.company_address || "",
        companyLogo: response.data.template.company_logo,
        profileImage: response.data.template.profile_image,
        profileBanner: response.data.template.profile_banner,
        subtitle: response.data.template.subtitle || "",
        isProfileOpensLocked: Boolean(
          response.data.template.profile_opens_locked
        ),
        captureLead: Boolean(response.data.template.capture_lead),
        openDirect: Boolean(response.data.template.open_direct),
        isControlButtonsLocked: Boolean(
          response.data.template.control_buttons_locked
        ),
        connectButton: Boolean(response.data.template.connect_button),
        saveContactButton: Boolean(response.data.template.save_contact_button),
        isColorsCustomLocked: Boolean(
          response.data.template.colors_custom_locked
        ),
        assigneeIds: response.data.assignees_ids.map((id) => Number(id)),
        isDefault: Boolean(response.data.template.is_default),
      },
      newTemplateLinks: response.data.links.map((aNewLink) => ({
        id: aNewLink.id,
        linkTypeCode: aNewLink.profile_code,
        iconSVG: getSVGString(aNewLink.icon_url) || aNewLink.icon_svg || "",
        title: aNewLink.title,
        valueType: aNewLink.type,
        value: aNewLink.value,
        href: aNewLink.href,
        sequence: aNewLink.sequence,
        visible: Boolean(aNewLink.visible),
        isHighlighted: Boolean(aNewLink.is_highlighted),
        templateId: response.data.template.id,
        isUnique: Boolean(aNewLink.is_unique),
        isAddedToContactCardBy:
          aNewLink.added_to_contact_card || AddedToContactCardBy.NONE,
      })),
      newTemplateSettings: {
        colors: {
          sectionColor:
            getRGBAString(
              response.data.template.settings.colors.section_color ?? ""
            ) || SettingsColorsDefaults.SECTION_COLOR,
          profileColor:
            getRGBAString(
              response.data.template.settings.colors.profile_color ?? ""
            ) || SettingsColorsDefaults.PROFILE_COLOR,
          borderColor:
            getRGBAString(
              response.data.template.settings.colors.border_color ?? ""
            ) || SettingsColorsDefaults.BORDER_COLOR,
          textColor:
            getRGBAString(
              response.data.template.settings.colors.text_color ?? ""
            ) || SettingsColorsDefaults.TEXT_COLOR,
          controlButtonTextColor:
            getRGBAString(
              response.data.template.settings.colors
                .control_button_text_color ?? ""
            ) || SettingsColorsDefaults.CONTROL_BUTTON_TEXT_COLOR,
          controlButtonBgColor:
            getRGBAString(
              response.data.template.settings.colors.control_button_color ?? ""
            ) || SettingsColorsDefaults.CONTROL_BUTTON_BG_COLOR,
          profileButtonIconColor:
            getRGBAString(
              response.data.template.settings.colors
                .profile_button_icon_color ?? ""
            ) || SettingsColorsDefaults.PROFILE_BUTTON_ICON_COLOR,
          profileButtonBgColor:
            getRGBAString(
              response.data.template.settings.colors.profile_button_bg_color ??
                ""
            ) || SettingsColorsDefaults.PROFILE_BUTTON_BG_COLOR,
          colorLinkIcons: Boolean(
            getBoolean(
              response.data.template.settings.colors.color_link_icons
            ) || Boolean(SettingsColorsDefaults.COLOR_LINK_ICONS)
          ),
        },
        templateId: response.data.template.id,
      },
    },
  };
}

export function mapReadTemplatesResponse(
  response: ReadTemplatesAPIResponse
): ReadTemplatesResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      templates: response.data.templates.map((t) => ({
        id: t.id,
        name: t.name,
        userId: t.user_id,
        companyName: t.company_name || "",
        companyAddress: t.company_address || "",
        companyLogo: t.company_logo,
        profileImage: t.profile_image,
        profileBanner: t.profile_banner,
        subtitle: t.subtitle || "",
        isProfileOpensLocked: Boolean(t.profile_opens_locked),
        captureLead: Boolean(t.capture_lead),
        openDirect: Boolean(t.open_direct),
        isControlButtonsLocked: Boolean(t.control_buttons_locked),
        connectButton: Boolean(t.connect_button),
        saveContactButton: Boolean(t.save_contact_button),
        isColorsCustomLocked: Boolean(t.colors_custom_locked),
        assigneeIds: t.assignees_ids.map((id) => Number(id)),
        isDefault: Boolean(t.is_default),
      })),
      templateLinks: response.data.templates.flatMap((aTemplate) =>
        aTemplate.links.map((aLink) => ({
          id: aLink.id,
          linkTypeCode: aLink.profile_code,
          iconSVG: getSVGString(aLink.icon_url) || aLink.icon_svg || "",
          title: aLink.title,
          valueType: aLink.type,
          value: aLink.value,
          href: aLink.href,
          sequence: aLink.sequence,
          visible: Boolean(aLink.visible),
          isHighlighted: Boolean(aLink.is_highlighted),
          templateId: aTemplate.id,
          isUnique: Boolean(aLink.is_unique),
          isAddedToContactCardBy:
            aLink.added_to_contact_card || AddedToContactCardBy.NONE,
        }))
      ),
      templateSettings: response.data.templates.map((t) => {
        return {
          colors: {
            sectionColor:
              getRGBAString(t.settings.colors.section_color ?? "") ||
              SettingsColorsDefaults.SECTION_COLOR,
            profileColor:
              getRGBAString(t.settings.colors.profile_color ?? "") ||
              SettingsColorsDefaults.PROFILE_COLOR,
            borderColor:
              getRGBAString(t.settings.colors.border_color ?? "") ||
              SettingsColorsDefaults.BORDER_COLOR,
            textColor:
              getRGBAString(t.settings.colors.text_color ?? "") ||
              SettingsColorsDefaults.TEXT_COLOR,
            controlButtonTextColor:
              getRGBAString(
                t.settings.colors.control_button_text_color ?? ""
              ) || SettingsColorsDefaults.CONTROL_BUTTON_TEXT_COLOR,
            controlButtonBgColor:
              getRGBAString(t.settings.colors.control_button_color ?? "") ||
              SettingsColorsDefaults.CONTROL_BUTTON_BG_COLOR,
            profileButtonIconColor:
              getRGBAString(
                t.settings.colors.profile_button_icon_color ?? ""
              ) || SettingsColorsDefaults.PROFILE_BUTTON_ICON_COLOR,
            profileButtonBgColor:
              getRGBAString(t.settings.colors.profile_button_bg_color ?? "") ||
              SettingsColorsDefaults.PROFILE_BUTTON_BG_COLOR,
            colorLinkIcons:
              getBoolean(t.settings.colors.color_link_icons) ||
              Boolean(SettingsColorsDefaults.COLOR_LINK_ICONS),
          },
          templateId: t.id,
        };
      }),
    },
  };
}

export function mapDeleteTemplateResponse(
  response: DeleteTemplateAPIResponse
): DeleteTemplateResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      removedTemplateId: response.data.template_id,
      removedMemberLinkIds: response.data.member_links_ids,
    },
  };
}

export function getUpdatedTemplateData(
  newTemplateData: TemplateUpdatedInfo,
  oldTemplateData: Template
): UpdateTemplateDataRequestArgs {
  const updatedTemplateData: UpdateTemplateDataRequestArgs = {
    id: oldTemplateData.id,
  };

  if (newTemplateData.name !== oldTemplateData.name) {
    updatedTemplateData.name = newTemplateData.name;
  }
  if (
    newTemplateData.profileImageObj !== undefined &&
    !(
      oldTemplateData.profileImage === "" &&
      newTemplateData.profileImageObj === null
    )
  ) {
    updatedTemplateData.profileImageObj = newTemplateData.profileImageObj;
  }
  if (
    newTemplateData.profileBannerObj !== undefined &&
    !(
      oldTemplateData.profileBanner === "" &&
      newTemplateData.profileBannerObj === null
    )
  ) {
    updatedTemplateData.profileBannerObj = newTemplateData.profileBannerObj;
  }
  if (
    newTemplateData.companyLogoObj !== undefined &&
    !(
      oldTemplateData.companyLogo === "" &&
      newTemplateData.companyLogoObj === null
    )
  ) {
    updatedTemplateData.companyLogoObj = newTemplateData.companyLogoObj;
  }
  if (newTemplateData.companyName !== oldTemplateData.companyName) {
    updatedTemplateData.companyName = newTemplateData.companyName;
  }
  if (newTemplateData.companyAddress !== oldTemplateData.companyAddress) {
    updatedTemplateData.companyAddress = newTemplateData.companyAddress;
  }
  if (newTemplateData.subtitle !== oldTemplateData.subtitle) {
    updatedTemplateData.subtitle = newTemplateData.subtitle;
  }

  return updatedTemplateData;
}

export function getUpdatedTemplateColors(
  templateId: Template["id"],
  newTemplateColors: TemplateUpdatedColors,
  oldTemplateColors: TemplateSettings["colors"]
): UpdateTemplateDataRequestArgs {
  const updatedTemplateColors: UpdateTemplateDataRequestArgs = {
    id: templateId,
  };

  if (newTemplateColors.sectionColor !== oldTemplateColors.sectionColor) {
    updatedTemplateColors.sectionColor = newTemplateColors.sectionColor;
  }
  if (newTemplateColors.profileColor !== oldTemplateColors.profileColor) {
    updatedTemplateColors.profileColor = newTemplateColors.profileColor;
  }
  if (newTemplateColors.borderColor !== oldTemplateColors.borderColor) {
    updatedTemplateColors.borderColor = newTemplateColors.borderColor;
  }
  if (newTemplateColors.textColor !== oldTemplateColors.textColor) {
    updatedTemplateColors.textColor = newTemplateColors.textColor;
  }
  if (
    newTemplateColors.controlButtonTextColor !==
    oldTemplateColors.controlButtonTextColor
  ) {
    updatedTemplateColors.controlButtonTextColor =
      newTemplateColors.controlButtonTextColor;
  }
  if (
    newTemplateColors.controlButtonBgColor !==
    oldTemplateColors.controlButtonBgColor
  ) {
    updatedTemplateColors.controlButtonBgColor =
      newTemplateColors.controlButtonBgColor;
  }
  if (
    newTemplateColors.profileButtonIconColor !==
    oldTemplateColors.profileButtonIconColor
  ) {
    updatedTemplateColors.profileButtonIconColor =
      newTemplateColors.profileButtonIconColor;
  }
  if (
    newTemplateColors.profileButtonBgColor !==
    oldTemplateColors.profileButtonBgColor
  ) {
    updatedTemplateColors.profileButtonBgColor =
      newTemplateColors.profileButtonBgColor;
  }
  if (newTemplateColors.colorLinkIcons !== oldTemplateColors.colorLinkIcons) {
    updatedTemplateColors.colorLinkIcons = newTemplateColors.colorLinkIcons;
  }

  return updatedTemplateColors;
}

export function getUpdateTemplateDataRequestBody(
  templateDataToUpdate: Omit<UpdateTemplateDataRequestArgs, "id">
): FormData {
  const formdata = new FormData();

  if (templateDataToUpdate.name !== undefined) {
    formdata.append("name", templateDataToUpdate.name);
  }
  if (templateDataToUpdate.profileImageObj !== undefined) {
    formdata.append(
      "profile_image",
      templateDataToUpdate.profileImageObj ?? ""
    );
  }
  if (templateDataToUpdate.profileBannerObj !== undefined) {
    formdata.append(
      "profile_banner",
      templateDataToUpdate.profileBannerObj ?? ""
    );
  }
  if (templateDataToUpdate.companyLogoObj !== undefined) {
    formdata.append("company_logo", templateDataToUpdate.companyLogoObj ?? "");
  }
  if (templateDataToUpdate.companyName !== undefined) {
    formdata.append("company_name", templateDataToUpdate.companyName);
  }
  if (templateDataToUpdate.companyAddress !== undefined) {
    formdata.append("company_address", templateDataToUpdate.companyAddress);
  }
  if (templateDataToUpdate.subtitle !== undefined) {
    formdata.append("subtitle", templateDataToUpdate.subtitle);
  }
  if (templateDataToUpdate.sectionColor !== undefined) {
    formdata.append("section_color", templateDataToUpdate.sectionColor);
  }
  if (templateDataToUpdate.profileColor !== undefined) {
    formdata.append("profile_color", templateDataToUpdate.profileColor);
  }
  if (templateDataToUpdate.borderColor !== undefined) {
    formdata.append("border_color", templateDataToUpdate.borderColor);
  }
  if (templateDataToUpdate.textColor !== undefined) {
    formdata.append("text_color", templateDataToUpdate.textColor);
  }
  if (templateDataToUpdate.controlButtonTextColor !== undefined) {
    formdata.append(
      "control_button_text_color",
      templateDataToUpdate.controlButtonTextColor
    );
  }
  if (templateDataToUpdate.controlButtonBgColor !== undefined) {
    formdata.append(
      "control_button_color",
      templateDataToUpdate.controlButtonBgColor
    );
  }
  if (templateDataToUpdate.profileButtonIconColor !== undefined) {
    formdata.append(
      "profile_button_icon_color",
      templateDataToUpdate.profileButtonIconColor
    );
  }
  if (templateDataToUpdate.profileButtonBgColor !== undefined) {
    formdata.append(
      "profile_button_bg_color",
      templateDataToUpdate.profileButtonBgColor
    );
  }
  if (templateDataToUpdate.colorLinkIcons !== undefined) {
    formdata.append(
      "color_link_icons",
      Number(templateDataToUpdate.colorLinkIcons).toString()
    );
  }

  return formdata;
}

export function mapUpdateTemplateDataResponse(
  response: UpdateTemplateDataAPIResponse
): UpdateTemplateDataResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      template: {
        id: response.data.template.id,
        name: response.data.template.name,
        profileImage: response.data.template.profile_image,
        profileBanner: response.data.template.profile_banner,
        companyLogo: response.data.template.company_logo,
        companyName: response.data.template.company_name ?? "",
        companyAddress: response.data.template.company_address ?? "",
        subtitle: response.data.template.subtitle ?? "",
      },
      templateSettings: {
        colors: {
          sectionColor:
            getRGBAString(
              response.data.template.settings.colors.section_color ?? ""
            ) || SettingsColorsDefaults.SECTION_COLOR,
          profileColor:
            getRGBAString(
              response.data.template.settings.colors.profile_color ?? ""
            ) || SettingsColorsDefaults.PROFILE_COLOR,
          borderColor:
            getRGBAString(
              response.data.template.settings.colors.border_color ?? ""
            ) || SettingsColorsDefaults.BORDER_COLOR,
          textColor:
            getRGBAString(
              response.data.template.settings.colors.text_color ?? ""
            ) || SettingsColorsDefaults.TEXT_COLOR,
          controlButtonTextColor:
            getRGBAString(
              response.data.template.settings.colors
                .control_button_text_color ?? ""
            ) || SettingsColorsDefaults.CONTROL_BUTTON_TEXT_COLOR,
          controlButtonBgColor:
            getRGBAString(
              response.data.template.settings.colors.control_button_color ?? ""
            ) || SettingsColorsDefaults.CONTROL_BUTTON_BG_COLOR,
          profileButtonIconColor:
            getRGBAString(
              response.data.template.settings.colors
                .profile_button_icon_color ?? ""
            ) || SettingsColorsDefaults.PROFILE_BUTTON_ICON_COLOR,
          profileButtonBgColor:
            getRGBAString(
              response.data.template.settings.colors.profile_button_bg_color ??
                ""
            ) || SettingsColorsDefaults.PROFILE_BUTTON_BG_COLOR,
          colorLinkIcons:
            getBoolean(
              response.data.template.settings.colors.color_link_icons
            ) || Boolean(SettingsColorsDefaults.COLOR_LINK_ICONS),
        },
        templateId: response.data.template.id,
      },
    },
  };
}

export function mapSetAsDefaultTemplateDataResponse(
  response: SetAsDefaultTemplateDataAPIResponse
): SetAsDefaultTemplateDataResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      defaultTemplateId: response.data.default_template_id,
    },
  };
}

export function mapSetTemplateCaptureLeadResponse(
  response: SetTemplateProfileOpensAPIResponse
): SetTemplateProfileOpensResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      updatedTemplate:
        response.data.template.open_direct !== undefined
          ? {
              id: response.data.template.id,
              captureLead: Boolean(response.data.template.capture_lead),
              openDirect: Boolean(response.data.template.open_direct),
            }
          : {
              id: response.data.template.id,
              captureLead: Boolean(response.data.template.capture_lead),
            },
    },
  };
}

export function mapSetTemplateOpenDirectResponse(
  response: SetTemplateProfileOpensAPIResponse
): SetTemplateProfileOpensResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      updatedTemplate:
        response.data.template.capture_lead !== undefined
          ? {
              id: response.data.template.id,
              openDirect: Boolean(response.data.template.open_direct),
              captureLead: Boolean(response.data.template.capture_lead),
            }
          : {
              id: response.data.template.id,
              openDirect: Boolean(response.data.template.open_direct),
            },
    },
  };
}

export function mapReorderTemplateLinksResponse(
  response: ReorderTemplateLinksAPIResponse
): ReorderTemplateLinksResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      reorderedTemplateLinks: response.data.templateLinks.map((l) => ({
        id: l.id,
        sequence: l.sequence,
      })),
    },
  };
}

export function getUpdateTemplateLinkRequestBody(
  updates: Omit<UpdateTemplateLinkRequestArgs, "id" | "templateId">
): FormData {
  const formData = new FormData();

  if (updates.iconFile !== undefined && updates.iconFile !== null) {
    formData.append("icon", updates.iconFile);
  }
  if (updates.iconSVG !== undefined) {
    formData.append("icon_svg", updates.iconSVG);
  }
  if (updates.title !== undefined) {
    formData.append("title", updates.title);
  }
  if (updates.value !== undefined) {
    formData.append("value", updates.value);
  }
  if (updates.visible !== undefined) {
    formData.append("visible", Number(updates.visible).toString());
  }
  if (updates.isHighlighted !== undefined) {
    formData.append("is_highlighted", Number(updates.isHighlighted).toString());
  }

  return formData;
}

export function mapUpdateTemplateLinkResponse(
  response: UpdateTemplateLinkAPIResponse
): UpdateTemplateLinkResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      updatedTemplateLink: {
        id: response.data.template_link.id,
        iconSVG:
          getSVGString(response.data.template_link.icon_url) ||
          response.data.template_link.icon_svg ||
          "",
        title: response.data.template_link.title,
        value: response.data.template_link.value,
        href: response.data.template_link.href,
        visible: Boolean(response.data.template_link.visible),
        isHighlighted: Boolean(response.data.template_link.is_highlighted),
      },
      updatedMemberLinks: response.data.member_links.map((anUpdatedLink) => ({
        id: anUpdatedLink.id,
        iconSVG:
          getSVGString(anUpdatedLink.icon_url) || anUpdatedLink.icon_svg || "",
        title: anUpdatedLink.title,
        value: anUpdatedLink.value,
        href: anUpdatedLink.href,
        visible: Boolean(anUpdatedLink.visible),
        isHighlighted: Boolean(anUpdatedLink.is_highlighted),
      })),
    },
  };
}

export function getCreateTemplateLinkRequestBody(
  newLink: NewTemplateLink
): FormData {
  const formData = new FormData();

  formData.append("code", newLink.linkTypeCode);
  if (newLink.iconFile !== undefined && newLink.iconFile !== null) {
    formData.append("icon", newLink.iconFile);
  }
  formData.append("title", newLink.title);
  formData.append("value", newLink.value);
  formData.append("is_highlighted", Number(newLink.isHighlighted).toString());
  formData.append("is_unique", Number(newLink.isUnique).toString());

  return formData;
}

export function mapCreateTemplateLinkResponse(
  response: CreateTemplateLinkAPIResponse
): CreateTemplateLinkResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      newTemplateLink: {
        id: response.data.template_link.id,
        linkTypeCode: response.data.template_link.profile_code,
        iconSVG:
          getSVGString(response.data.template_link.icon_url) ||
          response.data.template_link.icon_svg ||
          "",
        title: response.data.template_link.title,
        valueType: response.data.template_link.type,
        value: response.data.template_link.value,
        href: response.data.template_link.href,
        sequence: response.data.template_link.sequence,
        visible: Boolean(response.data.template_link.visible),
        isHighlighted: Boolean(response.data.template_link.is_highlighted),
        templateId: response.data.template_link.template_id,
        isUnique: Boolean(response.data.template_link.is_unique),
        isAddedToContactCardBy:
          response.data.template_link.added_to_contact_card ||
          AddedToContactCardBy.NONE,
      },
      clonedMemberLinks: response.data.member_links.map((aClonedLink) => ({
        id: aClonedLink.id,
        linkTypeCode: aClonedLink.profile_code,
        iconSVG:
          getSVGString(aClonedLink.icon_url) || aClonedLink.icon_svg || "",
        title: aClonedLink.title,
        valueType: aClonedLink.type,
        value: aClonedLink.value,
        href: aClonedLink.href,
        sequence: aClonedLink.sequence,
        visible: Boolean(aClonedLink.visible),
        isHighlighted: Boolean(aClonedLink.is_highlighted),
        userId: aClonedLink.user_id,
        globalId: aClonedLink.global_id || null,
        templateId: aClonedLink.template_id || null,
        isUnique: Boolean(aClonedLink.is_unique),
        isAddedToContactCardBy:
          aClonedLink.added_to_contact_card || AddedToContactCardBy.NONE,
        templateLinkId: aClonedLink.template_link_id || null,
        syncedWith: null,
      })),
    },
  };
}

export function getUpdatedTemplateLinkProps(
  oldLink: TemplateLink,
  newLink: TemplateLinkToEdit
): EditableTemplateLinkProps {
  const updatedProps: EditableTemplateLinkProps = {};

  if (newLink.iconFile !== null) {
    updatedProps.iconFile = newLink.iconFile;
  } else if (newLink.iconSVG !== oldLink.iconSVG) {
    updatedProps.iconSVG = newLink.iconSVG;
  }

  if (newLink.title !== oldLink.title) {
    updatedProps.title = newLink.title;
  }

  if (newLink.value !== oldLink.value) {
    updatedProps.value = newLink.value;
  }

  if (newLink.isHighlighted !== oldLink.isHighlighted) {
    updatedProps.isHighlighted = newLink.isHighlighted;
  }

  return updatedProps;
}

export function getSwitchTemplateButtonsRequestBody(
  updates: SwitchedTemplateButtons
): SwitchTemplateButtonsRequestBody {
  const requestBody: SwitchTemplateButtonsRequestBody = {};

  if (updates.connectButton !== undefined) {
    requestBody.connect_button = updates.connectButton ? 1 : 0;
  }
  if (updates.saveContactButton !== undefined) {
    requestBody.save_contact_button = updates.saveContactButton ? 1 : 0;
  }

  return requestBody;
}

export function mapSwitchTemplateButtonsResponse(
  response: SwitchTemplateButtonsAPIResponse
): SwitchTemplateButtonsResponse {
  const updatedTemplateData: SwitchedTemplateButtons = {};
  if (response.data.template.connect_button !== undefined) {
    updatedTemplateData.connectButton = Boolean(
      response.data.template.connect_button
    );
  }
  if (response.data.template.save_contact_button !== undefined) {
    updatedTemplateData.saveContactButton = Boolean(
      response.data.template.save_contact_button
    );
  }

  return {
    success: response.success,
    message: response.message,
    data: {
      updatedTemplate: {
        id: response.data.template.id,
        ...updatedTemplateData,
      },
    },
  };
}

export function mapDeleteTemplateLinkResponse(
  response: DeleteTemplateLinkAPIResponse
): DeleteTemplateLinkResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      deletedTemplateLinkId: response.data.template_link_id,
      deletedMemberLinkIds: response.data.member_link_ids,
    },
  };
}

export function mapAssignTemplateResponse(
  response: AssignTemplateAPIResponse
): AssignTemplateResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      assignedTemplate: {
        id: response.data.template.id,
        assigneeIds: response.data.template.assignees_ids.map((id) =>
          Number(id)
        ),
      },
      clonedMemberLinks: response.data.member_links.map((aClonedLink) => ({
        id: aClonedLink.id,
        linkTypeCode: aClonedLink.profile_code,
        iconSVG:
          getSVGString(aClonedLink.icon_url) || aClonedLink.icon_svg || "",
        title: aClonedLink.title,
        valueType: aClonedLink.type,
        value: aClonedLink.value,
        href: aClonedLink.href,
        sequence: aClonedLink.sequence,
        visible: Boolean(aClonedLink.visible),
        isHighlighted: Boolean(aClonedLink.is_highlighted),
        userId: aClonedLink.user_id,
        globalId: aClonedLink.global_id || null,
        templateId: aClonedLink.template_id || null,
        isUnique: Boolean(aClonedLink.is_unique),
        isAddedToContactCardBy:
          aClonedLink.added_to_contact_card || AddedToContactCardBy.NONE,
        templateLinkId: aClonedLink.template_link_id || null,
        syncedWith: null,
      })),
    },
  };
}

export function getSwitchTemplateLocksRequestBody(
  updates: Omit<SwitchTemplateLocksRequestArgs, "id">
): SwitchTemplateLocksRequestBody {
  const requestBody: SwitchTemplateLocksRequestBody = {};

  if (updates.isProfileOpensLocked !== undefined) {
    requestBody.profile_opens_locked = updates.isProfileOpensLocked ? 1 : 0;
  }
  if (updates.isControlButtonsLocked !== undefined) {
    requestBody.control_buttons_locked = updates.isControlButtonsLocked ? 1 : 0;
  }
  if (updates.isColorsCustomLocked !== undefined) {
    requestBody.colors_custom_locked = updates.isColorsCustomLocked ? 1 : 0;
  }

  return requestBody;
}

export function mapSwitchTemplateLocksResponse(
  response: SwitchTemplateLocksAPIResponse
): SwitchTemplateLocksResponse {
  const updatedTemplateData: Omit<SwitchTemplateLocksRequestArgs, "id"> = {};
  if (response.data.template.profile_opens_locked !== undefined) {
    updatedTemplateData.isProfileOpensLocked = Boolean(
      response.data.template.profile_opens_locked
    );
  }
  if (response.data.template.control_buttons_locked !== undefined) {
    updatedTemplateData.isControlButtonsLocked = Boolean(
      response.data.template.control_buttons_locked
    );
  }
  if (response.data.template.colors_custom_locked !== undefined) {
    updatedTemplateData.isColorsCustomLocked = Boolean(
      response.data.template.colors_custom_locked
    );
  }

  return {
    success: response.success,
    message: response.message,
    data: {
      updatedTemplate: {
        id: response.data.template.id,
        ...updatedTemplateData,
      },
    },
  };
}

export function getAddTemplateLinksToContactCardRequestBody(
  templateLinkIds: Array<TemplateLink["id"]>,
  isAddedToContactCardBy: TemplateLink["isAddedToContactCardBy"]
): FormData {
  const formData = new FormData();

  formData.append("ids", templateLinkIds.join(","));
  formData.append(
    "added_to_contact_card",
    String(isAddedToContactCardBy !== AddedToContactCardBy.NONE)
  );

  return formData;
}

export function mapAddTemplateLinksToContactCardResponse(
  response: AddTemplateLinksToContactCardAPIResponse
): AddTemplateLinksToContactCardResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      updatedTemplateLinks: response.data.template_links.map((tl) => ({
        id: tl.id,
        isAddedToContactCardBy:
          tl.added_to_contact_card || AddedToContactCardBy.NONE,
      })),
      updatedClonedMemberLinks: response.data.cloned_member_links?.map(
        (cl) => ({
          id: cl.id,
          isAddedToContactCardBy:
            cl.added_to_contact_card || AddedToContactCardBy.NONE,
        })
      ),
    },
  };
}
