import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import type { DevicesState, MemberDevice } from "./devicesTypes";

const initialState: DevicesState = {
  devices: undefined,
};

const devicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    resetDevices: (state: DevicesState) => {
      state.devices = undefined;
    },
    setDevices: (
      state: DevicesState,
      action: PayloadAction<MemberDevice[]>
    ) => {
      state.devices = action.payload;
    },
    removeDevices: (
      state: DevicesState,
      action: PayloadAction<Array<MemberDevice["id"]>>
    ) => {
      state.devices = state.devices?.filter(
        (d) => !action.payload.includes(d.id)
      );
    },
  },
});

export const { resetDevices, setDevices, removeDevices } = devicesSlice.actions;

export default devicesSlice.reducer;

export const getDevices = (state: RootState) => state.devices.devices;

export const getDevicesByAssignedTo = createSelector(
  [getDevices, (_, assignedTo: MemberDevice["assignedTo"]) => assignedTo],
  (devices, assignedTo) => devices?.filter((d) => d.assignedTo === assignedTo)
);
