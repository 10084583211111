import AppConfigs from "../../configs/appConfigs";
import {
  getBase64Url,
  getProcessedImage,
  getSVGString,
  validateImage,
} from "../../utils/images";
import { MemberDataLabels } from "../members/membersEnums";
import {
  AddedToContactCardBy,
  LinkCategoryType,
  LinkTypeCode,
} from "./linksEnums";
import type {
  AddMemberLinksToContactCardAPIResponse,
  AddMemberLinksToContactCardResponse,
  CreateBulkLinkAPIResponse,
  CreateBulkLinkRequestArgs,
  CreateBulkLinkResponse,
  CreateNewLinkAPIResponse,
  CreateNewLinkRequestArgs,
  CreateNewLinkResponse,
  EditableMemberLinkProps,
  GetMemberLinkIconResponse,
  LinkType,
  MemberLink,
  MemberLinkToEdit,
  ReadLinkTypesAPIResponse,
  ReadLinkTypesResponse,
  ReadMemberLinksAPIResponse,
  ReadMemberLinksResponse,
  ReorderMemberLinksAPIResponse,
  ReorderMemberLinksResponse,
  UpdateMemberLinkAPIResponse,
  UpdateMemberLinkRequestArgs,
  UpdateMemberLinkResponse,
} from "./linksTypes";

export function mapReadLinkTypesResponse(
  response: ReadLinkTypesAPIResponse
): ReadLinkTypesResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      linkCategories: response.data.link_categories.map((aCategory) => ({
        id: aCategory.id,
        title: aCategory.title,
      })),
      linkTypes: response.data.link_types
        .filter((aLinkType) => aLinkType.code !== LinkTypeCode.CONTACT_CARD)
        .map((aLinkType) => ({
          id: aLinkType.id,
          code: aLinkType.code,
          iconSVGDefault:
            aLinkType.icon_svg_default || getSVGString(aLinkType.icon_url),
          iconSVGColorized: aLinkType.icon_svg_colorized ?? "",
          title: aLinkType.title,
          valueType: aLinkType.value_type,
          baseUrl: aLinkType.base_url || "",
          categoryId: aLinkType.category_id,
          membership: aLinkType.is_pro,
          isIconEditable: Boolean(aLinkType.is_icon_editable),
          isTitleEditable: Boolean(aLinkType.is_title_editable),
          isValueEditable: Boolean(aLinkType.is_value_editable),
          description: aLinkType.description || "",
        })),
    },
  };
}

export function mapReadMemberLinksResponse(
  response: ReadMemberLinksAPIResponse
): ReadMemberLinksResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      links: response.data.links.map((aLink) => ({
        id: aLink.id,
        linkTypeCode: aLink.profile_code,
        iconSVG: getSVGString(aLink.icon_url) || aLink.icon_svg || "",
        title: aLink.title,
        valueType: aLink.type,
        value: aLink.value,
        href: aLink.href,
        sequence: aLink.sequence,
        visible: Boolean(aLink.visible),
        isHighlighted: Boolean(aLink.is_highlighted),
        userId: aLink.user_id,
        globalId: aLink.global_id || null,
        templateId: aLink.template_id || null,
        isUnique: Boolean(aLink.is_unique),
        isAddedToContactCardBy:
          aLink.added_to_contact_card || AddedToContactCardBy.NONE,
        templateLinkId: aLink.template_link_id || null,
        syncedWith: aLink.synced_with || null,
      })),
    },
  };
}

export function mapCreateNewLinkResponse(
  response: CreateNewLinkAPIResponse
): CreateNewLinkResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      links: response.data.links.map((aNewLink) => ({
        id: aNewLink.id,
        linkTypeCode: aNewLink.profile_code,
        iconSVG: getSVGString(aNewLink.icon_url) || aNewLink.icon_svg || "",
        title: aNewLink.title,
        valueType: aNewLink.type,
        value: aNewLink.value,
        href: aNewLink.href,
        sequence: aNewLink.sequence,
        visible: Boolean(aNewLink.visible),
        isHighlighted: Boolean(aNewLink.is_highlighted),
        userId: aNewLink.user_id,
        globalId: aNewLink.global_id || null,
        templateId: null,
        isUnique: false,
        isAddedToContactCardBy:
          aNewLink.added_to_contact_card || AddedToContactCardBy.NONE,
        templateLinkId: aNewLink.template_link_id || null,
        syncedWith: null,
      })),
    },
  };
}

export function getCreateNewLinkRequestBody(
  newLink: CreateNewLinkRequestArgs
): FormData {
  const formData = new FormData();

  formData.append("code", newLink.linkTypeCode);
  formData.append("title", newLink.title);
  formData.append("value", newLink.value);
  formData.append("is_highlighted", Number(newLink.isHighlighted).toString());
  formData.append("is_global", Number(newLink.isGlobal).toString());
  if (newLink.iconFile !== undefined && newLink.iconFile !== null) {
    formData.append("icon", newLink.iconFile);
  }

  return formData;
}

export function getCreateBulkLinkRequestBody({
  memberIds,
  link,
}: CreateBulkLinkRequestArgs): FormData {
  const formData = new FormData();

  formData.append("member_ids", memberIds.join(","));
  formData.append("profile_code", link.linkTypeCode);
  if (link.iconFile !== undefined && link.iconFile !== null) {
    formData.append("icon", link.iconFile);
  }
  if (link.title !== undefined) formData.append("title", link.title);
  formData.append("profile_link_value", link.value);
  if (link.isHighlighted !== undefined)
    formData.append("is_highlighted", Number(link.isHighlighted).toString());

  return formData;
}

export function mapCreateBulkLinkResponse(
  response: CreateBulkLinkAPIResponse
): CreateBulkLinkResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      links: response.data.links.map((aNewBulkLink) => ({
        id: aNewBulkLink.id,
        linkTypeCode: aNewBulkLink.profile_code,
        iconSVG:
          getSVGString(aNewBulkLink.icon_url) || aNewBulkLink.icon_svg || "",
        title: aNewBulkLink.title,
        valueType: aNewBulkLink.type,
        value: aNewBulkLink.profile_link_value,
        href: aNewBulkLink.profile_link,
        sequence: aNewBulkLink.sequence,
        visible: Boolean(aNewBulkLink.visible),
        isHighlighted: Boolean(aNewBulkLink.is_highlighted),
        userId: aNewBulkLink.user_id,
        globalId: aNewBulkLink.global_id,
        templateId: null,
        isUnique: false,
        isAddedToContactCardBy:
          aNewBulkLink.added_to_contact_card || AddedToContactCardBy.NONE,
        templateLinkId: aNewBulkLink.template_link_id || null,
        syncedWith: null,
      })),
    },
  };
}

export function getUpdatedMemberLinkProps(
  link: MemberLink,
  linkToEdit: MemberLinkToEdit
): EditableMemberLinkProps {
  const updatedProps: EditableMemberLinkProps = {};

  if (linkToEdit.iconFile !== null) {
    updatedProps.iconFile = linkToEdit.iconFile;
  } else if (linkToEdit.iconSVG !== link.iconSVG) {
    updatedProps.iconSVG = linkToEdit.iconSVG;
  }

  if (linkToEdit.title !== link.title) {
    updatedProps.title = linkToEdit.title;
  }

  if (linkToEdit.value !== link.value) {
    updatedProps.value = linkToEdit.value;
  }

  if (linkToEdit.isHighlighted !== link.isHighlighted) {
    updatedProps.isHighlighted = linkToEdit.isHighlighted;
  }

  return updatedProps;
}

export function getUpdateMemberLinkRequestBody(
  propsToUpdate: Omit<UpdateMemberLinkRequestArgs, "id" | "userId">
): FormData {
  const formData = new FormData();

  if ("visible" in propsToUpdate) {
    formData.append("visible", Number(propsToUpdate.visible).toString());
  } else {
    const updatedProps: EditableMemberLinkProps = propsToUpdate;

    if (updatedProps.iconFile !== undefined && updatedProps.iconFile !== null) {
      formData.append("icon", updatedProps.iconFile);
    }
    if (updatedProps.iconSVG !== undefined) {
      formData.append("icon_svg", updatedProps.iconSVG);
    }
    if (updatedProps.title !== undefined) {
      formData.append("title", updatedProps.title);
    }
    if (updatedProps.value !== undefined) {
      formData.append("value", updatedProps.value);
    }
    if (updatedProps.isHighlighted !== undefined) {
      formData.append(
        "is_highlighted",
        Number(updatedProps.isHighlighted).toString()
      );
    }
  }

  return formData;
}

export function mapUpdateMemberLinkResponse(
  response: UpdateMemberLinkAPIResponse
): UpdateMemberLinkResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      links: response.data.links.map((anUpdatedLink) => ({
        id: anUpdatedLink.id,
        iconSVG:
          getSVGString(anUpdatedLink.icon_url) || anUpdatedLink.icon_svg || "",
        title: anUpdatedLink.title,
        value: anUpdatedLink.value,
        visible: Boolean(anUpdatedLink.visible),
        isHighlighted: Boolean(anUpdatedLink.is_highlighted),
      })),
    },
  };
}

export function mapReorderMemberLinksResponse(
  response: ReorderMemberLinksAPIResponse
): ReorderMemberLinksResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      memberId: Number(response.data.id),
      links: response.data.links,
    },
  };
}

export function getLinkValuePlaceholder(
  linkType: LinkType | undefined
): string {
  return linkType?.title
    ? linkType.valueType === LinkCategoryType.OTHER ||
      linkType.title.toLowerCase().includes(linkType.valueType.toLowerCase())
      ? linkType.title
      : `${linkType.title} ${linkType.valueType}`
    : linkType?.valueType || "";
}

export async function getMemberLinkIcon(
  image: File
): Promise<GetMemberLinkIconResponse> {
  let success = false;
  let message = "Unable to process the image. Please try again.";
  let resizedImageUrl = "";

  const resizedImage = await getProcessedImage(image, {
    maxWidth: AppConfigs.getMaximumWidth(MemberDataLabels.PROFILE_LINK),
  });

  if (resizedImage) {
    success = true;
    message = "Unable to get the image URL. Please try again.";

    resizedImageUrl = await getBase64Url(resizedImage);
    if (resizedImageUrl) {
      const { success: thisSuccess, message: thisMessage } = validateImage({
        file: resizedImage,
        accept: AppConfigs.getAcceptedFileTypes(MemberDataLabels.PROFILE_LINK),
        maxSize: AppConfigs.getMaximumFileSize(MemberDataLabels.PROFILE_LINK),
      });

      success = thisSuccess;
      message = thisMessage;
    }
  }

  return {
    success,
    message,
    data: {
      iconUrl: resizedImageUrl,
      iconObj: resizedImage,
    },
  };
}

export function getAddMemberLinksToContactCardRequestBody(
  memberLinkIds: Array<MemberLink["id"]>,
  isAddedToContactCardBy: MemberLink["isAddedToContactCardBy"]
): FormData {
  const formData = new FormData();

  formData.append("ids", memberLinkIds.join(","));
  formData.append(
    "added_to_contact_card",
    String(isAddedToContactCardBy !== AddedToContactCardBy.NONE)
  );

  return formData;
}

export function mapAddMemberLinksToContactCardResponse(
  response: AddMemberLinksToContactCardAPIResponse
): AddMemberLinksToContactCardResponse {
  return {
    success: response.success,
    message: response.message,
    data: response.data.map((aMemberLink) => ({
      id: aMemberLink.id,
      isAddedToContactCardBy:
        aMemberLink.added_to_contact_card || AddedToContactCardBy.NONE,
    })),
  };
}
