import { createApi } from "@reduxjs/toolkit/query/react";
import APIConfigs from "../../configs/apiConfigs";
// eslint-disable-next-line
import { baseQueryWithReauth } from "../rtk-query/baseQuery";
import {
  getUpdateMemberColorsSettingsFormData,
  getUpdateMemberDataRequestBody,
  mapCreateMemberHashTagsResponse,
  mapDeleteMemberHashTagsResponse,
  mapGetAllMembersResponse,
  mapGetMemberColorsSettingsResponse,
  mapLockUnlockMembersResponse,
  mapReadMemberHashTagsResponse,
  mapResetPasswordResponse,
  mapSetCaptureLeadResponse,
  mapSetCompanyLogoResponse,
  mapSetOpenDirectResponse,
  mapSetProfileBannerResponse,
  mapSetProfilePictureResponse,
  mapSwitchConnectButtonResponse,
  mapSwitchDirectOpenResponse,
  mapSwitchMembersVisibilityResponse,
  mapSwitchSaveContactButtonResponse,
  mapToAPIMember,
  mapUpdateMemberColorsSettingsResponse,
  mapUpdateMemberDataResponse,
  mapUpdateMemberHashTagResponse,
  mapUpdateMemberResponse,
} from "./membersMapper";
import type {
  CreateMemberHashTagsRequestArgs,
  CreateMemberHashTagsResponse,
  DeleteMemberHashTagsRequestArgs,
  DeleteMemberHashTagsResponse,
  DeleteMembersRequestArgs,
  DeleteMembersResponse,
  GetAllMembersResponse,
  GetMemberColorsSettingsResponse,
  Member,
  ReadMemberHashTagsResponse,
  SetCaptureLeadRequestArgs,
  SetCaptureLeadResponse,
  SetOpenDirectRequestArgs,
  SetOpenDirectResponse,
  SwitchConnectButtonRequestArgs,
  SwitchDirectOpenRequestArgs,
  SwitchMembersVisibilityRequestArgs,
  SwitchSaveContactButtonRequestArgs,
  UpdateMemberColorsSettingsRequestArgs,
  UpdateMemberColorsSettingsResponse,
  UpdateMemberDataRequestArgs,
  UpdateMemberDataResponse,
  UpdateMemberHashTagRequestArgs,
  UpdateMemberHashTagResponse,
  UpdateMemberRequestArgs,
  UpdateMemberResponse,
  UpdateMembersImageRequestArgs,
  UpdateMembersRequestArgs,
  UpdateMembersResponse,
} from "./membersTypes";

const membersService = createApi({
  reducerPath: "membersService",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllMembers: builder.mutation<GetAllMembersResponse, void>({
      query: () => APIConfigs.Members.RUD_MEMBERS,
      transformResponse: mapGetAllMembersResponse,
    }),
    updateMember: builder.mutation<
      UpdateMemberResponse,
      UpdateMemberRequestArgs
    >({
      query: ({ id, ...propsToUpdate }) => ({
        method: "PUT",
        url: APIConfigs.Members.UPDATE_A_MEMBER.replace(":id", id.toString()),
        body: mapToAPIMember(propsToUpdate),
      }),
      transformResponse: mapUpdateMemberResponse,
    }),
    lockMembers: builder.mutation<
      UpdateMembersResponse,
      UpdateMembersRequestArgs
    >({
      query: (ids) => ({
        method: "POST",
        url: APIConfigs.Members.BULK_UPDATE_MEMBERS,
        body: {
          user_ids: ids.toString(),
          op_type: "editable",
          status: "0",
        },
      }),
      transformResponse: mapLockUnlockMembersResponse,
    }),
    unlockMembers: builder.mutation<
      UpdateMembersResponse,
      UpdateMembersRequestArgs
    >({
      query: (ids) => ({
        method: "POST",
        url: APIConfigs.Members.BULK_UPDATE_MEMBERS,
        body: {
          user_ids: ids.toString(),
          op_type: "editable",
          status: "1",
        },
      }),
      transformResponse: mapLockUnlockMembersResponse,
    }),
    switchMembersVisibility: builder.mutation<
      UpdateMembersResponse,
      SwitchMembersVisibilityRequestArgs
    >({
      query: ({ ids, isPublic }) => ({
        method: "PUT",
        url: APIConfigs.Members.RUD_MEMBERS,
        body: {
          ids: ids.toString(),
          is_public: Number(isPublic),
        },
      }),
      transformResponse: mapSwitchMembersVisibilityResponse,
    }),
    switchDirectOpen: builder.mutation<
      UpdateMembersResponse,
      SwitchDirectOpenRequestArgs
    >({
      query: ({ ids, on }) => ({
        method: "PUT",
        url: APIConfigs.Members.RUD_MEMBERS,
        body: {
          ids: ids.toString(),
          open_direct: Number(on),
        },
      }),
      transformResponse: mapSwitchDirectOpenResponse,
    }),
    sendResetPasswordInvite: builder.mutation<
      UpdateMembersResponse,
      UpdateMembersRequestArgs
    >({
      query: (ids) => ({
        method: "POST",
        url: APIConfigs.Members.BULK_UPDATE_MEMBERS,
        body: {
          user_ids: ids.toString(),
          op_type: "reset_password_invite",
        },
      }),
      transformResponse: mapResetPasswordResponse,
    }),
    setProfilePicture: builder.mutation<
      UpdateMembersResponse,
      UpdateMembersImageRequestArgs
    >({
      query: ({ ids, file }) => {
        const body = new FormData();
        body.append("op_type", "profile_photo");
        body.append("user_ids", ids.toString());
        body.append("image", file);
        return {
          method: "POST",
          url: APIConfigs.Members.BULK_UPDATE_MEMBERS,
          body,
        };
      },
      transformResponse: mapSetProfilePictureResponse,
    }),
    setProfileBanner: builder.mutation<
      UpdateMembersResponse,
      UpdateMembersImageRequestArgs
    >({
      query: ({ ids, file }) => {
        const body = new FormData();
        body.append("op_type", "profile_banner");
        body.append("user_ids", ids.toString());
        body.append("image", file);
        return {
          method: "POST",
          url: APIConfigs.Members.BULK_UPDATE_MEMBERS,
          body,
        };
      },
      transformResponse: mapSetProfileBannerResponse,
    }),
    setCompanyLogo: builder.mutation<
      UpdateMembersResponse,
      UpdateMembersImageRequestArgs
    >({
      query: ({ ids, file }) => {
        const body = new FormData();
        body.append("op_type", "company_logo");
        body.append("user_ids", ids.toString());
        body.append("image", file);
        return {
          method: "POST",
          url: APIConfigs.Members.BULK_UPDATE_MEMBERS,
          body,
        };
      },
      transformResponse: mapSetCompanyLogoResponse,
    }),
    setCaptureLead: builder.mutation<
      SetCaptureLeadResponse,
      SetCaptureLeadRequestArgs
    >({
      query: ({ id, captureLead }) => ({
        method: "POST",
        url: APIConfigs.Members.UPDATE_A_MEMBER.replace(":id", id.toString()),
        body: captureLead
          ? { capture_lead: Number(captureLead), open_direct: 0 }
          : { capture_lead: Number(captureLead) },
      }),
      transformResponse: mapSetCaptureLeadResponse,
    }),
    setOpenDirect: builder.mutation<
      SetOpenDirectResponse,
      SetOpenDirectRequestArgs
    >({
      query: ({ id, openDirect }) => ({
        method: "POST",
        url: APIConfigs.Members.UPDATE_A_MEMBER.replace(":id", id.toString()),
        body: openDirect
          ? { open_direct: Number(openDirect), capture_lead: 0 }
          : { open_direct: Number(openDirect) },
      }),
      transformResponse: mapSetOpenDirectResponse,
    }),
    updateMemberData: builder.mutation<
      UpdateMemberDataResponse,
      UpdateMemberDataRequestArgs
    >({
      query: ({ id, ...memberDataToUpdate }) => ({
        method: "POST",
        url: APIConfigs.Members.UPDATE_A_MEMBER.replace(":id", id.toString()),
        body: getUpdateMemberDataRequestBody(memberDataToUpdate),
      }),
      transformResponse: mapUpdateMemberDataResponse,
    }),
    getMemberColorsSettings: builder.mutation<
      GetMemberColorsSettingsResponse,
      Member["id"]
    >({
      query: (memberId) =>
        APIConfigs.Members.READ_MEMBER_COLOR_SETTINGS.replace(
          ":memberId",
          memberId.toString()
        ),
      transformResponse: mapGetMemberColorsSettingsResponse,
    }),
    updateMemberColorsSettings: builder.mutation<
      UpdateMemberColorsSettingsResponse,
      UpdateMemberColorsSettingsRequestArgs
    >({
      query: (theme) => ({
        method: "POST",
        url: APIConfigs.Members.UPDATE_MEMBER_COLOR_SETTINGS,
        body: getUpdateMemberColorsSettingsFormData(theme),
      }),
      transformResponse: mapUpdateMemberColorsSettingsResponse,
    }),
    deleteMembers: builder.mutation<
      DeleteMembersResponse,
      DeleteMembersRequestArgs
    >({
      query: ({ ids, reason }) => {
        const body = new URLSearchParams();
        body.append("ids", ids.toString());
        body.append("reason", reason);
        return {
          method: "DELETE",
          url: APIConfigs.Members.RUD_MEMBERS,
          body,
        };
      },
    }),
    switchConnectButton: builder.mutation<
      UpdateMembersResponse,
      SwitchConnectButtonRequestArgs
    >({
      query: ({ ids, show }) => ({
        method: "PUT",
        url: APIConfigs.Members.RUD_MEMBERS,
        body: {
          ids: ids.toString(),
          connect_button: Number(show),
        },
      }),
      transformResponse: mapSwitchConnectButtonResponse,
    }),
    switchSaveContactButton: builder.mutation<
      UpdateMembersResponse,
      SwitchSaveContactButtonRequestArgs
    >({
      query: ({ ids, show }) => ({
        method: "PUT",
        url: APIConfigs.Members.RUD_MEMBERS,
        body: {
          ids: ids.toString(),
          save_contact_button: Number(show),
        },
      }),
      transformResponse: mapSwitchSaveContactButtonResponse,
    }),
    readMemberHashTags: builder.mutation<ReadMemberHashTagsResponse, void>({
      query: () => APIConfigs.MemberHashTags.CREATE_READ_DELETE_HASHTAGS,
      transformResponse: mapReadMemberHashTagsResponse,
    }),
    updateMemberHashTag: builder.mutation<
      UpdateMemberHashTagResponse,
      UpdateMemberHashTagRequestArgs
    >({
      query: ({ id, ...updatedHashTagData }) => ({
        method: "PUT",
        url: APIConfigs.MemberHashTags.UPDATE_HASHTAG.replace(
          ":id",
          id.toString()
        ),
        body: updatedHashTagData,
      }),
      transformResponse: mapUpdateMemberHashTagResponse,
    }),
    deleteMemberHashTags: builder.mutation<
      DeleteMemberHashTagsResponse,
      DeleteMemberHashTagsRequestArgs
    >({
      query: (ids) => ({
        method: "DELETE",
        url: APIConfigs.MemberHashTags.CREATE_READ_DELETE_HASHTAGS,
        body: { userIds: ids },
      }),
      transformResponse: mapDeleteMemberHashTagsResponse,
    }),
    createMemberHashTags: builder.mutation<
      CreateMemberHashTagsResponse,
      CreateMemberHashTagsRequestArgs
    >({
      query: ({ userIds, ...newHashTagData }) => ({
        method: "POST",
        url: APIConfigs.MemberHashTags.CREATE_READ_DELETE_HASHTAGS,
        body: { userIds, ...newHashTagData },
      }),
      transformResponse: mapCreateMemberHashTagsResponse,
    }),
  }),
});

export default membersService;
export const {
  useGetAllMembersMutation,
  useUpdateMemberMutation,
  useLockMembersMutation,
  useUnlockMembersMutation,
  useSwitchMembersVisibilityMutation,
  useSwitchDirectOpenMutation,
  useSendResetPasswordInviteMutation,
  useSetProfilePictureMutation,
  useSetProfileBannerMutation,
  useSetCompanyLogoMutation,
  useSetCaptureLeadMutation,
  useSetOpenDirectMutation,
  useUpdateMemberDataMutation,
  useGetMemberColorsSettingsMutation,
  useUpdateMemberColorsSettingsMutation,
  useDeleteMembersMutation,
  useSwitchConnectButtonMutation,
  useSwitchSaveContactButtonMutation,
  useReadMemberHashTagsMutation,
  useUpdateMemberHashTagMutation,
  useDeleteMemberHashTagsMutation,
  useCreateMemberHashTagsMutation,
} = membersService;
